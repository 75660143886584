import * as types from "./actionTypes"

const INIT_STATE = {
  menu: [],
  staffs: [],
  staff: {},
  staffQL: [],
  staffKD: [],
  error: {},
  companys: [],
  departments: [],
  positions: [],
  groups: [],
  groupsPermission: [],
}

const Staff = (state = INIT_STATE, action) => {
 
  switch (action.type) {
    case types.GET_STAFFS_SUCCESS:
      return {
        ...state,
        staffs: action.payload,
      }
    case types.GET_STAFFS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case types.GET_STAFF:
      const staff = state.staffs.find(item => {
        return item.maNV === Number(action.payload)
      })
      return {
        ...state,
        staff: staff,
      }
    case types.GET_STAFF_SAGA_SUCCESS:
      return {
        ...state,
        staffQL: action.payload,
      }
    case types.GET_STAFF_KD_SAGA_SUCCESS:
      return {
        ...state,
        staffKD: action.payload,
      }
    case types.DELETE_STAFF_SUCCESS:
      const staffDL = state.staffs.filter(item => {
        return item.maNV !== Number(action.payload.MaNV)
      })
      return {
        ...state,
        staffs: staffDL,
      }
    // case types.EDIT_STAFF_SUCCESS:
    //   // debugger
    //   // const editStaffs = [...state.staffs]
    //   state.staffs.filter((item, i) => {
    //     if (item.maNV === action.payload.MaNV) {
    //       return (item[i].hoTen = action.payload.HoTen)
    //     }
    //   })
    //   // const nv = editStaffs[action.payload.MaNV];
    //   // editStaffs[action.payload.MaNV].hoTen = action.payload.HoTen
    //   return { ...state }
    // case types.ADD_STAFF_SUCCESS:
    //   debugger
    //   const staffs = [...state.staffs, action.payload]
    //   return {
    //     ...state,
    //     staffs: staffs,
    //   }
    case types.GET_CATEGORIES_SUCCESS:
      // const staffs = [...state.staffs, action.payload]
      return {
        ...state,
        companys: action.payload.companys,
        departments: action.payload.departments,
        positions: action.payload.positions,
        groups: action.payload.groups,
        groupsPermission: action.payload.groupsPermission,
      }
    case types.GET_MENU_SUCCESS:
      return {
        ...state,
        menu: action.payload,
      }
    default:
      return state
  }
}

export default Staff
