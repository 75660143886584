import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Drawer, Row, Col, Input, DatePicker, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import SelectCustom from "components/Forms/SelectCustom"
import InputCustom from "components/Forms/InputCustom"
import DatePickerCustom from "components/Forms/DatePickerCustom"
const { Option } = Select

export const FormDrawerUser = props => {
  const { isEdit, isOpen, toggle, detail } = props
  const {
    status,
    quyDanh,
    mucDich,
    address,
    groupCustomer,
    ngheNghiep,
    nguonDen,
  } = useSelector(state => ({
    status: state.Customer.status,
    quyDanh: state.Customer.quyDanh,
    mucDich: state.Customer.mucDich,
    address: state.Customer.address,
    groupCustomer: state.Customer.groupCustomer,
    ngheNghiep: state.Customer.ngheNghiep,
    nguonDen: state.Customer.nguonDen,
  }))

  const [maTinh, setMaTinh] = useState(0)
  const [maHuyen, setMaHuyen] = useState(0)
  const [maTinh2, setMaTinh2] = useState(0)
  const [maHuyen2, setMaHuyen2] = useState(0)

  useEffect(() => {
    setMaTinh(detail?.maTinh)
    setMaHuyen(detail?.maHuyen)
    setMaTinh2(detail?.maTinh2)
    setMaHuyen2(detail?.maHuyen2)
  }, [detail])

  const dispatch = useDispatch()

  const handleValidOrderSubmit = values => {
    const insertAndUpdate = {
      MaKH: isEdit ? detail.maKH : null,
      TenKH: values.TenKH,
      MaSoKH: values.MaSoKH,
      NgaySinh: values.NgaySinh?.format("DD/MM/YYYY"),
      SoCMND: values.SoCMND,
      NgayCap: values.NgayCap?.format("DD/MM/YYYY"),
      NoiCap: values.NoiCap,
      DiDong: values.DiDong,
      DiDong2: values.DiDong2,
      Email: values.Email,
      Email2: values.Email2,
      PurposeID: Number(values.PurposeID),
      ThuongTru: values.ThuongTru,
      DiaChi: values.DiaChi,
      MaXa: Number(values.MaXa) === 0 ? null : Number(values.MaXa),
      MaHuyen: Number(values.MaHuyen) === 0 ? null : Number(values.MaHuyen),
      MaTinh: Number(values.MaTinh) === 0 ? null : Number(values.MaTinh),
      MaXa2: Number(values.MaXa2) === 0 ? null : Number(values.MaXa2),
      MaHuyen2: Number(values.MaHuyen2) === 0 ? null : Number(values.MaHuyen2),
      MaTinh2: Number(values.MaTinh2) === 0 ? null : Number(values.MaTinh2),
      MaNKH: Number(values.MaNKH),
      HowToKnowID: Number(values.HowToKnowID),
      MaNN: Number(values.MaNN),
      MaTT: Number(values.MaTT),
      MaQD: Number(values.MaQD),
    }
    // dispatch(addEditCustomer(insertAndUpdate))
    // dispatch(getCustomers({ inputSearch: "", Offset: 1, Limit: 10 }))
    toggle()
  }

  const getHuyen = () => {
    return address?.find(item => {
      return item.maTinh === maTinh
    })?.listHuyen
  }

  const getXa = () => {
    return getHuyen()?.find(item => {
      return item.maHuyen === maHuyen
    })?.listXa
  }

  const getHuyen2 = () => {
    return address?.find(item => {
      return item.maTinh === maTinh2
    })?.listHuyen
  }

  const getXa2 = () => {
    return getHuyen2()?.find(item => {
      return item.maHuyen === maHuyen2
    })?.listXa
  }

  const onChangeTinh = value => {
    setMaTinh(Number(value))
  }

  const onChangeTinh2 = value => {
    setMaTinh2(Number(value))
  }

  const onChangeHuyen = value => {
    setMaHuyen(Number(value))
  }

  const onChangeHuyen2 = value => {
    setMaHuyen2(Number(value))
  }

  const onClickClose = () => {
    toggle()
  }

  const applyChange = (field, value) => {
    // Update detail state or other state as needed
    // Example:
    // setDetail(prev => ({ ...prev, [field]: value }));
  }

  return (
    <Drawer
      title={!!isEdit ? "Sửa khách hàng" : "Thêm khách hàng"}
      visible={isOpen}
      onClose={onClickClose}
      width={920}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClickClose} style={{ marginRight: 8 }}>
            Close
          </Button>
          <Button
            onClick={() => {
              document
                .getElementById("customerForm")
                .dispatchEvent(
                  new Event("submit", { cancelable: true, bubbles: true })
                )
            }}
            type="primary"
          >
            Save changes
          </Button>
        </div>
      }
    >
    
      <Row gutter={[20, 16]}>
        <Col span={8}>
          <SelectCustom
            label="Quý danh"
            value={detail?.maQD}
            prop="maQD"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              quyDanh?.map(item => ({ label: item.tenQD, value: item.maQD })) ||
              []
            }
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Họ tên"
            value={detail?.tenKH}
            prop="tenKH"
            applyChange={applyChange}
            placeholder="Nhập họ tên"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Mã số KH"
            value={detail?.maSoKH}
            prop="maSoKH"
            applyChange={applyChange}
            placeholder="Nhập mã số"
          />
        </Col>
        <Col span={8}>
          <DatePickerCustom
            label="Ngày sinh:"
            applyChange={applyChange}
            prop={"NgaySinh"}
            value={detail?.NgaySinh}
            placeholder={"Nhập ngày sinh"}
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Số CMND"
            value={detail?.soCMND}
            prop="soCMND"
            applyChange={applyChange}
            placeholder="Nhập số CMND"
          />
        </Col>
        <Col span={8}>
          <DatePickerCustom
            label="Ngày cấp:"
            applyChange={applyChange}
            prop={"NgapCap"}
            value={detail?.ngayCap}
            placeholder={"Nhập ngày cấp"}
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Nơi cấp"
            value={detail?.noiCap}
            prop="noiCap"
            applyChange={applyChange}
            placeholder="Nhập nơi cấp"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Di động"
            value={detail?.diDong}
            prop="diDong"
            applyChange={applyChange}
            placeholder="Nhập số đi động"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Di động 2"
            value={detail?.diDong2}
            prop="diDong2"
            applyChange={applyChange}
            placeholder="Nhập số đi động 2"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Email"
            value={detail?.email}
            prop="email"
            applyChange={applyChange}
            placeholder="Nhập email"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Email 2"
            value={detail?.email2}
            prop="email2"
            applyChange={applyChange}
            placeholder="Nhập email 2"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Địa chỉ"
            value={detail?.diaChi}
            prop="diaChi"
            applyChange={applyChange}
            placeholder="Nhập địa chỉ"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Thường trú"
            value={detail?.thuongTru}
            prop="thuongTru"
            applyChange={applyChange}
            placeholder="Nhập địa chỉ thường trú"
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Nhóm KH"
            value={detail?.maNKH}
            prop="maNKH"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              groupCustomer?.map(item => ({
                label: item.tenNKH,
                value: item.maNKH,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Nghề nghiệp"
            value={detail?.maNN}
            prop="maNN"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              ngheNghiep?.map(item => ({
                label: item.tenNN,
                value: item.maNN,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Mục đích"
            value={detail?.purposeID}
            prop="purposeID"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              mucDich?.map(item => ({
                label: item.tenMD,
                value: item.purposeID,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Nguồn đến"
            value={detail?.howToKnowID}
            prop="howToKnowID"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              nguonDen?.map(item => ({
                label: item.tenHTK,
                value: item.howToKnowID,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Tỉnh/Thành phố"
            value={detail?.maTinh}
            prop="maTinh"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              address?.map(item => ({
                label: item.tenTinh,
                value: item.maTinh,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Quận/Huyện"
            value={detail?.maHuyen}
            prop="maHuyen"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getHuyen()?.map(item => ({
                label: item.tenHuyen,
                value: item.maHuyen,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Xã/Phường"
            value={detail?.maXa}
            prop="maXa"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getXa()?.map(item => ({ label: item.tenXa, value: item.maXa })) ||
              []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Tỉnh/Thành phố (HK)"
            value={detail?.maTinh2}
            prop="maTinh2"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              address?.map(item => ({
                label: item.tenTinh,
                value: item.maTinh,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Quận/Huyện (HK)"
            value={detail?.maHuyen2}
            prop="maHuyen2"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getHuyen2()?.map(item => ({
                label: item.tenHuyen,
                value: item.maHuyen,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Xã/Phường (HK)"
            value={detail?.maXa2}
            prop="maXa2"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getXa2()?.map(item => ({
                label: item.tenXa,
                value: item.maXa,
              })) || []
            }
          />
        </Col>
      </Row>

      {/* <div
        style={{
          textAlign: "right",
          marginTop: 16,
        }}
      >
        <Button onClick={onClickClose} style={{ marginRight: 8 }}>
          Close
        </Button>
        <Button
          onClick={() => {
            document
              .getElementById("customerForm")
              .dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              )
          }}
          type="primary"
        >
          Save changes
        </Button>
      </div> */}
    </Drawer>
  )
}

FormDrawerUser.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  detail: PropTypes.object,
}

export default FormDrawerUser
