import React, { useState, useImperativeHandle, forwardRef } from "react"
import {
  Drawer,
  Input,
  Row,
  Col,
  Button,
  Space,
  notification,
  Select,
  Checkbox,
  InputNumber,
} from "antd"
import _ from "lodash"
import { CongTyService } from "services/CongTyService"
import { PermissionService } from "services/PermissionService"
import { NhanVienService } from "services/NhanVienService"
const FormAdd = (props, ref) => {
  const defaultData = { Server: "beesky" }
  const { onAfterSubmit } = props
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState(defaultData)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState("0")
  const [nhommodules, setNhommodules] = useState([])
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const loadCategories = async () => {
    let _res = await CongTyService.NhomModules.Get()
    setNhommodules(_res.data ?? [])
  }
  useImperativeHandle(ref, () => ({
    show: async tab => {
      setOpen(true)
      setActiveTab(tab)
      loadCategories()
    },
    update: async (data, tab) => {
      setOpen(true)
      setData(data)
      setActiveTab(tab)
      loadCategories()
    },
  }))
  const onClose = () => {
    setOpen(false)
    setData(defaultData)
    onAfterSubmit()
  }
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data)
    switch (p) {
      default:
        _data[p] = v
        break
    }
    setData(_data)
  }
  const onSubmit = async () => {
    setLoading(true)
    let _data = _.cloneDeep(data)

    if (activeTab === "0") {
      let _res = await CongTyService.addCongTy(_data)
      if (_res.status === 2000) {
        let _resPer = await PermissionService.addNhomQuyen({
          PerName: "admin",
          Description: "admin",
          MaCTDK: _res.data,
          Server: _data.Server,
        })
        let _resCN = await CongTyService.addChiNhanh({
          TenCTVT: _data.MaCT,
          TenCT: _data.TenCT,
          DiaChi: _data.DiaChi,
          Email: _data.Email1,
          DienThoai: _data.DienThoai1,
          MaCTDK: _res.data,
          Server: _data.Server,
        })
        if (_resPer.status === 2000) {
          let _n = await PermissionService.TheoNhomModules.getFormByMaNhom({
            MaNhom: _data.MaNhomSP,
          })
          _n?.data?.map(item => {
            PermissionService.addAccessData({
              HavePer: true,
              PerID: _resPer.data,
              FormID: item.FormID,
              SDBID: 1,
              Server: _data.Server,
            })
            PermissionService.TheoCongTy.addForm({
              MaCTDK: _res.data,
              Server: _data.Server,
              FormID: item.FormID,
              HavePer: true,
            })
          })
          // await PermissionService.addAccessData({
          //   HavePer: true,
          //   PerID: _resPer.data,
          //   FormID: 62,
          //   SDBID: 1,
          //   Server: _data.Server,
          // });
          await NhanVienService.addNhanVien({
            HoTen: "admin",
            MaSo: _data.TaiKhoan,
            Password: _data.MatKhau,
            PerID: _resPer.data,
            MaCT: _resCN.data,
            NgayCap: new Date(),
            NgaySinh: new Date(),
            MaCTDK: _res.data,
            Email: "",
            DienThoai: "",
            Server: _data.Server,
          })
        }
        openNotification("success", "topRight", `Thêm công ty thành công!`)
        onClose()
      } else {
        openNotification("error", "topRight", _res?.message)
      }
    } else {
      let _res = await CongTyService.NhomModules.Add(_data)
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Thêm công ty thành công!`)
        onClose()
      } else {
        openNotification("error", "topRight", _res?.message)
      }
    }
    setLoading(false)
  }
  const renderTab1 = () => {
    return (
      <Row gutter={[15, 10]} style={{ marginTop: 20 }}>
        <Col span={8}>
          <p>Mã công ty</p>
          <Input
            placeholder="Nhập tên nhóm quyền"
            onChange={v => applyChange("MaCT", v.target.value)}
            value={data.MaCT}
          />
        </Col>
        <Col span={16}>
          <p>Tên công ty</p>
          <Input
            placeholder="Nhập tên nhóm quyền"
            onChange={v => applyChange("TenCT", v.target.value)}
            value={data.TenCT}
          />
        </Col>
        <Col span={12}>
          <p>Điện thoại</p>
          <Input
            placeholder="Nhập số điện thoại"
            onChange={v => applyChange("DienThoai1", v.target.value)}
            value={data.DienThoai1}
          />
        </Col>
        <Col span={12}>
          <p>Email</p>
          <Input
            placeholder="Nhập email"
            onChange={v => applyChange("Email1", v.target.value)}
            value={data.Email1}
          />
        </Col>
        <Col span={24}>
          <p>Địa chỉ</p>
          <Input
            placeholder="Nhập địa chỉ"
            onChange={v => applyChange("DiaChi", v.target.value)}
            value={data.DiaChi}
          />
        </Col>
        <Col span={12}>
          <p>Tài khoản</p>
          <Input
            placeholder="Tài khoản"
            onChange={v => applyChange("TaiKhoan", v.target.value)}
            value={data.TaiKhoan}
          />
        </Col>
        <Col span={12}>
          <p>Mật khẩu</p>
          <Input
            placeholder="Nhập mật khẩu"
            onChange={v => applyChange("MatKhau", v.target.value)}
            value={data.MatKhau}
          />
        </Col>
        <Col span={12}>
          <p>Server</p>
          <Select
            defaultValue={1}
            onChange={v => applyChange("Server", v)}
            style={{
              width: "100%",
            }}
            options={[
              { ID: "beesky", Name: "Beesky" },
              { ID: "iec", Name: "IEC" },
            ]}
            value={data?.Server}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={12}>
          <p>Nhóm modules</p>
          <Select
            // defaultValue={1}
            onChange={v => applyChange("MaNhomSP", v)}
            style={{
              width: "100%",
            }}
            options={nhommodules}
            value={data?.MaNhomSP}
            fieldNames={{ label: "TenSanPham", value: "ID" }}
          />
        </Col>
      </Row>
    )
  }
  const renderTab2 = () => {
    return (
      <Row gutter={[15, 10]} style={{ marginTop: 20 }}>
        <Col span={24}>
          <p>Tên sản phẩm</p>
          <Input
            placeholder="Nhập tên sản phẩm"
            onChange={v => applyChange("TenSanPham", v.target.value)}
            value={data.TenSanPham}
          />
        </Col>
        <Col span={24}>
          <p>Số tiền / tháng</p>
          <InputNumber
            suffix="vnđ"
            style={{ width: "100%" }}
            value={data?.SoTienTrenThang}
            onChange={v => applyChange("SoTienTrenThang", v)}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={24}>
          <Checkbox
            checked={data?.ApDung}
            onChange={v => applyChange("ApDung", !data?.ApDung)}
          >
            Áp dụng
          </Checkbox>
        </Col>
      </Row>
    )
  }
  return (
    <Drawer
      title="Thêm công ty"
      width={activeTab == "0" ? 900 : 500}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
      extra={
        <Space>
          {/* <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button> */}
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {activeTab == "0" ? renderTab1() : renderTab2()}
      {contextHolder}
    </Drawer>
  )
}
export default forwardRef(FormAdd)
