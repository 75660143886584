import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation"
import {
  Format_Currency,
  formatNumberPT,
} from "../../common/common"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-datepicker/dist/react-datepicker.css"
import Breadcrumbs from "../../components/Common/Breadcrumb3"
import {
  getCustomers,
  getCustomer,
  addKhachHang,
  getSanGiaoDich,
} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import {withRouter, useHistory, useLocation } from "react-router-dom"

const FormGiuCho = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [selectKHValue, setSelectKHValue] = useState(null)
  const [selectKHOption, setSelectKHOption] = useState([])
  const [maTinh, setMaTinh] = useState(0)
  const [maHuyen, setMaHuyen] = useState(0)
  const [maXa, setMaXa] = useState(0)

  const { address, customers, customer, dataEdit, listSanGiaoDich } =
    useSelector(state => ({
      address: state.Agency.address,
      customers: state.Customer.customers,
      customer: state.Customer.customer,
      dataEdit: state.Agency.dataEdit,
      listSanGiaoDich: state.Common.listSanGiaoDich,
    }))
  useEffect(() => {
    dispatch(getCustomer({ MaKH: 0 }));
    dispatch(getSanGiaoDich())
  }, [])
  const handleValidOrderSubmit = (e, values) => {
    const addKH = {
      TenKH: values.TenKH,
      DiDong: values.DiDong,
      DiaChi: values.DiaChi,
      Email: values.Email,
      SoCMND: values.SoCMND,
      MaXa: 0,//quanlykinhdoanhNumber(values.MaXa) === 0 ? null : Number(values.MaXa),
      MaHuyen:0, //Number(values.MaHuyen) === 0 ? null : Number(values.MaHuyen),
      MaTinh:0,// Number(values.MaTinh) === 0 ? null : Number(values.MaTinh),
    }
    
    const dataBK = {
      MaSP: location.state[0].maSP,
      MaSan: location.state[0].maSan,
      MaDA: location.state[0].maDA,
      MaKH:0,
      TongGiaGomPBT:
        location.type === 2
          ? location.state[0].tongGiaTriHDMB
          : location.state[0].tongGiaGomPBT,

      DTThongThuy: location.state[0].dienTichTT
        ? location.state[0].dienTichTT
        : 0,
      DonGiaTT: location.state[0].donGiaTT ? location.state[0].donGiaTT : 0,
      TongGiaGomVAT: location.state[0].tongGiaGomVAT
        ? location.state[0].tongGiaGomVAT
        : 0,
      PhiBaoTri: location.state[0].tienPBT ? location.state[0].tienPBT : 0,

      DienTichDat: location.state[0].dienTichDat
        ? location.state[0].dienTichDat
        : 0,
      DonGiaDat: location.state[0].donGiaDat ? location.state[0].donGiaDat : 0,
      TongGiaDat: location.state[0].tongGiaTriDat
        ? location.state[0].tongGiaTriDat
        : 0,
      DienTichXD: location.state[0].dienTichXD
        ? location.state[0].dienTichXD
        : 0,
      DonGiaXD: location.state[0].donGiaXD ? location.state[0].donGiaXD : 0,
      ThanhTienXD: location.state[0].thanhTienXDCoVAT
        ? location.state[0].thanhTienXDCoVAT
        : 0,
    }
    dispatch(addKhachHang({ dataKH: addKH, dataBoking: dataBK, history,type: location.type }))
  }
  const onChangeTinh = e => {
   // setMaTinh(Number(e.target.value))
  }
  const onChangeHuyen = e => {
    setMaHuyen(Number(e.target.value))
  }
  const onChangeXa = e => {
    setMaXa(Number(e.target.value))
  }
  const changeSearchKH = e => {
    setSelectKHValue(e)
  }
  useEffect(() => {
    let arr = []
    if (customers != "[]") {
      customers?.map(item => {
        arr.push({
          label: `${item.tenKH} - ${item.diDong} - ${item.email}`,
          value: item.maKH,
          tenKH: item.tenKH,
          diDong: item.diDong,
          email: item.email,
          diaChi: item.diaChi,
        })
      })
      setSelectKHOption(arr)
    }
  }, [customers])
  const onKeyDownSearchKH = e => {
    dispatch(
      getCustomers({
        inputSearch: e,
        MaTT: 0,
        MaNVCS: 0,
        MaNKH: 0,
        Offset: 1,
        Limit: 10,
      })
    )
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Giữ chỗ | Beesky</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg="7">
            {/* <Card> */}
            {/* <CardBody> */}
            <AvForm onValidSubmit={handleValidOrderSubmit}>
              <Breadcrumbs
                title="Đất nền biệt thự"
                route="/thaptang"
                breadcrumbItem="Thêm giữ chỗ"
              />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Col md="12">
                          <Label>Khách hàng</Label>
                          <Select
                            name="MaKH"
                            value={selectKHValue}
                            onChange={e => changeSearchKH(e)}
                            options={selectKHOption}
                            isLoading={true}
                            onInputChange={e => onKeyDownSearchKH(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="6">
                          <AvField
                            name="TenKH"
                            label="Tên khách hàng"
                            type="text"
                            value={selectKHValue?.tenKH || ""}
                          />
                        </Col>
                        <Col md="6">
                          <AvField
                            name="SoCMND"
                            label="Số CMND"
                            type="text"
                            value={selectKHValue?.soCMND || ""}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="DiDong"
                            label="Di động"
                            type="number"
                            value={selectKHValue?.diDong || null}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="Email"
                            label="Email"
                            type="email"
                            value={selectKHValue?.email || null}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="DiaChi"
                            label="Địa chỉ"
                            type="text"
                            value={selectKHValue?.diaChi || null}
                          />
                        </Col>
                      </FormGroup>
                      {/* <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="MaTinh"
                            label="Tỉnh"
                            type="select"
                            className="form-select"
                            value={dataEdit?.maTinh || 0}
                            onChange={e => onChangeTinh(e)}
                          >
                            <option value={0}>Chọn</option>
                            {address &&
                              address.map(item => {
                                return (
                                  <option
                                    key={item.maTinh}
                                    value={Number(item.maTinh)}
                                  >
                                    {item.tenTinh}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaHuyen"
                            type="select"
                            label="Huyện"
                            className="form-select"
                            value={dataEdit?.maHuyen || null}
                            onChange={e => onChangeHuyen(e)}
                          >
                            <option value={0}>Chọn</option>
                            {getHuyen() &&
                              getHuyen().map(item => {
                                return (
                                  <option
                                    key={item.maHuyen}
                                    value={Number(item.maHuyen)}
                                  >
                                    {item.tenHuyen}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaXa"
                            label="Xã"
                            type="select"
                            className="form-select"
                            value={maXa}
                            onChange={e => onChangeXa(e)}
                          >
                            <option value={0}>Chọn</option>
                            {getXa() &&
                              getXa().map(item => {
                                return (
                                  <option
                                    key={item.MaXa}
                                    value={Number(item.MaXa)}
                                  >
                                    {item.TenXa}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                      </FormGroup> */}
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="MaSan"
                            label="Sàn giao dịch"
                            type="select"
                            className="form-select"
                            value={location.state[0]?.maSan}
                            onChange={e => onChangeTinh(e)}
                          >
                            <option value={0}>Chọn</option>
                            {listSanGiaoDich &&
                              listSanGiaoDich.map(item => {
                                return (
                                  <option key={item.ID} value={Number(item.ID)}>
                                    {item.TenCT}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </Col>
          <Col lg="3">
            <div className="rounded border-0 shadow-lg bg-white">
              <div>
                <img
                  className="rounded-top"
                  style={{ width: "100%" }}
                  src="https://quanlykinhdoanh.com.vn/httpdocs/Upload/REM/DEMO41/documents/sanpham/2021/12/17/banner-15132841859786129866.jpg"
                />
                <h5 className="text-center fw-bold mt-2">
                  {location.state[0]?.tenDA}
                </h5>
              </div>
              <div className="row p-3" style={{ lineHeight: "200%" }}>
                {location.type === 2 ? (
                  <>
                    <Col lg="7">Mã sản phẩm</Col>
                    <Col lg="5" className="fw-bold">
                      {location.state[0]?.maSanPham}
                    </Col>
                    <Col lg="7">Hướng</Col>
                    <Col lg="5" className="fw-bold">
                      {location.state[0]?.huongNha}
                    </Col>
                    <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7">Diện tích đất</Col>
                    <Col className="fw-bold" lg="5">
                      {location.state[0]?.dienTichDat} m2
                    </Col>
                    <Col lg="7">Đơn giá đất</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.donGiaDat)}
                    </Col>
                    <Col lg="7">Thành tiền đất</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.tongGiaTriDat)}
                    </Col>
                    <Col lg="7">Diện tích XD</Col>
                    <Col lg="5" className="fw-bold">
                      {location.state[0]?.dienTichXD} m2
                    </Col>
                    <Col lg="7">Đơn giá XD</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.donGiaXD)}
                    </Col>
                    <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7">Thành tiền XD có VAT</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.thanhTienXDCoVAT)}
                    </Col>
                    <Col lg="7">VAT</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.tienVATXD)}
                    </Col>
                    <hr className=" mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7" className="fw-bold">
                      Tổng giá trị HĐ
                    </Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.tongGiaTriHDMB)}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg="7">Mã sản phẩm</Col>
                    <Col lg="5" className="fw-bold">
                      {location.state[0]?.maSanPham}
                    </Col>
                    <Col lg="7">Hướng</Col>
                    <Col lg="5" className="fw-bold">
                      {location.state[0]?.huongBC}
                    </Col>
                    <Col lg="7">Tầng</Col>
                    <Col lg="5" className="fw-bold">
                      {location.state[0]?.tang}
                    </Col>
                    <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7">Diện tích thông thủy</Col>
                    <Col className="fw-bold" lg="5">
                      {location.state[0]?.dienTichTT} m2
                    </Col>
                    <Col lg="7">Đơn giá thông thủy</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.donGiaTT)}
                    </Col>
                    <Col lg="7">Thành tiền gổm VAT</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.tongGiaGomVAT)}
                    </Col>
                    <Col lg="7">Phí bảo trì</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.tienPBT)}
                    </Col>

                    <hr className=" mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7" className="fw-bold">
                      Tổng giá gồm PBT
                    </Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(location.state[0]?.tongGiaGomPBT)}
                    </Col>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(FormGiuCho)
