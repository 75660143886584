import * as types from "./actionTypes"

export const getCustomers = payload => ({
  type: types.GET_CUSTOMERS,
  payload,
})
export const getCustomer = payload => ({
  type: types.GET_CUSTOMER,
  payload,
})

export const addEditCustomer = payload => ({
  type: types.ADD_EDIT_CUSTOMER,
  payload,
})
export const getCategoriesCustomer = () => ({
  type: types.GET_CATEGORIES_CUSTOMER,
})

export const deleteCustomer = (payload) => ({
  type: types.DELETE_CUSTOMER,
  payload
})
export const deleteCustomerSuccess = payload => ({
  type: types.DELETE_CUSTOMER_SUCCESS,
  payload,
})


export const getGhiChuCustomer = payload => ({
  type: types.GET_GHICHU_CUSTOMER,
  payload,
})
export const addGhiChuCustomer = payload => ({
  type: types.ADD_GHICHU_CUSTOMER,
  payload,
})
