import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { getHexColor, handleValidDate } from "../../common/common"
// import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
  getDuAn,
  getKhu,
  getViTriBG,
  getTangBG,
  getKhuChiTietBG,
} from "../../store/actions"
const localization = {
  allItemsAreSelected: "Đã chọn tất cả.",
  clearSearch: "Clear Search",
  noOptions: "Không có dự án",
  search: "Tìm kiếm",
  selectAll: "Tất cả",
  selectAllFiltered: "Tất cả(Đã lọc)",
  selectSomeItems: "Dự án...",
}
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import _ from "lodash"
import { Form, Tabs } from "antd"
import DetailProduct from "./DetailProduct"
const BangGiaTrucTuyen = props => {
  // const navigate = useNavigate()

  const { TabPane } = Tabs

  const [hubConnection, setHubConnection] = useState(null)

  const dispatch = useDispatch()
  const {
    listBangGiaTT,
    listKhu,
    listDuAn,
    listTangBG,
    listViTriBG,
    isLoadFilter,
    listKhuChiTiet,
  } = useSelector(state => ({
    listBangGiaTT: state.HopDong.listBangGiaTT,
    isLoadFilter: state.SanPham.isLoadFilter,
    listKhu: state.Common.listKhu,
    listDuAn: state.Agency.duAn,
    listTangBG: state.HopDong.listTangBG,
    listViTriBG: state.HopDong.listViTriBG,
    listKhuChiTiet: state.HopDong.listKhuChiTiet,
  }))
  const [textSearch, setTextSearch] = useState("")
  const [data, setData] = useState([])
  const [maKhu, setMaKhu] = useState(0)
  const [maDuAn, setMaDuAn] = useState(-1)
  useEffect(() => {
    if (listViTriBG.length < 10) {
      let tb = document.querySelector(".table-banggiaTT")
      if (listViTriBG.length > 0) {
        tb.style.width = listViTriBG.length * 100 + "px"
      } else {
        tb.style.width = "150px"
      }
    } else {
      let tb = document.querySelector(".table-banggiaTT")
      tb.style.width = "auto"
    }
  }, [listViTriBG])
  useEffect(() => {
    setMaKhu(listKhu[0]?.MaKhu)
  }, [listKhu])
  useEffect(() => {
    setMaDuAn(listDuAn[0]?.MaDA)
  }, [listDuAn])
  useEffect(() => {
    if (maDuAn) dispatch(getKhu({ MaDA: maDuAn }))
  }, [maDuAn])
  useEffect(() => {
    if (maKhu) {
      dispatch(getViTriBG({ MaKhu: maKhu }))
      dispatch(getTangBG({ MaKhu: maKhu }))
      dispatch(getKhuChiTietBG({ MaKhu: maKhu }))
    }
  }, [maKhu])
  const [change, setChange] = useState({})
  const Columns = useCallback(() => {
    let columns = [
      {
        dataField: "stt",
        text: (
          <React.Fragment>
            <p>Phòng ngủ</p>
            <p>Phương hướng</p>
            <p>S Thông thủy</p>
          </React.Fragment>
        ),
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.stt}
          </Link>
        ),
      },
    ]
    listViTriBG.map(item => {
      var objcl = {
        dataField: "col" + item.maVT,
        text: (
          <React.Fragment>
            <p>{item.detailVT[0]?.PhongNgu}</p>
            <p>{item.detailVT[0]?.TenPhuongHuong}</p>
            <p>{item.detailVT[0]?.DTThongThuy}</p>
          </React.Fragment>
        ),
        sort: true,
      }
      columns.push(objcl)
    })
    return columns
  }, [listViTriBG])
  const refDetailSanPham = useRef()
  const handleDetail = data => {
    refDetailSanPham.current.show(data)
  }
  useEffect(() => {
    let lstData = []
    listTangBG.map(item => {
      let obj = {
        stt: (
          <React.Fragment>
            <p className="mb-0" style={{ padding: "5px 10px", width: "100%" }}>
              {item.tenTang} ({item.soLuongCon})
            </p>
          </React.Fragment>
        ),
      }
      item.detailFloor.map((item2, i) => {
        let tableaa = "col" + item2.MaVT
        let colValue = "col-value" + item2.MaVT
        obj[tableaa] = (
          <div
            className="dropdown"
            style={{
              padding: "5px 10px",
              width: "100%",
              backgroundColor: getHexColor(item2.MauNen),
            }}
            onClick={() => handleDetail(item2)}
          >
            <p className="mb-0 " style={{ color: "black", cursor: "pointer" }}>
              {item2.KyHieu}
            </p>
            <div
              className="dropdown-content"
              style={{ right: i < 10 ? "-250px" : "20px" }}
            >
              <DropBangGia data={item2} />
            </div>
          </div>
        )
        obj[colValue] = item2
      })
      obj.maTang = item.maTang
      lstData.push(obj)
    })
    setData(lstData)
  }, [listTangBG])
  useEffect(() => {
    ;(async () => {
      let connect = new HubConnectionBuilder()
        .withUrl("https://api-client.mybeeland.com/signalr-beeland")
        .configureLogging(LogLevel.Information)
        .build()
      await connect.start()
      setHubConnection(connect)
    })()
  }, [])

  useEffect(() => {
    if (hubConnection) {
      let _data = _.cloneDeep(data)
      hubConnection.on("ChangeTable", async response => {
        if (maKhu === response.data?.MaKhu) {
          _data.map((item, i) => {
            if (
              item["col" + response.data?.MaVT] &&
              item.maTang === response.data?.MaTang
            ) {
              let valueCol = item["col-value" + response.data?.MaVT]
              item["col" + response.data?.MaVT] = (
                <div
                  className="dropdown"
                  style={{
                    backgroundColor: "yellow",
                    padding: "5px 10px",
                    width: "100%",
                  }}
                  onClick={() => handleDetail(valueCol)}
                >
                  <div className="dropbtn">
                    <p className="mb-0" style={{ color: "black" }}>
                      {valueCol?.KyHieu}
                    </p>
                  </div>
                  <div
                    className="dropdown-content"
                    style={{ right: i < 10 ? "-250px" : "20px" }}
                  >
                    <DropBangGia data={valueCol} />
                  </div>
                </div>
              )
              setChange({ KyHieu: valueCol?.KyHieu, MauNen: response?.mauNen })
            }
          })
          setData(_data)
        }
      })
    }
  }, [hubConnection, data])
  useEffect(() => {
    let _time = null
    if (change.KyHieu) {
      _time = setTimeout(() => {
        let nodes = document.querySelectorAll("td .dropdown")
        let a = [...nodes]
        let node = a?.find(v => v.innerText === change.KyHieu)
        node.style.backgroundColor = getHexColor(change.MauNen)
        node.style.padding = "5px 10px"
        node.style.width = "100%"
        setChange({})
      }, 1000)
    }
    return () => {
      clearTimeout(_time)
    }
  }, [change])
  useEffect(() => {
    dispatch(getDuAn())
  }, [])
  const onChangeTextSearch = e => {
    setTextSearch(e.target.value)
  }
  const duAnOnChange = v => {
    setMaDuAn(Number(v.target.value))
    dispatch(getKhu({ MaDA: Number(v.target.value) }))
  }
  const khuOnChange = v => {
    setMaKhu(v)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Bảng giá trực tuyến | Beesky</title>
        </MetaTags>
        <Container fluid>
          <Row className="">
            <AvForm className="col-md-2">
              <Row>
                <Col>
                  <AvField
                    name="MaDA"
                    type="select"
                    className="form-select"
                    onChange={v => duAnOnChange(v)}
                    value={maDuAn}
                  >
                    {listKhu &&
                      listDuAn.map(item => {
                        return (
                          <option key={item.MaDA} value={Number(item.MaDA)}>
                            {item.TenDA}
                          </option>
                        )
                      })}
                  </AvField>
                </Col>
              </Row>
            </AvForm>

            <Col sm="8">
              <div className="text-sm-start">
                {listKhu &&
                  listKhu.map(item => {
                    return (
                      <Button
                        key={item.MaKhu}
                        type="button"
                        outline
                        style={{
                          borderColor: maKhu === item.MaKhu ? "red" : "",
                          fontWeight: maKhu === item.MaKhu ? "bold" : "",
                          color: "black",
                        }}
                        className=" me-2"
                        onClick={() => khuOnChange(item.MaKhu)}
                      >
                        {props.t(item.TenKhu)}
                      </Button>
                    )
                  })}
              </div>
            </Col>
          </Row>
          <div className="progress" style={{ height: "5px" }}>
            <div
              className="progress-bar progress-bar-striped bg-danger"
              role="progressbar"
              style={{
                width: `${
                  (listKhuChiTiet[0]?.DaBan / listKhuChiTiet[0]?.Tong) * 100
                }%`,
              }}
              // aria-valuenow="30"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>{" "}
          <div className="table-scrollable table-banggiaTT">
            <BootstrapTable
              keyField="MaSP"
              data={data === "[]" ? new Array([]) : data}
              columns={Columns()}
              responsive
              bordered={true}
              classes={
                "table align-middle  table-nowrap table-check table-hover table-fixed "
              }
              headerWrapperClasses={"table-light"}
            />
          </div>
        </Container>
        <DetailProduct ref={refDetailSanPham} />
      </div>
    </React.Fragment>
  )
}

BangGiaTrucTuyen.propTypes = {
  customers: PropTypes.array,
  onGetPermision: PropTypes.func,
  t: PropTypes.any,
}
const DropBangGia = props => {
  const {
    PhongNgu,
    KyHieu,
    TongGiaGomPBTView,
    TenPhuongHuong,
    Tang,
    MuaNgay,
    ThoiHanMua,
  } = props.data
  const history = useHistory()
  const muaNgayClick = data => {
    history.push("/banggiatructuyen/addgiucho/" + data.MaSP)
    // history.push({
    //   pathname: "/banggiatructuyen/addgiucho",
    //   type: 1,
    //   state: data,
    // })
  }
  const chiTietClick = data => {
    history.push({
      pathname: "/sanpham-chitiet",
      type: 2,
      state: data,
    })
  }

  return (
    <div style={{ textAlign: "left" }}>
      <Row
        style={{
          // backgroundColor: MuaNgay === 0 ? "red" : "rgb(50, 205, 50)",
          backgroundColor: "#556ee6",
          color: "white",
          padding: "10px 10px 0px",
          margin: 0,
        }}
      >
        <Col xs="6">
          <p style={{ marginBottom: "3px" }}>Mã sản phẩm</p>
          <p style={{ marginBottom: "3px" }}>{KyHieu}</p>
        </Col>
        <Col xs="6">
          <p style={{ marginBottom: "3px" }}>Giá niêm yết</p>
          <p style={{ marginBottom: "0px" }}>{TongGiaGomPBTView}</p>
        </Col>
      </Row>
      <Row style={{ padding: "2px 10px" }}>
        <Col xs="6">Hướng</Col>
        <Col xs="6"> {TenPhuongHuong}</Col>
      </Row>
      <Row style={{ padding: "2px 10px" }}>
        <Col xs="6">Phòng ngủ</Col>
        <Col xs="6">{PhongNgu}</Col>
      </Row>
      <Row style={{ padding: "2px 10px" }}>
        <Col xs="6">Tầng</Col>
        <Col xs="6"> {Tang}</Col>
      </Row>
      {MuaNgay === 0 && (
        <Row style={{ padding: "10px" }}>
          <Col xs="6">Thời hạn đặt mua:</Col>
          <Col xs="6">{handleValidDate(ThoiHanMua)}</Col>
        </Row>
      )}
      {/* {MuaNgay === 0 ? (
        <Row style={{ padding: "10px" }}>
          <Col xs="6">Thời hạn đặt mua:</Col>
          <Col xs="6">{handleValidDate(ThoiHanMua)}</Col>
        </Row>
      ) : (
        <Row>
          <Col xs="6" style={{ marginRight: "0px", paddingRight: "0px" }}>
            <Button
              style={{
                width: "100%",
                backgroundColor: "#34c38f",
                borderRadius: "0",
                borderWidth: "0",
              }}
              onClick={() => chiTietClick(props.data)}
            >
              Chi tiết
            </Button>
          </Col>
          <Col xs="6" style={{ marginLeft: "0px", paddingLeft: "0px" }}>
            <Button
              style={{
                width: "100%",
                backgroundColor: "#be9355",
                borderRadius: "0",
                borderWidth: "0",
              }}
              onClick={() => muaNgayClick(props.data)}
            >
              Mua ngay
            </Button>
          </Col>
        </Row>
      )} */}
    </div>
  )
}
export default withRouter(withTranslation()(BangGiaTrucTuyen))
