import { all, fork } from "redux-saga/effects"

//public
// import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
// import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
// import ecommerceSaga from "./e-commerce/saga"
// import calendarSaga from "./calendar/saga"
// import chatSaga from "./chat/saga"
// import cryptoSaga from "./crypto/saga"
// import mailsSaga from "./mails/saga"
// import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import staffSaga from './staff/saga'
import permissionSaga from './permission/saga'
import customerSaga from './customer/saga'
import agencySaga from "./agency/saga"
import commonSaga from "./common/saga"
import sanPhamSaga from "./sanpham/saga";
import hopdongSaga from "./hopdong/saga";
import tinTucSaga from "./tintuc/sagas"
export default function* rootSaga() {
  yield all([
    
    //public
    // fork(AccountSaga),
    fork(AuthSaga),
    // fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    // fork(ecommerceSaga),
    // fork(calendarSaga),
    // fork(chatSaga),
    // fork(mailsSaga),
    // fork(contactsSaga),
    fork(dashboardSaga),
    fork(staffSaga),
    fork(permissionSaga),
    fork(customerSaga),
    fork(agencySaga),
    fork(commonSaga),
    fork(sanPhamSaga),
    fork(hopdongSaga),
    fork(tinTucSaga),
  ])
}
