import React, { useEffect, useState } from "react"
import { Upload, Button, message } from "antd"
import { API_UPLOAD_URL, UploadService } from "services/UploadService"

const UploadComponent = ({ files, affterUpload, onRemove, multiple }) => {
  const [fileList, setFileList] = useState(files ?? [])
  useEffect(()=>{
    if(files?.length > 0){
      setFileList(files)
    }
    return () => setFileList([]);
  },[files])
  const customUpload = async options => {
    const { onSuccess, onError } = options
    const files = options.fileList ? options.fileList : [options.file]
    const formData = new FormData()
    for (var i = 0; i < files.length; i++) {
      formData.append("Image", files[i])
    }
    try {
      const _res = await UploadService.upload(formData)
      if (_res.status === 2000) {
        affterUpload(_res.data)
        setFileList(prevList => [
          ...prevList,
          ..._res.data.map(file => (file = API_UPLOAD_URL + file)),
        ])
      }
      //   if (response.ok) {
      //     // Call the onSuccess callback
      //     onSuccess(result, file)
      //     // Update the UI or state as needed
      //     setFileList(prevList => [...prevList, result])
      //     message.success(`${file.name} file uploaded successfully`)
      //   } else {
      //     onError(new Error("Upload failed"))
      //   }
    } catch (error) {
      onError(new Error("Upload failed"))
    }
  }
  const handleRemove = (file) => {
    onRemove(file);
    setFileList(prevList => prevList.filter((item) => item !== file.url))
  }
  return (
    <Upload
      customRequest={customUpload}
      listType="picture-card"
      fileList={fileList?.map((file, index) => ({
        uid: index, // Assuming your response includes a unique file ID
        status: "done",
        url: file,
      }))}
      onRemove={(file) => handleRemove(file)}
      multiple={multiple}
    >
      {multiple || fileList?.length === 0 ? <p>Tải lên</p> : null}
    </Upload>
  )
}

export default React.memo(UploadComponent);
