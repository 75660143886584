import React from "react"
import { createRoot } from 'react-dom/client';
import App from "./App"
// import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import store from "./store"
import { ToastContainer } from 'react-toastify';
import { ConfirmDialog } from 'primereact/confirmdialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import { SignalrContextProvider } from "contexts/SignalrContext";

const domNode = document.getElementById('root');
const root = createRoot(domNode);

const app = (
  <Provider store={store}>
    <SignalrContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ToastContainer />
    <ConfirmDialog />
    </SignalrContextProvider>
  </Provider>
)

root.render(app);

// ReactDOM.render(app, document.getElementById("root"))
// serviceWorker.unregister()
