import { truncate } from "lodash-es"
import * as types from "./actionTypes"

const INIT_STATE = {
  menu: [],
  staffs: [],
  listKhu:[],
  listPhanKhu:[],
  listBDSTrangThai:[],
  isUploadDone: true,
  listSanGiaoDich:[]
}

const Common = (state = INIT_STATE, action) => {
 
  switch (action.type) {
    case types.UPLOAD_IMAGE:
      return {
        ...state,
        isUploadDone: false,
      }
    case types.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isUploadDone: true,
      }
    case types.GET_SANGIAODICH_SUCCESS:
      return {
        ...state,
        listSanGiaoDich: action.payload,
      }
    case types.GET_KHU_SUCCESS:
      return {
        ...state,
        listKhu: action.payload,
      }
    case types.GET_KHU_BY_LISTDA_SUCCESS:
      return {
        ...state,
        listKhu: action.payload,
      }
    case types.GET_PHANKHU_SUCCESS:
      return {
        ...state,
        listPhanKhu: action.payload,
      }
    case types.GET_BDS_TRANGTHAI_SUCCESS:
      return {
        ...state,
        listBDSTrangThai: action.payload,
      }
    default:
      return state
  }
}

export default Common
