import { del, get, post, put } from "../helpers/api_helper"

export const SanPhamService = {
  BanChoThue: {
    getKhoangGia: async payload => {
      return await post("admin/cho-thue/get-khoang-gia", payload).then(
        res => res
      )
    },
    getByidNoauth: async payload => {
      return await post("admin/ban-cho-thue/get-byid-noauth", payload).then(
        res => res
      )
    },
  },
  NhuCauMuaThue: {
    get: async payload => {
      return await post("admin/san-pham/mua-thue/get", payload).then(res => res)
    },
    getByid: async payload => {
      return await post("admin/san-pham/mua-thue/get-byid", payload).then(
        res => res
      )
    },
    add: async payload => {
      return await post("admin/san-pham/mua-thue/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/nguon/delete", payload).then(res => res)
    },
    upload: async payload => {
      return await post("admin/CanBan/upload-image", payload).then(
        res => res
      )
    },
    getImages: async payload => {
      return await post("admin/CanBan/List_imageMGL", payload).then(res => res)
    },

    GetLoai: {
      get: async payload => {
        return await post("admin/mgl/get-loai", payload).then(res => res)
      },
    },
  },
}
