import { call, put, takeEvery } from "redux-saga/effects"
import * as types from "./actionTypes"
import { toast } from "react-toastify"
import {
  getTinTucAPI,
  getLoaiTinTucAPI,
  addTinTucAPI,
  deleteTinTucAPI,
} from "./services"

function* getTinTuc(action) {
  try {
    const response = yield call(getTinTucAPI, action.payload)
    yield put({ type: types.GET_TINTUC_SUCCESS, payload: response.data })
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* addTinTuc(action) {
  try {
    // debugger
    const response = yield call(addTinTucAPI, action.payload.data);
    if(response.status ===2000){
      action.payload.history.push("/news");
    }
    // yield put({ type: types.GET_TINTUC_SUCCESS, payload: response.data })
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* deleteTinTuc(action) {
  // debugger
  try {
    const response = yield call(deleteTinTucAPI, action.payload)
    if (response.status === 2000) {
       yield put({ type: types.DELETE_TINTUC_SUCCESS, payload: action.payload })
    }
    // yield put({ type: types.GET_TINTUC_SUCCESS, payload: response.data })
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* getLoaiTinTuc() {
  try {
    const response = yield call(getLoaiTinTucAPI)
    yield put({ type: types.GET_LOAI_TINTUC_SUCCESS, payload: response.data })
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
export default function* tinTucSaga() {
  yield takeEvery(types.GET_TINTUC, getTinTuc)
  yield takeEvery(types.ADD_TINTUC, addTinTuc)
  yield takeEvery(types.DELETE_TINTUC, deleteTinTuc)
  yield takeEvery(types.GET_LOAI_TINTUC, getLoaiTinTuc)
}
