import React, { useState } from "react"
import { Tabs, Table, Form, Button, Drawer, Row, Col } from "antd"
import InputCustom from "components/Forms/InputCustom"


const { TabPane } = Tabs

const initialData = []

const ChildrenFormAdd = () => {
  const [dataSource, setDataSource] = useState(initialData)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [errors, setErrors] = useState({})
  const [form] = Form.useForm()

  const handleSave = values => {
    const newData = [
      ...dataSource,
      {
        ...values,
        key: `${dataSource.length + 1}`,
      },
    ]
    setDataSource(newData)
    setDrawerVisible(false) // Close drawer after successful save
    form.resetFields() // Reset form fields after save
    setFormValues({}) // Clear form values
  }

  const handleAdd = () => {
    setDrawerVisible(true) // Open drawer to add new row
  }

  const handleCancel = () => {
    setDrawerVisible(false) // Cancel adding new row, close drawer
    form.resetFields() // Reset form fields on cancel
    setFormValues({}) // Clear form values
  }

  const handleDelete = key => {
    const newData = dataSource.filter(item => item.key !== key)
    setDataSource(newData)
  }

  const applyChange = (prop, value) => {
    setFormValues({ ...formValues, [prop]: value })
  }

  const validateFields = () => {
    const currentErrors = {}
    if (!formValues.SanPham) {
      currentErrors.SanPham = "Vui lòng nhập Chọn sản phẩm"
    }
    // Add validation for other fields similarly
    setErrors(currentErrors)
    return Object.keys(currentErrors).length === 0
  }

  const handleSubmit = () => {
    if (validateFields()) {
      handleSave(formValues)
    }
  }

  const columns = [
    {
      title: "Xóa",
      dataIndex: "Xoa",
      key: "Xoa",
      render: (_, record) => (
        <Button onClick={() => handleDelete(record.key)}>Xóa</Button>
      ),
    },
    {
      title: "Sản phẩm",
      dataIndex: "SanPham",
      key: "SanPham",
      editable: true,
    },
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      key: "SoPhieu",
      editable: true,
    },
    {
      title: "Số tiền chi",
      dataIndex: "SoTienChi",
      key: "SoTienChi",
      editable: true,
    },
    {
      title: "Loại chi",
      dataIndex: "LoaiChi",
      key: "LoaiChi",
      editable: true,
    },
    {
      title: "Ngày chi",
      dataIndex: "NgayChi",
      key: "NgayChi",
      editable: true,
    },
    {
      title: "Diễn giải",
      dataIndex: "DienDai",
      key: "DienDai",
      editable: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
      editable: true,
    },
    {
      title: "PT giảm tính lãi",
      dataIndex: "PTGianTinhLai",
      key: "PTGianTinhLai",
      editable: true,
    },
    {
      title: "Số tiền chi giảm lãi",
      dataIndex: "SoTienChiGiamLai",
      key: "SoTienChiGiamLai",
      editable: true,
    },
    {
      title: "Số giao dịch",
      dataIndex: "SoDatMua",
      key: "SoDatMua",
      editable: true,
    },
    {
      title: "Số HĐMB",
      dataIndex: "SoHDMB",
      key: "SoHDMB",
      editable: true,
    },
    {
      title: "Sổ HĐ vay",
      dataIndex: "SoHDVay",
      key: "SoHDVay",
      editable: true,
    },
    {
      title: "Người nộp",
      dataIndex: "NguoiNop",
      key: "NguoiNop",
      editable: true,
    },
  ]

  const mergedColumns = columns.map(col => ({
    ...col,
    onCell: record => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave: handleSave,
    }),
  }))

  return (
    <Tabs defaultActiveKey="1">
      <TabPane key="1">
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          Thêm dòng mới
        </Button>
        <Table
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ x: 2000 }}
        />
        <Drawer
          title="Thêm dòng mới"
          placement="right"
          closable={false}
          onClose={handleCancel}
          visible={drawerVisible}
          width="60%"
          footer={
            <div style={{ textAlign: "right" }}>
              <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                Hủy
              </Button>
              <Button onClick={handleSubmit} type="primary">
                Lưu
              </Button>
            </div>
          }
        >
          <Form form={form} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <InputCustom
                  label="Chọn SP"
                  applyChange={applyChange}
                  prop={"SanPham"}
                  value={formValues.SanPham}
                  placeholder={"Nhập Chọn sản phẩm"}
                />
                <p style={{ color: "red" }}>{errors.SanPham}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Số phiếu"
                  applyChange={applyChange}
                  prop={"SoPhieu"}
                  value={formValues.SoPhieu}
                  placeholder={"Nhập Số phiếu"}
                />
                <p style={{ color: "red" }}>{errors.SoPhieu}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Số tiền chi"
                  applyChange={applyChange}
                  prop={"SoTienChi"}
                  value={formValues.SoTienChi}
                  placeholder={"Nhập Số tiền chi"}
                />
                <p style={{ color: "red" }}>{errors.SoTienChi}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Loại chi"
                  applyChange={applyChange}
                  prop={"LoaiChi"}
                  value={formValues.LoaiChi}
                  placeholder={"Nhập Loại chi"}
                />
                <p style={{ color: "red" }}>{errors.LoaiChi}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Ngày chi"
                  applyChange={applyChange}
                  prop={"NgayChi"}
                  value={formValues.NgayChi}
                  placeholder={"Nhập Ngày chi"}
                />
                <p style={{ color: "red" }}>{errors.NgayChi}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Diễn giải"
                  applyChange={applyChange}
                  prop={"DienDai"}
                  value={formValues.DienDai}
                  placeholder={"Nhập Diễn giải"}
                />
                <p style={{ color: "red" }}>{errors.DienDai}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Ghi chú"
                  applyChange={applyChange}
                  prop={"GhiChu"}
                  value={formValues.GhiChu}
                  placeholder={"Nhập Ghi chú"}
                />
                <p style={{ color: "red" }}>{errors.GhiChu}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="PT giảm tính lãi"
                  applyChange={applyChange}
                  prop={"PTGianTinhLai"}
                  value={formValues.PTGianTinhLai}
                  placeholder={"Nhập PT giảm tính lãi"}
                />
                <p style={{ color: "red" }}>{errors.PTGianTinhLai}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Số tiền chi giảm lãi"
                  applyChange={applyChange}
                  prop={"SoTienChiGiamLai"}
                  value={formValues.SoTienChiGiamLai}
                  placeholder={"Nhập Số tiền chi giảm lãi"}
                />
                <p style={{ color: "red" }}>{errors.SoTienChiGiamLai}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Số giao dịch"
                  applyChange={applyChange}
                  prop={"SoDatMua"}
                  value={formValues.SoDatMua}
                  placeholder={"Nhập Số giao dịch"}
                />
                <p style={{ color: "red" }}>{errors.SoDatMua}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Số HĐMB"
                  applyChange={applyChange}
                  prop={"SoHDMB"}
                  value={formValues.SoHDMB}
                  placeholder={"Nhập Số HĐMB"}
                />
                <p style={{ color: "red" }}>{errors.SoHDMB}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Sổ HĐ vay"
                  applyChange={applyChange}
                  prop={"SoHDVay"}
                  value={formValues.SoHDVay}
                  placeholder={"Nhập Sổ HĐ vay"}
                />
                <p style={{ color: "red" }}>{errors.SoHDVay}</p>
              </Col>
              <Col span={8}>
                <InputCustom
                  label="Người nộp"
                  applyChange={applyChange}
                  prop={"NguoiNop"}
                  value={formValues.NguoiNop}
                  placeholder={"Nhập Người nộp"}
                />
                <p style={{ color: "red" }}>{errors.NguoiNop}</p>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </TabPane>
    </Tabs>
  )
}
export default ChildrenFormAdd
