import { call, put, takeEvery } from "redux-saga/effects"
// Staff Redux States
import * as types from "./actionTypes"
import { toast } from "react-toastify"
//Include Both Helper File with needed methods
import {
  getPermissionAPI,
  addPermissionAPI,
  editPermissionAPI,
  deletePermissionAPI,
  getListModuleAPI,
  getPerSDBIDAPI,
  getFeatureHasNameAPI,
  addAccessDataPerAPI,
  getAccessDataPerAPI,
  addFeaturePerAPI,
  deleteFeaturePerAPI,
} from "./per.service"

function* getPermission(action) {
  try {
    const response = yield call(getPermissionAPI)
    if (response.status === 2000) {
      yield put({ type: types.GET_PERMISSION_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* addPermission(action) {
  try {
    const response = yield call(addPermissionAPI, action.payload)
    if (response.status === 2000) {
      toast.success("Thêm mới quyền thành công!", {
        autoClose: 1500,
        className: "mt-5",
      })
      // yield put({ type: types.GET_PERMISSION_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* editPermission(action) {
  try {
    const response = yield call(editPermissionAPI, action.payload)
    if (response.status === 2000) {
      toast.success("Sửa quyền thành công!", {
        autoClose: 1500,
        className: "mt-5",
      })
      // yield put({ type: types.GET_PERMISSION_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* deletePermission(action) {
  try {
    const response = yield call(deletePermissionAPI, action.payload)
    if (response.status === 2000) {
      toast.success("Xóa nhóm quyền thành công!", {
        autoClose: 1500,
        className: "mt-5",
      })
      yield put({
        type: types.DELETE_PERMISSION_SUCCESS,
        payload: action.payload,
      })
    }
  } catch (error) {}
}
function* getListModule(action) {
  try {
    const response = yield call(getListModuleAPI, action.payload)
    if (response.status === 2000) {
      yield put({
        type: types.GET_LIST_MODULE_SUCCESS,
        payload: response.data,
      })
    }
  } catch (error) {}
}
function* getPerSDBID() {
  try {
    const response = yield call(getPerSDBIDAPI)
    if (response.status === 2000) {
      yield put({
        type: types.GET_PER_SDBID_SUCCESS,
        payload: response.data,
      })
    }
  } catch (error) {}
}
function* getFeatureHasName(action) {
  try {
    const response = yield call(getFeatureHasNameAPI, action.payload)
    if (response.status === 2000) {
      yield put({
        type: types.GET_FEATURE_HAS_NAME_SUCCESS,
        payload: response.data,
      })
    }
  } catch (error) {}
}
function* addAccessDataPer(action) {
  try {
    const response = yield call(addAccessDataPerAPI, action.payload)
    // if (response.status === 2000) {
    //   yield put({
    //     type: types.GET_FEATURE_HAS_NAME_SUCCESS,
    //     payload: response.data,
    //   })
    // }
  } catch (error) {}
}
function* getAccessDataPer(action) {
  try {
    const response = yield call(getAccessDataPerAPI, action.payload)
    if (response.status === 2000) {
      yield put({
        type: types.GET_ACCESSDATA_PER_SUCCESS,
        payload: response.data,
      })
    }
  } catch (error) {}
}
function* addFeaturePer(action) {
  try {
    const response = yield call(addFeaturePerAPI, action.payload)
    if (response.status === 2000) {
      yield put({
        type: types.ADD_FEATURE_PER_SUCCESS,
        payload: action.payload,
      })
    }
  } catch (error) {}
}
function* deleteFeaturePer(action) {
  try {
    const response = yield call(deleteFeaturePerAPI, action.payload)
    if (response.status === 2000) {
      yield put({
        type: types.DELETE_FEATURE_PER_SUCCESS,
        payload: action.payload,
      })
    }
  } catch (error) {}
}
export default function* permissionSaga() {
  yield takeEvery(types.GET_PERMISSION, getPermission)
  yield takeEvery(types.ADD_PERMISSION, addPermission)
  yield takeEvery(types.EDIT_PERMISSION, editPermission)
  yield takeEvery(types.DELETE_PERMISSION, deletePermission)
  yield takeEvery(types.GET_LIST_MODULE, getListModule)
  yield takeEvery(types.GET_PER_SDBID, getPerSDBID)
  yield takeEvery(types.GET_FEATURE_HAS_NAME, getFeatureHasName)
  yield takeEvery(types.ADD_ACCESSDATA_PER, addAccessDataPer)
  yield takeEvery(types.GET_ACCESSDATA_PER, getAccessDataPer)
  yield takeEvery(types.ADD_FEATURE_PER, addFeaturePer)
  yield takeEvery(types.DELETE_FEATURE_PER, deleteFeaturePer)
}
