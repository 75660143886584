import { Col, Drawer, notification, Row, Spin } from "antd"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import Chart from "react-apexcharts"
import Filter from "./Filter"
// import { DateTime } from "luxon";
// import { ToaNhaService } from "services/ToaNhaService";
// import { DashboardService } from "services/dashboardService";
import _ from "lodash"
// import { YeuCauService } from "services/YeuCauService";
import YeuCau from "./uiDashboard/YeuCau"
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons"
// import {  } from "layouts/ultis/common";
// import { CategoryService } from "services/CategoryService";
import FilterYear from "./FilterYear"
const ViewDashboard2 = (props, ref) => {
  const { onAfterSubmit, options, ThongKeDV, ThongKeYC } = props
  const [api, contextHolder] = notification.useNotification()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [toaNha, setToaNha] = useState([])
  const [yeuCau, setYeuCau] = useState([])
  const [trangThai, setTrangThai] = useState([])
  const [soLuong, setSoLuong] = useState([])
  const [loaiXe, setLoaiXe] = useState([])
  const [nuoc, setNuoc] = useState([])
  const [dien, setDien] = useState([])
  const [phanLoaiYC, setPhanLoaiYC] = useState([])
  const [theXe, setTheXe] = useState([])

  const [thongTinDashboard, setThongTinDashboard] = useState([])
  // const currentDate = DateTime.now();
  const [filterCondition, setFilterCondition] = useState({
    // Month: currentDate.month,
    // Year: currentDate.year,
    MaTN: null,
    MaKN: 0,
  })
  const [filterCondition2, setFilterCondition2] = useState({
    // Year: currentDate.year,
    MaTN: null,
    MaKN: 0,
  })

  // const loadCategories = async () => {
  //   let _tn = await ToaNhaService.getToaNhaByNV();
  //   setToaNha(_tn.data);
  //   let _filter = _.cloneDeep(filterCondition);
  //   let _filter2 = _.cloneDeep(filterCondition2);
  //   _filter.MaTN = _tn.data[0]?.MaTN;
  //   _filter2.MaTN = _tn.data[0]?.MaTN;
  //   setFilterCondition(_filter);
  //   setFilterCondition2(_filter2);
  //   filter(_filter);
  //   filter2(_filter2);
  // };
  // const filter = async (_filter) => {
  //   setLoading(true);
  //   const _resYC = await DashboardService.getYC(_filter);
  //   if (_resYC.data) {
  //     let trueCountPerDay = Array(31).fill(0);

  //     _resYC.data.forEach((item) => {
  //       const ngay = item.Ngay;
  //       if (ngay >= 1 && ngay <= 31) {
  //         trueCountPerDay[ngay - 1]++;
  //       }
  //     });
  //     setYeuCau(_resYC.data);
  //     setSoLuong(trueCountPerDay);
  //   }

  //   const _res = await DashboardService.getThongTinDashboard(_filter);
  //   if (_res.data) {
  //     setThongTinDashboard(_res.data);
  //   }
  //   let _resTT = await YeuCauService.Categories.getTrangThai(_filter);
  //   if (_resTT.data) setTrangThai(_resTT.data);

  //   const _resLYC = await DashboardService.getPLYC(_filter);
  //   if (_resLYC.data) setPhanLoaiYC(_resLYC.data);
  //   setFilterCondition(_filter);
  //   // const _resTX = await DashboardService.getTheXe(_filter);
  //   // if (_resTX.data) {
  //   //   setTheXe(_resTX.data);
  //   // }

  //   // let _resLX = await CategoryService.DichVu.Xe.LoaiXe.get(_filter);
  //   // if (_resLX.data) {
  //   //   setLoaiXe(_resLX.data);
  //   // }
  //   // const _resDien = await DashboardService.getDien(_filter);
  //   // if (_resDien.data) {
  //   //   setDien(_resDien.data);
  //   // }
  //   // const _resNuoc = await DashboardService.getNuoc(_filter);
  //   // if (_resNuoc.data) {
  //   //   setNuoc(_resNuoc.data);
  //   // }

  //   setLoading(false);
  // };
  // const filter2 = async (_filter) => {
  //   setLoading(true);
  //   // const _resYC = await DashboardService.getYC(_filter);
  //   // if (_resYC.data) {
  //   //   let trueCountPerDay = Array(31).fill(0);

  //   //   _resYC.data.forEach((item) => {
  //   //     const ngay = item.Ngay;
  //   //     if (ngay >= 1 && ngay <= 31) {
  //   //       trueCountPerDay[ngay - 1]++;
  //   //     }
  //   //   });
  //   //   setYeuCau(_resYC.data);
  //   //   setSoLuong(trueCountPerDay);
  //   // }

  //   // const _res = await DashboardService.getThongTinDashboard(_filter);
  //   // if (_res.data) {
  //   //   setThongTinDashboard(_res.data);
  //   // }
  //   // let _resTT = await YeuCauService.Categories.getTrangThai(_filter);
  //   // if (_resTT.data) setTrangThai(_resTT.data);

  //   // const _resLYC = await DashboardService.getPLYC(_filter);
  //   // if (_resLYC.data) setPhanLoaiYC(_resLYC.data);

  //   const _resTX = await DashboardService.getTheXe(_filter);
  //   if (_resTX.data) {
  //     setTheXe(_resTX.data);
  //   }

  //   let _resLX = await CategoryService.DichVu.Xe.LoaiXe.get(_filter);
  //   if (_resLX.data) {
  //     setLoaiXe(_resLX.data);
  //   }
  //   const _resDien = await DashboardService.getDien(_filter);
  //   if (_resDien.data) {
  //     setDien(_resDien.data);
  //   }
  //   const _resNuoc = await DashboardService.getNuoc(_filter);
  //   if (_resNuoc.data) {
  //     setNuoc(_resNuoc.data);
  //   }
  //   setFilterCondition2(_filter);
  //   setLoading(false);
  // };

  const countMaTT = yeuCau.reduce((acc, item) => {
    const maTT = item.MaTT
    if (acc[maTT]) {
      acc[maTT]++
    } else {
      acc[maTT] = 1
    }
    return acc
  }, {})

  const MaTT = trangThai.map(item => item?.MaTT)
  const dataSLTTT = MaTT.map(maTT => countMaTT[maTT] || 0)

  const countMaYC = yeuCau.reduce((acc, item) => {
    const maLYC = item.MaLYC
    if (acc[maLYC]) {
      acc[maLYC]++
    } else {
      acc[maLYC] = 1
    }
    return acc
  }, {})

  function countMaLXByMonth(data, maLX) {
    let result = Array(12).fill(0)
    data.forEach(item => {
      if (item.MaLX === maLX) {
        result[item.Thang - 1]++
      }
    })
    return result
  }

  const MaLYC = phanLoaiYC.map(item => item?.MaLYC)

  const dataPLYC = MaLYC.map(maTT => countMaYC[maTT] || 0)

  const onChangeFilter = _filter => {
    filter(_filter)
  }
  const onChangeFilter2 = _filter => {
    filter2(_filter)
  }
  const onClose = () => {
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({
    show: async item => {
      // loadCategories();
      setOpen(true)
    },
  }))

  useEffect(() => {
    // loadCategories();
  }, [])

  const handleGetDataDN = data => {
    let result = []
    const newData = _.groupBy(data, "Thang")
    Object.values(newData).forEach(arr => {
      arr.forEach(item => {
        const existingMonth = result.find(entry => entry.Thang === item.Thang)
        if (existingMonth) {
          existingMonth.SoTieuThu += item.SoTieuThu
        } else {
          result.push({
            Thang: item.Thang,
            SoTieuThu: item.SoTieuThu,
          })
        }
      })
    })
    let result2 = Array(12).fill(0)
    result.forEach(item => {
      if (item.Thang) {
        result2[item.Thang - 1] = item?.SoTieuThu
      }
    })
    return result2
  }

  const chartOptionsPLYC = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true, // Đặt biểu đồ nằm ngang
      },
    },
    dataLabels: {
      enabled: false, // Ẩn nhãn trên các thanh
    },
    xaxis: {
      // categories: phanLoaiYC.map((item) => item?.TenLYC),
      categories: ["Jan", "Feb", "Mar", "Apr"],
    },
  }

  const chartSeriesPLYC = [
    {
      name: "Sales",
      // data: dataPLYC, // Dữ liệu cho từng thanh
      data: [10, 35, 91, 148],
    },
  ]
  const chartOptionsSLYC = {
    chart: {
      type: "bar",
    },

    plotOptions: {
      bar: {
        horizontal: false, // Đặt biểu đồ nằm dọc
      },
    },
    dataLabels: {
      enabled: false, // Ẩn nhãn trên các cột
    },
    xaxis: {
      categories: Array.from({ length: 31 }, (_, i) => i + 1),
      title: {
        text: "", // Tiêu đề trục X
      },
    },
    yaxis: {
      title: {
        text: "", // Tiêu đề trục Y
      },
    },
    colors: ["#FFA500"],
  }

  const chartSeriesSLYC = [
    {
      name: "Số lượng yêu cầu",
      data: soLuong,
    },
  ]

  const chartOptionsSLTTT = {
    chart: {
      type: "pie",
    },
    labels: trangThai.map(item => item?.TenTT),

    legend: {
      position: "bottom",
    },
    plotOptions: {
      pie: {
        expandOnClick: true, // Cho phép mở rộng khi nhấp vào
      },
    },
    colors: ["#FF4560", "#008FFB", "#00E396", "#775DD0"],
  }

  const chartSeriesSLTTT = dataSLTTT

  const chartOptionsTKTLX = {
    chart: {
      type: "bar", // Đặt loại biểu đồ là cột
    },
    plotOptions: {
      bar: {
        horizontal: false, // Đặt biểu đồ cột dọc
        columnWidth: "50%", // Đặt chiều rộng cột
      },
    },
    dataLabels: {
      enabled: false, // Ẩn nhãn trên các cột
    },
    xaxis: {
      categories: [
        "Tháng 1",
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
        "Tháng 9",
        "Tháng 10",
        "Tháng 11",
        "Tháng 12",
      ],
      title: {
        text: "", // Tiêu đề trục X
      },
      style: {
        fontSize: "0px", // Đặt kích thước phông chữ là 0 để ẩn tiêu đề
      },
    },
    yaxis: {
      title: {
        text: "", // Tiêu đề trục Y
      },
    },
    colors: ["#FF6347", "#4682B4", "#32CD32", "#FFD700"],
  }

  const chartSeriesTKTLX = loaiXe.map(item => ({
    name: item?.TenLX,
    data: countMaLXByMonth(theXe, item?.MaLX),
  }))

  const chartOptionsElectricity = {
    chart: {
      type: "bar", // Loại biểu đồ
    },
    plotOptions: {
      bar: {
        horizontal: false, // Đặt biểu đồ nằm dọc
      },
    },
    dataLabels: {
      enabled: false, // Ẩn nhãn trên các cột
    },
    xaxis: {
      categories: [
        "Tháng 1",
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
        "Tháng 9",
        "Tháng 10",
        "Tháng 11",
        "Tháng 12",
      ], // Các tháng trong năm
      title: {
        text: "", // Tiêu đề trục X
      },
    },
    yaxis: {
      title: {
        text: "", // Tiêu đề trục Y
      },
    },
    colors: ["#FFA500"], // Màu sắc cho cột
    legend: {
      show: true, // Hiển thị chú thích
      position: "top", // Vị trí chú thích
      horizontalAlign: "center", // Căn giữa chú thích
    },
  }

  const chartSeriesElectricity = [
    {
      name: "Điện tiêu thụ",
      data: handleGetDataDN(dien),
    },
  ]
  const chartOptionsWater = {
    chart: {
      type: "bar", // Loại biểu đồ
    },
    plotOptions: {
      bar: {
        horizontal: false, // Đặt biểu đồ nằm dọc
      },
    },
    dataLabels: {
      enabled: false, // Ẩn nhãn trên các cột
    },
    xaxis: {
      categories: [
        "Tháng 1",
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
        "Tháng 9",
        "Tháng 10",
        "Tháng 11",
        "Tháng 12",
      ], // Các tháng trong năm
      title: {
        text: "", // Tiêu đề trục X
      },
    },
    yaxis: {
      title: {
        text: "", // Tiêu đề trục Y
      },
    },
    colors: ["#00BFFF"], // Màu sắc cho cột
    legend: {
      show: true, // Hiển thị chú thích
      position: "top", // Vị trí chú thích
      horizontalAlign: "center", // Căn giữa chú thích
    },
  }

  const chartSeriesWater = [
    {
      name: "Nước tiêu thụ",
      data: handleGetDataDN(nuoc),
    },
  ]

  const mauDashboard1 = () => {
    return (
      <>
        {ThongKeYC ? (
          <>
            {" "}
            <div style={{ marginLeft: "20px", marginBottom: "15px" }}>
              <Filter
                filterCondition={filterCondition}
                onChangeFilter={onChangeFilter}
                options={{
                  ToaNha: toaNha,
                }}
              />
            </div>
            <Row>
              <YeuCau
                chartOptionsPLYC={chartOptionsPLYC}
                chartSeriesPLYC={chartSeriesPLYC}
                chartOptionsSLYC={chartOptionsSLYC}
                chartSeriesSLYC={chartSeriesSLYC}
                chartOptionsSLTTT={chartOptionsSLTTT}
                chartSeriesSLTTT={chartSeriesSLTTT}
              />{" "}
            </Row>
          </>
        ) : (
          <div style={{ padding: "20px" }}>
            {ThongKeDV ? (
              <> </>
            ) : (
              <>
                <Row
                  style={{ paddingBottom: "10px" }}
                  justify="center"
                  align="middle"
                  gutter={[20, 20]}
                >
                  <Col span={24}>
                    <Filter
                      filterCondition={filterCondition}
                      onChangeFilter={onChangeFilter}
                      options={{
                        ToaNha: toaNha,
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        textAlign: "center",
                        backgroundImage:
                          "linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%)",
                        padding: "20px",
                        borderRadius: "10px",
                        margin: "0px 10px",
                        color: "white",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "15px",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        Số lượng yêu cầu
                      </p>

                      <p style={{ fontWeight: "500", fontSize: "24px" }}>
                        {thongTinDashboard.yeuCau?.thangNay}

                        {thongTinDashboard.yeuCau?.tangTruong > 0 ? (
                          <span
                            style={{
                              color: "green",
                              paddingLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {thongTinDashboard.yeuCau?.tangTruong}
                            %
                            <CaretUpOutlined />
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              paddingLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {thongTinDashboard.yeuCau?.tangTruong}
                            % <CaretDownOutlined />
                          </span>
                        )}
                      </p>
                      <p>
                        Kỳ trước{" "}
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          {thongTinDashboard.yeuCau?.thangTruoc}
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        textAlign: "center",
                        backgroundImage:
                          "linear-gradient(135deg, #2AFADF 10%, #4C83FF 100%)",
                        padding: "20px",
                        borderRadius: "10px",
                        margin: "0px 10px",
                        color: "white",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "15px",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Sự cố
                      </p>
                      <p style={{ fontWeight: "600", fontSize: "24px" }}>
                        25{" "}
                        <span
                          style={{
                            color: "green",
                            paddingLeft: "1px",
                            fontSize: "14px",
                          }}
                        >
                          150%
                          <CaretUpOutlined />
                        </span>
                      </p>
                      <p>
                        Kỳ trước <span style={{ fontWeight: "600" }}>10</span>
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        textAlign: "center",
                        backgroundImage:
                          "linear-gradient(135deg, #FFD3A5 10%, #FD6585 100%)",
                        padding: "20px",
                        borderRadius: "10px",
                        margin: "0px 10px",
                        color: "white",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "15px",
                          fontWeight: "600",
                          fontSize: "16px",
                          borderRadius: "10px",
                        }}
                      >
                        Đã thu
                      </p>

                      <p style={{ fontWeight: "500", fontSize: "18px" }}>
                        {thongTinDashboard.daThu?.thangNay}
                        {thongTinDashboard.daThu?.tangTruong > 0 ? (
                          <span
                            style={{
                              color: "green",
                              paddingLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {thongTinDashboard.daThu?.tangTruong}
                            %
                            <CaretUpOutlined />
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              paddingLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {thongTinDashboard.daThu?.tangTruong}
                            %
                            <CaretDownOutlined />
                          </span>
                        )}
                      </p>
                      <p>
                        Kỳ trước{" "}
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          {thongTinDashboard.daThu?.thangTruoc}
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        textAlign: "center",
                        backgroundImage:
                          "linear-gradient(135deg, #EE9AE5 10%, #5961F9 100%)",
                        padding: "20px",
                        borderRadius: "10px",
                        margin: "0px 10px",
                        color: "white",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "15px",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        Còn phải thu
                      </p>

                      <p style={{ fontWeight: "500", fontSize: "18px" }}>
                        {thongTinDashboard.conLai?.thangNay}

                        {thongTinDashboard.conLai?.tangTruong > 0 ? (
                          <span
                            style={{
                              color: "green",
                              paddingLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {thongTinDashboard.conLai?.tangTruong}
                            %
                            <CaretUpOutlined />
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              paddingLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {thongTinDashboard.conLai?.tangTruong}
                            %
                            <CaretDownOutlined />
                          </span>
                        )}
                      </p>
                      <p>
                        Kỳ trước{" "}
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          {thongTinDashboard.conLai?.thangTruoc}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              {ThongKeDV ? (
                <> </>
              ) : (
                <>
                  {" "}
                  <YeuCau
                    chartOptionsPLYC={chartOptionsPLYC}
                    chartSeriesPLYC={chartSeriesPLYC}
                    chartOptionsSLYC={chartOptionsSLYC}
                    chartSeriesSLYC={chartSeriesSLYC}
                    chartOptionsSLTTT={chartOptionsSLTTT}
                    chartSeriesSLTTT={chartSeriesSLTTT}
                  />{" "}
                </>
              )}

              <Col style={{ marginBottom: "20px" }} span={24}>
                {/* <Spin spinning={loading}> */}
                <FilterYear
                  filterCondition={filterCondition2}
                  onChangeFilter={onChangeFilter2}
                  options={{
                    ToaNha: toaNha,
                  }}
                />
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    // padding: "20px 0",
                    fontSize: "17px",
                  }}
                >
                  Thống kê theo loại xe
                </h3>
                <Chart
                  options={chartOptionsTKTLX}
                  series={chartSeriesTKTLX}
                  type="bar"
                  height={350} // Chiều cao biểu đồ
                />
                {/* </Spin> */}
              </Col>
              <Col span={12}>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    padding: "20px 0",
                    fontSize: "17px",
                  }}
                >
                  Điện
                </h3>
                <Chart
                  options={chartOptionsElectricity}
                  series={chartSeriesElectricity}
                  type="bar"
                  height={350} // Chiều cao biểu đồ
                />
              </Col>
              <Col span={12}>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    padding: "20px 0",
                    fontSize: "17px",
                  }}
                >
                  Nước
                </h3>
                <Chart
                  options={chartOptionsWater}
                  series={chartSeriesWater}
                  type="bar"
                  height={350} // Chiều cao biểu đồ
                />
              </Col>
            </Row>
          </div>
        )}
      </>
    )
  }
  return (
    <div style={{marginTop:'50px'}}>
      {/* <Drawer
        width="100%"
        title="Template dashboard"
        onClose={onClose}
        open={open}
      > */}
      {mauDashboard1()}
      {/* </Drawer> */}
    </div>
  )
}

export default forwardRef(ViewDashboard2)
