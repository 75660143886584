import React, { useEffect, useState, useCallback } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { toast } from "react-toastify"
import {
  handleValidDate,
  Format_Currency,
  formatNumberPT,
  getHexColor,
} from "../../../common/common"
import {
  Col,
  Container,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap"
import { Button } from "primereact/button"

import Pagination from "../../../components/Common/Pagination"
import { useDispatch } from "react-redux"
import ToolBar from "components/Toolbar"
import DatNen_Filter from "./DatNen_Filter"
import { ProjectService } from "services/Project"
import LoadingBar from "components/LoadingBar"
import { Drawer, Dropdown, Image, Space, Spin } from "antd"
import { ProductService } from "services/Product"
const DatNenIndex = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const defaultCondition = {
    InputSearch: "",
    MaDA: -1,
    MaKhu: -1,
    MaPK: -1,
    MaTT: -1,
    MaLBDS: -1,
    Offset: 1,
    Limit: 20,
  }
  const [product, setProduct] = useState({})
  const [visibleDetail, setVisibleDetail] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [timeLock, setTimeLock] = useState(0)
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [modalDelete, setModalDelete] = useState({})
  const [modalDropBtn, setModalDropBtn] = useState(false)
  const [modalImage, setModalImage] = useState(false)
  const [item, setItem] = useState([])
  const [checkBooking, setCheckBooking] = useState()
  const [dateFrom, setDateFrom] = useState("01/01/2021")
  const [dateTo, setDateTo] = useState("12/30/2021")
  const toggleDropdownBtn = () => setModalDropBtn(prevState => !prevState)
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maSP !== row.maSP
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const getProduct = async maSP => {
    setLoading(true)
    let _res = await ProductService.DatNen.getById({
      maSP: maSP,
    })
    
    if (_res.status === 2000) {
      setProduct(_res.data)
      setTimeLock(_res.data?.ThoiGianConLai)
      _res.data?.ThoiGianConLai > 0 && setIsStart(true)
    }
    let _resBooking = await ProductService.checkBooking({
      MaSP: maSP,
    })
    if (_resBooking?.data) setCheckBooking(_resBooking?.data)
    setLoading(false)
  }
  useEffect(() => {
    let _time = null
    if (isStart === true) {
      _time = setInterval(() => {
        setTimeLock(pre => {
          pre < 0 && setIsStart(false)
          return pre - 1
        })
      }, 1000)
    }
    return () => clearInterval(_time)
  }, [isStart])
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.stt}
          </Link>
        ),
      },
      // {
      //   dataField: "maSP",
      //   text: "Xem",
      //   sort: true,
      //   formatter: (cellContent, row) => (
      //     <Link
      //       to="#"
      //       // onClick={() => toggleDetail(row)}
      //       className="text-body fw-bold"
      //     >
      //       <i className="mdi mdi-17px mdi-eye"></i>
      //     </Link>
      //   ),
      // },
      {
        dataField: "tenTT",
        text: "Trạng thái",
        sort: true,
        formatter: (cellContent, row) => (
          <div
            className="shadow-4 rounded"
            style={{
              backgroundColor: row.mauNen,
              padding: "5px",
            }}
          >
            {row.tenTT}
          </div>
        ),
      },
      {
        dataField: "tenDA",
        text: "Dự án",
        sort: true,
      },
      {
        dataField: "khu",
        text: "Khu",
        sort: true,
      },
      {
        dataField: "maSanPham",
        text: "Mã sản phẩm",
        sort: true,
        formatter: (cellContent, row) => (
          <span
            // className="link"
            style={{
              cursor: "pointer",
              color: "#556ee6",
            }}
            onClick={() => {
              setVisibleDetail(true)
              getProduct(row.maSP)
            }}
          >
            {row.maSanPham}
          </span>
        ),
      },
      {
        dataField: "soO",
        text: "Số ô",
        sort: true,
      },

      {
        dataField: "chieuDai2",
        text: "Chiều dài 2",
        sort: true,
      },
      {
        dataField: "chieuRong2",
        text: "Chiều rộng 2",
        sort: true,
      },
      // {
      //   dataField: "tienDatKhongChiuThue",
      //   text: "Tiền đất không chịu thuế",
      //   sort: true,
      //   formatter: (cellContent, row) => Format_Currency(row.tienDatKhongChiuThue),
      // },
      {
        dataField: "huongNha",
        text: "Hướng nhà",
        sort: true,
      },
      {
        dataField: "dienTichDat",
        text: "Diện tích đất",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dienTichDat),
      },
      {
        dataField: "tongDienTichXD",
        text: "Tổng DT XD",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.tongDienTichXD),
      },
      {
        dataField: "dienTichXD",
        text: "Diện tích xây dựng",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dienTichXD),
      },
      {
        dataField: "donGiaDat",
        text: "Đơn giá đất",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.donGiaDat),
      },
      {
        dataField: "ptVATDat",
        text: "% VAT đất",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.ptVATDat),
      },
      {
        dataField: "tienVATDat",
        text: "Tiền VAT đất",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tienVATDat),
      },
      {
        dataField: "tongDatChuaVAT",
        text: "Tổng đất chưa VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongDatChuaVAT),
      },
      {
        dataField: "tongGiaTriDat",
        text: "Tổng giá trị đất",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaTriDat),
      },
      {
        dataField: "donGiaXD",
        text: "Đơn giá XD",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.donGiaXD),
      },
      {
        dataField: "tienVATXD",
        text: "Tiền VAT XD",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tienVATXD),
      },
      {
        dataField: "tongXDChuaVAT",
        text: "Tổng XD chưa VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongXDChuaVAT),
      },
      {
        dataField: "thanhTienXDCoVAT",
        text: "Tổng XD có VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.thanhTienXDCoVAT),
      },
      {
        dataField: "tongGiaTriHDMB",
        text: "Tổng giá trị HDMB",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaTriHDMB),
      },
      {
        dataField: "nguoiNhap",
        text: "Người nhập",
        sort: true,
      },
      {
        dataField: "ngayNhap",
        text: "Ngày nhập",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),
      },
      {
        dataField: "nguoiSua",
        text: "Người sửa",
        sort: true,
      },
      {
        dataField: "ngaySua",
        text: "Ngày sửa",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngaySua),
      },
    ]
  }, [])

  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    let res = await ProjectService.DatNen.filter(_filter)
    setData(res.data || [])
    setLoading(false)
  }
  const afterSubmit = async () => {
    await filter(filterCondition)
  }
  useEffect(() => {
    ;(async () => {
      await filter(filterCondition)
    })()
  }, [])

  const handleEdit = () => {}
  const toggleImage = () => {
    setModalImage(!modalImage)
  }
  const onClickXemAnh = () => {
    dispatch(getImageForsale({ MaBC: item[0]?.maSP }))
    toggleImage()
  }
  const onClickGiuCho = () => {
    if (item[0].maTT !== 2) {
      toast.warning("Chỉ được phép giữ chỗ cho sản phẩm đang mở bán!", {
        autoClose: 1500,
        className: "mt-5",
      })
      return
    }
    history.push({
      pathname: "/thaptang/them-giucho",
      type: 2,
      state: item,
    })
  }
  const handleDelete = useCallback(itemDL => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(itemDL),
    })
  }, [])
  const onClickDelete = useCallback(itemDL => {
    const arrDelete = []
    itemDL?.map(i => {
      arrDelete.push(i.maSP)
    })
    if (arrDelete.length > 0) {
      dispatch(deleteForSale({ MaBC: arrDelete }))
    }
  }, [])

  const handleAdd = () => {}

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  // useEffect(() => {
  //   let time
  //   if (!time) {
  //     time = setTimeout(() => {
  //       dispatch(
  //         getDatNen({
  //           MaDA: maDuAn,
  //           MaKhu: maKhu,
  //           MaPK: maPK,
  //           MaTT: maTT,
  //           KyHieu: textSearch,
  //           MaLBDS: -1,
  //           Offset: page,
  //           Limit: itemPerPage,
  //         })
  //       )
  //       setItem([])
  //     }, [1000])
  //   }
  //   return () => {
  //     clearTimeout(time)
  //   }
  // }, [textSearch])
  const afterChangeFilter = async _filter => {
    setFilterCondition(_filter)
    await filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "page":
        _filter.Offset = v
        break
      case "itemPerPage":
        _filter.Offset = 1
        _filter.Limit = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }

  const renderDrawerDetailProduct = () => {
    const confirmRemoveProduct = async () => {
      setVisibleDetail(false)
      let _res = await AgencyService.SoDo.create({
        isadd: false,
        data: [
          {
            MaDA: Number(params?.MaDA),
            geometry: JSON.stringify(selectedLayer.geometry),
            id: selectedLayer.id,
            type: "Feature",
            MaSP: null,
          },
        ],
      })
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data)
        let item = _data.geoValues.find(v => v.id === selectedLayer?.id)
        if (item) item.maSP = null
        item.properties = {
          mauNen: null,
          id: selectedLayer?.id,
          dienTichDat: null,
          dienTichXD: null,
          kyHieu: null,
          maSP: null,
          tenTT: null,
          tongGiaTriHDMB: null,
        }
        setData(_data)
        // filterSoDo()
      }
    }
    const addBooking = () => {
      history.push("/banggiatructuyen/addgiucho/" + product.MaSP)
    }
    const lockCan = async () => {
      let _res = await ProductService.DatNen.lockCan({ maSP: product.MaSP })
      if (_res.status === 2000) {
        setTimeLock(_res.data)
        setIsStart(true)
        afterSubmit()
      }
    }
    const items = [
      {
        key: "1",
        label: (
          <p
            className="m-0"
            style={{ color: "red" }}
            onClick={() => confirmRemoveProduct()}
          >
            <i className="pi pi-trash" /> Xoá sản phẩm
          </p>
        ),
      },
    ]
    const getValueTime = () => {
      let _ss = Math.floor(timeLock % 60)
      let _mm = Math.floor((timeLock % (60 * 60)) / 60)
      return `${_mm < 10 ? "0" + _mm : _mm}:${_ss < 10 ? "0" + _ss : _ss}`
    }
    return (
      <Drawer
        title="Thông tin sản phẩm"
        width={500}
        onClose={() => {
          setVisibleDetail(false)
          setIsStart(false)
          setProduct({})
        }}
        open={visibleDetail}
        extra={
          <Space>
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
              <i className="pi pi-ellipsis-v" />
            </Dropdown>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Image
            width="100%"
            height={250}
            src={product?.HinhAnh}
            style={{ borderRadius: "5px" }}
          />
          <div className="product-content">
            <p className="mt-3 mb-2" style={{ color: "#646464" }}>
              Dự án:{" "}
              <span
                style={{ fontWeight: "700", fontSize: 14, color: "#343434" }}
              >
                {product?.TenDA}
              </span>
            </p>
            <p className="mt-3 mb-2" style={{ color: "#646464" }}>
              Mã sản phẩm:{" "}
              <span
                style={{ fontWeight: "700", fontSize: 14, color: "#343434" }}
              >
                {product?.KyHieu}
              </span>
            </p>
            <div className="flex align-items-center  mb-2">
              <p className="mb-0" style={{ color: "#646464" }}>
                Trạng thái:
              </p>
              <div
                className="py-1 px-2"
                style={{
                  backgroundColor: getHexColor(product.MauNen),
                  borderRadius: "5px",
                  width: "100px",
                }}
              >
                <p className="m-0" style={{ fontSize: 12, fontWeight: "700" }}>
                  {product.TenTT}
                </p>
              </div>
            </div>
            <p className="mb-2" style={{ color: "#646464" }}>
              Giá trị:{" "}
              <span
                style={{ fontWeight: "900", fontSize: 15, color: "#343434" }}
              >
                {" "}
                {Format_Currency(product.TongGiaTriHDMB)}
              </span>{" "}
            </p>
          </div>
          <div className="product-footer flex  gap-3 pt-4">
            <Button
              onClick={() => lockCan()}
              danger
              disabled={timeLock > 0 ? true : false}
            >
              {timeLock > 0 ? getValueTime() : "Lock căn"}
            </Button>
            {checkBooking?.isBooking || timeLock <= 0 ? (
              <Button onClick={() => addBooking()} type="primary">
                Book ngay
              </Button>
            ) : (
              <Button disabled={true} type="primary">
                Book ngay
              </Button>
            )}
          </div>
        </Spin>
      </Drawer>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Đất nền | Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div className="text-sm-start">
                <Button
                  label="Thêm"
                  icon="mdi mdi-plus"
                  className="p-button-outlined p-button-success mr-1"
                  onClick={handleAdd}
                />
                {item.length === 1 && (
                  <Button
                    label="Sửa"
                    icon="mdi mdi-pencil"
                    className="p-button-outlined p-button-warning mr-1"
                    onClick={handleEdit}
                  />
                )}
                {item.length >= 1 && (
                  <Button
                    label="Xóa"
                    icon="mdi mdi-delete"
                    className="p-button-outlined p-button-danger mr-1"
                    onClick={handleDelete}
                  />
                )}
                {item.length >= 1 && (
                  <ButtonDropdown
                    className="me-2"
                    isOpen={modalDropBtn}
                    toggle={() => toggleDropdownBtn()}
                  >
                    <DropdownToggle
                      tag="button"
                      className="btn btn-info rounded"
                    >
                      Hành động <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {item.length === 1 && (
                        <React.Fragment>
                          <DropdownItem onClick={() => onClickXemAnh()}>
                            <i className="bx bx-search-alt me-1" />
                            Xem ảnh
                          </DropdownItem>
                          <DropdownItem onClick={() => onClickGiuCho()}>
                            <i className="bx bx-search-alt me-1" />
                            Giữ chỗ
                          </DropdownItem>
                        </React.Fragment>
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                )}
              </div>
            )}
            right={() => (
              <DatNen_Filter
                filterCondition={filterCondition}
                afterChangeFilter={afterChangeFilter}
              />
            )}
          />
          <ToolkitProvider
            keyField="maSP"
            data={data}
            columns={Columns()}
            bootstrap4
          >
            {toolkitProps => (
              <React.Fragment>
                <LoadingBar loading={loading} />
                <div className="table-responsive table-scrollable shadow-2">
                  <BootstrapTable
                    keyField="maSP"
                    responsive
                    bordered={false}
                    striped={false}
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                    classes={
                      "table align-middle  table-nowrap table-check table-hover table-fixed "
                    }
                    headerWrapperClasses={"table-light"}
                    {...toolkitProps.baseProps}
                  />
                </div>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <select
                        className="form-select"
                        value={filterCondition.Limit}
                        onChange={e =>
                          onChangeFilter("itemPerPage", Number(e.target.value))
                        }
                      >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={1000}>1000</option>
                      </select>
                    </div>
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <Pagination
                        totalPage={Math.ceil(
                          data[0]?.totalRows / filterCondition.Limit
                        )}
                        changePage={i => onChangeFilter("page", i)}
                      />
                    </Col>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Container>
      </div>
      {renderDrawerDetailProduct()}
    </React.Fragment>
  )
}

DatNenIndex.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DatNenIndex))
