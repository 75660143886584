import * as types from "./actionTypes"

const INIT_STATE = {
  listChungCu: [],
  listDatNen: [],
  listSanPham: [],
  isLoadFilter: false,
  isDone: false,
}

const SanPham = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_CHUNGCU:
      return { ...state, isLoadFilter: true }
    case types.GET_SANPHAM:
      return { ...state, listSanPham: action.payload }
    case types.GET_CHUNGCU_SUCCESS:
      return { ...state, listChungCu: action.payload, isLoadFilter: false }
    case types.GET_DATNEN:
      return { ...state, isLoadFilter: true }
    case types.GET_DATNEN_SUCCESS:
      return { ...state, listDatNen: action.payload, isLoadFilter: false }
    default:
      return state
  }
}

export default SanPham
