import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback,
} from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import {
  BEE_AutoComplete,
  BEE_Dropdown,
  BEE_InputNumber,
  BEE_InputText,
  BEE_XLAYOUT_FORM,
} from "pages/Forms/BEE_Form"
import { PlusOutlined } from "@ant-design/icons"
import { Button } from "primereact/button"
import { ProjectService } from "services/Project"
import { RadioButton } from "primereact/radiobutton"
import { Checkbox, Radio, Upload } from "antd"
import { CustomerService } from "services/Customer"
import { CommonService } from "services/Common"
import { AgencyService } from "services/Agency"
import { StaffService } from "services/Staff"
import { Col, Drawer, Row, Flex } from "antd"
import InputCustom from "components/Forms/InputCustom"
import SelectCustom from "components/Forms/SelectCustom"
import InputNumberCustom from "components/Forms/InputNumberCustom"
import { getStaffs } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import TextArea from "antd/es/input/TextArea"
import UploadComponent from "components/UploadComponent"
import { SanPhamService } from "services/SanPhamService"
import { API_UPLOAD_URL } from "services/UploadService"
function ForSale_Detail(props, ref) {
  const {
    afterSubmit,
    nhuCau,
    phuongHuong1,
    loaiDuong1,
    phapLy1,
    trangThai1,
    staffs1,
  } = props
  const refFunctionMode = useRef(null)
  const [show, setShow] = useState(false)
  const [tinh, setTinh] = useState([])
  const [huyen, setHuyen] = useState([])
  const [xa, setXa] = useState([])
  const [duong, setDuong] = useState([])
  const [loaiBDS, setloaiBDS] = useState([])
  const [quyHoach, setQuyHoach] = useState([])
  const [nguonGoc, setNguonGoc] = useState([])
  // const [images,setImages] = useState([]);
  const refImages = useRef(null);
  const defaultErrors = {
    // khachHang: null,
    // Email: null,
    tenKH: null,
    diDong: null,
    maTinh: null,
    maHuyen: null,
    maXa: null,
    tenDuong: null,
    soNha: null,
    maLBDS: null,
    // nvQuanLy: null,
    // nvKinhDoanh: null,
    dienTich: null,
    // maTT: null,
  }
  const [errors, setErrors] = useState(defaultErrors)
  const defaultdata = {
    maBC: null,
    maLBDS: null,
    ngangXD: 0,
    maQuyHoach: null,
    soTang: null,
    maPL: null,
    maXa: null,
    maHuyen: null,
    maTinh: null,
    maDuong: null,
    tenDuong: "",
    soNha: "",
    maKH: null,
    duongRong: null,
    dacTrung: "",
    isBan: true,
    dienTich: 0,
    donGia: 0,
    thanhTien: 0,
    tyLeMG: 0,
    cachTinhPMG: 0,
    daiXD: 0,
    sauXD: 0,
    kyHieu: "",
    maHuong: null,
    huongBanCong: null,
    soDK: "",
    trangThaiHDMG: 1,
    maLD: null,
    maNVKD: null,
    maNVQL: null,
    maTT: null,
    maNguon: null,
    thuongLuong: true,
    tangHam: true,
    isCanGoc: true,
    dauOto: true,
    isThangMay: true,
  }

  const [data, setData] = useState(defaultdata)
  const loadOptions = async () => {
    CommonService.getTinh().then(res => {
      setTinh(res.data || [])
    })
    AgencyService.ForSale.getLoaiBDS().then(res => {
      setloaiBDS(res.data || [])
    })

    CommonService.getQuyHoach().then(res => {
      setQuyHoach(res.data || [])
    })
    CommonService.getNguonGoc().then(res => {
      setNguonGoc(res.data || [])
    })
  }
  const loadHuyen = async maTinh => {
    let _res = await CommonService.getHuyen({ MaTinh: maTinh })
    setHuyen(_res.data || [])
  }
  const loadXaDuong = async maHuyen => {
    let _res = await CommonService.getXa({ MaHuyen: maHuyen })
    setXa(_res.data || [])
    let _resD = await CommonService.getDuong({ MaHuyen: maHuyen })
    setDuong(_resD.data || [])
  }

  useImperativeHandle(ref, () => ({
    // create: async () => {
    //   let _data = _.cloneDeep(data)
    //   loadOptions()
    //   refFunctionMode.current = "create"
    //   setData(_data)
    //   setShow(true)
    // },
    show: async () => {
      setErrors(defaultErrors)
      let _data = _.cloneDeep(data)
      loadOptions()
      // refFunctionMode.current = "create"
      setData({
        ..._data,
        maNhuCau: nhuCau.length > 0 && nhuCau[0].ID,
      })
      setShow(true)
    },
    update: async _item => {
      let _data = _.cloneDeep(data)
      let _resimages = await SanPhamService.NhuCauMuaThue.getImages({MaBC: _item.maBC});
      refImages.current = _.map(_resimages.data, "duongDan2")
      // setImages(_.map(_resimages.data, "duongDan2"));
      _data = {
        ..._data,
        ..._item,
        // images: _.map(_resimages.data, "duongDan2"),
        maHuong: _item.maHuongCua,
        huongBanCong: _item.maHuongBC,
        maNVKD: _item.maNVKD > 0 ? _item.maNVKD : null,
        maNVQL: _item.maNVQL > 0 ? _item.maNVQL : null,
        khachHang: {
          tenKH: _item.hoTenKH,
          maKH: _item.maKH,
          diaChi: _item.diaChi,
        },
        nvQuanLy: {
          hoTen: _item.nvQuanLy,
        },
        nvKinhDoanh: {
          hoTen: _item.nvKinhDoanh,
        },
        tenKH: _item.hoTenKH,
        diDong: _item.dienThoai,
        diaChi: _item.diaChi,
      }
      loadOptions()
      await loadHuyen(_item.maTinh)
      await loadXaDuong(_item.maHuyen)
      refFunctionMode.current = "update"
      setData(_data)
      setShow(true)
    },
  }))

  const applyChange = (p, v) => {
    let _data = _.cloneDeep(data)
    switch (p) {
      case "khachHang":
        _data.tenKH = v.tenKH
        _data.maKH = v.maKH
        _data.diDong = v.diDong
        _data.diaChi = v.diaChi

        _data[p] = v
        break
      case "nvQuanLy":
        _data.maNVQL = v.maNV
        _data[p] = v
        break
      case "dienTich":
        _data.thanhTien = v * _data.donGia
        _data[p] = v
        break
      case "donGia":
        _data.thanhTien = v * _data.dienTich
        _data[p] = v
        break
      case "nvKinhDoanh":
        _data.maNVKD = v.maNV
        _data[p] = v
        break
      case "maDuong":
        let d = duong.find(f => f.MaDuong === v)
        _data.tenDuong = d?.TenDuong
        _data[p] = v
        break
      case "maTinh":
        loadHuyen(v)
        _data[p] = v
        break
      case "maHuyen":
        loadXaDuong(v)
        _data[p] = v
        break
      default:
        _data[p] = v
        break
    }
    validate(_data)
    setData(_data)
  }
  const submit = async () => {
    let valid = validate()
    if (valid.length > 0) {
      return
    }
    let _data = _.cloneDeep(data)
    let _res = await AgencyService.ForSale.createCanBan(_data)
    if (_res.status === 2000) {
      onClickClose()
      afterSubmit()
    }
  }
  const validate = _data => {
    let _error = _.cloneDeep(errors)
    let _dt = _data || _.cloneDeep(data)
    let keys = Object.keys(_error)
    keys?.map(item => {
      _error[item] = _dt[item] || _dt[item] === 0 ? null : "Không được để trống"
    })
    setErrors(_error)
    let valids = Object.values(_error).filter(v => v)
    return valids
  }
  const onClickClose = () => {
    setShow(false)
    setData({})
    setErrors({})
  }

  // const affterUpload = async file => {
  //   let result = _.map(file, function (item) {
  //     return { linkImage: item }
  //   })
  //   await SanPhamService.NhuCauMuaThue.upload({
  //     MaBC: data?.maBC,
  //     RequestIMG: result,
  //   })
  // }

  const affterUpload = useCallback(async file => {
     let result = _.map(file, function (item) {
       return { linkImage: item }
     })
     await SanPhamService.NhuCauMuaThue.upload({
       MaBC: data?.maBC,
       RequestIMG: result,
     })
  }, [data])
  const onRemove = useCallback(async (file)=>{

  },[]);
  return (
    <Drawer
      title={true ? "Bán cho thuê" : "Thêm khách hàng"}
      open={show}
      onClose={onClickClose}
      width={1280}
      extra={
        <div style={{ textAlign: "right" }}>
          <Button onClick={() => submit()} type="primary">
            Lưu và đóng
          </Button>
        </div>
      }
    >
      {nhuCau?.map(item => {
        return (
          <Checkbox
            key={item.ID}
            value={item.ID}
            checked={data?.maNhuCau === item.ID}
            onChange={v => applyChange("maNhuCau", v.target.value)}
          >
            {item.TenLoai}
          </Checkbox>
        )
      })}
      <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
        Thông tin khách hàng
      </p>

      <Row gutter={[20, 12]}>
        <Col span={6}>
          <BEE_AutoComplete
            api={CustomerService.filter}
            params={{
              Offset: 1,
              Limit: 50,
            }}
            name="tenKH"
            diDong="diDong"
            require
            col={2}
            object={data}
            errors={errors}
            onChangeFunction={applyChange}
            prop="khachHang"
          />
        </Col>
        <Col span={6}>
          <InputCustom
            label="Họ tên (*)"
            applyChange={applyChange}
            prop="tenKH"
            value={data?.tenKH}
            placeholder="Nhập tên khách hàng"
          />
          <p style={{ color: "red" }}>{errors?.tenKH}</p>
        </Col>
        <Col span={6}>
          <InputCustom
            label="Di động :"
            applyChange={applyChange}
            prop="diDong"
            value={data?.diDong}
            placeholder="Nhập di động "
          />
          <p style={{ color: "red" }}>{errors?.diDong}</p>
        </Col>

        <Col span={6}>
          <InputCustom
            label="Địa chỉ:"
            applyChange={applyChange}
            prop="diaChi"
            value={data?.diaChi}
            placeholder="Nhập địa chỉ"
          />
          <p style={{ color: "red" }}>{errors?.diaChi}</p>
        </Col>
      </Row>
      <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
        Thông tin khác
      </p>
      <Row gutter={[20, 12]}>
        <Col span={6}>
          <SelectCustom
            label="Tỉnh"
            value={data.maTinh}
            prop="maTinh"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              tinh?.map(item => ({
                label: item.TenTinh,
                value: item.MaTinh,
              })) || []
            }
            onChange={e => setTinh(Number(e.target.value))}
          />
          <p style={{ color: "red" }}>{errors?.maTinh}</p>
        </Col>

        <Col span={6}>
          <SelectCustom
            label="Huyện"
            value={data.maHuyen}
            prop="maHuyen"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              huyen.map(item => ({
                label: item.TenHuyen,
                value: item.MaHuyen,
              })) || []
            }
            onChange={e => setHuyen(Number(e.target.value))}
          />
          <p style={{ color: "red" }}>{errors?.maHuyen}</p>
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Xã"
            value={data.maXa}
            prop="maXa"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              xa.map(item => ({ label: item.TenXa, value: item.MaXa })) || []
            }
          />
          <p style={{ color: "red" }}>{errors?.maXa}</p>
        </Col>
        <Col span={6}>
          <InputCustom
            label="Tên đường:"
            applyChange={applyChange}
            prop="tenDuong"
            value={data?.tenDuong}
            placeholder="Nhập tên đường"
          />
          <p style={{ color: "red" }}>{errors?.tenDuong}</p>
        </Col>
        <Col span={6}>
          <InputCustom
            label="Số nhà:"
            applyChange={applyChange}
            prop="soNha"
            value={data?.soNha}
            placeholder="Nhập số nhà"
          />
          <p style={{ color: "red" }}>{errors?.soNha}</p>
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Loại BĐS:"
            value={data.maLBDS}
            prop="maLBDS"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              loaiBDS.map(item => ({
                label: item.tenLBDS,
                value: item.maLBDS,
              })) || []
            }
            onChange={e => setloaiBDS(Number(e.target.value))}
          />
          <p style={{ color: "red" }}>{errors?.maLBDS}</p>
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Loại đường:"
            value={data.maLD}
            prop="maLD"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              loaiDuong1.map(item => ({
                label: item.tenLD,
                value: item.maLD,
              })) || []
            }
            // onChange={e => setLoaiDuong(Number(e.target.value))}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Pháp lý:"
            value={data.maPL}
            prop="maPL"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              phapLy1.map(item => ({
                label: item.tenPL,
                value: item.maPL,
              })) || []
            }
            // onChange={e => setPhapLy(Number(e.target.value))}
          />
        </Col>

        <Col span={6}>
          <InputNumberCustom
            label="Diện tích:"
            applyChange={applyChange}
            prop={"dienTich"}
            value={data?.dienTich}
            placeholder={"Nhập dien tich"}
          />
          <p style={{ color: "red" }}>{errors?.dienTich}</p>
        </Col>

        <Col span={6}>
          <InputNumberCustom
            label="Đơn giá:"
            applyChange={applyChange}
            prop={"donGia"}
            value={data?.donGia}
            placeholder={"Nhập đơn giá"}
          />
        </Col>

        <Col span={6}>
          <InputNumberCustom
            label="Thành tiền:"
            applyChange={applyChange}
            prop={"thanhTien"}
            value={data?.thanhTien}
            placeholder={"Nhập tổng tiền"}
          />
        </Col>

        <Col span={6}>
          <SelectCustom
            label="Trạng thái:"
            value={data.maTT}
            prop="maTT"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              trangThai1.map(item => ({
                label: item.tenTT,
                value: item.maTT,
              })) || []
            }
            // onChange={e => setTrangThai(Number(e.target.value))}
          />
        </Col>

        <Col span={6}>
          <InputNumberCustom
            label="Sau XD:"
            applyChange={applyChange}
            prop={"sauXD"}
            value={data?.sauXD}
            placeholder={"Nhập sau XD"}
          />
        </Col>

        <Col span={6}>
          <InputNumberCustom
            label="Dai XD:"
            applyChange={applyChange}
            prop={"daiXD"}
            value={data?.daiXD}
            placeholder={"Nhập dai XD"}
          />
        </Col>

        <Col span={6}>
          <InputNumberCustom
            label="Ngang XD:"
            applyChange={applyChange}
            prop={"ngangXD"}
            value={data?.ngangXD}
            placeholder={"Nhập ngang XD"}
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Số tầng:"
            applyChange={applyChange}
            prop={"soTang"}
            value={data?.soTang}
            placeholder={"Nhập số tầng"}
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Cách tính PMG:"
            applyChange={applyChange}
            prop={"cachTinhPMG"}
            value={data?.cachTinhPMG}
            placeholder={"Nhập cách tính PMG"}
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Tỷ lệ MG:"
            applyChange={applyChange}
            prop={"tyLeMG"}
            value={data?.tyLeMG}
            placeholder={"Nhập tỷ lệ MG"}
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="Đường rộng:"
            applyChange={applyChange}
            prop={"duongRong"}
            value={data?.duongRong}
            placeholder={"Nhập chiều rộng đường"}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Quy hoạch"
            value={data.maQuyHoach}
            prop="maQuyHoach"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              quyHoach.map(item => ({
                label: item.tenQuyHoach,
                value: item.id,
              })) || []
            }
            onChange={e => setQuyHoach(Number(e.target.value))}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Phương hướng"
            value={data.maHuong}
            prop="maHuong"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              phuongHuong1.map(item => ({
                label: item.tenPhuongHuong,
                value: item.maPhuongHuong,
              })) || []
            }
            // onChange={e => setPhuongHuong(Number(e.target.value))}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Nguồn"
            value={data.maNguon}
            prop="maNguon"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              nguonGoc.map(item => ({
                label: item.tenNguon,
                value: item.maNguon,
              })) || []
            }
            onChange={e => setNguonGoc(Number(e.target.value))}
          />
        </Col>
        <Col span={12}>
          <SelectCustom
            label="Hướng ban công"
            value={data.huongBanCong}
            prop="huongBanCong"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              phuongHuong1.map(item => ({
                label: item.tenPhuongHuong,
                value: item.maPhuongHuong,
              })) || []
            }
            // onChange={e => setPhuongHuong(Number(e.target.value))}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Nhân viên MG:"
            applyChange={applyChange}
            prop="maNVKD"
            value={data?.maNVKD}
            placeholder="Chọn nhân viên MG"
            options={staffs1}
            fieldNames={{ label: "hoTen", value: "maNV" }}
          />

          <p style={{ color: "red" }}>{errors?.maNVKD}</p>
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Nhân viên QL:"
            applyChange={applyChange}
            prop="maNVQL"
            value={data?.maNVQL}
            placeholder="Chọn nhân viên QL"
            options={staffs1}
            fieldNames={{ label: "hoTen", value: "maNV" }}
          />
          <p style={{ color: "red" }}>{errors?.maNVQL}</p>
        </Col>
        {/* <Col span={18}>
          <InputCustom
            customWidth2="15%"
            customWidth="100% "
            label="Đặc trưng:"
            applyChange={applyChange}
            prop="dacTrung"
            value={data?.dacTrung}
            placeholder="Nhập đặc trưng"
          />
        </Col> */}

        <Col span={24}>
          <p style={{ fontWeight: "600" }}>Đặc trưng:</p>
          <TextArea
            value={data?.dacTrung}
            onChange={e => applyChange("dacTrung", e.target.value)}
          />
        </Col>
      </Row>

      <Flex
        justifyContent="center"
        style={{ gap: "40px", marginTop: "10px", flexWrap: "wrap" }}
      >
        <div>
          <label> Thương lượng </label>
          <Radio.Group
            onChange={e => applyChange("thuongLuong", e.target.value)}
            value={data?.thuongLuong}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <Radio.Button value={true}>Có</Radio.Button>
            <Radio.Button value={false}>Không</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <label> Tầng hầm </label>
          <Radio.Group
            onChange={e => applyChange("tangHam", e.target.value)}
            value={data?.tangHam}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <Radio.Button value={true}>Có</Radio.Button>
            <Radio.Button value={false}>Không</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <label> Căn góc </label>
          <Radio.Group
            onChange={e => applyChange("isCanGoc", e.target.value)}
            value={data?.isCanGoc}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <Radio.Button value={true}>Có</Radio.Button>
            <Radio.Button value={false}>Không</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <label> Đậu ô tô </label>
          <Radio.Group
            onChange={e => applyChange("dauOto", e.target.value)}
            value={data?.dauOto}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <Radio.Button value={true}>Có</Radio.Button>
            <Radio.Button value={false}>Không</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <label> Thang máy </label>
          <Radio.Group
            onChange={e => applyChange("isThangMay", e.target.value)}
            value={data?.isThangMay}
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <Radio.Button value={true}>Có</Radio.Button>
            <Radio.Button value={false}>Không</Radio.Button>
          </Radio.Group>
        </div>
      </Flex>
      {data?.maBC && (
        <div className="mt-4">
          <p className="mb-3 fw-bold"> Thêm ảnh</p>
          <UploadComponent
            files={refImages.current}
            multiple={true}
            affterUpload={affterUpload}
            onRemove={onRemove}
          />
        </div>
      )}
    </Drawer>
  )
}
ForSale_Detail = forwardRef(ForSale_Detail)
export default ForSale_Detail
