export const GET_CHUNGCU = "GET_CHUNGCU"
export const GET_CHUNGCU_SUCCESS = "GET_CHUNGCU_SUCCESS"

export const GET_DATNEN = "GET_DATNEN"
export const GET_DATNEN_SUCCESS = "GET_DATNEN_SUCCESS"

export const GET_SANPHAM = "GET_SANPHAM";
export const GET_SANPHAM_SUCCESS = "GET_DATNEN_SUCCESS"

export const ADD_KHACHHANG = "ADD_KHACHHANG"
export const ADD_KHACHHANG_SUCCESS = "ADD_KHACHHANG_SUCCESS"

export const ADD_BOOKING = "ADD_BOOKING"
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS"
