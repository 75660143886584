import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import { InputMask } from 'primereact/inputmask';
import moment from 'moment';
import classNames from 'classnames';
import "./scss/XCalendar.scss";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

/**
 * props:
 *      - id: "" // input mask id
 *      - value: string or date // value
 *      - onChange: (value) => {} // on change value event
 *      - label: "" // if label's not null, convert to float label type
 *      - require: "" // add require label mark
 *      - className: "" // input mask class name
 *      - labelClassName: "" // label class name
 *      - placeholder: "" // input mask place holder
 *      - disabled: false
 *      - showDate: true
 *      - showTime: fasle
 *      - showSecond: false
 *      - display: "" // "month": only select month
 *      - style : {} // object style
 */
export const XCalendar = (props) => {
    const { value, onChange, label, require, className, labelClassName, id, placeholder, disabled
        , isSeparateLabel
        , showDate, showTime, showSecond, display, time, style, timeOnly } = props;
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const defaultTime = time || { hour: "00", minute: "00", second: "00" }
    const [selectedTime, setSelectedTime] = useState(defaultTime);
    const refSelectedValue = useRef();
    const [mask, setMask] = useState("");
    const refFormat = useRef(null);
    const refDateTimeSelection = useRef(true);
    const refShowingSelection = useRef(false);
    const [comboOptions, setComboOptions] = useState({
        month: [],
        year: [],
        hour: [],
        minute: [],
        second: []
    });

    useEffect(() => {
        // prepare mask, format
        let _format = getFormat();
        let _mask =
            _format.replaceAll("D", "9").replaceAll("M", "9").replaceAll("Y", "9")
                .replaceAll("H", "9").replaceAll("m", "9").replaceAll("s", "9");

        // prepare combo options
        let _comboOptions = {
            month: [
                { label: "01", key: "01" }, { label: "02", key: "02" }, { label: "03", key: "03" },
                { label: "04", key: "04" }, { label: "05", key: "05" }, { label: "06", key: "06" },
                { label: "07", key: "07" }, { label: "08", key: "08" }, { label: "09", key: "09" },
                { label: "10", key: "10" }, { label: "11", key: "11" }, { label: "12", key: "12" }
            ],
            year: [],
            hour: [
                { label: "00", key: "00" }, { label: "01", key: "01" }, { label: "02", key: "02" }, { label: "03", key: "03" },
                { label: "04", key: "04" }, { label: "05", key: "05" }, { label: "06", key: "06" }, { label: "07", key: "07" },
                { label: "08", key: "08" }, { label: "09", key: "09" }, { label: "10", key: "10" }, { label: "11", key: "11" },
                { label: "12", key: "12" }, { label: "13", key: "13" }, { label: "14", key: "14" }, { label: "15", key: "15" },
                { label: "16", key: "16" }, { label: "17", key: "17" }, { label: "18", key: "18" }, { label: "19", key: "19" },
                { label: "20", key: "20" }, { label: "21", key: "21" }, { label: "22", key: "22" }, { label: "23", key: "23" }
            ],
            minuteAndSecond: [
                { label: "00", key: "00" }, { label: "01", key: "01" }, { label: "02", key: "02" }, { label: "03", key: "03" },
                { label: "04", key: "04" }, { label: "05", key: "05" }, { label: "06", key: "06" }, { label: "07", key: "07" },
                { label: "08", key: "08" }, { label: "09", key: "09" }, { label: "10", key: "10" }, { label: "11", key: "11" },
                { label: "12", key: "12" }, { label: "13", key: "13" }, { label: "14", key: "14" }, { label: "15", key: "15" },
                { label: "16", key: "16" }, { label: "17", key: "17" }, { label: "18", key: "18" }, { label: "19", key: "19" },
                { label: "20", key: "20" }, { label: "21", key: "21" }, { label: "22", key: "22" }, { label: "23", key: "23" },
                { label: "24", key: "24" }, { label: "25", key: "25" }, { label: "26", key: "26" }, { label: "27", key: "27" },
                { label: "28", key: "28" }, { label: "29", key: "29" }, { label: "30", key: "30" }, { label: "31", key: "31" },
                { label: "32", key: "32" }, { label: "33", key: "33" }, { label: "34", key: "34" }, { label: "35", key: "35" },
                { label: "36", key: "36" }, { label: "37", key: "37" }, { label: "38", key: "38" }, { label: "39", key: "39" },
                { label: "40", key: "40" }, { label: "41", key: "41" }, { label: "42", key: "42" }, { label: "43", key: "43" },
                { label: "44", key: "44" }, { label: "45", key: "45" }, { label: "46", key: "46" }, { label: "47", key: "47" },
                { label: "48", key: "48" }, { label: "49", key: "49" }, { label: "50", key: "50" }, { label: "51", key: "51" },
                { label: "52", key: "52" }, { label: "53", key: "53" }, { label: "54", key: "54" }, { label: "55", key: "55" },
                { label: "56", key: "56" }, { label: "57", key: "57" }, { label: "58", key: "58" }, { label: "59", key: "59" }
            ]
        }, v;

        // year
        for (let i = (new Date()).getFullYear() + 5; i >= 1970; i--) {
            v = i.toString();
            _comboOptions.year.push({ label: v, key: v });
        }

        setComboOptions(_comboOptions);
        refFormat.current = _format;
        setMask(_mask);
    }, [])

    useEffect(() => {
        let _date = null, _time = { ...defaultTime }, _dateInString = null;
        try {
            if (value) {
                if (value instanceof Date) {
                    _date = value;
                } else {
                    _date = new Date(value);
                }

                _time.hour = `00${_date.getHours().toString()}`.slice(-2);
                _time.minute = `00${_date.getMinutes().toString()}`.slice(-2);
                _time.second = `00${_date.getSeconds().toString()}`.slice(-2);
                let _format = getFormat();
                // _dateInString = CommonFunction.formatDate(_date, _format);
            }
        } catch (error) {
            console.log("x-calendar", error);
        }

        // don't know why but if set directly will raise error
        setTimeout(() => {
            refSelectedValue.current = _dateInString;
            setSelectedDate(_date);
            setSelectedTime(_time);
            setSelectedValue(_dateInString);
        }, 100);

    }, [props])

    /**
     * get date time format
     * @returns
     */
    const getFormat = () => {
        if (display === "month") {
            return "MM/YYYY";
        } else if (display === 'year') {
            return "YYYY";
        } else {
            // return CommonFunction.getDateTimeFormat(showDate !== false, showTime === true, showSecond === true).trim();
        }
    }

    /**
     * on change value
     * @param {*} value
     */
    const onChangeValue = (value) => {
        refSelectedValue.current = value;
        setSelectedValue(value);
    }

    const onBlur = () => {
        let isValid = false, valueInDate = null;
        try {
            if (refSelectedValue.current.indexOf("_") === -1) {
                let _momentDate = moment(refSelectedValue.current, refFormat.current);
                isValid = _momentDate._isValid;
                if (isValid) {
                    valueInDate = _momentDate.toDate();
                }
            }
        } catch (error) {
            console.log("x-calendar", error);
        }

        if (isValid) {
            callOnChangeEvent(valueInDate);
        } else {
            refSelectedValue.current = null;
            setSelectedValue(null);
            callOnChangeEvent(null);
        }
    }

    /**
     * call onchange event
     * @param {*} value
     */
    const callOnChangeEvent = (_date) => {
        if (onChange && typeof onChange === "function") {
            onChange(_date);
        }
    }

    /**
     * show Date selection
     */
    const showSelection = (e) => {
        if (!disabled && !refShowingSelection.current) {
            refShowingSelection.current = true;
            let _date = null;
            let _time = { ...defaultTime };
            try {
                if (refSelectedValue.current) {
                    let _momentDate = moment(refSelectedValue.current, refFormat.current);
                    if (_momentDate.isValid) {
                        _date = _momentDate.toDate();
                    }
                    if (showTime || showSecond) {
                        _time.hour = `00${_date.getHours().toString()}`.slice(-2);
                        _time.minute = `00${_date.getMinutes().toString()}`.slice(-2);
                        _time.second = `00${_date.getSeconds().toString()}`.slice(-2);
                    }
                }
            } catch (error) {
                console.log("x-calendar", error);
            }
            setSelectedDate(_date);
            setSelectedTime(_time);
            refDateTimeSelection.current.toggle(e);
        }
    }

    /**
     * on date select
     * @param {*} value
     */
    const onDateSelect = (value) => {
        let _date = null;
        if (value) {
            _date = value;
            _date.setHours(selectedTime.hour);
            _date.setMinutes(selectedTime.minute);
            _date.setSeconds(selectedTime.second);
        }
        callOnChangeEvent(_date);
    }

    /**
     * on selection hide
     */
    const onSelectionHide = () => {
        refShowingSelection.current = false;
    }

    /**
     * on time change
     * @param {*} key
     * @param {*} value
     */
    const onTimeChange = (key, value) => {
        let _date = null;
        if (selectedDate && selectedDate.toString() !== "Invalid Date" && selectedDate instanceof Date) {
            _date = selectedDate;
        } else {
            _date = new Date();
            _date.setHours(0);
            _date.setMinutes(0);
            _date.setSeconds(0);
        }

        switch (key) {
            case "hour":
                _date.setHours(value);
                break;
            case "minute":
                _date.setMinutes(value);
                break;
            case "second":
                _date.setSeconds(value);
                break;
            default:
                break;
        }
        callOnChangeEvent(_date);
    }

    /**
     * on month change
     * @param {*} key
     * @param {*} value
     */
    const onMonthChange = (key, value) => {
        let _date = null;
        if (selectedDate && selectedDate.toString() !== "Invalid Date" && selectedDate instanceof Date) {
            _date = selectedDate;
        } else {
            _date = new Date();
            _date.setHours(0);
            _date.setMinutes(0);
            _date.setSeconds(0);
        }

        switch (key) {
            case "month":
                _date.setMonth(parseInt(value) - 1);
                break;
            case "year":
                _date.setFullYear(parseInt(value));
                break;
            default:
                break;
        }
        callOnChangeEvent(_date);
    }

    const renderInputMask = () => {
        switch (display) {
            case "month":
                return (<>
                    <InputMask
                        // id={inputMaskId.current}
                        disabled={disabled === true}
                        mask={mask}
                        placeholder={placeholder || null}
                        value={selectedValue}
                        onChange={(e) => onChangeValue(e.value)}
                        onBlur={(e) => onBlur()}
                        className={`x-calendar-mask ${className} ${isSeparateLabel ? 'separate-label' : ''}`}
                    ></InputMask>
                    <div
                        className="x-calendar-icon bx bx-calendar"
                        onClick={(e) => showSelection(e)}
                    ></div>
                    <OverlayPanel
                        ref={refDateTimeSelection}
                        className="x-calendar-panel"
                        onHide={() => onSelectionHide()}
                    >
                        <div className='p-grid p-formgrid p-fluid' style={{ width: "300px" }}>
                            <div className='p-field p-col-4'>
                                <span>Tháng</span>
                            </div>
                            <div className='p-field p-col-8'>
                                <span>Năm</span>
                            </div>
                            <div className='p-field p-col-4'>
                                <Dropdown
                                    optionLabel='label'
                                    optionValue='key'
                                    options={comboOptions.month}
                                    value={selectedDate ? (selectedDate.getMonth() + 1).toString().padStart(2, "0") : null}
                                    onChange={(e) => onMonthChange("month", e.value)}
                                    filter
                                ></Dropdown>
                            </div>
                            <div className='p-field p-col-8'>
                                <Dropdown
                                    optionLabel='label'
                                    optionValue='key'
                                    options={comboOptions.year}
                                    value={selectedDate ? selectedDate.getFullYear().toString() : null}
                                    onChange={(e) => onMonthChange("year", e.value)}
                                    filter
                                ></Dropdown>
                            </div>
                        </div>
                    </OverlayPanel>
                </>)
            case "year":
                return (<>
                    <InputMask
                        // id={inputMaskId.current}
                        disabled={disabled === true}
                        mask={mask}
                        placeholder={placeholder || null}
                        value={selectedValue}
                        onChange={(e) => onChangeValue(e.value)}
                        onBlur={(e) => onBlur()}
                        className={`x-calendar-mask ${className} ${isSeparateLabel ? 'separate-label' : ''}`}
                    ></InputMask>
                    <div
                        className="x-calendar-icon bx bx-calendar"
                        onClick={(e) => showSelection(e)}
                    ></div>
                    <OverlayPanel
                        ref={refDateTimeSelection}
                        className="x-calendar-panel"
                        onHide={() => onSelectionHide()}
                    >
                        <div className='p-grid p-formgrid p-fluid' style={{ width: "300px" }}>
                            <div className='p-field p-col-8'>
                                <span>Năm</span>
                            </div>
                            <div className='p-field p-col-8'>
                                <Dropdown
                                    optionLabel='label'
                                    optionValue='key'
                                    options={comboOptions.year}
                                    value={selectedDate ? selectedDate.getFullYear().toString() : null}
                                    onChange={(e) => onMonthChange("year", e.value)}
                                    filter
                                ></Dropdown>
                            </div>
                        </div>
                    </OverlayPanel>
                </>)
            default:
                return (<>
                    <InputMask
                        // id={inputMaskId.current}
                        disabled={disabled === true}
                        mask={mask}
                        placeholder={placeholder || null}
                        value={selectedValue}
                        onChange={(e) => onChangeValue(e.value)}
                        onBlur={(e) => onBlur()}
                        className={`x-calendar-mask ${className} ${isSeparateLabel ? 'separate-label' : ''}`}
                    ></InputMask>
                    <div
                        className={classNames({
                            "x-calendar-icon bx": true,
                            "bx-calendar": !showTime && !showSecond,
                            "bx-time": showTime || showSecond
                        })}
                        onClick={(e) => showSelection(e)}
                    ></div>
                    <OverlayPanel
                        ref={refDateTimeSelection}
                        className="x-calendar-panel"
                        onHide={() => onSelectionHide()}
                    >
                        {showDate !== false &&
                            <Calendar
                                locale="vi-VN"
                                value={selectedDate}
                                onChange={(e) => onDateSelect(e.value)}
                                inline
                            ></Calendar>
                        }
                        {(showTime || showSecond) && <>
                            <hr className='p-my-2'></hr>
                            <div className='p-grid p-formgrid p-fluid'>
                                <div className='p-field p-col'>
                                    Giờ
                                </div>
                                <div className='p-field p-col'>
                                    Phút
                                </div>
                                {showSecond &&
                                    <div className='p-field p-col'>
                                        Giây
                                    </div>
                                }
                            </div>
                            <div className='p-grid p-formgrid p-fluid'>
                                <div className='p-field p-col'>
                                    <Dropdown
                                        placeholder="00"
                                        optionLabel='label'
                                        optionValue='key'
                                        options={comboOptions.hour}
                                        value={selectedTime ? selectedTime.hour : "00"}
                                        onChange={(e) => onTimeChange("hour", e.value)}
                                        filter
                                    ></Dropdown>
                                </div>
                                <div className='p-field p-col'>
                                    <Dropdown
                                        placeholder="00"
                                        optionLabel='label'
                                        optionValue='key'
                                        options={comboOptions.minuteAndSecond}
                                        value={selectedTime ? selectedTime.minute : "00"}
                                        onChange={(e) => onTimeChange("minute", e.value)}
                                        filter
                                    ></Dropdown>
                                </div>
                                {showSecond && <>
                                    <div className='p-field p-col'>
                                        <Dropdown
                                            placeholder="00"
                                            optionLabel='label'
                                            optionValue='key'
                                            options={comboOptions.minuteAndSecond}
                                            value={selectedTime ? selectedTime.second : "00"}
                                            onChange={(e) => onTimeChange("second", e.value)}
                                            filter
                                        ></Dropdown>
                                    </div>
                                </>}
                            </div>
                        </>}

                    </OverlayPanel>
                </>)
        }
    }

    //#endregion
    if (!label) {
        return (<div className="x-calendar" style={style}>{renderInputMask()}</div>);
    } else {
        return (
            <>
                {!isSeparateLabel &&
                    <div className="x-calendar" style={style}>
                        <span className="p-float-label">
                            {renderInputMask()}
                            <label
                                // htmlFor={inputMaskId.current} 
                                className={`${require ? "require" : ""} ${labelClassName || ""}`}>{label}</label>
                        </span>
                    </div>
                }
                {isSeparateLabel &&
                    <span className="x-calendar-separate-label" style={{ ...style, ...{ position: 'relative' } }}>
                        <label
                            // htmlFor={inputMaskId.current} 
                            className={`${require ? "require" : ""} ${labelClassName || ""}`}>{label}</label>
                        {renderInputMask()}
                    </span>
                }
            </>
        )
    }
}
