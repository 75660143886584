import * as types from "./actionTypes"

export const uploadImage = payload => ({
  type: types.UPLOAD_IMAGE,
  payload,
})
export const getKhu = payload => ({
  type: types.GET_KHU,
  payload,
})
export const getKhuByListDA = payload => ({
  type: types.GET_KHU_BY_LISTDA,
  payload,
})
export const getPhanKhu = payload => ({
  type: types.GET_PHANKHU,
  payload,
})
export const getBDSTrangThai = payload => ({
  type: types.GET_BDS_TRANGTHAI,
  payload,
})
export const getSanGiaoDich = () => ({
  type: types.GET_SANGIAODICH
})