import {
  HomeOutlined,
  AppstoreOutlined,
  FileOutlined,
  SettingOutlined,
  UserOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons"

const routes = [
  {
    id: 2,
    label: "Dự án",
    key: "projects",
    icon: <AppstoreOutlined />,
    children: [
      { label: "Danh sách dự án", key: "list", path: "/projects", id: 1 },
    ],
  },
  {
    id: 60,
    label: "Sản phẩm",
    key: "san-pham",
    icon: <FileOutlined />,

    children: [
      { label: "Đất nền biệt thự", key: "land", path: "/thaptang", id: 4 },
      {
        label: "Sơ đồ phân lô",
        key: "so-do-phan-lo",
        path: "/so-do-phan-lo",
        id: 189,
      },
      {
        label: "Xem tổng quan",
        key: "banggiatructuyen",
        path: "/banggiatructuyen",
        id: 190,
      },

      {
        id: 297,
        label: "Căn hộ chung cư",
        key: "chungcu",
        path: "/chungcu",
      },
      {
        label: "Sản phẩm bán/cho thuê",
        key: "forsale",
        path: "/forsale",
        id: 344,
      },

      {
        id: 345,
        label: "Nhu cầu mua/thuê",
        key: "nhu-cau-mua-thue",
        path: "/nhu-cau-mua-thue",
      },
      {
        id: 403,
        label: "Bảng giá sản phẩm",
        key: "price",
        path: "/price-list",
      },
    ],
  },
  {
    id: 6,
    label: "Hợp đồng",
    key: "contracts",
    icon: <FileOutlined />,
    children: [
      {
        label: "Danh sách lock căn",
        key: "transfer",
        path: "/danh-sach-lock-can",
        id: 494,
      },
      { label: "Giữ chỗ-Booking", key: "booking", path: "/booking", id: 27 },
      { label: "Đặt cọc", key: "deposit", path: "/deposit", id: 28 },
      { label: "Góp vốn", key: "gop-von", path: "/gop-von", id: 26 },
      {
        label: "Hợp đồng mua bán",
        key: "contract",
        path: "/contract",
        id: 29,
      },
      {
        label: "Thanh lý",
        key: "liquidation",
        path: "/liquidation",
        id: 31,
      },
      { label: "Chuyển nhượng", key: "transfer", path: "/transfer", id: 32 },
      {
        label: "Hợp đồng môi giới",
        key: "transfer",
        path: "/hop-dong-moi-gioi",
        id: 339,
      },
    ],
  },

  {
    id: 61,
    label: "Bàn giao",
    key: "ban-giao-cdt",
    icon: <FileOutlined />,

    children: [
      {
        label: "Quỹ bàn giao",
        key: "/handover-fund",
        path: "/handover-fund",
        id: 209,
      },
      {
        label: "Biên bản bàn giao",
        key: "handover",
        path: "/handover",
        id: 210,
      },
      {
        id: 228,
        label: "Quản lý sổ đỏ",
        key: "so-do",
        path: "/so-do",
      },
    ],
  },
  {
    id: 12,
    label: "Kế toán",
    key: "finance",
    icon: <FileOutlined />,
    children: [
      { label: "Phiếu thu", key: "receipts", path: "/receipts", id: 33 },
      { label: "Phiếu chi", key: "payment", path: "/payment", id: 34 },
      { label: "Chi tiết sổ quỹ", key: "ledger", path: "/so-quy", id: 219 },
      // { label: "Hoá đơn", key: "invoice", path: "/list-bill" },
    ],
  },
  {
    id: 21,
    label: "Công nợ",
    key: "debit",
    icon: <FileOutlined />,
    children: [
      {
        label: "Tổng hợp công nợ",
        key: "summary",
        path: "/debit/list",
        id: 120,
      },
      {
        label: "Công nợ theo đợt",
        key: "detail",
        path: "/debit/detail",
        id: 300,
      },
    ],
  },
  {
    id: 14,
    label: "CRM",
    key: "crm",
    icon: <UserOutlined />,

    children: [
      {
        label: "Khách hàng",
        key: "customers",
        path: "/customer",
        id: 9,
      },
    ],
  },
  {
    id: 15,
    label: "Công việc",
    key: "cong-viec",
    icon: <UserOutlined />,

    children: [],
  },

  {
    id: 35,
    label: "Danh mục",
    key: "danh-muc",
    icon: <FileOutlined />,

    children: [
      {
        label: "Danh mục MGL",
        key: "moi-gioi-le",
        path: "/moi-gioi-le",
        id: 178,
      },
    ],
  },

  {
    id: 8,
    label: "Hệ thống",
    key: "system",
    icon: <SettingOutlined />,
    children: [
      { label: "Danh sách nhân viên", key: "staff", path: "/staff", id: 15 },
      {
        id: 62,
        label: "Phân quyền",
        key: "permission",
        path: "/permission",
      },
      {
        label: "Phân quyền công ty",
        key: "phan-quyen-cong-ty",
        path: "/phan-quyen-cong-ty",
        id: 526,
      },
      {
        label: "Công ty thành viên",
        key: "cong-ty-thanh-vien",
        path: "/cong-ty-thanh-vien",
        id: 214,
      },
      {
        label: "Lịch sử thông báo",
        key: "lich-su-notification",
        path: "/lich-su-notification",
        id: 62,
      },
      // {
      //   label: "Công ty / sàn giao dịch",
      //   key: "company",
      //   path: "/company",
      // },
      // {
      //   label: "Danh mục MGL",
      //   key: "catalogy",
      //   path: "/catalogy-agent",
      // },
    ],
  },
  {
    id: 3,
    label: "Báo cáo",
    key: "bao-cao",
    icon: <FileOutlined />,

    children: [],
  },
]

export default routes
