import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { editStaff, addStaff, getStaffs } from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
const FormModal = props => {
  const { isEdit, isOpen, toggle, staffList } = props
  const { companys, departments, positions, groups, groupsPermission } =
    useSelector(state => ({
      companys: state.Staff.companys,
      departments: state.Staff.departments,
      positions: state.Staff.positions,
      groups: state.Staff.groups,
      groupsPermission: state.Staff.groupsPermission,
    }))
  const dispatch = useDispatch()
  const handleValidOrderSubmit = (e, values) => {
    if (isEdit) {
      const updateStaff = {
        MaNV: staffList.MaNV,
        HoTen: values.HoTen,
        MaSo: values.MaSo,
        SoCMND: values.SoCMND,
        NgayCap: values.NgayCap,
        NoiCap: values.NoiCap,
        NgaySinh: values.NgaySinh,
        DienThoai: values.DienThoai,
        Email: values.Email,
        DiaChiLL: values.DiaChiLL,
        MaCT: Number(values.MaCT),
        MaPB: Number(values.MaPB),
        MaCV: Number(values.MaCV),
        MaNKD: Number(values.MaNKD),
        PerID: Number(values.PerID),
      }
      dispatch(editStaff(updateStaff))
    } else {
      const newOrder = {
        HoTen: values["HoTen"],
        MaSo: values["MaSo"],
        SoCMND: values["SoCMND"],
        NgayCap: values["NgayCap"],
        NoiCap: values["NoiCap"],
        NgaySinh: values["NgaySinh"] === "" ? null : values["NgaySinh"],
        DienThoai: values["DienThoai"],
        Email: values["Email"],
        DiaChiLL: values["DiaChiLL"],
        MaCT: Number(values["MaCT"]) === 0 ? null : Number(values["MaCT"]),
        MaPB: Number(values["MaPB"]) === 0 ? null : Number(values["MaPB"]),
        MaCV: Number(values["MaCV"]) === 0 ? null : Number(values["MaCV"]),
        MaNKD: Number(values["MaNKD"]) === 0 ? null : Number(values["MaNKD"]),
        PerID: Number(values["PerID"]) === 0 ? null : Number(values["PerID"]),
      }
      // save new order
      dispatch(addStaff(newOrder))
    }
    dispatch(getStaffs({ inputSearch: "", Offset: 1, Limit: 1000 }))
    toggle()
  }
  return (
    <Modal size="lg" isOpen={isOpen}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? "Sửa nhân viên" : "Thêm nhân viên"}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidOrderSubmit}>
          <Row form>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <AvField
                    name="HoTen"
                    label="Họ tên"
                    type="text"
                    errorMessage="Nhập họ tên"
                    validate={{
                      required: { value: true },
                    }}
                    value={staffList?.HoTen || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="MaSo"
                    label="Mã số"
                    type="text"
                    errorMessage="Vui lòng nhập mã số"
                    validate={{
                      required: { value: true },
                    }}
                    value={staffList?.MaSo || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="SoCMND"
                    label="Số CMND"
                    type="text"
                    value={staffList?.SoCMND || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="NgayCap"
                    label="Ngày cấp"
                    type="date"
                    value={staffList?.NgayCap || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="NoiCap"
                    label="Nơi cấp"
                    type="text"
                    value={staffList?.NoiCap || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="NgaySinh"
                    label="Ngày sinh"
                    type="date"
                    value={staffList?.NgaySinh || null}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="DienThoai"
                    label="Điện thoại"
                    type="text"
                    value={staffList?.DienThoai || ""}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <AvField
                    name="Email"
                    label="Email"
                    type="text"
                    errorMessage="Email không được để trống"
                    validate={{
                      required: { value: true },
                    }}
                    value={staffList?.Email || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="DiaChiLL"
                    label="Địa chỉ LL"
                    type="text"
                    value={staffList?.DiaChiLL || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="MaCT"
                    label="Công ty"
                    type="select"
                    className="form-select"
                    errorMessage="Vui lòng chọn công ty"
                    validate={{
                      required: { value: true },
                    }}
                    value={staffList?.MaCT || 0}
                  >
                    <option value={0}>Chọn công ty</option>
                    {companys &&
                      companys.map(item => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.tenCT}
                          </option>
                        )
                      })}
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    name="MaPB"
                    label="Phòng ban"
                    type="select"
                    className="form-select"
                    // errorMessage="Vui lòng chọn công ty"
                    // validate={{
                    //   required: { value: true },
                    // }}
                    value={staffList?.MaPB || 0}
                  >
                    <option value={0}>Chọn phòng ban</option>
                    {departments &&
                      departments.map(item => {
                        return (
                          <option key={item.MaPB} value={item.MaPB}>
                            {item.TenPB}
                          </option>
                        )
                      })}
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    name="MaCV"
                    label="Chức vụ"
                    type="select"
                    className="form-select"
                    // errorMessage="Vui lòng chọn công ty"
                    // validate={{
                    //   required: { value: true },
                    // }}
                    value={staffList?.MaCV || 0}
                  >
                    <option value={0}>Chọn chức vụ</option>
                    {positions &&
                      positions.map(item => {
                        return (
                          <option key={item.MaCV} value={item.MaCV}>
                            {item.TenCV}
                          </option>
                        )
                      })}
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    name="MaNKD"
                    label="Nhóm kinh doanh"
                    type="select"
                    className="form-select"
                    // errorMessage="Vui lòng chọn công ty"
                    // validate={{
                    //   required: { value: true },
                    // }}
                    value={staffList?.MaNKD || 0}
                  >
                    <option value={0}>Chọn nhóm</option>
                    {groups &&
                      groups.map(item => {
                        return (
                          <option key={item.MaNKD} value={item.MaNKD}>
                            {item.TenNKD}
                          </option>
                        )
                      })}
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    name="PerID"
                    label="Nhóm quyền"
                    type="select"
                    className="form-select"
                    errorMessage="Vui lòng chọn nhóm quyền"
                    validate={{
                      required: { value: true },
                    }}
                    value={staffList?.PerID || 0}
                  >
                    <option value={0}>Chọn nhóm quyền</option>
                    {groupsPermission &&
                      groupsPermission.map(item => {
                        return (
                          <option key={item.perID} value={item.perID}>
                            {item.perName}
                          </option>
                        )
                      })}
                  </AvField>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Lưu
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

FormModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FormModal
