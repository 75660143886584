import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getPermision, addPermision, editPermision } from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
const FormModal = props => {
  const { isEdit, isOpen, toggle, detail } = props
  const dispatch = useDispatch()
  const handleValidSubmit = (e, values) => {
    if (isEdit) {
      const update = {
        PerID: detail.PerID,
        PerName: values.PerName,
        Description: values.Description,
        IsAgent: false,
      }
      dispatch(editPermision(update))
    } else {
      const newPer = {
        PerName: values["PerName"],
        Description: values["Description"],
        IsAgent: false,
      }
      // save new order
      dispatch(addPermision(newPer))
    }
    dispatch(getPermision())
    toggle()
  }
  return (
    <Modal size="md" isOpen={isOpen}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? "Sửa nhóm quyền" : "Thêm nhóm quyền"}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidSubmit}>
          <Row form>
            <div className="mb-3">
              <AvField
                name="PerName"
                label="Permission Name"
                type="text"
                errorMessage="Nhập tên quyền"
                validate={{
                  required: { value: true },
                }}
                value={detail?.PerName || ""}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="Description"
                label="Description"
                type="text"
                errorMessage="Nhập diễn giải"
                validate={{
                  required: { value: true },
                }}
                value={detail?.Description || ""}
              />
            </div>
            {/* <div className="mb-3">
              <AvField
                name="IsAgent"
                label="IsAgent"
                type="select"
                className="form-select"
                value={detail?.IsAgent || 0}
              >
                <option value={0}>Chọn  </option>
                <option value={1}>IsAgent</option>
              </AvField>
            </div> */}
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Lưu
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

FormModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FormModal
