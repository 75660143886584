import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import QuyBanGiao_Filter from "./QuyBanGiao_Filter"
import { handleValidDate, Format_Currency, formatNumberPT } from "../../../common/common"
import { Button } from "primereact/button"
import { Col, Container, Row } from "reactstrap"
import Pagination from "../../../components/Common/Pagination"
import { ContractService } from "services/Contract"
import QuyBanGiao_Detail from "./QuyBanGiao_Detail"
import LoadingBar from "components/LoadingBar";
import { Badge } from 'primereact/badge';

const QuyBanGiao = props => {
  const refDetail = useRef(null)
  const refHistory = useRef(null)
  const [loading, setLoading] = useState(false)
  const defaultCondition = {
    TuNgay: "2019-01-04",
    DenNgay: "2024-10-13",
    inputSearch: "",
    DuAn: [],
    Offset: 1,
    Limit: 20,
  }
  const [data, setData] = useState([])
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    _filter.DuAn =
      _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await ContractService.BanGiao.getQuyBanGiao(_filter)
    setData(res?.data || [])
    setLoading(false)
  }
  useEffect(() => {
    filter(filterCondition)
  }, [])
  const afterChangeFilter = _filter => {
    setFilterCondition(_filter)
    filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "search":
        _filter.inputSearch = v
        break
      case "page":
        _filter.Offset = v
        break
      case "itemPerPage":
        _filter.Offset = 1
        _filter.Limit = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }
  const [item, setItem] = useState([])
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maPGC !== row.maPGC
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row,i) => (
          <Link to="#" className="text-body fw-bold">
            {i+1}
          </Link>
        ),
      },
      {
        dataField: "ngayKy",
        text: "Ngày ký",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayKyHD),
      },
      {
        dataField: "soHDMB",
        text: "Số HDMB",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <span
              // onClick={() => refHistory.current.view(row)}
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                color: "#556ee6",
              }}
            >
              {row.soHDMB}
            </span>
          )
        },
      },
      {
        dataField: "kyHieu",
        text: "Mã sản phẩm",
        sort: true,
      },
      {
        dataField: "tenTT",
        text: "Trạng thái",
        sort: true,
        formatter: (cellContent, row) => (
          <div
           className="shadow-1 rounded"
            style={{
              backgroundColor: row.mauNen,
              padding: "5px",
            }}
          >
            {row.tenTT}
          </div>
        ),
      },
      {
        dataField: "ngayBGDK",
        text: "Từ ngày",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayBGDK),
      },
      {
        dataField: "denNgay",
        text: "Từ ngày",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.denNgay),
      },
      {
        text: "DT hợp đồng",
        sort: true,
        formatter: (cellContent, row) => row.dienTichHD,
      },
      {
        text: "DT bàn giao",
        sort: true,
        formatter: (cellContent, row) => row.dienTichBG,
      },
      {
        text: "Tỷ lệ tăng giảm",
        sort: true,
        formatter: (cellContent, row) => <span>{row.tyLeTangGiam} %</span>,
      },
      {
        text: "% Tiến độ",
        sort: true,
        formatter: (cellContent, row) => <span>{formatNumberPT(row.tyLeTTTienDo)} %</span>,
      },
      {
        text: "% Tiến độ PBT",
        sort: true,
        formatter: (cellContent, row) => <span>{row.tyLeTTPBT} %</span>,
      },
      {
        dataField: "hoTenKH",
        text: "Tên KH",
        sort: true,
      },
      {
        dataField: "diDong",
        text: "Di động",
        sort: true,
      },
     
      {
        dataField: "diaChi",
        text: "Địa chỉ",
        sort: true,
      },
      {
        dataField: "nguoiNhap",
        text: "Người tạo",
        sort: true,
      },
      {
        dataField: "ngayTao",
        text: "Ngày tạo",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayTao),
      }
    ]
  }, [])
  const rowStyle = (row, rowIndex) => {
    return rowIndex % 2 !== 0
      ? {
          background: "#f6f8fb",
        }
      : null
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Quỹ bàn giao | Beesky</title>
        </MetaTags>
        <Container fluid>
          <div
            style={{
              background: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
            }}
            className="px-2 my-1 shadow-2"
          >
            <div>
              <Button
                label="Thêm"
                icon="mdi mdi-plus"
                className="p-button-outlined p-button-success mr-1"
                onClick={() => refDetail.current.create()}
              />
            </div>
            <div>
              <QuyBanGiao_Filter
                filterCondition={filterCondition}
                afterChangeFilter={afterChangeFilter}
              />
            </div>
          </div>
          <React.Fragment>
            <LoadingBar loading={loading} />
            <div className="table-responsive table-scrollable shadow-2">
              <BootstrapTable
                data={data === "[]" ? new Array([]) : data}
                keyField="maPGC"
                responsive
                columns={Columns()}
                bordered={false}
                striped={false}
                defaultSorted={defaultSorted}
                rowStyle={rowStyle}
                // selectRow={selectRow}
                // rowClasses ={rowClasses}
                classes={
                  "table align-middle  table-nowrap table-check table-hover table-fixed "
                }
                headerWrapperClasses={"table-light"}
              />
            </div>
            <Row className="align-items-md-center mt-30">
              <Col className="inner-custom-pagination d-flex">
                <div>
                  <select
                    className="form-select"
                    value={filterCondition.Limit}
                    onChange={e =>
                      onChangeFilter("itemPerPage", Number(e.target.value))
                    }
                  >
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                  </select>
                </div>
                <Col className="pagination pagination-rounded justify-content-end  inner-custom-pagination">
                  <Pagination
                    totalPage={Math.ceil(
                      data[0]?.totalRows / filterCondition.Limit
                    )}
                    changePage={i => onChangeFilter("page", i)}
                  />
                </Col>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>
      <QuyBanGiao_Detail ref={refDetail} />
    </React.Fragment>
  )
}

QuyBanGiao.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(QuyBanGiao))
