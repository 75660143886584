import { del, get, post, put } from "../../helpers/api_helper"

export const getGiuChoAPI = payload => post("/admin/hop-dong/giu-cho", payload)
export const getDatCocAPI = payload => post("/admin/hop-dong/dat-coc", payload)
export const getTrangThaiHDAPI = payload => post("/admin/hop-dong/trang-thai", payload)
export const getBangGiaTTAPI = payload => post("/beeland/block", payload)
export const getTangBGAPI = payload => post("/admin/banggia/get-tang", payload)
export const getVitriBGAPI = payload => post("/admin/banggia/get-vitri", payload)
export const getKhuChiTietBGAPI = payload =>
  post("/admin/banggia/get-khu-chitiet", payload)
