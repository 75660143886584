import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import _ from 'lodash';
function Debt_TheoDot_Detail(props, ref) {
	const { afterSubmit, reloadPage, openTask } = props;
	const refFunctionMode = useRef(null);
	const [show, setShow] = useState(false);
	const defaultdata = {
	}
	const [data, setData] = useState(defaultdata);
	useImperativeHandle(ref, () => ({
		create: async () => {
			let _data = _.cloneDeep(data);
			refFunctionMode.current = 'create';
			setData(_data);
			setShow(true);
		},
		update: async (_item) => {
			let _data = _.cloneDeep(data)
			refFunctionMode.current = 'update';
			setData(_data);
			setShow(true);
		},
		view: async (_item) => {
			refFunctionMode.current = 'view';
			setData(_item);
			setShow(true);
		}
	}));
	const cancel = () => {
		if (!openTask && typeof reloadPage == "function") {
			reloadPage();
		}
		setShow(false);
		setData(defaultdata)
	};
	try {
		return (
			<Dialog
				visible={show}
				header={
					refFunctionMode.current === 'create'
						? 'Thêm'
						: refFunctionMode.current === 'update' ?
							'Sửa' : 'Xem'
				}
				className={`${show ? 'fadeinleft' : 'fadeoutleft'} animation-duration-300 animation-iteration-1`}
				modal
				maximizable
				maximized
				onHide={() => cancel()}
			>
			</Dialog>
		);
	} catch (error) {
	}
}
Debt_TheoDot_Detail = forwardRef(Debt_TheoDot_Detail);
export default Debt_TheoDot_Detail;
