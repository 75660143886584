import React, { useEffect, useState, useRef, useMemo } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Container } from "reactstrap"
import _ from "lodash"
import L, { marker } from "leaflet"
import { Sidebar } from "primereact/sidebar"
import { MapContainer, FeatureGroup, ImageOverlay } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw.css"
import "./scss/SoDoPhanLo.scss"
import { EditControl } from "react-leaflet-draw"
import { FullscreenControl } from "react-leaflet-fullscreen"
import "react-leaflet-fullscreen/styles.css"
import drawLocales from "leaflet-draw-locales"
import { AgencyService } from "services/Agency"
import { Alert, Col, Flex, Form, Tabs } from "antd"
import {
  Image,
  Button,
  Drawer,
  Divider,
  Space,
  Radio,
  Spin,
  Input,
  Dropdown,
  Select,
} from "antd"
import VirtualList from "rc-virtual-list"
import { ProductService } from "services/Product"
import { Format_Currency, getHexColor, handleValidHour } from "common/common"
import { ProjectService } from "services/Project"
import { useDispatch, useSelector } from "react-redux"
import { getDuAn } from "store/actions"
import TabPane from "antd/es/tabs/TabPane"
// Customize locale language
const locale = drawLocales("en")
locale.draw.toolbar.actions.text = "Huỷ"
locale.draw.toolbar.finish.text = "Hoàn thành"
locale.draw.toolbar.undo.text = "Xoá điểm vừa vẽ"

// handlers
locale.draw.handlers.polygon = {
  tooltip: {
    start: "Click 1 điểm đề bắt đầu vẽ.",
    cont: "Click 1 điểm tiếp theo.",
    end: "Click 2 lần để lưu.",
  },
}
locale.draw.handlers.rectangle = {
  tooltip: {
    start: "Click giữ và kéo để vẽ.",
    end: "Click 1 điểm tiếp theo.",
  },
}
locale.draw.handlers.simpleshape = {
  tooltip: {
    end: "Nhả chuột để kết thúc vẽ.",
  },
}

locale.edit.toolbar.actions.save.text = "Lưu"
locale.edit.toolbar.actions.cancel.text = "Huỷ"
locale.edit.toolbar.actions.clearAll.text = "Xoá tất cả"
locale.edit.handlers.edit = {
  tooltip: {
    text: 'Click vào "Huỷ" nếu bạn không muốn lưu thay đổi.',
    subtext: "Giữ một điểm và kéo thả để thay đổi hình vẽ.",
  },
}
locale.edit.handlers.remove = {
  tooltip: {
    text: "Click vào một căn bạn muốn xoá",
  },
}
locale.draw.toolbar.buttons.polygon = "Vẽ hình đa giác"
locale.draw.toolbar.buttons.rectangle = "Vẽ hình chữ nhật"
L.drawLocal = locale

const SoDoPhanLo = props => {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent)

  const editLayerRef = React.useRef()
  let mapRef = React.useRef()
  const bounds = [
    [-500, -0],
    [500, 500],
  ]
  const purpleOptions = { color: "purple", fillColor: "red", fillOpacity: 1 }
  const [data, setData] = useState([])
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [visibleDetail, setVisibleDetail] = useState(false)
  const [visibleFloor, setVisibleFloor] = useState(false)
  const [visibleDetailFloor, setVisibleDetailFloor] = useState(false)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({})
  const [selectedProduct, setSelectedProduct] = useState({})
  const [selectedLayer, setSelectedLayer] = useState({})
  const [loading, setLoading] = useState(false)
  const [timeLock, setTimeLock] = useState(0)
  const [isStart, setIsStart] = useState(false)
  const [lstKhu, setLstKhu] = useState([])
  const [checkBooking, setCheckBooking] = useState()
  const [activeKey, setActiveKey] = useState(
    Number(params?.MaDA) ? params?.MaDA : null
  )
  const [productByFloor, setProductByFloor] = useState([])
  const [floors, setFloors] = useState([])
  const [selectedFloor, setSelectedFloor] = useState({})
  const [khu, setKhu] = useState({})
  const defaultFilter = {
    limit: 10,
    KyHieu: "",
    MaDA: Number(params?.MaDA),
  }
  const [filterCondition, setFilterCondition] = useState(defaultFilter)
  const dispatch = useDispatch()
  const { listDuAn } = useSelector(state => ({
    listDuAn: state.Agency.duAn,
  }))

  const getProduct = async maSP => {
    setLoading(true)
    let _res = await ProductService.DatNen.getById({
      maSP: maSP,
    })
    if (_res.status === 2000) {
      setProduct(_res.data)
      setTimeLock(_res.data?.ThoiGianConLai)
      _res.data?.ThoiGianConLai > 0 && setIsStart(true)
    }
    let _resBooking = await ProductService.checkBooking({
      MaSP: maSP,
    })
    if (_resBooking?.data) setCheckBooking(_resBooking?.data)
    setLoading(false)
  }
  const getProductByFloor = async item => {
    setLoading(true)
    let _res = await AgencyService.SoDo.getProductByFloor({
      MaTang: item.maTang,
      MaKhu: item.maKhu,
    })
    if (_res.status === 2000) {
      // let _data = []
      let value = _res.data.map(item => {
        let date = new Date().setSeconds(item.ThoiGianConLai ?? 0)
        return {
          ...item,
          TimeLock:
            item.ThoiGianConLai && item.ThoiGianConLai > 0 ? new Date(date) : 0,
        }
      })
      setProductByFloor(value)
    }
    setLoading(false)
  }
  const getKhu = async () => {
    let res = await ProjectService.getKhu({ MaDA: Number(params?.MaDA) })
    setLstKhu(res.data || [])
  }
  const filterProducts = async _filter => {
    setLoading(true)
    let _productAdded = _.cloneDeep(data.geoValues)
    let _ids = _.map(_productAdded, "maSP")
    let _res = await ProductService.DatNen.filter({
      Offset: 1,
      ..._filter,
    })
    if (_res.status === 2000) {
      let _product = _res.data?.filter(v => !_ids.includes(v.maSP))
      setProducts(_product)
    }
    setLoading(false)
  }
  const filterSoDo = async () => {
    setLoading(true)
    // let updatedMaDA = params?.MaDA

    // if (!updatedMaDA && !maDuAn) {
    //   updatedMaDA = listDuAn[0]?.MaDA
    // }
    // let res = await AgencyService.SoDo.filter({ MaDA: Number(updatedMaDA) })
    let res = await AgencyService.SoDo.filter({ MaDA: Number(params?.MaDA) })
    let _data = []
    if (res.status === 2000) {
      res?.data?.geoValues?.map(item => {
        _data.push({
          geometry: JSON.parse(item.geometry),
          type: item.type,
          id: item.id,
          maSP: item.maSP,
          properties: {
            id: item.id,
            maSP: item.maSP,
            kyHieu: item.kyHieu,
            dienTichXD: item.dienTichXD,
            dienTichDat: item.dienTichDat,
            tongGiaTriHDMB: item.tongGiaTriHDMB,
            mauNen: item.mauNen,
            tenTT: item.tenTT,
            tenTang: item.tenTang,
            maTang: item.maTang,
            maKhu: item.maKhu,
          },
        })
      })
      setData({ geoValues: _data, urlImage: res.data?.urlImage })
    }
    setLoading(false)
  }
  useEffect(() => {
    let _time = null
    if (isStart === true) {
      _time = setInterval(() => {
        setTimeLock(pre => {
          pre < 0 && setIsStart(false)
          return pre - 1
        })
      }, 1000)
    }
    return () => clearInterval(_time)
  }, [isStart])
  useEffect(() => {
    if (isMobileDevice()) {
      const leafletLeftElement = document.querySelector(".leaflet-left")
      if (leafletLeftElement) {
        leafletLeftElement.style.display = "none"
      }
    }
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get("token")
    const tenCTDK = queryParams.get("tenCTDK")
    if (token) {
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          acessToken: token,
        })
      )
      localStorage.setItem("TenCTDKVT", tenCTDK)
    }
    filterSoDo()
    dispatch(getDuAn())
  }, [])
  useEffect(() => {
    // cần setTimeout để tránh case editControlRef trả về null trong lần render đầu tiên
    // setTimeout(() => {
    //   if (data && editLayerRef?.current) {
    _loadFeatureLayer()
    //   }
    // }, 1)
  }, [data, editLayerRef])
  const _loadFeatureLayer = () => {
    let geoValues = _.cloneDeep(data.geoValues)
    // clear hết layer trước khi add layer tránh bị ghi đè
    editLayerRef?.current?.clearLayers()
    if (geoValues?.length) {
      const dataGeoByFundId = {
        type: "FeatureCollection",
        features: geoValues,
      }
      L?.geoJSON(dataGeoByFundId, {
        style: function (feature) {
          let row = feature.properties
          let classNames = ""
          let arrayStyle = {}
          // case có data
          if (row?.maSP) {
            arrayStyle = {
              fillColor: row.mauNen,
              fillOpacity: 0.3,
              color: row.mauNen,
              opacity: 0.3,
              className: classNames,
            }
          } else if (row?.maTang) {
            arrayStyle = {
              fillColor: "yellow",
              fillOpacity: 0.5,
              color: "yellow",
              opacity: 0.5,
            }
          } else {
            // không có data
            arrayStyle = {
              fillColor: "#FFFFFF",
              fillOpacity: 1,
              color: "#366FEB",
              opacity: 0.5,
              className: classNames,
            }
          }
          return arrayStyle
        },
        onEachFeature: function (feature, layer) {
          let properties = feature.properties
          // let realEstate = properties.realEstate
          // if (properties.maSP && !properties.isView) {
          //   layer.bindTooltip(tooltipNotAccessView()).on("tooltipopen", a => {
          //     mapRef?.current.closePopup()
          //   })
          // }
          // else {
          if (properties.maSP) {
            layer
              .bindTooltip(tooltipShowProduct(properties), toolTipOptions)
              .on("tooltipopen", a => {
                mapRef?.current.closePopup()
              })
              .on("click", () => {
                setSelectedLayer(feature)
                setVisibleDetail(true)
                getProduct(properties.maSP)
              })
          } else if (properties.maTang) {
            layer
              .bindTooltip(tooltipShowFloor(properties), toolTipOptions)
              .on("tooltipopen", a => {
                mapRef?.current.closePopup()
              })
              .on("click", () => {
                setSelectedLayer(feature)
                setVisibleDetailFloor(true)
                getProductByFloor(properties)
              })
          } else {
            layer.bindPopup(popupAddSP(properties)).on("click", () => {
              let btnAdd = document.querySelector(".choose-product")
              let btnAddFloor = document.querySelector(".add-floor")
              if (btnAdd) {
                btnAdd.addEventListener("click", () => {
                  filterProducts(filterCondition)
                  setSelectedLayer(properties)
                  setVisibleAdd(true)
                })
              }
              if (btnAddFloor) {
                btnAddFloor.addEventListener("click", () => {
                  getKhu()
                  setSelectedLayer(properties)
                  setVisibleFloor(true)
                })
              }
            })
          }

          // layer.bindTooltip(customToolTip(properties), toolTipOptions)
          //   .on("tooltipopen", (a) => {
          //     mapRef?.current.closePopup();
          //   })
          //   .on('click', function () {
          //     if ($('.leaflet-toolbar-1').is(":visible")) {
          //       layer.setStyle({
          //         fillColor: '#FFFFFF',
          //         color: '#366FEB',
          //       });
          //     }
          //     // else if (!$('.leaflet-draw-actions-bottom').is(":visible") && !$('.leaflet-draw-actions-top').is(":visible")) {
          //     //   // đang thực hiện các action edit/delete thì sẽ ko cho click detail
          //     //   onClickInSideLayer(realEstate)
          //     // }
          //   })
          // }
        },
      }).eachLayer(layer => {
        editLayerRef?.current?.addLayer(layer)
      })
    }
  }
  const onCreate = async (e, maDA) => {
    const { layerType, layer } = e
    layer.setStyle(objectStyleCreate)
    const layerData = layer.toGeoJSON()
    layerData.properties.action = "add"
    let _res = await AgencyService.SoDo.create({
      isadd: true,
      data: [
        {
          MaDA: Number(params?.MaDA),
          geometry: JSON.stringify(layerData.geometry),
          type: "Feature",
          MaSP: null,
        },
      ],
    })
    if (_res.status === 2000) {
      filterSoDo()
      //   _data.push({
      //      geometry: value.geometry,
      //       type: "Feature",
      //       id: _res.data,
      //       properties: {
      //         id: _res.data,
      //         maSP: null
      //         },
      //   })
      //   setData(_data);
    }
  }
  const _onEdit = async e => {
    const listLayers = []
    e.layers.eachLayer(layer => {
      const layerData = layer.toGeoJSON()
      layerData.properties = {
        ...layerData.properties,
        action: "eidt",
      }
      listLayers.push(layerData)
    })
    if (listLayers?.length === 0) {
      return
    }
    let data = []
    listLayers.map(item => {
      data.push({
        MaDA: Number(params?.MaDA),
        geometry: JSON.stringify(item.geometry),
        id: item.properties.id,
        type: "Feature",
        MaSP: item.maSP,
      })
    })
    await AgencyService.SoDo.create({
      isadd: false,
      data: data,
    })
  }
  const _onDelete = e => {
    const listLayers = []
    e.layers.eachLayer(layer => {
      const layerData = layer.toGeoJSON()
      listLayers.push({ id: layerData.id })
    })
    ProductService.DatNen.removeViTri({
      data: listLayers,
    })
  }
  const configEdit = useMemo(() => {
    let role = {
      edit: {
        selectedPathOptions: {
          // color main
          fillColor: "#F1F8FF",
          fillOpacity: 1,

          // color border
          color: "#366FEB",
          opacity: 1,
          weight: 1.5,
          className: "___edit_leaflet",
        },
      },
      poly: {
        allowIntersection: false,
      },
    }
    // if ((!canAddNewRealEstateRole && !canEditOwnerRealEstateRole)) {
    //   role.edit = false
    //   role.remove = false
    // }
    return role
  }, [])
  const configDraw = useMemo(() => {
    let role = {
      polyline: false,
      marker: false,
      circlemarker: false,
      circle: false,
      polygon: {
        allowIntersection: false,
        icon: new L.DivIcon({
          iconSize: new L.Point(6, 6),
          className: "leaflet-div-icon leaflet-editing-icon ___icon_circle",
        }),
        // config shape(hình) khi đang chọn điểm
        shapeOptions: {
          color: "#104FD9", // màu chung cho cả border và màu bên trong
          opacity: 0.4,
        },
        // config shape khi bị lỗi
        drawError: {
          color: "#E35B47", // màu chung cho cả border và màu bên trong
          opacity: 0.4,
          message: "Lỗi: Các cạnh của hình không thể cắt nhau",
        },
      },
    }
    // if ((!canAddNewRealEstateRole && !canEditOwnerRealEstateRole) || (!canAddNewRealEstateRole && canEditOwnerRealEstateRole)) {
    //   role.polygon = false
    //   role.rectangle = false
    // }
    return role
  }, [])
  const objectStyleCreate = {
    fillColor: "#F1F8FF",
    fillOpacity: 1,
    opacity: 1,
  }
  var toolTipOptions = {
    className: "",
    opacity: 1,
    padding: 0,
  }
  let tooltipNotAccessView = () => {
    return `<div>
      Bạn không có quyền xem quỹ hàng này!
    </div>`
  }

  let tooltipShowProduct = properties => {
    return `<div style="width:200px">
    <div class="header" style="background-color:${
      properties.mauNen
    };border-radius:6px">
      <p style="padding:5px 10px;font-weight:600">Mã căn: ${
        properties.kyHieu
      }</p>
    </div>
    <div style="padding:0px 10px">
      <p style="font-weight:600">Trạng thái:<span style="font-weight:600"> ${
        properties.tenTT
      }</span> </p>
      <p style="font-weight:600">${Format_Currency(
        properties.tongGiaTriHDMB
      )}</p>
      <p style="font-weight:600">Biệt thự . Tây</p>
    </div>
  </div>`
  }
  let tooltipShowFloor = properties => {
    return `<div style="width:200px">
      <p style="padding:15px;margin:0px;font-weight:600">${properties.tenTang}</p>
  </div>`
  }
  let popupAddSP = () => {
    return `<div>
  
    <p class="choose-product" style="cursor:pointer"> <i class="pi pi-check"></i>  Chọn sản phẩm có sẵn</p>
    <p class="add-floor" style="cursor:pointer"> <i class="pi pi-check"></i> Thêm tầng</p>
  </div>`
  }

  const applyChangeFilter = async (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    _filter.MaDA = Number(params?.MaDA)
    switch (p) {
      case "load":
        _filter.limit = _filter.limit + 10
        break
      case "search":
        _filter.KyHieu = v
        break
    }
    setFilterCondition(_filter)
    filterProducts(_filter)
  }

  const renderDrawerAddProduct = () => {
    const confirmChooseProduct = async () => {
      setVisibleAdd(false)
      if (selectedProduct) {
        let _res = await ProductService.DatNen.addProduct({
          id: selectedLayer?.id,
          maSP: selectedProduct.maSP,
        })
        if (_res.status === 2000) {
          let _data = _.cloneDeep(data)
          let item = _data.geoValues.find(v => v.id === selectedLayer?.id)
          if (item) item.maSP = selectedProduct.maSP
          item.properties = {
            mauNen: selectedProduct.mauNen,
            id: selectedLayer?.id,
            dienTichDat: selectedProduct.dienTichDat,
            dienTichXD: selectedProduct.dienTichXD,
            kyHieu: selectedProduct.maSanPham,
            maSP: selectedProduct.maSP,
            tenTT: selectedProduct.tenTT,
            tongGiaTriHDMB: selectedProduct.tongGiaTriHDMB,
          }
          setData(_data)
        }
      }
    }

    return (
      <Drawer
        title="Chọn sản phẩm"
        width={500}
        onClose={() => setVisibleAdd(false)}
        open={visibleAdd}
        // styles={{ body:{width:'200px'} }}
        extra={
          <Space>
            {/* <Button onClick={() => setVisibleAdd(false)}>Huỷ</Button> */}
            <Button onClick={() => confirmChooseProduct()} type="primary">
              Xác nhận
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Input
            placeholder="Tìm kiếm"
            allowClear
            style={{ marginBottom: "20px" }}
            onChange={e => applyChangeFilter("search", e.target.value)}
          />
          {products &&
            products?.map(item => {
              return (
                <React.Fragment key={item.maSP}>
                  <div
                    className="pt-0"
                    onClick={() => setSelectedProduct(item)}
                  >
                    <div className="list-item-exist flex gap-3">
                      <Radio
                        checked={
                          selectedProduct?.maSP === item.maSP ? true : false
                        }
                      />
                      <div className="item-image">
                        <Image
                          width={100}
                          height={120}
                          src="https://io.meeymedia.com/meeycrm/images/2023/06/19/anh-du-an.1687142096266.jpg"
                          style={{ borderRadius: "5px" }}
                        />
                        <div
                          className="item-status"
                          style={{
                            backgroundColor: item.mauNen,
                            position: "absolute",
                            borderRadius: "8px",
                            transform: "translate(6%,-130%)",
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              textAlign: "center",
                              fontSize: "9px",
                              fontWeight: "600",
                              padding: "3px 5px",
                            }}
                          >
                            {item.tenTT}
                          </p>
                        </div>
                      </div>
                      <div className="item0-content">
                        <p
                          className="mb-1 "
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          {item.maSanPham} - {item.tenDA}
                        </p>

                        <p className="mb-1" style={{ fontWeight: "900" }}>
                          {Format_Currency(item.tongGiaTriHDMB)}
                        </p>
                        <div className="flex gap-4">
                          <p className="mb-1">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.551 12.4691C15.551 12.8917 15.2084 13.2344 14.7857 13.2344H12.932V14.9691C12.932 15.3917 12.5893 15.7344 12.1667 15.7344C11.744 15.7344 11.4014 15.3917 11.4014 14.9691V13.2344H4.56464V14.9691C4.56464 15.3917 4.22199 15.7344 3.79934 15.7344C3.37668 15.7344 3.03403 15.3917 3.03403 14.9691V13.2344H1.31633C0.893674 13.2344 0.55102 12.8917 0.55102 12.4691C0.55102 12.0464 0.893674 11.7038 1.31633 11.7038H3.03403V4.86703H1.26531C0.842653 4.86703 0.5 4.52437 0.5 4.10172C0.5 3.67907 0.842653 3.33642 1.26531 3.33642H3.03403V1.49968C3.03403 1.07703 3.37668 0.734375 3.79934 0.734375C4.22199 0.734375 4.56464 1.07703 4.56464 1.49968V3.2854H11.4014V1.49968C11.4014 1.07703 11.744 0.734375 12.1667 0.734375C12.5893 0.734375 12.932 1.07703 12.932 1.49968V3.33642H14.7347C15.1573 3.33642 15.5 3.67907 15.5 4.10172C15.5 4.52437 15.1573 4.86703 14.7347 4.86703H12.983V11.7038H14.7857C15.2084 11.7038 15.551 12.0464 15.551 12.4691Z"
                                fill="#B0B0B0"
                              ></path>
                            </svg>
                            <span> {item.dienTichDat} m2</span>
                          </p>
                          <p className="mb-1">
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.0165 2.59113V5.55473L14.5774 7.05706C14.8478 7.30334 14.6778 7.77128 14.3224 7.77128H13.3101C13.2502 7.77128 13.1902 7.76356 13.1316 7.74839V13.0871C13.1316 13.6394 12.6839 14.0871 12.1316 14.0871H3.07895C2.52666 14.0871 2.07895 13.6394 2.07895 13.0871V7.74881C2.01993 7.7637 1.95885 7.77128 1.89697 7.77128H0.884703C0.529249 7.77128 0.366977 7.30334 0.629703 7.05706L7.08968 0.875356C7.38332 0.596235 7.8315 0.596235 8.12514 0.875356L10.6983 3.33819V2.59113C10.6983 2.13961 11.046 1.77018 11.471 1.77018H12.2438C12.6688 1.77018 13.0165 2.13961 13.0165 2.59113ZM1.28947 14.8765C0.85346 14.8765 0.5 15.23 0.5 15.666C0.5 16.102 0.85346 16.4555 1.28947 16.4555H14.7105C15.1465 16.4555 15.5 16.102 15.5 15.666C15.5 15.23 15.1465 14.8765 14.7105 14.8765H1.28947ZM6.52632 8.56075C6.25017 8.56075 6.02632 8.78461 6.02632 9.06075V12.7976C6.02632 13.0737 6.25017 13.2976 6.52632 13.2976H8.68421C8.96035 13.2976 9.18421 13.0737 9.18421 12.7976V9.06075C9.18421 8.78461 8.96035 8.56075 8.68421 8.56075H6.52632Z"
                                fill="#9A9A9A"
                              ></path>
                            </svg>
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              {item.dienTichXD} m2
                            </span>
                          </p>
                        </div>
                        <p className="mb-1" style={{ color: "#646464" }}>
                          Biet thu . {item.huongNha}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              )
            })}
          <div className="flex justify-content-center">
            <Button
              type="primary"
              loading={loading}
              onClick={() => applyChangeFilter("load", 1)}
            >
              Xem thêm
            </Button>
          </div>
        </Spin>
      </Drawer>
    )
  }
  const renderDrawerDetailProduct = () => {
    const confirmRemoveProduct = async () => {
      setVisibleDetail(false)
      let _res = await AgencyService.SoDo.create({
        isadd: false,
        data: [
          {
            MaDA: Number(params?.MaDA),
            geometry: JSON.stringify(selectedLayer.geometry),
            id: selectedLayer.id,
            type: "Feature",
            MaSP: null,
          },
        ],
      })
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data)
        let item = _data.geoValues.find(v => v.id === selectedLayer?.id)
        if (item) item.maSP = null
        item.properties = {
          mauNen: null,
          id: selectedLayer?.id,
          dienTichDat: null,
          dienTichXD: null,
          kyHieu: null,
          maSP: null,
          tenTT: null,
          tongGiaTriHDMB: null,
        }
        setData(_data)
        // filterSoDo()
      }
    }
    const addBooking = () => {
      history.push("/banggiatructuyen/addgiucho/" + product.MaSP)
    }
    const lockCan = async () => {
      let _res = await ProductService.DatNen.lockCan({ maSP: product.MaSP })
      if (_res.status === 2000) {
        setTimeLock(_res.data)
        setIsStart(true)
        filterSoDo()
      }
    }
    const items = [
      {
        key: "1",
        label: (
          <p
            className="m-0"
            style={{ color: "red" }}
            onClick={() => confirmRemoveProduct()}
          >
            <i className="pi pi-trash" /> Xoá sản phẩm
          </p>
        ),
      },
    ]
    const getValueTime = () => {
      let _ss = Math.floor(timeLock % 60)
      let _mm = Math.floor((timeLock % (60 * 60)) / 60)
      return `${_mm < 10 ? "0" + _mm : _mm}:${_ss < 10 ? "0" + _ss : _ss}`
    }
    return (
      <Drawer
        title="Thông tin sản phẩm"
        width={500}
        onClose={() => {
          setVisibleDetail(false)
          setIsStart(false)
          setProduct({})
        }}
        open={visibleDetail}
        extra={
          <Space>
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
              <i className="pi pi-ellipsis-v" />
            </Dropdown>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Image
            width="100%"
            height={250}
            src={product?.HinhAnh}
            style={{ borderRadius: "5px" }}
          />
          <div className="product-content">
            <p className="mt-3 mb-2" style={{ color: "#646464" }}>
              Dự án:{" "}
              <span
                style={{ fontWeight: "700", fontSize: 14, color: "#343434" }}
              >
                {product?.TenDA}
              </span>
            </p>
            <p className="mt-3 mb-2" style={{ color: "#646464" }}>
              Mã sản phẩm:{" "}
              <span
                style={{ fontWeight: "700", fontSize: 14, color: "#343434" }}
              >
                {product?.KyHieu}
              </span>
            </p>
            <div className="flex align-items-center  mb-2">
              <p className="mb-0" style={{ color: "#646464" }}>
                Trạng thái:
              </p>
              <div
                className="py-1 px-2"
                style={{
                  backgroundColor: getHexColor(product.MauNen),
                  borderRadius: "5px",
                  width: "100px",
                }}
              >
                <p className="m-0" style={{ fontSize: 12, fontWeight: "700" }}>
                  {product.TenTT}
                </p>
              </div>
            </div>
            <p className="mb-2" style={{ color: "#646464" }}>
              Giá trị:{" "}
              <span
                style={{ fontWeight: "900", fontSize: 15, color: "#343434" }}
              >
                {" "}
                {Format_Currency(product.TongGiaTriHDMB)}
              </span>{" "}
            </p>
          </div>
          <div className="product-footer flex  gap-3 pt-4">
            <Button
              onClick={() => lockCan()}
              danger
              disabled={timeLock > 0 ? true : false}
            >
              {timeLock > 0 ? getValueTime() : "Lock căn"}
            </Button>
            {checkBooking?.isBooking ||timeLock <= 0 ? (
              <Button onClick={() => addBooking()} type="primary">
                Book ngay
              </Button>
            ) : (
              <Button disabled={true} type="primary">
                Book ngay
              </Button>
            )}
          </div>
        </Spin>
      </Drawer>
    )
  }
  const renderDrawerAddFloor = () => {
    const confirmAddFloorProduct = async () => {
      setVisibleFloor(false)
      if (selectedFloor) {
        let _res = await AgencyService.SoDo.addFloor({
          id: selectedLayer?.id,
          maKhu: khu,
          maTang: selectedFloor.MaTang,
          tenTang: "Tầng " + selectedFloor.TenTang,
        })
        if (_res.status === 2000) {
          let _data = _.cloneDeep(data)
          let item = _data.geoValues.find(v => v.id === selectedLayer?.id)
          if (item) item.maSP = selectedProduct.maSP
          item.properties = {
            // mauNen: selectedProduct.mauNen,
            id: selectedLayer?.id,
            maKhu: khu,
            maTang: selectedFloor.MaTang,
            tenTang: "Tầng " + selectedFloor.TenTang,
          }
          setData(_data)
        }
      }
    }
    const onChangeKhu = async makhu => {
      setLoading(true)
      setKhu(makhu)
      let _res = await AgencyService.ForSale.getTang({ MaKhu: makhu })
      setFloors(_res.data || [])
      setLoading(false)
    }
    return (
      <Drawer
        title="Chọn tầng"
        width={500}
        onClose={() => setVisibleFloor(false)}
        open={visibleFloor}
        extra={
          <Space>
            <Button onClick={() => confirmAddFloorProduct()} type="primary">
              Xác nhận
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Select
            // defaultValue=""
            style={{ width: "100%", marginBottom: "30px" }}
            onChange={onChangeKhu}
            placeholder="Chọn khu"
            fieldNames={{ label: "TenKhu", value: "MaKhu" }}
            options={lstKhu}
          />

          {floors &&
            floors?.map(item => {
              return (
                <React.Fragment key={item.MaTang}>
                  <div className="pt-0" onClick={() => setSelectedFloor(item)}>
                    <div className="list-item-exist flex gap-3 align-items-center">
                      <Radio
                        checked={
                          selectedFloor?.MaTang === item.MaTang ? true : false
                        }
                      />
                      <p className="m-0" style={{ cursor: "pointer" }}>
                        Tầng {item.TenTang}
                      </p>
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              )
            })}
        </Spin>
      </Drawer>
    )
  }
  const renderDrawerDetailFloor = () => {
    const lockCan = async item => {
      setLoading(true)
      let _res = await ProductService.DatNen.lockCan({ maSP: item.MaSP })
      if (_res.status === 2000) {
        let _data = _.cloneDeep(productByFloor)
        let product = _data.find(v => v.MaSP === item.MaSP)
        let time = new Date().setSeconds(_res.data)
        product.TimeLock = new Date(time)
        setProductByFloor(_data)
      }
      setLoading(false)
    }
    const addBooking = item => {
      history.push("/banggiatructuyen/addgiucho/" + item.MaSP)
    }
    return (
      <Drawer
        title="Chi tiết tầng"
        width={500}
        onClose={() => setVisibleDetailFloor(false)}
        open={visibleDetailFloor}
      >
        <Spin spinning={loading}>
          {productByFloor &&
            productByFloor?.map(item => {
              return (
                <React.Fragment key={item.maSP}>
                  <div className="list-item-exist flex gap-3">
                    <div className="item-image">
                      <Image
                        width={100}
                        height={120}
                        src="https://io.meeymedia.com/meeycrm/images/2023/06/19/anh-du-an.1687142096266.jpg"
                        style={{ borderRadius: "5px" }}
                      />
                      <div
                        className="item-status"
                        style={{
                          backgroundColor: getHexColor(item.MauNen),
                          position: "absolute",
                          borderRadius: "8px",
                          transform: "translate(6%,-130%)",
                        }}
                      >
                        <p
                          className="mb-0"
                          style={{
                            textAlign: "center",
                            fontSize: "9px",
                            fontWeight: "600",
                            padding: "3px 5px",
                          }}
                        >
                          {item.TenTT}
                        </p>
                      </div>
                    </div>
                    <div className="item-content">
                      <p
                        className="mb-1 "
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        {item.KyHieu} - {item.TenDA}
                      </p>

                      <p className="mb-1" style={{ fontWeight: "900" }}>
                        {Format_Currency(item.TongGiaTriHDMB)}
                      </p>
                      <div className="flex gap-4">
                        <p className="mb-1">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.551 12.4691C15.551 12.8917 15.2084 13.2344 14.7857 13.2344H12.932V14.9691C12.932 15.3917 12.5893 15.7344 12.1667 15.7344C11.744 15.7344 11.4014 15.3917 11.4014 14.9691V13.2344H4.56464V14.9691C4.56464 15.3917 4.22199 15.7344 3.79934 15.7344C3.37668 15.7344 3.03403 15.3917 3.03403 14.9691V13.2344H1.31633C0.893674 13.2344 0.55102 12.8917 0.55102 12.4691C0.55102 12.0464 0.893674 11.7038 1.31633 11.7038H3.03403V4.86703H1.26531C0.842653 4.86703 0.5 4.52437 0.5 4.10172C0.5 3.67907 0.842653 3.33642 1.26531 3.33642H3.03403V1.49968C3.03403 1.07703 3.37668 0.734375 3.79934 0.734375C4.22199 0.734375 4.56464 1.07703 4.56464 1.49968V3.2854H11.4014V1.49968C11.4014 1.07703 11.744 0.734375 12.1667 0.734375C12.5893 0.734375 12.932 1.07703 12.932 1.49968V3.33642H14.7347C15.1573 3.33642 15.5 3.67907 15.5 4.10172C15.5 4.52437 15.1573 4.86703 14.7347 4.86703H12.983V11.7038H14.7857C15.2084 11.7038 15.551 12.0464 15.551 12.4691Z"
                              fill="#B0B0B0"
                            ></path>
                          </svg>
                          <span> {item.DTThongThuy} m2</span>
                        </p>
                        <p className="mb-1">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.0165 2.59113V5.55473L14.5774 7.05706C14.8478 7.30334 14.6778 7.77128 14.3224 7.77128H13.3101C13.2502 7.77128 13.1902 7.76356 13.1316 7.74839V13.0871C13.1316 13.6394 12.6839 14.0871 12.1316 14.0871H3.07895C2.52666 14.0871 2.07895 13.6394 2.07895 13.0871V7.74881C2.01993 7.7637 1.95885 7.77128 1.89697 7.77128H0.884703C0.529249 7.77128 0.366977 7.30334 0.629703 7.05706L7.08968 0.875356C7.38332 0.596235 7.8315 0.596235 8.12514 0.875356L10.6983 3.33819V2.59113C10.6983 2.13961 11.046 1.77018 11.471 1.77018H12.2438C12.6688 1.77018 13.0165 2.13961 13.0165 2.59113ZM1.28947 14.8765C0.85346 14.8765 0.5 15.23 0.5 15.666C0.5 16.102 0.85346 16.4555 1.28947 16.4555H14.7105C15.1465 16.4555 15.5 16.102 15.5 15.666C15.5 15.23 15.1465 14.8765 14.7105 14.8765H1.28947ZM6.52632 8.56075C6.25017 8.56075 6.02632 8.78461 6.02632 9.06075V12.7976C6.02632 13.0737 6.25017 13.2976 6.52632 13.2976H8.68421C8.96035 13.2976 9.18421 13.0737 9.18421 12.7976V9.06075C9.18421 8.78461 8.96035 8.56075 8.68421 8.56075H6.52632Z"
                              fill="#9A9A9A"
                            ></path>
                          </svg>
                          <span> {item.DTTimTuong} m2 </span>
                        </p>
                      </div>

                      <div className="product-footer flex  gap-1 pt-2">
                        <Button
                          onClick={() => lockCan(item)}
                          danger
                          disabled={item.TimeLock > 0 ? true : false}
                        >
                          {item.TimeLock !== 0
                            ? handleValidHour(item.TimeLock)
                            : "Lock căn"}
                        </Button>
                        <Button onClick={() => addBooking(item)} type="primary">
                          Book ngay
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Divider />
                </React.Fragment>
              )
            })}
        </Spin>
      </Drawer>
    )
  }
  const handleTabChange = key => {
    const maDA = Number(key)
    setActiveKey(key) // Thiết lập activeKey theo giá trị tab mới
    history.push(`/so-do-phan-lo/${maDA}`)
    window.location.reload()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Col span={24}>
          <Form.Item name="maDuAn">
            <Tabs activeKey={activeKey} onChange={handleTabChange} type="card">
              {listDuAn.map(item => (
                <TabPane tab={item?.TenDA} key={String(item?.MaDA)}>
                  {/* Nội dung cho từng TabPane nếu cần */}
                </TabPane>
              ))}
            </Tabs>
          </Form.Item>
        </Col>
        <MetaTags>
          <title>SoDo PhanLo | Beesky</title>
        </MetaTags>
        {params.MaDA ? (
          <Container fluid>
            <MapContainer
              scrollWheelZoom={true}
              center={[50, 300]}
              minZoom={0}
              maxZoom={8}
              zoom={1}
              doubleClickZoom={false}
              ref={mapRef}
            >
              <ImageOverlay url={data.urlImage || ""} bounds={bounds} />
              <FeatureGroup ref={editLayerRef}>
                <EditControl
                  position="topleft"
                  onCreated={e => onCreate(e, params.MaDA)}
                  onEdited={e => _onEdit(e)}
                  // onDrawVertex={onDrawVertex}
                  onDeleted={_onDelete}
                  edit={configEdit}
                  draw={configDraw}
                />
              </FeatureGroup>
              <FullscreenControl title="Toàn màn hình" titleCancel="Cửa sổ" />
            </MapContainer>
            {renderDrawerAddProduct()}
            {renderDrawerDetailProduct()}
            {renderDrawerAddFloor()}
            {renderDrawerDetailFloor()}
          </Container>
        ) : (
          <Flex align="center" justify="center">
            <p style={{ fontWeight: "bold", fontSize: 30 }}>Chọn dự án</p>
          </Flex>
        )}
      </div>
    </React.Fragment>
  )
}
SoDoPhanLo.propTypes = {
  t: PropTypes.any,
}
export default withRouter(withTranslation()(SoDoPhanLo))
