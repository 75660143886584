import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Menu, Dropdown, Drawer, Button, Flex, Tooltip } from "antd"
import { Link, withRouter } from "react-router-dom"
import {
  HomeOutlined,
  AppstoreOutlined,
  FileOutlined,
  FullscreenOutlined,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons"

import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"
import login from "store/auth/login/reducer"
import routes from "./routes"
import Notification from "components/notification"

const Navbar = props => {
  const [dashboard, setDashboard] = useState(false)
  const [ui, setUi] = useState(false)
  const [app, setApp] = useState(false)
  const [extra, setExtra] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [menu, setMenu] = useState(routes)

  useEffect(() => {
    var matchingMenuItem = null
    var items = document.getElementById("navigation").getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) parent.classList.add("active")
    const parent2 = parent?.parentElement
    if (parent2) parent2.classList.add("active")
    const parent3 = parent2?.parentElement
    if (parent3) parent3.classList.add("active")
    const parent4 = parent3?.parentElement
    if (parent4) parent4.classList.add("active")
    const parent5 = parent4?.parentElement
    if (parent5) parent5.classList.add("active")
    const parent6 = parent5?.parentElement
    if (parent6) parent6.classList.add("active")
  }

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen?.() ||
        document.documentElement.mozRequestFullScreen?.() ||
        document.documentElement.webkitRequestFullscreen?.(
          Element.ALLOW_KEYBOARD_INPUT
        )
    } else {
      document.cancelFullScreen?.() ||
        document.mozCancelFullScreen?.() ||
        document.webkitCancelFullScreen?.()
    }
  }
  const renderMenu = items =>
    items.map(item =>
      item.children ? (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
          {renderMenu(item.children)}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={item.key} icon={item.icon}>
          <Link to={item.path}>{item.label}</Link>
        </Menu.Item>
      )
    )

  useEffect(() => {
    const menuCT = JSON.parse(localStorage.getItem("menu_congty"))
    const menuData = JSON.parse(localStorage.getItem("menu"))
    const accessMenuCongTy = menuCT?.map(item => item.FormID)
    const congtylv1 = menuCT?.map(item => item.ModulIDLevel1)
    const accessMenu = menuData?.map(item => item.FormID)
    const menulv1 = menuData?.map(item => item.ModulIDLevel1)
    const filteredRoutes = routes?.filter(item => {
      if (item.id === 0) return true
      if (congtylv1?.includes(item.id)) {
        if (item.children) {
          item.children = item.children.filter(
            v => accessMenu?.includes(v.id) && accessMenuCongTy?.includes(v.id)
          )
          return menulv1?.includes(item.id)
        } else {
          return accessMenu?.includes(item.id) || item.id === 0
        }
      }
      return false
    })

    setMenu(filteredRoutes)
  }, [])
  return (
    <React.Fragment>
      <div style={{ background: "#556ee6" }} className="topnav">
        <Row
          style={{ padding: "-10px 0" }}
          justify="space-between"
          align="middle"
        >
          <Col xs={15} sm={17} md={18} lg={19}>
            <Menu
              style={{
                backgroundColor: "#556ee6",
                border: "none",
                color: "#fff",
              }}
              mode="horizontal"
              selectable={false}
              id="navigation"
            >
              {renderMenu(menu)}
            </Menu>
          </Col>
          <Col
            xs={9}
            sm={7}
            md={6}
            lg={5}
            style={{
              textAlign: "Right",
              backgroundColor: "#556ee6",
              border: "none",
              color: "#fff",
            }}
          >
            <Flex justify="flex-end" align="center">
              <div style={{ marginBottom: "8px",marginRight:'5px' }}>
                <Notification />
              </div>
              {/* <Button
                icon={<FullscreenOutlined />}
                onClick={toggleFullscreen}
                // style={{ marginRight: 16 }}
              /> */}
              <Tooltip placement="top" title="Phóng to màm hình">
                <FullscreenOutlined
                  onClick={toggleFullscreen}
                  style={{ fontSize: "18px" }}
                />
              </Tooltip>
              <Tooltip placement="top" title="Menu">
                <MenuOutlined
                  onClick={() => setDrawerVisible(true)}
                  style={{ marginLeft: 16, fontSize: "18px" }}
                />
              </Tooltip>

              <ProfileMenu />
            </Flex>
          </Col>
        </Row>
        <Drawer
          title="Menu"
          placement="right"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          <Menu mode="inline" id="navigation">
            {renderMenu(menu)}
          </Menu>
        </Drawer>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
