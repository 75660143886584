import React, { useState, useEffect, createContext } from "react"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"

const SignalrContext = createContext({ name: "signalr" })
export const SignalrContextProvider = ({ children }) => {
  const [hubConnection, setHubConnection] = useState(null)
  useEffect(() => {
    try {
      let maNV = JSON.parse(localStorage.getItem("maNV"))
      let ctdk = localStorage.getItem("TenCTDKVT")
      ;(async () => {
        let connection = new HubConnectionBuilder()
          .withUrl("https://api-client.mybeeland.com/thongbao")
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build()
        connection.onreconnected(async connectionId => {
          await connection.invoke("JoinGroup", ctdk.toLowerCase() + maNV)
          // connection.invoke(ctdk.toLowerCase()+maNV);
        })
        await connection.start()
        await connection.invoke("JoinGroup", ctdk.toLowerCase() + maNV)
        setHubConnection(connection)
      })()
    } catch {}
    return () => {
      if (hubConnection) {
        hubConnection.stop()
      }
    }
  }, [])
  return (
    <SignalrContext.Provider value={{ hubConnection }}>
      {children}
    </SignalrContext.Provider>
  )
}
export default SignalrContext
