import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import {
  BEE_AutoComplete,
  BEE_Dropdown,
  BEE_InputNumber,
  BEE_InputText,
  BEE_InputTextArea,
  BEE_XLAYOUT_FORM,
} from "../../Forms/BEE_Form"
import { Button } from "primereact/button"
import { ProjectService } from "services/Project"
import { AgencyService } from "services/Agency"
import { Checkbox, Col, Drawer, Flex, Row } from "antd"
import InputCustom from "components/Forms/InputCustom"
import SelectCustom from "components/Forms/SelectCustom"
import InputNumberCustom from "components/Forms/InputNumberCustom"
import TextArea from "antd/es/input/TextArea"
function ChungCu_Detail(props, ref) {
  const { afterSubmit, reloadPage, openTask } = props
  const refFunctionMode = useRef(null)
  const [show, setShow] = useState(false)
  const defaultdata = {
    maSP: null,
    kyHieu: "",
    maDA: 0,
    maKhu: null,
    maLHBDS: 0,
    maHuong2: 0,
    maHuong: 0,
    maLM: 0,
    maPK: 0,
    maPN: null,
    dienTichTT: 0,
    dtTimTuong: 0,
    tang: 0,
    maLo: "",
    maVT: 0,
    lauSo: "",
    viewID: 0,
    dienGiai: "",
    daKyThuat: "",
    donGiaThongThuy: 0,
    tienDatPhanBo: 0,
    donGiaChuaVAT: 0,
    tongGiaChuaVAT: 0,
    vatch: 10,
    vatchtien: 0,
    tongGiaGomVAT: 0,
    tongGiaGomPBT: 0,
    ptPhiBaoTri: 2,
    tienDat: 0,
    phiBaoTri: 0,
  }
  const [data, setData] = useState(defaultdata)
  const [options, setOptions] = useState({
    projects: [],
    khu: [],
    phanKhu: [],
    loaiBDS: [],
    loaiCanHo: [],
    phuongHuong: [],
    view: [],
    phongNgu: [],
    tang: [],
    viTri: [],
  })
  const getOptions = async _options => {
    let pro = await ProjectService.filter({})
    _options.projects = pro.data || []

    let _ph = await AgencyService.ForSale.getPhuongHuong()
    _options.phuongHuong = _ph.data || []
  }
  useImperativeHandle(ref, () => ({
    // create: async () => {
    //   let _options = _.cloneDeep(options)
    //   let _data = _.cloneDeep(data)
    //   await getOptions(_options)
    //   refFunctionMode.current = "create"
    //   setOptions(_options)
    //   setData(_data)
    //   setShow(true)
    // },
    show: async () => {
      let _options = _.cloneDeep(options)
      let _data = _.cloneDeep(data)
      await getOptions(_options)
      // refFunctionMode.current = "create"
      setOptions(_options)
      setData(_data)
      setShow(true)
    },
    update: async _item => {
      let _options = _.cloneDeep(options)
      let _res = await AgencyService.ForSale.getByID({ maSP: _item.maSP })
      await getOptionsBymaDA(_options, _res?.data.maDA)
      await getOptionsBymaKhu(_options, _res.data.maKhu)
      await getOptions(_options)
      let _data = _.cloneDeep(data)
      _data = {
        ..._item,
        ..._res?.data,
        tienDat: _res?.data?.tiendat,
      }
      refFunctionMode.current = "update"
      setData(_data)
      setOptions(_options)
      setShow(true)
    },
  }))
  const cancel = () => {
    if (!openTask && typeof reloadPage == "function") {
      reloadPage()
    }
    setShow(false)
    setData(defaultdata)
  }
  const summary = _data => {
    _data.donGiaChuaVAT =
      ((_data.donGiaThongThuy + (_data.tienDatPhanBo * _data.vatch) / 100) *
        100) /
      110
    _data.tienDat = _data.tienDatPhanBo * _data.dienTichTT
    _data.tongGiaChuaVAT = _data.donGiaChuaVAT * _data.dienTichTT
    _data.vatchtien =
      (_data.vatch * (_data.tongGiaChuaVAT - _data.tienDat)) / 100
    _data.tongGiaGomVAT = _data.donGiaThongThuy * _data.dienTichTT
    _data.phiBaoTri = (_data.tongGiaChuaVAT * _data.ptPhiBaoTri) / 100
    _data.tongGiaGomPBT = _data.tongGiaGomVAT + _data.phiBaoTri
  }
  const getOptionsBymaDA = async (_options, maDA) => {
    let _res = await ProjectService.getKhu({ maDA: maDA })
    _options.khu = _res.data || []
    let _lbds = await AgencyService.ForSale.getLoaiBDSByMaDA({ maDA: maDA })
    _options.loaiBDS = _lbds.data || []
    let _lch = await AgencyService.Categories.getLoaiCH({ maDA: maDA })
    _options.loaiCanHo = _lch.data || []
    let _pn = await AgencyService.ForSale.getPhongNgu({ maDA: maDA })
    _options.phongNgu = _pn.data || []
    let _view = await AgencyService.ForSale.getView({ maDA: maDA })
    _options.view = _view.data || []
  }
  const getOptionsBymaKhu = async (_options, maKhu) => {
    let _resKhu = await ProjectService.getPhanKhu({ maKhu: maKhu })
    _options.phanKhu = _resKhu.data || []
    let _tang = await AgencyService.ForSale.getTang({ maKhu: maKhu })
    _options.tang = _tang.data || []
    let _vitri = await AgencyService.ForSale.getViTri({ maKhu: maKhu })
    _options.viTri = _vitri.data || []
  }
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data)
    let _options = _.cloneDeep(options)
    switch (p) {
      case "maDA":
        await getOptionsBymaDA(_options, v)
        _data[p] = v
        break
      case "maKhu":
        await getOptionsBymaKhu(_options, v)
        _data[p] = v
        break
      default:
        _data[p] = v
        summary(_data)
        break
    }
    setOptions(_options)
    setData(_data)
  }
  const submit = async () => {
    let _data = _.cloneDeep(data)
    let _res = await AgencyService.ForSale.create(_data)
    if (_res.status === 2000) {
      afterSubmit()
      cancel()
    }
  }
  const errors = {}
  console.log(data, "huan")
  return (
    <Drawer
      title={true ? "Sửa khách hàng" : "Thêm khách hàng"}
      open={show}
      onClose={cancel}
      width={1280}
      extra={
        <div style={{ textAlign: "right" }}>
          <Button onClick={() => submit()} type="primary">
            Lưu và đóng
          </Button>
        </div>
      }
    >
      {[].map(item => {
        return (
          <Checkbox
            key={item.ID}
            value={item.ID}
            checked={data?.MaNhuCau === item.ID}
            onChange={v => applyChange("MaNhuCau", v.target.value)}
          >
            {item.TenLoai}
          </Checkbox>
        )
      })}
      <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
        Thông tin chung
      </p>
      <Row gutter={[20, 12]}>
        <Col span={6}>
          <SelectCustom
            label="Dự án:"
            applyChange={applyChange}
            prop="maDA"
            value={data?.maDA}
            placeholder="Chọn dự án"
            options={options.projects}
            fieldNames={{ label: "TenDA", value: "MaDA" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Khu:"
            applyChange={applyChange}
            prop="maKhu"
            value={data?.maKhu}
            placeholder="Chọn khu"
            options={options.khu}
            fieldNames={{ label: "TenKhu", value: "MaKhu" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Phân khu:"
            applyChange={applyChange}
            prop="maPK"
            value={data?.maPK}
            placeholder="Chọn phân khu"
            options={options.phanKhu}
            fieldNames={{ label: "TenPK", value: "MaPK" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Loại BĐS:"
            applyChange={applyChange}
            prop="maLHBDS"
            value={data?.maLHBDS}
            placeholder="Chọn loại BĐS"
            options={options.loaiBDS}
            fieldNames={{ label: "TenLBDS", value: "MaLBDS" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Loại căn hộ:"
            applyChange={applyChange}
            prop="maLM"
            value={data?.maLM}
            placeholder="Chọn loại căn hộ"
            options={options.loaiCanHo}
            fieldNames={{ label: "TenLCH", value: "MALCH" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Hướng ban C:"
            applyChange={applyChange}
            prop="maHuong2"
            value={data?.maHuong2}
            placeholder="Chọn hướng ban công"
            options={options.phuongHuong}
            fieldNames={{ label: "tenPhuongHuong", value: "maPhuongHuong" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Hướng cửa:"
            applyChange={applyChange}
            prop="maHuong"
            value={data?.maHuong}
            placeholder="Chọn hướng cửa"
            options={options.phuongHuong}
            fieldNames={{ label: "tenPhuongHuong", value: "maPhuongHuong" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Hướng view:"
            applyChange={applyChange}
            prop="viewID"
            value={data?.viewID}
            placeholder="Chọn hướng view"
            options={options.view}
            fieldNames={{ label: "TenVeiw", value: "ID" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Số PN:"
            applyChange={applyChange}
            prop="maPN"
            value={data?.maPN}
            placeholder="Chọn hướng cửa"
            options={options.phongNgu}
            fieldNames={{ label: "TenLoaiPN", value: "ID" }}
          />
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Tầng:"
            applyChange={applyChange}
            prop="tang"
            value={data?.tang}
            placeholder="Chọn hướng cửa"
            options={options.tang}
            fieldNames={{ label: "TenTang", value: "MaTang" }}
          />
        </Col>
        <Col span={6}>
          <InputCustom
            label="Mã sản phẩm:"
            applyChange={applyChange}
            prop="kyHieu"
            value={data?.kyHieu}
            placeholder="Nhập mã sản phẩm"
          />
        </Col>
        <Col span={6}>
          <InputCustom
            label="Số căn hộ:"
            applyChange={applyChange}
            prop="maLo"
            value={data?.maLo}
            placeholder="Nhập số căn hộ"
          />
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="DT thông thủy:"
            applyChange={applyChange}
            prop={"dienTichTT"}
            value={data?.dienTichTT}
            placeholder={"Nhập diện tích TT"}
          />
          {/* <p style={{ color: "red" }}>{errors?.TenCongTy}</p> */}
        </Col>
        <Col span={6}>
          <InputNumberCustom
            label="DT tim tường:"
            applyChange={applyChange}
            prop={"dtTimTuong"}
            value={data?.dtTimTuong}
            placeholder={"Nhập diện tích TT"}
          />
          {/* <p style={{ color: "red" }}>{errors?.TenCongTy}</p> */}
        </Col>
        <Col span={6}>
          <SelectCustom
            label="Vị trí:"
            applyChange={applyChange}
            prop="maVT"
            value={data?.maVT}
            placeholder="Chọn vị trí"
            options={options.viTri}
            fieldNames={{ label: "TenVT", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <p style={{ fontWeight: "600" }}>Ghi chú:</p>
          <TextArea
            value={data?.dienGiai}
            onChange={e => applyChange("dienGiai", e.target.value)}
          />
        </Col>
      </Row>
      <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
        Thông tin bảng giá
      </p>
      <Row gutter={[20, 12]}>
        <Col span={8}>
          <InputNumberCustom
            label="Đơn giá thông thủy:"
            applyChange={applyChange}
            prop={"donGiaThongThuy"}
            value={data?.donGiaThongThuy}
            placeholder={""}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Tiền đất PB:"
            applyChange={applyChange}
            prop={"tienDatPhanBo"}
            value={data?.tienDatPhanBo}
            placeholder={"Nhập số tiền đất PB"}
          />
        </Col>

        <Col span={8}>
          <InputNumberCustom
            label="Đơn giá chưa VAT:"
            applyChange={applyChange}
            prop={"donGiaChuaVAT"}
            value={data?.donGiaChuaVAT}
            placeholder={"Nhập số tiền chưa VAT"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Tổng giá chưa VAT:"
            applyChange={applyChange}
            prop={"tongGiaChuaVAT"}
            value={data?.tongGiaChuaVAT}
            placeholder={"Nhập tổng số tiền chưa VAT"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="% VAT:"
            applyChange={applyChange}
            prop={"vatch"}
            value={data?.vatch}
            placeholder={"Nhập % VAT"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Tiền VAT:"
            applyChange={applyChange}
            prop={"vatchtien"}
            value={data?.vatchtien}
            placeholder={"Nhập tiền VAT"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Tổng giá gồm VAT:"
            applyChange={applyChange}
            prop={"tongGiaGomVAT"}
            value={data?.tongGiaGomVAT}
            placeholder={"Nhập tổng tiền gồm VAT"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="% Phí bảo trì:"
            applyChange={applyChange}
            prop={"ptPhiBaoTri"}
            value={data?.ptPhiBaoTri}
            placeholder={"Nhập % phí bảo trì"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Phí bảo trì:"
            applyChange={applyChange}
            prop={"phiBaoTri"}
            value={data?.phiBaoTri}
            placeholder={"Nhập phí bảo trì"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Tổng giá gồm PBT:"
            applyChange={applyChange}
            prop={"tongGiaGomPBT"}
            value={data?.tongGiaGomPBT}
            placeholder={"Nhập tổng giá gồm PBT"}
          />
        </Col>
        <Col span={8}>
          <InputNumberCustom
            label="Tiền SD đất:"
            applyChange={applyChange}
            prop={"tienDat"}
            value={data?.tienDat}
            placeholder={"Nhập tiền SD đất"}
          />
        </Col>
      </Row>
    </Drawer>
  )
}
ChungCu_Detail = forwardRef(ChungCu_Detail)
export default ChungCu_Detail
