import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Row, Col, Image, Drawer, Button } from "antd"
import {
  HomeOutlined,
  DollarOutlined,
  CompassOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  BankOutlined,
} from "@ant-design/icons"
import { SanPhamService } from "services/SanPhamService"

const FormInformationBDS = (props, ref) => {

  const [visible, setVisible] = useState(false)
  const [newData, setNewData] = useState();
  const [images,setImages]  = useState([])
  const info = [
    {
      icon: <HomeOutlined />,
      label: "Diện tích",
      value: `${newData?.dienTich} m²`,
    },
    { icon: <CompassOutlined />, label: "Hướng nhà", value: "Đông" },
    {
      icon: <DollarOutlined />,
      label: "Mức giá",
      value: `${newData?.thanhTien} đ`,
    },
    {
      icon: <CompassOutlined />,
      label: "Hướng ban công",
      value: `${newData?.huongBC} `,
    },
    { icon: <AppstoreAddOutlined />, label: "Số phòng ngủ", value: 3 },
    { icon: <AppstoreAddOutlined />, label: "Số toilet", value: 2 },
    { icon: <FileTextOutlined />, label: "Pháp lý", value: "Sổ hồng" },
    { icon: <BankOutlined />, label: "Nội thất", value: "Đầy đủ" },
  ]

  const showDrawer = () => {
    setVisible(true)
  }

  const closeDrawer = () => {
    setVisible(false)
  }
  useImperativeHandle(ref, () => ({
    show: async items => {
      let _resimages = await SanPhamService.NhuCauMuaThue.getImages({
        MaBC: items.maBC,
      })
      setImages(_resimages.data);
      setNewData(items)
      setVisible(true)
    },
  }))
  return (
    <>
      <Drawer
        title="CHI TIẾT BẤT ĐỘNG SẢN"
        width={1120}
        onClose={closeDrawer}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className="">
          <h3 style={{ fontWeight: "600" }} className="mt-4 mb-4">
            Đặc điểm bất động sản
          </h3>
          <Row style={{ padding: "0 30px" }} gutter={[50, 30]}>
            {info.map((item, index) => (
              <Col span={8} key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #d9d9d9",
                    paddingBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <p style={{ width: "50%" }}>
                    {item.icon} {item.label}
                  </p>
                  <p style={{ width: "50%", textAlign: "right" }}>
                    {item.value}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
          <h3 style={{ fontWeight: "600" }} className="mt-4 mb-4">
            Thông tin mô tả
          </h3>
          <div style={{ padding: "0 30px 20px" }}>
            <p>{newData?.dacTrung}</p>
          </div>
          <Row gutter={[10, 10]}>
            {images.map((item, index) => (
              <Col span={5}>
                <Image
                  width="100%"
                  src={item.duongDan2}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Drawer>
    </>
  )
}

export default forwardRef(FormInformationBDS)
