import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import { Button } from "primereact/button"
import { TabView, TabPanel } from "primereact/tabview"
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from "reactstrap"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ContractService } from "services/Contract"
import BootstrapTable from "react-bootstrap-table-next"
import { Format_Currency, handleValidDate } from "common/common"
function Contract_History(props, ref) {
  const refFunctionMode = useRef(null)
  const [show, setShow] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const defaultdata = {
    table: [],
  }
  const [data, setData] = useState(defaultdata)

  useImperativeHandle(ref, () => ({
    view: async _item => {
      refFunctionMode.current = "view"
      // setData(_item);
      filter(_item, 0)
      setShow(true)
    },
  }))
  const cancel = () => {
    setShow(false)
    setActiveTab(0)
    setData(defaultdata)
  }
  const filter = async (data, tab) => {
    switch (tab) {
      case 0: // lichj thanh toan
        let _data = _.cloneDeep(data)
        let res = await ContractService.Detail.getLichTT({ MaPGC: data.maHDMB })
        _data.table = res?.data || []
        setData(_data)
        break
      case 1: // tai lieu
        let _data2 = _.cloneDeep(data)
        let res2 = await ContractService.Detail.getDocument({
          MaPGC: data.maHDMB,
        })
        _data2.table = res2?.data || []
        setData(_data2)
        break
      case 2: // phieu thu
        let _data4 = _.cloneDeep(data)
        let res4 = await ContractService.Detail.getThuChi({
          MaPGC: data.maHDMB,
          isPhieuThu: true,
        })
        _data4.table = res4?.data || []
        setData(_data4)
        break
      case 3: // phieu chi
        let _data5 = _.cloneDeep(data)
        let res5 = await ContractService.Detail.getThuChi({
          MaPGC: data.maHDMB,
          isPhieuThu: false,
        })
        _data5.table = res5?.data || []
        setData(_data5)
        break
      case 4: // lich su chuyn nhuong
        let _data3 = _.cloneDeep(data)
        let res3 = await ContractService.Detail.getLichSuCN({
          MaPGC: data.maHDMB,
        })
        _data3.table = res3?.data || []
        setData(_data3)
        break

      default:
        break
    }
  }
  const renderLichTT = () => {
    const columns = [
      {
        dataField: "dotTT",
        text: "Đợt TT",
        footer:""
      },
      {
        text: "Ngày phải TT",
        formatter: (cellContent, row) => handleValidDate(row.ngayTT),
        footer:""
      },
      {
        text: "Tỷ lệ TT",
        formatter: (cellContent, row) => <span>{row.tyLeTT} %</span>,
        footer:""
      },
      { 
        dataField: "phaiThu",
        text: "Phải thu",
        formatter: (cellContent, row) => Format_Currency(row.phaiThu),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "daThu",
        text: "Đã thu",
        formatter: (cellContent, row) => Format_Currency(row.daThu),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "conLai",
        text: "Còn lại",
        formatter: (cellContent, row) => Format_Currency(row.conLai),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "phiBT",
        text: "Phí bảo trì",
        formatter: (cellContent, row) => Format_Currency(row.phiBT),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "daThuPBT",
        text: "Đã thu PBT",
        formatter: (cellContent, row) => Format_Currency(row.daThuPBT),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "conNoPBT",
        text: "Còn lại PBT",
        formatter: (cellContent, row) => Format_Currency(row.conNoPBT),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "luyKe",
        text: "Lũy kế",
        formatter: (cellContent, row) => Format_Currency(row.luyKe),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "dienGiai",
        text: "Diễn giải",
        footer:""
      },
      {
        dataField: "nguoiNhap",
        text: "Người tạo",footer:""
      },
      {
        text: "Ngày tạo",
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),footer:""
      },
    ]
    return (
      <BootstrapTable
        data={data.table === "[]" ? new Array([]) : data?.table}
        keyField="maPGC"
        responsive
        columns={columns}
        className="w-full h-full"
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed w-full"
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const renderTaiLieu = () => {
    const columns = [
      {
        dataField: "kyHieu",
        text: "Ký hiệu",
      },
      {
        dataField: "tenTL",
        text: "Tên tài liệu",
      },
      {
        text: "Kích thước",
        formatter: (cellContent, row) => <span>{row.kichThuoc} kb</span>,
      },
      {
        dataField: "dienGiai",
        text: "Diễn giải",
      },
      {
        dataField: "tenNhom",
        text: "Nhóm tài liệu",
      },
      {
        dataField: "hoTen",
        text: "Người tạo",
      },
      {
        text: "Ngày tạo",
        formatter: (cellContent, row) => handleValidDate(row.ngayTao),
      },
    ]
    return (
      <BootstrapTable
        data={data.table === "[]" ? new Array([]) : data?.table}
        keyField="id"
        responsive
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const renderThuChi = () => {
    const columns = [
      {
        dataField: "soPT",
        text: "Số phiếu tổng",
        footer:"",
      },
      {
        dataField: "kyHieu",
        text: "Sản phẩm",
        footer:"",
      },
      {
        text: "Ngày",
        formatter: (cellContent, row) => handleValidDate(row.ngayThu),
        footer:"",
      },
      {
        dataField: "tenTT",
        text: "Trạng thái HĐ",
        footer:"",
      },
      {
        dataField: "dotTT",
        text: "Đợt TT",
        footer:"",
      },
      {
        dataField: "name",
        text: "Loại thu",
        footer:"",
      },
      {
        dataField: "tienThu",
        text: "Số tiền thu",
        formatter: (cellContent, row) => Format_Currency(row.tienThu),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "tienChi",
        text: "Số tiền chi",
        formatter: (cellContent, row) => Format_Currency(row.tienChi),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + item, 0)),
      },
      {
        dataField: "tienChuaTruCongNo",
        text: "Tiên chưa trừ công nợ",
        formatter: (cellContent, row) => Format_Currency(row.tienChuaTruCongNo),
        footer: (columnData, column, columnIndex) => Format_Currency(columnData.reduce((acc, item) => acc + (item ?? 0), 0)),
      },
      {
        dataField: "hoTen",
        text: "Người nộp",
        footer:"",
      },
      {
        dataField: "diDong",
        text: "Di động",
        footer:"",
      },
      {
        dataField: "hinhThucTT",
        text: "Hình thức TT",
        footer:"",
      },
      {
        dataField: "dienGiai",
        text: "Diễn giải",
        footer:"",
      },
      {
        dataField: "nguoiTao",
        text: "Người tạo",
        footer:"",
      },
      {
        text: "Ngày tạo",
        formatter: (cellContent, row) => handleValidDate(row.ngayTao),
        footer:"",
      },
    ]
    return (
      <BootstrapTable
        data={data.table === "[]" ? new Array([]) : data?.table}
        keyField="id"
        responsive
        columns={columns}
        // bordered={false}
        // striped={false}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  const renderChuyenNhuong = () => {
    const columns = [
      {
        dataField: "soCN",
        text: "Số phiếu",
        footer:""
      },
      {
        text: "Ngày lập",
        formatter: (cellContent, row) => handleValidDate(row.ngayCN),
        footer:""
      },
      {
        dataField: "loaiCN",
        text: "Loại giao dịch",
        footer:""
      },
      {
        dataField: "hoTenKHCN",
        text: "Khách hàng chuyển nhượng",
        footer:""
      },
      {
        dataField: "hoTenKHNCN",
        text: "Khách hàng nhận chuyển nhượng",
        footer:""
      },
      {
        dataField: "giaTriCN",
        text: "Giá trị",
        formatter: (cellContent, row) => Format_Currency(row.giaTriCN),
        footer: (columnData) => Format_Currency(columnData.reduce((acc, item) => acc + (item??0), 0)),
      },
      {
        dataField: "hoTenNVN",
        text: "Người tạo",
        footer:""
      },
      {
        text: "Ngày tạo",
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),
        footer:""
      },
    ]
    return (
      <BootstrapTable
        data={data.table === "[]" ? new Array([]) : data?.table}
        keyField="id"
        responsive
        columns={columns}
        classes={
          "table align-middle  table-nowrap table-check table-hover table-fixed "
        }
        headerWrapperClasses={"table-light"}
      />
    )
  }
  try {
    return (
      <Dialog
        visible={show}
        header={
          refFunctionMode.current === "create"
            ? "Thêm"
            : refFunctionMode.current === "update"
            ? "Sửa"
            : "Xem"
        }
        style={{ width: "70%", height: "70%" }}
        className={` ${
          show ? "fadeinleft" : "fadeoutleft"
        } animation-duration-300 animation-iteration-1`}
        modal
        onHide={() => cancel()}
        // maximizable
        // maximized
      >
        <Card className="mt-3 mb-0">
          <CardBody>
            <TabView
              activeIndex={activeTab}
              onTabChange={e => {
                setActiveTab(e.index)
                filter(data, e.index)
              }}
            >
              <TabPanel header="Lịch thanh toán">
                <div className="table-responsive">
                  {renderLichTT()}
                </div>
              </TabPanel>
              <TabPanel header="Tài liệu">
                <div className="table-responsive">
                  {renderTaiLieu()}
                </div>
              </TabPanel>
              <TabPanel header="Phiếu thu">
                <div className="table-responsive">
                  {renderThuChi()}
                </div>
              </TabPanel>
              <TabPanel header="Phiếu chi">
                <div className="table-responsive">
                  {renderThuChi()}
                </div>
              </TabPanel>
              <TabPanel header="Lịch sử chuyển nhượng">
                <div className="table-responsive">
                  {renderChuyenNhuong()}
                </div>
              </TabPanel>
            </TabView>
          </CardBody>
        </Card>
      </Dialog>
    )
  } catch (error) {}
}
Contract_History = forwardRef(Contract_History)
export default Contract_History
