// import React, { useEffect, useState } from "react"
// import { Table, Tabs, Checkbox } from "antd"
// import { getPermissionAPI } from "store/permission/per.service" // Assuming this imports your API function
// import "./index.scss"
// import { DanhMucService } from "services/DanhMucService"

// const childrenTabsPQXTT = [
//   { title: "Cần bán", key: "0" },
//   { title: "Cần cho thuê", key: "1" },
//   { title: "Cần mua", key: "2" },
//   { title: "Cần thuê", key: "3" },
// ]

// const ChildrenTabsPQXTT = () => {
//   const [dataSource, setDataSource] = useState([])
//   const [data, setData] = useState([])
//   const [activeTab, setActiveTab] = useState("0")

//   useEffect(() => {
//     getPermissionAPI().then(res => setDataSource(res.data))
//     filter(activeTab)
//   }, [])

//   const filter = async tab => {
//     let _res
//     switch (tab) {
//       case "0":
//         _res = await DanhMucService.phanQuyenXemThongTin.get({ isBan: true })
//         break
//       case "1":
//         _res = await DanhMucService.phanQuyenXemThongTin.get({
//           isChoThue: true,
//         })
//         break
//       case "2":
//         _res = await DanhMucService.phanQuyenXemThongTin.get({ isMua: true })
//         break
//       case "3":
//         _res = await DanhMucService.phanQuyenXemThongTin.get({
//           isCanThue: true,
//         })
//         break
//     }
//     setData(_res?.data ?? [])
//   }

//   const handleCheckboxChange = async (checked, record, field) => {
//     const updatedRecord = { ...record, [field]: checked }

//     try {
//       await DanhMucService.phanQuyenXemThongTin.add(updatedRecord)

//       const newData = [...data]
//       const index = newData.findIndex(item => item.perID === record.perID)

//       if (index > -1) {
//         newData[index] = updatedRecord
//         setData(newData)
//       }
//     } catch (error) {
//       console.error("Failed to update permission", error)
//     }
//   }
//   const CheckPer = (perid, prop) => {
//     let check = data?.some(v => v.PerID === perid && v[prop])

//     return check
//   }
//   const columns = [
//     { title: "Nhóm quyền", dataIndex: "perName", key: "perName" },
//     {
//       title: "Điện thoại 1",
//       render: item => (
//         <Checkbox
//           checked={CheckPer(item.perID, "DienThoai1")}
//           onChange={e =>
//             handleCheckboxChange(e.target.checked, item, "DienThoai1")
//           }
//         />
//       ),
//     },
//     {
//       title: "Điện thoại 2",
//       render: item => (
//         <Checkbox
//           checked={CheckPer(item.perID, "DienThoai2")}
//           onChange={e =>
//             handleCheckboxChange(e.target.checked, item, "DienThoai2")
//           }
//         />
//       ),
//     },
//     {
//       title: "Khách hàng",

//       render: item => (
//         <Checkbox
//           checked={CheckPer(item.perID, "KhachHang")}
//           onChange={e =>
//             handleCheckboxChange(e.target.checked, item, "KhachHang")
//           }
//         />
//       ),
//     },
//     { title: "Số nhà", dataIndex: "SoNha", key: "SoNha" },
//     { title: "Địa chỉ", dataIndex: "diaChi", key: "diaChi" },
//   ]
//   const onChange = newActiveKey => {
//     setActiveTab(newActiveKey)
//     filter(newActiveKey)
//   }

//   return (
//     <Tabs
//       className="childrenMGL"
//       defaultActiveKey="CanBan"
//       centered
//       tabBarGutter={5}
//       onChange={onChange}
//     >
//       {childrenTabsPQXTT.map(tab => (
//         <Tabs.TabPane tab={tab.title} key={tab.key}>
//           <Table dataSource={dataSource} columns={columns} rowKey="perID" />
//         </Tabs.TabPane>
//       ))}
//     </Tabs>
//   )
// }

// export default ChildrenTabsPQXTT

import React, { useEffect, useState } from "react"
import { Table, Tabs, Checkbox } from "antd"
import { getPermissionAPI } from "store/permission/per.service" // Assuming this imports your API function
import "./index.scss"
import { DanhMucService } from "services/DanhMucService"

const childrenTabsPQXTT = [
  { title: "Cần bán", key: "0" },
  { title: "Cần cho thuê", key: "1" },
  { title: "Cần mua", key: "2" },
  { title: "Cần thuê", key: "3" },
]

const tabKeyToField = {
  0: "isBan",
  1: "isChoThue",
  2: "isMua",
  3: "isCanThue",
}

const ChildrenTabsPQXTT = () => {
  const [dataSource, setDataSource] = useState([])
  const [data, setData] = useState([])
  const [activeTab, setActiveTab] = useState("0")

  useEffect(() => {
    fetchInitialData()
  }, [])

  useEffect(() => {
    filter(activeTab)
  }, [activeTab])

  const fetchInitialData = async () => {
    const res = await getPermissionAPI()
    setDataSource(res.data)
  }

  const filter = async tab => {
    let _res
    switch (tab) {
      case "0":
        _res = await DanhMucService.phanQuyenXemThongTin.get({ isBan: true })
        break
      case "1":
        _res = await DanhMucService.phanQuyenXemThongTin.get({
          isChoThue: true,
        })
        break
      case "2":
        _res = await DanhMucService.phanQuyenXemThongTin.get({ isMua: true })
        break
      case "3":
        _res = await DanhMucService.phanQuyenXemThongTin.get({
          isCanThue: true,
        })
        break
      default:
        _res = { data: [] }
        break
    }
    setData(_res?.data ?? [])
  }

  const handleCheckboxChange = async (checked, record, field) => {
    const tabField = tabKeyToField[activeTab]
    let item = data?.find(v => v.PerID === record.perID)
    item = item
      ? item
      : {
          KhachHang: false,
          DienThoai1: false,
          DienThoai2: false,
          SoNha: false,
        }
    const updatedRecord = {
      ...item,
      PerID: record.perID,
      [field]: checked,
      [tabField]: true,
    }

    try {
      await DanhMucService.phanQuyenXemThongTin.add(updatedRecord)

      const newData = data.map(item =>
        item.PerID === updatedRecord.PerID ? updatedRecord : item
      )
      if (!newData.some(item => item.PerID === updatedRecord.PerID)) {
        newData.push(updatedRecord)
      }
      setData(newData)
    } catch (error) {
      console.error("Failed to update permission", error)
    }
  }

  const CheckPer = (perid, prop) => {
    return data.some(v => v.PerID === perid && v[prop])
  }

  const columns = [
    { title: "Nhóm quyền", dataIndex: "perName", key: "perName" },
    {
      title: "Điện thoại 1",
      render: item => (
        <Checkbox
          checked={CheckPer(item.perID, "DienThoai1")}
          onChange={e =>
            handleCheckboxChange(e.target.checked, item, "DienThoai1")
          }
        />
      ),
    },
    {
      title: "Điện thoại 2",
      render: item => (
        <Checkbox
          checked={CheckPer(item.perID, "DienThoai2")}
          onChange={e =>
            handleCheckboxChange(e.target.checked, item, "DienThoai2")
          }
        />
      ),
    },
    {
      title: "Khách hàng",
      render: item => (
        <Checkbox
          checked={CheckPer(item.perID, "KhachHang")}
          onChange={e =>
            handleCheckboxChange(e.target.checked, item, "KhachHang")
          }
        />
      ),
    },
    {
      title: "Số nhà",

      render: item => (
        <Checkbox
          checked={CheckPer(item.perID, "SoNha")}
          onChange={e => handleCheckboxChange(e.target.checked, item, "SoNha")}
        />
      ),
    },
    { title: "Địa chỉ", dataIndex: "diaChi", key: "diaChi" },
  ]

  const onChange = newActiveKey => {
    setActiveTab(newActiveKey)
  }

  return (
    <Tabs
      className="childrenMGL"
      defaultActiveKey="CanBan"
      centered
      tabBarGutter={5}
      onChange={onChange}
    >
      {childrenTabsPQXTT.map(tab => (
        <Tabs.TabPane tab={tab.title} key={tab.key}>
          <Table dataSource={dataSource} columns={columns} rowKey="perID" />
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

export default ChildrenTabsPQXTT
