import { del, get, post, put } from "../helpers/api_helper"

export const CRMService = {
  Zalo: {
    Send: async payload => {
      return await post("crm/send-zalo", payload).then(res => res)
    },
    SendOTP: async payload => {
      return await post("crm/send-zalo-otp", payload).then(res => res)
    },
  },
}
