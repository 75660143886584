import { Flex, Input } from "antd"
import React from "react"
import "./index.scss"
const InputCustom = ({
  label,
  applyChange,
  prop,
  value,
  placeholder,
  disabled,
}) => {
  return (
    <Flex className="input-custom" align="center" justify="flex-start">
      <p
        //   className="text-overflow-ellipsis white-space-nowrap overflow-hidden"
        style={{ fontWeight: "600", width: "35%" }}
      >
        {label}
      </p>
      <Input
        style={{ maxWidth: "65% ", border: "none" }}
        value={value}
        placeholder={placeholder}
        onChange={e =>
          typeof applyChange === "function" && applyChange(prop, e.target.value)
        }
        disabled={disabled}
      />
    </Flex>
  )
}

export default InputCustom
