// import React, { useEffect, useRef, useState } from "react"
// import {
//   Button,
//   Col,
//   Popconfirm,
//   Row,
//   Table,
//   Tooltip,
//   notification,
// } from "antd"
// import FilterModal from "./FilterModal"
// import FormAdd from "./FormAdd"
// import { Format_Date } from "../../Utility/common"
// import { Format_Currency } from "../../Utility/common"
// // import { CiTrash } from "react-icons/ci";
// // import { MdEdit } from "react-icons/md";
// import _ from "lodash"
// import Toolbar from "components/Toolbar"
// // import { SoQuyService } from "services/SoQuyService";
// // import { CongTyService } from "services/CongTyService";
// // import { OtherService } from "services/OtherService";
// export default function PhieuChi() {
//   const [api, contextHolder] = notification.useNotification()
//   const [data, setData] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [loaiPT, setLoaiPT] = useState([])
//   const [hinhThucTT, setHinhThucTT] = useState([])
//   const [filterCondition, setFilterCondition] = useState({
//     TuNgay: new Date(),
//     DenNgay: new Date(),
//     ListMaCT: ",,",
//     MaCT: 0,
//     LoaiThu: ",,",
//     HinhThuc: ",,",
//   })
//   const refDetail = useRef(null)
//   const openNotification = (type, placement, message) => {
//     api[type]({
//       message: `Thông báo`,
//       description: message,
//       placement,
//     })
//   }
//   const loadCategories = async () => {
//     return
//     // let _resCT = await CongTyService.getCongTy();
//     // setCompanies(_resCT.data);
//     OtherService.getHinhThucTT().then(res => setHinhThucTT(res.data ?? []))
//     SoQuyService.PhieuThu.getLoaiPhieuThu().then(res =>
//       setLoaiPT(res.data ?? [])
//     )
//   }
//   useEffect(() => {
//     filter(filterCondition)
//     loadCategories()
//   }, [])
//   const filter = async _filter => {
//     return
//     setLoading(true)
//     let _res = await SoQuyService.PhieuThu.getPhieuThu(_filter)
//     if (_res.data) setData(_res.data)
//     setLoading(false)
//   }
//   const onChangeFilter = _filter => {
//     filter(_filter)
//     setFilterCondition(_filter)
//   }
//   const onAfterSubmit = () => {
//     filter(filterCondition)
//   }
//   const columns = [
//     {
//       title: "Số phiếu",
//       dataIndex: "SoPhieu",
//       fixed: "left",
//     },
//     {
//       title: "Chứng từ gốc",
//       dataIndex: "ChungTuGoc",
//     },
//     {
//       title: "Ngay chi",
//       dataIndex: "NgayChi",
//       render: item => Format_Date(item),
//     },
//     {
//       title: "Xác nhận",
//       dataIndex: "XacNhan",
//       key: "XacNhan",
//       render: (text, record) => (
//         <Checkbox
//           checked={text}
//           onChange={e => handleCheckboxChange(e, record.key, "XacNhan")}
//         />
//       ),
//     },
//     {
//       title: "Tổng Chi",
//       dataIndex: "TongChi",
//     },
//     {
//       title: "Hình thức",
//       dataIndex: "HinhThuc",
//     },
//     {
//       title: "Người nhận",
//       dataIndex: "NguoiNhan",
//     },
//     {
//       title: "Địa chỉ",
//       dataIndex: "DiaChi",
//     },
//     {
//       title: "Diễn dãi",
//       dataIndex: "DienDai",
//     },

//     {
//       title: "Nhân viên XN",
//       dataIndex: "NhanVienXN",
//     },
//     {
//       title: "Diễn dãi XN",
//       dataIndex: "DuenDaiXN",
//     },
//     {
//       title: "Người nhập",
//       dataIndex: "NguoiNhap",
//     },
//     {
//       title: "Ngày Nhập",
//       dataIndex: "NgayNhap",
//       render: item => Format_Date(item),
//     },

//     {
//       title: "Người cập nhật",
//       dataIndex: "NguoiCapNhat",
//     },
//     {
//       title: "Ngày cập nhật",
//       dataIndex: "NgayCapNhat",
//     },

//     {
//       title: "",
//       fixed: "right",
//       width: "100px",
//       render: item => {
//         return (
//           <div>
//             <Tooltip placement="topRight" title="Sửa">
//               <Button
//                 style={{ marginLeft: 10 }}
//                 type="primary"
//                 ghost
//                 // icon={<MdEdit />}
//                 onClick={() => refDetail.current.update(item)}
//               />
//             </Tooltip>

//             <Popconfirm
//               title="Xoá phiếu thu"
//               description="Bạn có chắc chắn muốn xoá phiếu thu?"
//               okText="Đồng ý"
//               cancelText="Không"
//               onConfirm={() => remove(item.ID)}
//             >
//               <Tooltip placement="topRight" title="Xoá">
//                 <Button
//                   style={{ marginLeft: 10 }}
//                   danger
//                   ghost
//                   // icon={<CiTrash />}
//                 />
//               </Tooltip>
//             </Popconfirm>
//           </div>
//         )
//       },
//     },
//   ]
//   const remove = async value => {
//     return
//     let _res = await SoQuyService.PhieuThu.deletePhieuThu({ ID: Number(value) })
//     if (_res.status === 2000) {
//       let _data = _.cloneDeep(data)
//       let fil = _data.filter(v => v.ID !== Number(value))
//       setData(fil)
//       openNotification("success", "topRight", "Xoá phiếu thu thành công")
//     } else {
//       openNotification("error", "topRight", _res?.message)
//     }
//   }
//   return (
//     <Row gutter={15} className="page-content">
//       <Col span={4}>
//         <FilterModal
//           hinhThuc={hinhThucTT}
//           loai={loaiPT}
//           filterCondition={filterCondition}
//           onChangeFilter={onChangeFilter}
//         />
//       </Col>
//       <Col span={20}>
//         <Toolbar
//           right={() => (
//             <Button type="primary" onClick={() => refDetail.current.show()}>
//               Thêm
//             </Button>
//           )}
//         />
//         <Table
//           loading={loading}
//           dataSource={data}
//           columns={columns}
//           scroll={{
//             y: window.innerHeight - 150,
//             x: 2500,
//           }}
//           size="small"
//         />
//       </Col>
//       <FormAdd
//         ref={refDetail}
//         onAfterSubmit={onAfterSubmit}
//         hinhThuc={hinhThucTT}
//         loai={loaiPT}
//       />
//       {contextHolder}
//     </Row>
//   )
// }

import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
  Checkbox,
} from "antd"
import FilterModal from "./FilterModal"
import FormAdd from "./FormAdd"
import { Format_Date } from "../../Utility/common"
import _ from "lodash"
import Toolbar from "components/Toolbar"

const sampleData = [
  {
    ID: 1,
    SoPhieu: "PC001",
    ChungTuGoc: "CTG001",
    NgayChi: new Date("2023-01-01"),
    XacNhan: true,
    TongChi: 1000000,
    HinhThuc: "Chuyển khoản",
    NguoiNhan: "Nguyễn Văn A",
    DiaChi: "Hà Nội",
    DienDai: "Chi phí mua văn phòng phẩm",
    NhanVienXN: "Trần Văn B",
    DuenDaiXN: "Đã xác nhận",
    NguoiNhap: "Lê Văn C",
    NgayNhap: new Date("2023-01-02"),
    NguoiCapNhat: "Lê Văn D",
    NgayCapNhat: new Date("2023-01-03"),
  },
  {
    ID: 21,
    SoPhieu: "PC0011",
    ChungTuGoc: "CTG0011",
    NgayChi: new Date("2023-01-01"),
    XacNhan: true,
    TongChi: 1000000,
    HinhThuc: "Chuyển khoản",
    NguoiNhan: "Nguyễn Văn A",
    DiaChi: "Hà Nội",
    DienDai: "Chi phí mua văn phòng phẩm",
    NhanVienXN: "Trần Văn B",
    DuenDaiXN: "Đã xác nhận",
    NguoiNhap: "Lê Văn C",
    NgayNhap: new Date("2023-01-02"),
    NguoiCapNhat: "Lê Văn D",
    NgayCapNhat: new Date("2023-01-03"),
  },
  // Add more sample records as needed
]

const sampleLoaiPT = [
  { ID: 1, Ten: "Loại 1" },
  { ID: 2, Ten: "Loại 2" },
]

const sampleHinhThucTT = [
  { ID: 1, Ten: "Tiền mặt" },
  { ID: 2, Ten: "Chuyển khoản" },
]

export default function PhieuChi() {
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loaiPT, setLoaiPT] = useState([])
  const [hinhThucTT, setHinhThucTT] = useState([])
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
    MaCT: 0,
    LoaiThu: ",,",
    HinhThuc: ",,",
  })
  const refDetail = useRef(null)
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const loadCategories = async () => {
    setLoaiPT(sampleLoaiPT)
    setHinhThucTT(sampleHinhThucTT)
  }
  useEffect(() => {
    filter(filterCondition)
    loadCategories()
  }, [])
  const filter = async _filter => {
    setLoading(true)
    // Simulate API call
    setTimeout(() => {
      setData(sampleData)
      setLoading(false)
    }, 1000)
  }
  const onChangeFilter = _filter => {
    filter(_filter)
    setFilterCondition(_filter)
  }
  const onAfterSubmit = () => {
    filter(filterCondition)
  }
  const columns = [
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      fixed: "left",
    },
    {
      title: "Chứng từ gốc",
      dataIndex: "ChungTuGoc",
    },
    {
      title: "Ngay chi",
      dataIndex: "NgayChi",
      render: item => Format_Date(item),
    },
    {
      title: "Xác nhận",
      dataIndex: "XacNhan",
      key: "XacNhan",
      render: (text, record) => (
        <Checkbox
          checked={text}
          onChange={e => handleCheckboxChange(e, record.ID, "XacNhan")}
        />
      ),
    },
    {
      title: "Tổng Chi",
      dataIndex: "TongChi",
    },
    {
      title: "Hình thức",
      dataIndex: "HinhThuc",
    },
    {
      title: "Người nhận",
      dataIndex: "NguoiNhan",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
    },
    {
      title: "Diễn dãi",
      dataIndex: "DienDai",
    },
    {
      title: "Nhân viên XN",
      dataIndex: "NhanVienXN",
    },
    {
      title: "Diễn dãi XN",
      dataIndex: "DuenDaiXN",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày Nhập",
      dataIndex: "NgayNhap",
      render: item => Format_Date(item),
    },
    {
      title: "Người cập nhật",
      dataIndex: "NguoiCapNhat",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "NgayCapNhat",
    },
    // {
    //   title: "",
    //   fixed: "right",
    //   width: "100px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Tooltip placement="topRight" title="Sửa">
    //           <Button
    //             style={{ marginLeft: 10 }}
    //             type="primary"
    //             ghost
    //             // icon={<MdEdit />}
    //             onClick={() => refDetail.current.update(item)}
    //           />
    //         </Tooltip>

    //         <Popconfirm
    //           title="Xoá phiếu thu"
    //           description="Bạn có chắc chắn muốn xoá phiếu thu?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.ID)}
    //         >
    //           <Tooltip placement="topRight" title="Xoá">
    //             <Button
    //               style={{ marginLeft: 10 }}
    //               danger
    //               ghost
    //               // icon={<CiTrash />}
    //             />
    //           </Tooltip>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ]
  const remove = async value => {
    // Simulate API call to remove data
    setData(prevData => prevData.filter(item => item.ID !== value))
    openNotification("success", "topRight", "Xoá phiếu thu thành công")
  }
  const handleCheckboxChange = (e, recordId, fieldName) => {
    const newData = data.map(item => {
      if (item.ID === recordId) {
        return { ...item, [fieldName]: e.target.checked }
      }
      return item
    })
    setData(newData)
  }
  return (
    <Row gutter={15} className="page-content">
      <Col span={4}>
        <FilterModal
          hinhThuc={hinhThucTT}
          loai={loaiPT}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          right={() => (
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 2500,
          }}
          size="small"
        />
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        hinhThuc={hinhThucTT}
        loai={loaiPT}
      />
      {contextHolder}
    </Row>
  )
}
