import { del, get, post, put } from "../helpers/api_helper"

export const HopDongService = {
  DanhSachLookCan: {
    getDanhSach: async payload => {
      return await post("admin/san-pham/danh-sach-lock-can", payload).then(
        res => res
      )
    },
    detailLockCan: async payload => {
      return await post("admin/lock-can-chi-tiet", payload).then(res => res)
    },
    addBooking: async payload => {
      return await post("admin/san-pham/add-booking", payload).then(res => res)
    },

    historyLockCan: async payload => {
      return await post("admin/lock/get-bymasp", payload).then(res => res)
    },
  },
}
