import { confirmDialog  } from 'primereact/confirmdialog';
const CommonFunction  = {
    confirmRemove : (props) =>{
        return confirmDialog({
            message: props?.message,
            header: props?.header,
            icon: 'pi pi-exclamation-triangle',
            accept: () => typeof(props.accept) === 'function' && props.accept(),
            reject: () =>  {}
        });
    }
}
export default CommonFunction;
