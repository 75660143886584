import React, { useState, useImperativeHandle, forwardRef } from "react"
import { Drawer, notification } from "antd"
import _ from "lodash"
import { ContractService } from "services/Contract"
import UploadComponent from "components/UploadComponent"
import { API_UPLOAD_URL } from "services/UploadService"
const FormImage = (props, ref) => {
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState(null)
  const [MaPGC, setMaPGC] = useState(null)
  const [open, setOpen] = useState(false)
  useImperativeHandle(ref, () => ({
    show: async data => {
      setOpen(true)
      let _res = await ContractService.Booking.getImages({ MaPGC: data.maPGC })
      setMaPGC(data.maPGC)
      setData({
        data:_res.data,
        images: _.map(_res.data, "uri"),
      })
    },
  }))
  console.log(data);
  const onClose = () => {
    setOpen(false)
    setData(null)
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const affterUpload = async file => {
    await ContractService.Booking.addImages({
      MaPGC: MaPGC,
      Images: file.map(item => API_UPLOAD_URL + item),
    })
  }
  const onRemove = async file => {
    let id = data?.data?.find(item => item.uri === file.url)?.ID;
    await ContractService.Booking.deleteImage(id);
  }
  return (
    <Drawer
      title="Hình ảnh"
      width={700}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
          marginTop: 20,
        },
      }}
    >
      {contextHolder}
      <UploadComponent
        files={data?.images}
        multiple={true}
        affterUpload={affterUpload}
        onRemove={onRemove}
      />
    </Drawer>
  )
}
export default forwardRef(FormImage)
