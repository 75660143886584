import { del, get, post, put } from "../helpers/api_helper"

export const PermissionService = {
  checkQuyen: async (payload) => {
    return await post("permission/check", payload)
      .then((res) => res);
  },
  getNhomQuyen: async () => {
    return await post("nhanvien/get-nhom-quyen")
      .then((res) => res);
  },
  addNhomQuyen: async (payload) => {
    return await post("permission/add-update", payload)
      .then((res) => res);
  },
  deleteNhomQuyen: async (payload) => {
    return await post("permission/delete", payload)
      .then((res) => res);
  },
  getMenuLevel1: async (payload) => {
    return await post("permission/get-modules", payload)
      .then((res) => res);
  },
  getForm: async (payload) => {
    return await post("permission/get-form", payload)
      .then((res) => res);
  },
  addAccessData: async (payload) => {
    return await post("permission/add-accessdata", payload)
      .then((res) => res);
  },
  updateAccessData: async (payload) => {
    return await post("permission/update-accessdata", payload)
      .then((res) => res);
  },
  addAction: async (payload) => {
    return await post("permission/add-action", payload)
      .then((res) => res);
  },
  deleteAction: async (payload) => {
    return await post("permission/remove-action", payload)
      .then((res) => res);
  },
  TheoCongTy: {
    getCongTyDangKy: async () => {
      return await post("cong-ty/get").then(res => res)
    },
    addForm: async payload => {
      return await post("cong-ty/permission/add-form", payload).then(
        res => res
      )
    },
    getForm: async payload => {
      return await post("cong-ty/permission/get-form", payload).then(
        res => res
      )
    },
    getMenu: async () => {
      return await post("cong-ty/get-menu").then(res => res)
    },
  },
  TheoNhomModules: {
    addForm: async (payload) => {
      return await post("nhom-modules/add-form", payload)
        .then((res) => res);
    },
    getForm: async (payload) => {
      return await post("nhom-modules/get-form", payload)
        .then((res) => res);
    },
    getFormByMaNhom: async (payload) => {
      return await post("nhom-modules/get-form-bymanhom", payload)
        .then((res) => res);
    },
  },
}
