import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Popover,
  Row,
  Select,
} from "antd"
import _ from "lodash"
import dayjs from "dayjs"
import "dayjs/locale/vi"
import ActiveRoute from "components/ActiveRoute"
// import { ToaNhaService } from "services/ToaNhaService";
// import { NhanVienService } from "services/NhanVienService";
// import { YeuCauService } from "services/YeuCauService";
import { FaSort } from "react-icons/fa6"
import FilterDate from "components/FilterDate"

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter, options } = props

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "maDA":
        _filter[p] = v
        break

      default:
        _filter[p] = v
        break
    }
    onChangeFilter(_filter)
  }
  const onChangeDate = v => {
    let _filter = _.cloneDeep(filterCondition)
    _filter.TuNgay = v.TuNgay
    _filter.DenNgay = v.DenNgay
    onChangeFilter(_filter)
  }
  return (
    <div className="">
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <div className="box-search">
        <p className="label">Dự án</p>
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("maDA")}
          value={filterCondition.maDA}
          placeholder="Chọn dự án"
          onChange={v => applyChangeFilter("maDA", v)}
          style={{
            width: "100%",
            borderBottom: "1px solid rgb(217, 217, 217)",
          }}
          options={options?.ToaNha}
          fieldNames={{ label: "tenDA", value: "maDA" }}
        />
      </div>

      <div className="box-search">
        <p className="label">Loại thông báo</p>
        <Select
          // mode="multiple"
          // defaultValue={localStorage.getItem("maDA")}
          value={filterCondition.MaChucNang}
          placeholder="Chọn loại thông báo"
          onChange={v => applyChangeFilter("MaChucNang", v)}
          style={{
            width: "100%",
            borderBottom: "1px solid rgb(217, 217, 217)",

          }}
          options={[{ Name: "Tất cả", ID: 0 }, ...options?.LoaiTB]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
    </div>
  )
}
export default FilterModal
