import React, { useRef, useState, useEffect } from "react"
import { MultiSelect } from "primereact/multiselect"
import { OverlayPanel } from "primereact/overlaypanel"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import _ from "lodash"
import { ProjectService } from "services/Project"
import { ContractService } from "services/Contract"
import { Dropdown } from "primereact/dropdown"

function FilterLockCan(props, ref) {
  const { filterCondition, afterChangeFilter } = props
  const refFilterPanel = useRef(null)
  const [projects, setProjects] = useState([])
  const [status, setStatus] = useState([])
  const applyChangeFilter = (prop, val) => {
    let _filterCondition = _.cloneDeep(filterCondition)
    if (prop) {
      switch (prop) {
        default:
          _filterCondition[prop] = val
          break
      }
      afterChangeFilter(_filterCondition)
    }
  }
  useEffect(() => {
    ProjectService.filter({}).then(res => setProjects(res?.data))
    ContractService.getStatus({ Type: 2 }).then(res => setStatus(res?.data))
  }, [])
  const clearFilter = () => {
    let preyear = new Date().setFullYear(new Date().getFullYear() - 5)
    let _filterCondition = _.cloneDeep(filterCondition)
    _filterCondition.DuAn = []
    _filterCondition.MaTT = 0
    _filterCondition.inputSearch = ""
    _filterCondition.TuNgay = new Date(preyear)
    _filterCondition.DenNgay = new Date()
    afterChangeFilter(_filterCondition)
  }
  try {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <span className="p-input-icon-left">
            {/* <i className="bx bx-search-alt" /> */}
            <InputText
              value={filterCondition?.inputSearch}
              onInput={e => applyChangeFilter("inputSearch", e.target.value)}
              placeholder="Tìm kiếm"
            />
          </span>
          <Button
            icon="pi pi-filter"
            className="p-button-rounded p-button-text p-button-plain"
            onClick={e => refFilterPanel.current.toggle(e)}
            aria-label="Filter"
          />
        </div>
        <OverlayPanel
          ref={refFilterPanel}
          className="x-menu"
          style={{ width: "600px" }}
        >
          <div className="grid formgrid p-fluid">
            <div className="col-12 mb-2">
              <label htmlFor="icon">Dự án</label>
              <MultiSelect
                optionLabel="TenDA"
                showSelectAll={false}
                optionValue="MaDA"
                display="chip"
                value={filterCondition.DuAn}
                options={projects}
                onChange={e => applyChangeFilter("DuAn", e.value)}
              />
            </div>
            <div className="col-12 mb-2">
              <label htmlFor="icon">Trạng thái</label>
              <Dropdown
                value={filterCondition.MaTT}
                options={status}
                onChange={e => applyChangeFilter("MaTT", e.value)}
                optionLabel="TenTT"
                optionValue="MaTT"
              />
            </div>
            <div className="col-6 mb-2">
              <label htmlFor="icon">Từ ngày</label>
              <Calendar
                // locale="vi-VN"
                showDate
                showIcon
                label="Từ ngày"
                value={filterCondition.TuNgay}
                onChange={v => applyChangeFilter("TuNgay", v.value)}
                // maxDate={filterCondition.end || null}
              ></Calendar>
            </div>
            <div className="col-6 mb-2">
              <label htmlFor="icon">Đến ngày</label>
              <Calendar
                // locale="vi-VN"
                showDate
                showIcon
                value={filterCondition.DenNgay}
                onChange={v => applyChangeFilter("DenNgay", v.value)}
                // minDate={filterCondition.start || null}
              ></Calendar>
            </div>
          </div>
          <Button
            icon="pi pi-filter-slash"
            className="p-button-raised p-button-danger p-button-text"
            onClick={e => clearFilter()}
            label="Xóa lọc"
            aria-label="Filter"
          />
        </OverlayPanel>
      </React.Fragment>
    )
  } catch (err) {
    return null
  }
}
export default FilterLockCan
