import * as types from "./actionTypes"

const INIT_STATE = {
  customers: [],
  customer: [],
  status: [],
  quyDanh: [],
  mucDich: [],
  address: [],
  groupCustomer: [],
  ngheNghiep: [],
  nguonDen:[],
  isDone:false,
  historyCustomer:[],
}

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }
    case types.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload,
      }
    case types.DELETE_CUSTOMER_SUCCESS:
      // const cusDL = state.customers.filter(item => {
      //   return !action.payload.MaKH.includes(item.maKH)
      // })
      return {
        ...state,
        // customers: cusDL,
        isDone: true,
      }
    case types.DELETE_CUSTOMER:
      return {
        ...state,
        isDone: false,
      }
    case types.GET_CATEGORIES_CUSTOMER_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        quyDanh: action.payload.quyDanh,
        mucDich: action.payload.mucDich,
        address: action.payload.address,
        groupCustomer: action.payload.groupCustomer,
        ngheNghiep: action.payload.ngheNghiep,
        nguonDen: action.payload.nguonDen,
      }
    case types.GET_GHICHU_CUSTOMER_SUCCESS:
      return {
        ...state,
        historyCustomer: action.payload,
      }
    default:
      return state
  }
}

export default Customer
