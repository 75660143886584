import React, { useState, useImperativeHandle, forwardRef } from "react"
import {
  Select,
  Drawer,
  Divider,
  Checkbox,
  Row,
  Col,
  Collapse,
  Button,
} from "antd"
import _ from "lodash"
import "./index.scss"

// import { PermissionService } from "services/PermissionService";
import { CaretRightOutlined } from "@ant-design/icons"
import { Panel } from "primereact/panel"
import { Accordion, AccordionTab } from "primereact/accordion"
import { PermissionService } from "services/PermissionService"

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [perID, setPerID] = useState(0)
  const [menulv1, setMenulv1] = useState([])
  const loadModules = async () => {
    

    let _res2 = await PermissionService.getMenuLevel1({})

    const menuCT = JSON.parse(localStorage.getItem("menu_congty"))
    const menuData = JSON.parse(localStorage.getItem("menu"))
    const accessMenuCongTy = menuCT?.map(item => item.FormID)
    const congtylv1 = menuCT?.map(item => item.ModulIDLevel1)
    const accessMenu = menuData?.map(item => item.FormID)
    const menulv1 = menuData?.map(item => item.ModulIDLevel1)

    const filteredRoutes = _res2?.data?.filter(item => {
      if (congtylv1?.includes(item.ModulID)) {
        return true
        // if (item.children) {
        //   item.children = item.children.filter(
        //     (v) =>
        //       accessMenu?.includes(v.id) && accessMenuCongTy?.includes(v.id)
        //   );
        //   return menulv1?.includes(item.id);
        // } else {
        // return accessMenu?.includes(item.id) || item.id === 0;
        // }
      }
      return false
    })
    setMenulv1(filteredRoutes)
  }
  const loadForm = async _perid => {
    
    let _res = await PermissionService.getForm({ PerID: _perid })

      
    const menuCT = JSON.parse(localStorage.getItem("menu_congty"))
    const accessMenuCongTy = menuCT?.map(item => item.FormID)
    const filter = _res?.data?.filter(item => {
      if (accessMenuCongTy?.includes(item.formID)) {
        return true
      }
      return false
    })
    setData(filter)
  }
  const loadCategories = async _perid => {
    // let _res = await PermissionService.getForm({ PerID: _perid });
    // setData(_res?.data ?? []);
    loadModules()
    loadForm(_perid)
  }
  useImperativeHandle(ref, () => ({
    show: async _perid => {
      console.log(_perid, "tesstttttt")
      setOpen(true)
      setPerID(_perid)
      loadCategories(_perid)
    },
  }))
  const onClose = () => {
    setOpen(false)
    setData([])
    onAfterSubmit()
  }
  const onChangePer = async item => {
    if (item.per) {
      let payload = {
        SDBID: 1,
        PerID: perID,
        FormID: item.formID,
        HavePer: null,
      }
      let _res = await PermissionService.addAccessData(payload)
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data)
        let _dt = _data.find(v => v.formID === item.formID)
        _dt.per = null
        _dt.sdbid = null
        setData(_data)
      }
    } else {
      let payload = {
        SDBID: 1,
        PerID: perID,
        FormID: item.formID,
        HavePer: true,
      }
      let _res = await PermissionService.addAccessData(payload)
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data)
        let _dt = _data.find(v => v.formID === item.formID)
        _dt.per = item.formID
        _dt.sdbid = 1
        setData(_data)
      }
    }
  }
  const handleChange = async (v, item) => {
    let _data = _.cloneDeep(data)
    let _dt = _data.find(v => v.formID === item.formID)
    let hascheck = _.map(
      _dt.actions?.filter(v => v.isCheck),
      "id"
    )
    let _res = null
    if (hascheck?.length > v.length) {
      //remove
      let id = _.difference(hascheck, v)
      _res = await PermissionService.deleteAction({
        PerID: perID,
        FormID: item.formID,
        FeatureID: id[0],
      })
    } else {
      let id = _.difference(v, hascheck)
      _res = await PermissionService.addAction({
        PerID: perID,
        FormID: item.formID,
        FeatureID: id[0],
      })
    }
    if (_res?.status === 2000) {
      _dt.actions?.map(item => {
        item.isCheck = v.includes(item.id)
      })
      setData(_data)
    }
  }
  const onSelectDuLieu = async (v, item) => {
    let _data = _.cloneDeep(data)
    let _dt = _data.find(v => v.formID === item.formID)
    let payload = {
      SDBID: v,
      PerID: perID,
      FormID: _dt.formID,
    }
    let _res = await PermissionService.updateAccessData(payload)
    if (_res.status === 2000) {
      _dt.sdbid = v
      setData(_data)
    }
  }

  return (
    <Drawer
      title="PHÂN QUYỀN"
      width={920}
      onClose={onClose}
      open={open}
      className="giai-dau-detail1"
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
    >
      <Accordion multiple>
        {menulv1?.map(lv1 => {
          return (
            <AccordionTab key={lv1.ModulID} header={lv1.ModulName}>
              {data
                ?.filter(v => v.modulIDLevel1 === lv1.ModulID)
                ?.map(item => {
                  return (
                    <div key={item.formID}>
                      <Row gutter={[20]}>
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "baseline",
                              gap: 8,
                            }}
                          >
                            <Checkbox
                              onChange={() => onChangePer(item)}
                              checked={item.per}
                            />
                            <p style={{ fontWeight: "500", fontSize: "16px" }}>
                              {item.formName}
                            </p>
                          </div>
                        </Col>
                        <Col span={6}>
                          <Select
                            // mode="multiple"
                            style={{
                              width: "100%",
                            }}
                            placeholder="Quyền xem dữ liệu"
                            onChange={v => onSelectDuLieu(v, item)}
                            fieldNames={{ label: "Name", value: "ID" }}
                            value={item.sdbid}
                            maxTagCount="responsive"
                            options={[
                              { ID: 1, Name: "Xem tất cả" },
                              { ID: 2, Name: "Theo chi nhánh" },
                              { ID: 3, Name: "Theo nhân viên" },
                            ]}
                          />
                        </Col>
                        <Col span={10}>
                          <Select
                            mode="multiple"
                            style={{
                              width: "100%",
                            }}
                            placeholder="Chọn action"
                            onChange={v => handleChange(v, item)}
                            fieldNames={{ label: "name", value: "id" }}
                            value={_.map(
                              item.actions.filter(v => v.isCheck),
                              "id"
                            )}
                            maxTagCount="responsive"
                            options={item.actions}
                          />
                        </Col>
                      </Row>

                      <Divider dashed style={{ margin: "8px 0px" }} />
                    </div>
                  )
                })}
            </AccordionTab>
          )
        })}
      </Accordion>
    </Drawer>
  )
}
export default forwardRef(FormAdd)
