import React, { useEffect, useState, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { IoIosAdd } from "react-icons/io"
import { MdEdit } from "react-icons/md"
import { CiTrash } from "react-icons/ci"

import {
  handleValidDate,
  Format_Currency,
  formatNumberPT,
} from "../../../common/common"
import { Col, Container, Row } from "reactstrap"

import Pagination from "../../../components/Common/Pagination"
import LoadingBar from "components/LoadingBar"
import GiuCho_Filter from "./GiuCho_Filter"
// import { Button } from "primereact/button"
import ToolBar from "components/Toolbar"
import { ContractService } from "services/Contract"
import FormImage from "./FormImage"
import { CiImageOn } from "react-icons/ci"
import { Tooltip, Button, Flex, Dropdown } from "antd"
import { RiListIndefinite } from "react-icons/ri"
import FormDetail from "./FormDetail"

const GiuChoIndex = props => {
  const [modalDelete, setModalDelete] = useState({})
  const [modalDropBtn, setModalDropBtn] = useState(false)
  const [item, setItem] = useState([])
  const toggleDropdownBtn = () => setModalDropBtn(prevState => !prevState)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [filterTT, setFilterTT] = useState([])
  const refImage = useRef(null)
  const refHistory = useRef(null)
  let preyear = new Date().setFullYear(new Date().getFullYear() - 5)
  const defaultCondition = {
    inputSearch: "",
    DuAn: [],
    TuNgay: new Date(preyear),
    DenNgay: new Date(),
    MaKhu: 0,
    MaTT: 0,
    Offset: 1,
    Limit: 20,
  }
  const [filterCondition, setFilterCondition] = useState(defaultCondition)
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maPGC !== row.maPGC
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.stt}
          </Link>
        ),
      },
      // {
      //   dataField: "maPGC",
      //   text: "Xem",
      //   sort: true,
      //   formatter: (cellContent, row) => (
      //     <Link
      //       to="#"
      //       // onClick={() => toggleDetail(row)}
      //       className="text-body fw-bold"
      //     >
      //       <i className="mdi mdi-17px mdi-eye"></i>
      //     </Link>
      //   ),
      // },
      {
        dataField: "maSanPham",
        text: "Mã sản phẩm",
        sort: true,
      },
      {
        dataField: "ngayGiuCho",
        text: "Ngày giữ chỗ",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayGiuCho),
      },
      {
        dataField: "soPhieu",
        text: "Số phiếu",

        sort: true,
        formatter: (cellContent, row) => {
          return (
            <span
              onClick={() => refHistory.current.show(row?.maPGC)}
              className="link"
            >
              {row.soPhieu}
            </span>
          )
        },
      },
      {
        dataField: "tenTT",
        text: "Trạng thái",
        sort: true,
        formatter: (cellContent, row) => (
          <div
            className="shadow-4 rounded"
            style={{
              backgroundColor: row.mauNen,
              padding: "5px",
            }}
          >
            {row.tenTT}
          </div>
        ),
      },
      {
        dataField: "thoiGian",
        text: "Thời gian",
        sort: true,
      },
      {
        dataField: "thoiGianConLai",
        text: "TG còn lại",
        sort: true,
      },
      {
        dataField: "khachHang",
        text: "Khách hàng",
        sort: true,
      },
      {
        dataField: "soCMND",
        text: "Số CMND",
        sort: true,
      },
      {
        dataField: "ngayCap",
        text: "Ngày cấp",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayCap),
      },
      {
        dataField: "noiCap",
        text: "Nơi cấp",
        sort: true,
      },
      {
        dataField: "diaChi",
        text: "Địa chỉ",
        sort: true,
      },
      {
        dataField: "thuongTru",
        text: "Thường trú",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "diDong",
        text: "Di động",
        sort: true,
      },
      {
        dataField: "diDong2",
        text: "Di động 2",
        sort: true,
      },
      {
        dataField: "nhomBangHang",
        text: "Nhóm bảng hàng",
        sort: true,
      },
      {
        dataField: "tenDA",
        text: "Dự án",
        sort: true,
      },
      {
        dataField: "khu",
        text: "Khu",
        sort: true,
      },
      {
        dataField: "dienTichDat",
        text: "Diện tích đất",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dienTichDat),
      },
      {
        dataField: "dienTichXD",
        text: "Diện tích XD",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dienTichXD),
      },
      {
        dataField: "dtTimTuong",
        text: "S Tim tường",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dtTimTuong),
      },
      {
        dataField: "dienTichTT",
        text: "Diện tích TT",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.dienTichTT),
      },

      {
        dataField: "giaChuaVAT",
        text: "Giá chưa VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.giaChuaVAT),
      },
      {
        dataField: "tienVAT",
        text: "Tiền VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tienVAT),
      },
      {
        dataField: "tongGiaGomVAT",
        text: "Tổng giá gồm VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaGomVAT),
      },
      {
        dataField: "phiBaoTri",
        text: "Phí bảo trì",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.phiBaoTri),
      },
      {
        dataField: "tongGiaDat",
        text: "Tổng giá đất",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaDat),
      },
      {
        dataField: "tongGiaXD",
        text: "Tổng giá XD",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaXD),
      },
      {
        dataField: "tongGiaTriHDMB",
        text: "Tổng giá trị HDMB",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaTriHDMB),
      },
      {
        dataField: "donGiaTT",
        text: "Đơn giá T.Thủy",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.donGiaTT),
      },
      {
        dataField: "daThu",
        text: "Đã thu",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.daThu),
      },
      {
        dataField: "ptThanhToan",
        text: "% Thanh toán",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.ptThanhToan),
      },

      {
        dataField: "nhanVienBH",
        text: "Nhân viên BH",
        sort: true,
      },
      {
        dataField: "nhomKD",
        text: "Nhóm KD",
        sort: true,
      },
      {
        dataField: "phongKD",
        text: "Phòng KD",
        sort: true,
      },
      {
        dataField: "sanGiaoDich",
        text: "Sàn giao dịch",
        sort: true,
      },
      {
        dataField: "ngayNhap",
        text: "Ngày nhập",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayNhap),
      },
      {
        dataField: "ngaySua",
        text: "Ngày sửa",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngaySua),
      },
      {
        dataField: "nguoiNhap",
        text: "Người nhập",
        sort: true,
      },

      {
        dataField: "nguoiSua",
        text: "Người sửa",
        sort: true,
      },

      {
        style: {
          backgroundColor: "white",
          position: "sticky",
          right: 0,
          paddingLeft: "0px",
        },
        headerStyle: {
          backgroundColor: "rgb(236, 240, 247)",
          position: "sticky",
          right: 0,
        },
        formatter: (cellContent, row) => (
          <Flex align="center" style={{ boxShadow: "-5px 0 5px -5px #333" }}>
            <Tooltip placement="topRight" title="Xem ảnh">
              <Button
                danger
                ghost
                icon={<CiImageOn />}
                style={{ marginLeft: 5 }}
                onClick={() => refImage.current.show(row)}
              />
            </Tooltip>
          </Flex>
        ),
      },
    ]
  }, [])

  const applyChangeFilter = (prop, val) => {
    let _filterCondition = _.cloneDeep(filterCondition)
    console.log(prop)
    console.log(val)
    console.log(_filterCondition[prop])
    if (prop) {
      switch (prop) {
        default:
          _filterCondition[prop] = val
          break
      }
      afterChangeFilter(_filterCondition)
    }
  }
  const filter = async filter => {
    setLoading(true)
    let _filter = _.cloneDeep(filter)
    _filter.DuAn =
      _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await ContractService.Booking.filter(_filter)
    ContractService.getStatus({ Type: 1 }).then(res => setFilterTT(res?.data))
    setData(res.data || [])
    setLoading(false)
  }
  useEffect(() => {
    ;(async () => {
      await filter(filterCondition)
    })()
  }, [])
  const handleEdit = () => {}

  const onClickXemAnh = () => {}
  const handleDelete = useCallback(itemDL => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(itemDL),
    })
  }, [])
  const onClickDelete = useCallback(itemDL => {}, [])

  const handleAdd = () => {}

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  // useEffect(() => {
  //   let time
  //   if (!time) {
  //     time = setTimeout(() => {
  //       dispatch(
  //         getGiuCho({
  //           TuNgay: dateFrom,
  //           DenNgay: dateTo,
  //           DuAn: maDuAn,
  //           MaTT: maTT,
  //           MaKhu: maKhu,
  //           inputSearch: textSearch,
  //           Offset: page,
  //           Limit: itemPerPage,
  //         })
  //       )
  //       setItem([])
  //     }, [1000])
  //   }
  //   return () => {
  //     clearTimeout(time)
  //   }
  // }, [textSearch])
  const afterChangeFilter = async _filter => {
    setFilterCondition(_filter)
    await filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "page":
        _filter.Offset = v
        break
      case "itemPerPage":
        _filter.Offset = 1
        _filter.Limit = v
        break
    }
    setFilterCondition(_filter)
    filter(_filter)
  }
  const items = [
    {
      key: "1",
      label: <p>Duyệt</p>,
    },
    {
      key: "2",
      label: <p>Chờ duyệt</p>,
    },
    {
      key: "3",
      label: <p>Huỷ phiếu</p>,
    },
  ]

  return (
    <div className="page-content">
      <MetaTags>
        <title>Giữ chỗ| Beesky</title>
      </MetaTags>
      <Container fluid>
        <ToolBar
          left={() => (
            <Flex gap={5} className="text-sm-start">
              <Button icon={<IoIosAdd />} onClick={handleAdd}>
                Thêm
              </Button>
              <Button
                type={filterCondition?.MaTT === 0 ? "primary" : ""}
                onClick={() => applyChangeFilter("MaTT", 0)}
              >
                Tất cả
              </Button>
              {filterTT.map(item => (
                <Button
                  onClick={() => applyChangeFilter("MaTT", item?.MaTT)}
                  key={item.tenTT}
                  type={filterCondition?.MaTT === item?.MaTT ? "primary" : ""}
                >
                  {item.TenTT}
                </Button>
              ))}
              {item.length === 1 && (
                <Button icon={<MdEdit />} onClick={handleEdit}>
                  Sửa
                </Button>
              )}
              {item.length >= 1 && (
                <Button icon={<CiTrash />} danger onClick={handleDelete}>
                  Xoá
                </Button>
              )}
              {item.length >= 1 && (
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomLeft"
                >
                  <Button icon={<RiListIndefinite />}>Hành động</Button>
                </Dropdown>
              )}
            </Flex>
          )}
          right={() => (
            <GiuCho_Filter
              filterCondition={filterCondition}
              afterChangeFilter={afterChangeFilter}
            />
          )}
        />
        <ToolkitProvider
          keyField="maPGC"
          data={data}
          columns={Columns()}
          bootstrap4
        >
          {toolkitProps => (
            <React.Fragment>
              <LoadingBar loading={loading} />
              <div className="table-responsive table-scrollable">
                <BootstrapTable
                  keyField="maPGC"
                  responsive
                  bordered={false}
                  striped={false}
                  defaultSorted={defaultSorted}
                  selectRow={selectRow}
                  classes={
                    "table align-middle  table-nowrap table-check table-hover table-fixed "
                  }
                  headerWrapperClasses={"table-light"}
                  {...toolkitProps.baseProps}
                />
              </div>
              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <select
                      className="form-select"
                      value={filterCondition.Limit}
                      onChange={e =>
                        onChangeFilter("itemPerPage", Number(e.target.value))
                      }
                    >
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                    </select>
                  </div>
                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                    <Pagination
                      totalPage={Math.ceil(
                        data[0]?.totalRows / filterCondition.Limit
                      )}
                      changePage={i => onChangeFilter("page", i)}
                    />
                  </Col>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Container>
      <FormImage ref={refImage} />
      <FormDetail ref={refHistory} />
    </div>
  )
}

GiuChoIndex.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(GiuChoIndex))
