import { post } from "../helpers/api_helper";
export const AgencyService = {
    Categories : {
        getLoaiCH: async (payload) => { //MaDA
            return await post("/admin/san-pham/LoaiCanHo",payload)
        },
        addLoaiCH: async (payload) => { //MaDA
            return await post("/admin/san-pham/add-loai-can-ho",payload)
        },
        removeLoaiCH: async (payload) => { //MaDA
            return await post("/admin/san-pham/xoa-loai-can-ho",payload)
        },

        addLoaiBDS: async (payload) => { 
            return await post("/admin/san-pham/add-loai-bds",payload)
        },
        removeLoaiBDS: async (payload) => { 
            return await post("/admin/san-pham/xoa-loai-bds",payload)
        },
        addTang: async (payload) => { 
            return await post("/admin/san-pham/add-tang",payload)
        },
        removeTang: async (payload) => { 
            return await post("/admin/san-pham/xoa-tang",payload)
        },
        addViTri: async (payload) => { 
            return await post("/admin/san-pham/add-vi-tri",payload)
        },
        removeViTri: async (payload) => { 
            return await post("/admin/san-pham/xoa-vi-tri",payload)
        },
    },
    ForSale: {
        filter: async (payload) => {
            return await post("/admin/CanBan/List_MGLCanBan", payload)
        },
        createCanBan: async (payload) => {
            return await post("/admin/CanBan/Add", payload)
        },
        removeCanBan: async (payload) => {
            return await post("/admin/CanBan/Delete", payload)
        },
        getByID: async (payload) => { // MaSP
            return await post("/admin/SanPham/ChiTiet", payload)
        },
        create: async (payload) =>{
            return await post("/admin/SanPham/ChungCu/Add", payload)
        },
        getLoaiBDS: async () => { 
            return await post("/admin/CanBan/LoaiBDS")
        },
        getLoaiBDSByMaDA: async (payload) => { //MaDA
            return await post("/admin/san-pham/LoaiBDS",payload)
        },
       
        getView: async (payload) => { //MaKhu
            return await post("/admin/san-pham/View",payload)
        },
        addView: async (payload) => { //MaKhu
            return await post("/admin/san-pham/add-view",payload)
        },
        removeView: async (payload) => { //MaKhu
            return await post("/admin/san-pham/xoa-view",payload)
        },
        getPhongNgu: async (payload) => { //MaDA
            return await post("/admin/san-pham/PhongNgu",payload)
        },
        addPhongNgu: async (payload) => { 
            return await post("/admin/san-pham/add-phong-ngu",payload)
        },
        removePhongNgu: async (payload) => { 
            return await post("/admin/san-pham/xoa-phong-ngu",payload)
        },
        getTang: async (payload) => { //MaKhu
            return await post("/admin/san-pham/Tang",payload)
        },
        getViTri: async (payload) => { //MaKhu
            return await post("/admin/san-pham/ViTri",payload)
        },
        getPhuongHuong: async () => {
            return await post("/admin/CanBan/PhuongHuong")
        },
        getKhoangGia: async (payload) => {
            return await post("/admin/CanBan/KhoangGia", payload)
        },
        getDienTich: async (payload) => {
            return await post("/admin/CanBan/KhoangDT", payload)
        },
        getTrangThai: async () => {
            return await post("/admin/CanBan/TrangThai")
        },

    },
    SoDo :{
        filter: async (payload) => {
            return await post("/admin/san-pham/list-coord", payload)
        },
        create: async (payload) => {
            return await post("/admin/san-pham/add-coord", payload)
        },
        addFloor: async (payload) => {
            return await post("/admin/san-pham/add-floor-coord", payload)
        },
        getProductByFloor: async (payload) => {
            return await post("/admin/san-pham/chung-cu-byfloor", payload)
        },
    }
}
