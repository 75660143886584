
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"

export const GET_CUSTOMER = "GET_CUSTOMER"
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS"

export const ADD_EDIT_CUSTOMER = "ADD_EDIT_CUSTOMER"

export const GET_CATEGORIES_CUSTOMER = "GET_CATEGORIES_CUSTOMER"
export const GET_CATEGORIES_CUSTOMER_SUCCESS = "GET_CATEGORIES_CUSTOMER_SUCCESS"

export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"

export const GET_GHICHU_CUSTOMER = "GET_GHICHU_CUSTOMER"
export const GET_GHICHU_CUSTOMER_SUCCESS = "GET_GHICHU_CUSTOMER_SUCCESS"

export const ADD_GHICHU_CUSTOMER = "ADD_GHICHU_CUSTOMER"
export const ADD_GHICHU_CUSTOMER_SUCCESS = "ADD_GHICHU_CUSTOMER_SUCCESS"
// export const ADD_CUSTOMER = "ADD_CUSTOMER"