import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
// import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"


//Calendar
// import calendar from "./calendar/reducer"


//contacts
// import contacts from "./contacts/reducer"

//mails
// import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";


//Staff
import Staff from "./staff/reducer";
import Permission from './permission/reducer'
import Customer from "./customer/reducer"
import Agency from './agency/reducer'
import Common from "./common/reducer"
import SanPham from './sanpham/reducer'
import HopDong from './hopdong/reducer'
import TinTuc from './tintuc/reducers'
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  // ForgetPassword,
  Profile,
  // calendar,
  // chat,
  // mails,
  // projects,
  // contacts,
  Dashboard,
  Staff,
  Permission,
  Customer,
  Agency,
  Common,
  SanPham,
  HopDong,
  TinTuc
})
//
export default rootReducer
