import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Dashboard from "../pages/Dashboard/index"
//  // Inner Authentication
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

//Pages
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Staff
import StaffIndex from "../pages/Staff/StaffIndex"

// //Permission
import PerIndex from "../pages/Permision/PerIndex"
import SetupPermission from "../pages/Permision/SetupPermission"
import PhanQuyenCongTy from "pages/PhanQuyenCongTy/PhanQuyenCongTy"
// //Customer
import CustomerIndex from "../pages/Customer/CustomerIndex"
// // for sale
import ForSaleIndex from "../pages/Agency/ForSale/ForSaleIndex"
import Form from "../pages/Agency/ForSale/Form"
// //sản phẩm
import ChungCuIndex from "../pages/SanPham/ChungCu/ChungCuIndex"
import DatNenIndex from "../pages/SanPham/DatNen/DatNenIndex"
import FormGiuCho from "../pages/SanPham/FormGiuCho"
import DetailSanPham from "../pages/SanPham/DetailSanPham"
// // hợp đồng
import GiuChoIndex from "../pages/HopDong/GiuCho/GiuChoIndex"
import DatCocIndex from "../pages/HopDong/DatCoc/DatCocIndex"
import BangGiaTrucTuyen from "../pages/HopDong/BangGiaTrucTuyen"
import AddGiuChoFromBangGia from "../pages/HopDong/AddGiuChoFromBangGia"
import Contract from "pages/HopDong/HDMB/Contract"
import Contract2 from "pages/HopDong/HDMB/Contract2"
import ThanhLy from "pages/HopDong/ThanhLy/ThanhLy"
import ChuyenNhuong from "pages/HopDong/ChuyenNhuong/ChuyenNhuong"
// debt
import Debt from "pages/Debt/Debt"
import DebtTheoDot from "pages/Debt/Debt_TheoDot"

// thu chi

//projects
import Project from "pages/Projects/Project"
import SoDoPhanLo from "pages/HopDong/SoDoPhanLo"

//projects
import QuyBanGiao from "pages/BanGiao/QuyBanGiao/QuyBanGiao"
import BBBanGiao from "pages/BanGiao/BienBanBanGiao/BBBanGiao"
import SoDo from "pages/BanGiao/SoDo/SoDo"
import CagegoryBangGia from "pages/Projects/BangGia/Cagegory_BangGia"
import BangGia from "pages/Projects/BangGia/BangGia"

// //tin tức
import TinTucIndex from "../pages/TinTuc/TinTucIndex"
import FormTinTuc from "../pages/TinTuc/FormTinTuc"
import MoiGoiLe from "pages/DanhMuc/MoiGoiLe"
import NhuCauMuaThue from "pages/SanPham/NhuCauMuaThue"
import GopVon from "pages/HopDong/GopVon"
import HopDongMoiGoi from "pages/HopDong/HopDongMoiGoi"
import DanhSachLockCan from "pages/HopDong/DanhSachLockCan"
import XemTongQuan from "pages/SanPham/XemTongQuan"
import PhieuThu from "pages/KeToan/PhieuThu"
import PhieuChi from "pages/KeToan/PhieuChi"
import ChiTietSoQuy from "pages/KeToan/ChiTietSoQuy"
import LinkPublickBDS from "pages/Agency/ForSale/LinkPublickBDS"
import InfoUser from "pages/InfoUser"
import HistoryNotification from "pages/LichSuNotification"
import ViewDashboard2 from "pages/Dashboard/DashboardCopy/ViewDashboard2"

const userRoutes = [
  // { path: "/home", component: Dashboard }, sửa trang chủ
  { path: "/home", component: ViewDashboard2 },
  { path: "/staff", component: StaffIndex },
  { path: "/lich-su-notification", component: HistoryNotification },

  { path: "/permission", component: PerIndex },
  { path: "/phan-quyen-cong-ty", component: PhanQuyenCongTy },
  { path: "/permission/:id", component: SetupPermission },
  { path: "/customer", component: CustomerIndex },
  { path: "/profile", component: UserProfile },
  { path: "/forsale", component: ForSaleIndex },

  { path: "/chi-tiet-san-pham/:param", component: LinkPublickBDS },
  { path: "/forsale/add", component: Form },

  //sản phẩm
  { path: "/chungcu", component: ChungCuIndex },
  { path: "/thaptang", component: DatNenIndex },
  { path: "/thaptang/them-giucho", component: FormGiuCho },
  { path: "/sanpham-chitiet", component: DetailSanPham },

  //hợp đồng
  { path: "/booking", component: GiuChoIndex },
  { path: "/deposit", component: DatCocIndex },
  { path: "/banggiatructuyen", component: BangGiaTrucTuyen },
  { path: "/gop-von", component: GopVon },
  { path: "/hop-dong-moi-gioi", component: HopDongMoiGoi },
  { path: "/danh-sach-lock-can", component: DanhSachLockCan },
  { path: "/nhu-cau-mua-thue", component: NhuCauMuaThue },
  { path: "/xem-tong-quan", component: XemTongQuan },
  {
    path: "/banggiatructuyen/addgiucho/:MaSP",
    component: AddGiuChoFromBangGia,
  },
  { path: "/contract", component: Contract2 },
  { path: "/liquidation", component: ThanhLy },
  { path: "/transfer", component: ChuyenNhuong },

  //debt
  { path: "/debit/list", component: Debt },
  { path: "/debit/detail", component: DebtTheoDot },

  //tin tức
  { path: "/news", component: TinTucIndex },
  { path: "/them-sua-tin-tuc", component: FormTinTuc },

  //Ke toan
  { path: "/receipts", component: PhieuThu },
  { path: "/payment", component: PhieuChi },
  { path: "/so-quy", component: ChiTietSoQuy },

  //projects
  { path: "/projects", component: Project },
  { path: "/category-price-list", component: CagegoryBangGia },
  { path: "/price-list", component: BangGia },
  { path: "/so-do-phan-lo/:MaDA", component: SoDoPhanLo },
  { path: "/so-do-phan-lo", component: SoDoPhanLo },

  //banf giao
  { path: "/handover-fund", component: QuyBanGiao },
  { path: "/handover", component: BBBanGiao },
  { path: "/so-do", component: SoDo },

  //danh muc
  //banf giao
  { path: "/moi-gioi-le", component: MoiGoiLe },

  { path: "/info-user", component: InfoUser },

  { path: "/", component: () => <Redirect to="/home" /> },
  { component: Pages404 },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/chi-tiet-san-pham/:param", component: LinkPublickBDS },

  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  // { path: "/pages-login", component: Login1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/so-do-phan-lo-noauth/:MaDA", component: SoDoPhanLo },
  { path: "/so-do-phan-lo-noauth", component: SoDoPhanLo },
]

export { userRoutes, authRoutes }
