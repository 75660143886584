import {call,put,takeEvery,takeLatest} from 'redux-saga/effects';
import * as types from './actionTypes'
import { getChungCuAPI, getDatNenAPI,addKhachHangAPI,addBookingAPI } from "./services"

function* getChungCu(action){
    try{
        const response =yield call(getChungCuAPI,action.payload);
        if(response.status === 2000){
            yield put({type: types.GET_CHUNGCU_SUCCESS,payload: response.data});
        }
    }
    catch{

    }
}
function* getDatNen(action) {
  try {
    const response = yield call(getDatNenAPI, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.GET_DATNEN_SUCCESS, payload: response.data })
    }
  } catch {}
}
function* addKhachHang(action) {
  try {
    // debugger
    let makh = 0;
    const response = yield call(addKhachHangAPI, action.payload.dataKH);
    makh = response.data;
    if (response.status === 2000) {
       let obj = action.payload.dataBoking;
       obj.MaKH = makh
       const response = yield call(addBookingAPI,obj)
       if(response.status === 2000){
         action.payload.type === 1
           ? action.payload.history.push("/chungcu")
           : action.payload.type === 3
           ? action.payload.history.push("/banggiatructuyen")
           : action.payload.history.push("/thaptang")
          
       }
    }
  } catch {}
}
export default function*  sanPhamSaga(){
    yield takeEvery(types.GET_CHUNGCU,getChungCu);
    yield takeEvery(types.GET_DATNEN,getDatNen);
    yield takeEvery(types.ADD_KHACHHANG,addKhachHang);
}