import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react"
import { Dialog } from "primereact/dialog"
import {
  LoadingOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons"
// import { Button } from "antd"
import _ from "lodash"
import { BEE_InputText, BEE_XLAYOUT_FORM } from "pages/Forms/BEE_Form"
import { Button } from "primereact/button"
import { ProjectService } from "services/Project"
import { Flex, Upload } from "antd"
import { API_UPLOAD_URL, UploadService } from "services/UploadService"
import UploadComponent from "components/UploadComponent"

function Project_Detail(props, ref) {
  const { afterSubmit } = props
  const refFunctionMode = useRef(null)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const defaultdata = {
    tenDA: "",
    tenVietTat: "",
    diaChi: "",
    maTT: 1,
  }
  const [data, setData] = useState(defaultdata)
  useImperativeHandle(ref, () => ({
    create: async () => {
      let _data = _.cloneDeep(data)
      refFunctionMode.current = "create"
      setData(_data)
      setShow(true)
    },
    update: async _item => {
      refFunctionMode.current = "update"
      setData({
        ..._item,
        ImageUrl: _item.uri ? [_item.uri] : [],
      })
      setShow(true)
    },
  }))
  const cancel = () => {
    setShow(false)
    setData(defaultdata)
  }
  const applyChange = (p, v) => {
    let _data = _.cloneDeep(data)
    _data[p] = v
    setData(_data)
  }
  const submit = async () => {
    let _data = _.cloneDeep(data)
    let _res = await ProjectService.add(_data)
    if (_res.status === 2000) {
      cancel()
      afterSubmit()
    }
  }
  const affterUpload = async _res => {
    if (_res.length > 0) {
      let _data = _.cloneDeep(data)
      _data.ImageUrl = _res[0]
      let _resadd = await ProjectService.add(_data)
      if (_resadd.status === 2000) {
        _data.ImageUrl = [API_UPLOAD_URL + _res[0]]
        setData(_data);
        afterSubmit();
      }
    }
  }
  const onRemoveFile = async _file => {
    await ProjectService.add({
      ...data,
      ImageUrl: null,
    })
    afterSubmit();
  }
  try {
    return (
      <Dialog
        visible={show}
        header={
          refFunctionMode.current === "create"
            ? "Thêm"
            : refFunctionMode.current === "update"
            ? "Sửa"
            : "Xem"
        }
        style={{ width: "40%", height: "70%", position: "relative" }}
        className={`${
          show ? "fadeinleft" : "fadeoutleft"
        } animation-duration-300 animation-iteration-1`}
        modal
        // maximizable
        onHide={() => cancel()}
      >
        <div
          style={{
            height: "55px",
            background: "#ffffff",
            position: "absolute",
            bottom: 0,
            width: "100%",
            zIndex: 2,
          }}
          className="shadow-5 rounded flex justify-content-center align-items-center"
        >
          <div>
            <Button
              label="Đóng"
              icon="pi pi-x"
              className="p-button-secondary p-button-outlined mr-1"
              onClick={() => cancel()}
            />
            <Button
              label="Lưu"
              icon="pi pi-save"
              className="p-button-success mr-1"
              onClick={() => submit()}
            />
          </div>
        </div>
        <BEE_XLAYOUT_FORM
          header="Thông tin chung"
          className="pb-3"
          fullWidth
          renderBody={() => (
            <React.Fragment>
              <BEE_InputText
                require
                col={6}
                object={data}
                onChangeFunction={applyChange}
                prop="tenDA"
                label="Tên dự án"
              />
              <BEE_InputText
                require
                col={6}
                object={data}
                onChangeFunction={applyChange}
                prop="tenVietTat"
                label="Tên viết tắt"
              />
              <BEE_InputText
                require
                col={12}
                object={data}
                onChangeFunction={applyChange}
                prop="diaChi"
                label="Địa chỉ"
              />
            </React.Fragment>
          )}
        />
        <Flex style={{ padding: "0px 20px" }}>
          <UploadComponent
            files={data.ImageUrl}
            affterUpload={affterUpload}
            onRemove={onRemoveFile}
          />
        </Flex>
      </Dialog>
    )
  } catch (error) {}
}
Project_Detail = forwardRef(Project_Detail)
export default Project_Detail
