import React, { useRef, useState, useEffect } from "react"
import { MultiSelect } from "primereact/multiselect"
import { OverlayPanel } from "primereact/overlaypanel"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import _ from "lodash"
import { ProjectService } from "services/Project"
import { AgencyService } from "services/Agency"
import { Dropdown } from "primereact/dropdown"
import { Col, Divider, Form, Input, Row, Select, Space } from "antd"
import ChilrendForsaleKV from "./ChilrendForsaleKV"
import SelectCustom from "components/Forms/SelectCustom"
import FilterDate from "components/FilterDate"
function ForSale_Filter(props, ref) {
  const {
    filterCondition,
    afterChangeFilter,
    nhuCau,
    phuongHuong,
    loaiDuong,
    phapLy,
    trangThai,
    staffs,
    khoangGiaThue,
    maNhuCau,
  } = props
  const refFilterPanel = useRef(null)
  const [projects, setProjects] = useState([])
  const [priceRange, setPriceRange] = useState([])
  const [acreage, setAcreage] = useState([])
  const [types, setTypes] = useState([])
  const [status, setStatus] = useState([])

  const applyChangeFilter = (prop, val) => {
    let _filterCondition = _.cloneDeep(filterCondition)
    if (prop) {
      switch (prop) {
        default:
          _filterCondition[prop] = val
          break
      }
      afterChangeFilter(_filterCondition)
    }
  }
  useEffect(() => {
    ProjectService.filter({}).then(res => setProjects(res?.data))
    AgencyService.ForSale.getDienTich().then(res => setAcreage(res?.data))
    AgencyService.ForSale.getKhoangGia().then(res => setPriceRange(res?.data))
    AgencyService.ForSale.getLoaiBDS().then(res => setTypes(res?.data))
    AgencyService.ForSale.getTrangThai().then(res => setStatus(res?.data))
  }, [])
  const clearFilter = () => {
    let preyear = new Date().setFullYear(new Date().getFullYear() - 5)
    let _filterCondition = _.cloneDeep(filterCondition)
    _filterCondition.MaNhuCau = 0
    _filterCondition.inputSearch = ""
    _filterCondition.DuAn = []
    _filterCondition.TuNgay = new Date(preyear)
    _filterCondition.DenNgay = new Date()
    _filterCondition.MaTT = 0

    _filterCondition.Offset = 1
    _filterCondition.Limit = 20
    afterChangeFilter(_filterCondition)
  }
  const handleChange = value => {
    console.log(`selected ${value}`)
  }
  const onChangeDate = v => {
    let _filter = _.cloneDeep(filterCondition)
    _filter.TuNgay = v.TuNgay
    _filter.DenNgay = v.DenNgay
    afterChangeFilter(_filter)
  }
  return (
    <React.Fragment>
      <div
        className="forSale-fill"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <Form layout="inline">
          <Space size="">
            <span className="p-input-icon-left">
              {/* <i className="bx bx-search-alt" /> */}
              <InputText
                style={{
                  border: "none",
                  backgroundColor: "#eff2f7",
                  marginRight: "10px",
                }}
                value={filterCondition?.inputSearch}
                onInput={e => applyChangeFilter("inputSearch", e.target.value)}
                placeholder="Tìm kiếm "
              />
            </span>
            <Divider type="vertical" />
            <Select
              allowClear
              value={filterCondition.MaNhuCau}
              onChange={value => applyChangeFilter("MaNhuCau", value)}
              style={{ width: 100, color: "red" }}
              options={nhuCau}
              fieldNames={{ label: "TenLoai", value: "ID" }}
              placeholder="Nhu cầu"
            />
            <Divider type="vertical" />
            <Select
              allowClear
              value={filterCondition.MaLBDS}
              onChange={value => applyChangeFilter("MaLBDS", value)}
              options={types}
              style={{ width: 120 }}
              fieldNames={{ label: "tenLBDS", value: "maLBDS" }}
              placeholder="Căn hộ chung cư"
            />
            {/* <ChilrendForsaleKV /> */}
            <Divider type="vertical" />

            <Select
              allowClear
              style={{ width: 95 }}
              value={filterCondition.KhoangGia}
              options={maNhuCau === 2 ? khoangGiaThue : priceRange}
              onChange={value => applyChangeFilter("KhoangGia", value)}
              fieldNames={
                maNhuCau === 2
                  ? { label: "TenKhoangGia", value: "ID" }
                  : { label: "tenKhoangGia", value: "id" }
              }
              placeholder="Giá"
            />
            <Divider type="vertical" />
            <Select
              allowClear
              style={{ width: 100 }}
              value={filterCondition.KhoangDT}
              options={acreage}
              onChange={value => applyChangeFilter("KhoangDT", value)}
              fieldNames={{ label: "tenDT", value: "id" }}
              placeholder="Diện tích"
            />
            <Divider type="vertical" />
            <Select
              mode="multiple"
              style={{ maxWidth: 400, minWidth: 150 }}
              value={filterCondition.DuAn}
              options={projects}
              onChange={value => applyChangeFilter("DuAn", value)}
              fieldNames={{ label: "TenDA", value: "MaDA" }}
              placeholder="Dự án"
              maxTagCount={"responsive"}
            />
            <Divider type="vertical" />
          </Space>
        </Form>
        <Button
          icon={<i className="pi pi-filter" />}
          shape="round"
          type="text"
          onClick={e => refFilterPanel.current.toggle(e)}
          aria-label="Filter"
          style={{ marginLeft: "10px" }}
        />
      </div>

      <OverlayPanel
        ref={refFilterPanel}
        className="x-menu"
        style={{ width: "600px" }}
      >
        <Row gutter={[16, 20]} className="filter-bidding">
          {/* Thêm các trường Select của Ant Design */}
          <Col span={12}>
            <SelectCustom
              mode="multiple"
              maxTagCount={"responsive"}
              label="Loại đường:"
              value={filterCondition.maLD}
              prop="maLD"
              applyChange={applyChangeFilter}
              placeholder="Chọn"
              options={
                loaiDuong.map(item => ({
                  label: item.tenLD,
                  value: item.maLD,
                })) || []
              }
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              mode="multiple"
              maxTagCount={"responsive"}
              label="Pháp lý:"
              value={filterCondition.maPL}
              prop="maPL"
              applyChange={applyChangeFilter}
              placeholder="Chọn"
              options={
                phapLy.map(item => ({
                  label: item.tenPL,
                  value: item.maPL,
                })) || []
              }
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              maxTagCount={"responsive"}
              mode="multiple"
              label="Trạng thái:"
              value={filterCondition.maTT}
              prop="maTT"
              applyChange={applyChangeFilter}
              placeholder="Chọn"
              options={
                trangThai.map(item => ({
                  label: item.tenTT,
                  value: item.maTT,
                })) || []
              }
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              mode="multiple"
              maxTagCount={"responsive"}
              label="Hướng cửa"
              value={filterCondition.maHuong}
              prop="maHuong"
              applyChange={applyChangeFilter}
              placeholder="Chọn"
              options={
                phuongHuong.map(item => ({
                  label: item.tenPhuongHuong,
                  value: item.maPhuongHuong,
                })) || []
              }
            />
          </Col>
          <Col span={12}>
            <SelectCustom
              mode="multiple"
              maxTagCount={"responsive"}
              label="Nhân viên môi giới:"
              applyChange={applyChangeFilter}
              prop="maNVKD"
              value={filterCondition?.maNVKD}
              placeholder="Chọn nhân viên MG"
              options={staffs}
              fieldNames={{ label: "hoTen", value: "maNV" }}
            />
          </Col>
          <Col span={24}>
            <FilterDate onChangeDate={onChangeDate} />
          </Col>
          {/* <Col span={12}>
            <label htmlFor="icon">Từ ngày</label>
            <Calendar
              showDate
              showIcon
              label="Từ ngày"
              value={filterCondition.TuNgay}
              onChange={v => applyChangeFilter("TuNgay", v.value)}
            />
          </Col>
          <Col span={12}>
            <label htmlFor="icon">Đến ngày</label>
            <Calendar
              showDate
              showIcon
              value={filterCondition.DenNgay}
              onChange={v => applyChangeFilter("DenNgay", v.value)}
            />
          </Col> */}
        </Row>

        <Button
          icon="pi pi-filter-slash"
          className="mt-4 p-button-raised p-button-danger p-button-text"
          onClick={e => clearFilter()}
          label="Xóa lọc"
          aria-label="Filter"
        />
      </OverlayPanel>
    </React.Fragment>
  )
}
export default ForSale_Filter
