import { Col, Row } from "antd";
import React from "react";
import Chart from "react-apexcharts";

const YeuCau = (prop) => {
  const {
    chartOptionsPLYC,
    chartSeriesPLYC,
    chartOptionsSLYC,
    chartSeriesSLYC,
    chartOptionsSLTTT,
    chartSeriesSLTTT,
  } = prop;
  return (
      <>
        <Col
          style={{
            textAlign: "center",
            fontWeight: "600",
            padding: "20px 0",
            fontSize: "17px",
          }}
          span={6}
        >
          <h3>Phân loại yêu cầu</h3>{" "}
          <Chart
            options={chartOptionsPLYC}
            series={chartSeriesPLYC}
            type="bar"
            height={350} // Chiều cao của biểu đồ
          />
        </Col>
        <Col
          style={{
            textAlign: "center",
            fontWeight: "600",
            padding: "20px 0",
            fontSize: "17px",
          }}
          span={12}
        >
          <h3>Số lượng yêu cầu</h3>

          <Chart
            options={chartOptionsSLYC}
            series={chartSeriesSLYC}
            type="bar"
            height={350} // Chiều cao biểu đồ
          />
        </Col>
        <Col
          style={{
            textAlign: "center",
            fontWeight: "600",
            padding: "20px 0",
            fontSize: "17px",
          }}
          span={6}
        >
          <h3 style={{ paddingBottom: "15px" }}>Số lượng theo trạng thái</h3>
          <Chart
            options={chartOptionsSLTTT}
            series={chartSeriesSLTTT}
            type="pie"
            height={350} // Chiều cao biểu đồ
          />
        </Col>
      </>
  );
};

export default YeuCau;
