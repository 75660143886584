export const GET_GIUCHO = "GET_GIUCHO";
export const GET_GIUCHO_SUCCESS = "GET_GIUCHO_SUCCESS";

export const GET_DATCOC = "GET_DATCOC"
export const GET_DATCOC_SUCCESS = "GET_DATCOC_SUCCESS"

export const GET_HOPDONG_TRANGTHAI = "GET_HOPDONG_TRANGTHAI"
export const GET_HOPDONG_TRANGTHAI_SUCCESS = "GET_HOPDONG_TRANGTHAI_SUCCESS"

export const GET_BANGGIATT = "GET_BANGGIATT"
export const GET_BANGGIATT_SUCCESS = "GET_BANGGIATT_SUCCESS"

export const GET_TANG_BG = "GET_TANG_BG"
export const GET_TANG_BG_SUCCESS = "GET_TANG_BG_SUCCESS"

export const GET_VITRI_BG = "GET_VITRI_BG"
export const GET_VITRI_BG_SUCCESS = "GET_VITRI_BG_SUCCESS"

export const GET_KHU_CHITIET_BG = "GET_KHU_CHITIET_BG"
export const GET_KHU_CHITIET_BG_SUCCESS = "GET_KHU_CHITIET_BG_SUCCESS"