import { del, get, post, put } from "../helpers/api_helper"
export const StaffService = {
  filter: async payload => {
    return await post("/admin/staffs", payload)
  },
  getAction: async payload => {
    // FormID
    return await post("/admin/actions", payload)
  },
  // login : async (payload) => {
  //     return await post("/admin/staffs", payload)
  // },
  login: payload => post("/login", payload),

  getMenuCTDK: () => post("cong-ty-dang-ky/get-menu"),
  getMenu: () => post("cong-ty/get-menu"),
}
