
// House For lease
export const GET_HOUSE_FOR_LEASES = "GET_HOUSE_FOR_LEASES"
export const GET_HOUSE_FOR_LEASES_SUCCESS = "GET_HOUSE_FOR_LEASES_SUCCESS"

// For Sale
export const GET_FORSALE = "GET_FORSALE"
export const GET_FORSALE_SUCCESS = "GET_FORSALE_SUCCESS"

export const DELETE_FORSALE = "DELETE_FORSALE"
export const DELETE_FORSALE_SUCCESS = "DELETE_FORSALE_SUCCESS"

export const GET_CATEGORIES_FORSALE = "GET_CATEGORIES_FORSALE"
export const GET_CATEGORIES_FORSALE_SUCCESS = "GET_CATEGORIES_FORSALE_SUCCESS"

export const GET_STREET = "GET_STREET"
export const GET_STREET_SUCCESS = "GET_STREET_SUCCESS"

export const ADD_EDIT_FORSALE = "ADD_EDIT_FORSALE"
export const ADD_EDIT_FORSALE_SUCCESS = "ADD_EDIT_FORSALE_SUCCESS"

export const EDIT_FORSALE = "EDIT_FORSALE"
export const EDIT_FORSALE_SUCCESS = "EDIT_FORSALE_SUCCESS"

export const SEARCH_CUSTOMERS_FORSALE = "SEARCH_CUSTOMERS_FORSALE"
export const SEARCH_CUSTOMERS_FORSALE_SUCCESS = "SEARCH_CUSTOMERS_FORSALE_SUCCESS"

export const GET_LOAIBDS = "GET_LOAIBDS"
export const GET_LOAIBDS_SUCCESS = "GET_LOAIBDS_SUCCESS"

export const GET_KHOANGDT = "GET_KHOANGDT"
export const GET_KHOANGDT_SUCCESS = "GET_KHOANGDT_SUCCESS"

export const GET_KHOANGGIA = "GET_KHOANGGIA"
export const GET_KHOANGGIA_SUCCESS = "GET_KHOANGGIA_SUCCESS"

export const GET_TRANGTHAI_FORSALE = "GET_TRANGTHAI_FORSALE"
export const GET_TRANGTHAI_FORSALE_SUCCESS = "GET_TRANGTHAI_FORSALE_SUCCESS"

export const GET_DUAN = "GET_DUAN"
export const GET_DUAN_SUCCESS = "GET_DUAN_SUCCESS"

export const GET_IMAGE_FORSALE = "GET_IMAGE_FORSALE"
export const GET_IMAGE_FORSALE_SUCCESS = "GET_IMAGE_FORSALE_SUCCESS"

export const ADD_IMAGE_FORSALE = "ADD_IMAGE_FORSALE"
export const ADD_IMAGE_FORSALE_SUCCESS = "ADD_IMAGE_FORSALE_SUCCESS"


