import { call, put, takeEvery } from "redux-saga/effects"
// Staff Redux States
import * as types from "./actionTypes"
import { toast } from "react-toastify"
import { deleteCustomerSuccess, getGhiChuCustomer } from "./actions"
//Include Both Helper File with needed methods
import {
  getCustomersAPI,
  addEditCustomersAPI,
  getQuyDanhAPI,
  getMucDichAPI,
  getAddressAPI,
  getNhomKH,
  getNgheNghiep,
  getTrangThai,
  deleteCustomerAPI,
  getNguonDen,
  getCustomerAPI,
  getGhiChuCustomerAPI,
  addGhiChuCustomerAPI,
} from "./service"

function* getCustomers(action) {
  try {
    const response = yield call(getCustomersAPI, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.GET_CUSTOMERS_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* getCustomer(action) {
  try {
    const response = yield call(getCustomerAPI, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.GET_CUSTOMER_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* addEditCustomer(action) {
  try {
    const response = yield call(addEditCustomersAPI, action.payload)
    if (response.status === 2000) {
      // yield put({ type: types.GET_CUSTOMERS_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* getCategoriesCustomer() {
  try {
    const response = yield call(getQuyDanhAPI)
    const response1 = yield call(getMucDichAPI)
    const response2 = yield call(getAddressAPI, {
      TenCTDKVT: localStorage.getItem("TenCTDKVT"),
    })
    const response3 = yield call(getNhomKH)
    const response4 = yield call(getNgheNghiep)
    const response5 = yield call(getTrangThai)
    const response6 = yield call(getNguonDen)
    // if (response.status === 2000) {
    yield put({
      type: types.GET_CATEGORIES_CUSTOMER_SUCCESS,
      payload: {
        quyDanh: response.data,
        mucDich: response1.data,
        address: response2.data,
        groupCustomer: response3.data,
        ngheNghiep: response4.data,
        status: response5.data,
        nguonDen:response6.data
      },
    })
    // }
  } catch (error) {}
}
function* deleteCustomer(action) {
  try {
    const response = yield call(deleteCustomerAPI, action.payload)
    if (response.status === 2000) {
      yield put(deleteCustomerSuccess(action.payload))
    }
  } catch (error) {}
}
function* addGhiChuCustomer(action) {
  try {
    const response = yield call(addGhiChuCustomerAPI, action.payload)
    // if (response.status === 2000) {
    //   yield put(getGhiChuCustomer,{MaKH:action.payload.MaKH})
    // }
  } catch (error) {}
}
function* getGhiChuCustomerSaga(action) {
  try {
    const response = yield call(getGhiChuCustomerAPI, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.GET_GHICHU_CUSTOMER_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
export default function* customerSaga() {
  yield takeEvery(types.GET_CUSTOMERS, getCustomers)
  yield takeEvery(types.GET_CUSTOMER, getCustomer)
  yield takeEvery(types.ADD_EDIT_CUSTOMER, addEditCustomer)
  yield takeEvery(types.GET_CATEGORIES_CUSTOMER, getCategoriesCustomer)
  yield takeEvery(types.DELETE_CUSTOMER, deleteCustomer)
  yield takeEvery(types.GET_GHICHU_CUSTOMER, getGhiChuCustomerSaga)
  yield takeEvery(types.ADD_GHICHU_CUSTOMER, addGhiChuCustomer)
}
