import { call, put, takeLatest } from "redux-saga/effects"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { StaffService } from "services/Staff"

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "beehome") {
      const response = yield call(StaffService.login, user)
      if (response.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(response))
        localStorage.setItem("maNV",response.maNV);
        const _resmenuct = yield call(StaffService.getMenuCTDK)
        localStorage.setItem("menu_congty", JSON.stringify(_resmenuct.data))
        const _resmenu = yield call(StaffService.getMenu)
        localStorage.setItem("menu", JSON.stringify(_resmenu.data))
        yield put(loginSuccess(response))
        history.push("/")
      } else {
        yield put(apiError(response.message))
      }
    }
  } catch (error) {
    yield put(apiError("Lỗi không xác định. (Server Error)"))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout)
    //   yield put(logoutUserSuccess(response))
    // }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeLatest(LOGOUT_USER, logoutUser)
}

export default authSaga
