import { del, get, post, put } from "../helpers/api_helper";
export const CommonService = {
    getTinh : async (payload) => {
        return await get("/admin/get-tinh")
    },
    getHuyen : async (payload) => {
        return await post("/admin/get-huyen",payload)
    },
    getXa : async (payload) => {
        return await post("/admin/get-xa",payload)
    },
    getDuong : async (payload) => {
        return await post("/admin/get-duong",payload)
    },
    getLoadDuong : async () => {
        return await get("/admin/CanBan/LoaiDuong")
    },
    getPhapLy : async () => {
        return await get("/admin/CanBan/list-phap-ly")
    },
    getQuyHoach : async () => {
        return await get("/admin/CanBan/QuyHoach")
    },
    getNguonGoc : async () => {
        return await get("/admin/CanBan/NguonGoc")
    },
}
