import * as types from './actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  getGiuChoAPI,
  getTrangThaiHDAPI,
  getDatCocAPI,
  getBangGiaTTAPI,
  getTangBGAPI,
  getVitriBGAPI,
  getKhuChiTietBGAPI,
} from "./services"
function* getGiuCho(action){
    try{
        const res = yield call(getGiuChoAPI,action.payload);
        if(res.status === 2000){
            yield put({type: types.GET_GIUCHO_SUCCESS,payload: res.data});
        }
    }
    catch(err){}
}
function* getDatCoc(action) {
  try {
    const res = yield call(getDatCocAPI, action.payload)
    if (res.status === 2000) {
      yield put({ type: types.GET_DATCOC_SUCCESS, payload: res.data })
    }
  } catch (err) {}
}
function* getHDTrangThai(action) {
  try {
    const res = yield call(getTrangThaiHDAPI,action.payload)
    if (res.status === 2000) {
      yield put({ type: types.GET_HOPDONG_TRANGTHAI_SUCCESS, payload: res.data })
    }
  } catch (err) {}
}
function* getBangGiaTT(action) {
  try {
    const res = yield call(getBangGiaTTAPI, action.payload)
    if (res.status === 2000) {
      yield put({
        type: types.GET_BANGGIATT_SUCCESS,
        payload: res.data,
      })
    }
  } catch (err) {}
}
function* getTangBG(action) {
  try {
    const res = yield call(getTangBGAPI, action.payload)
    if (res.status === 2000) {
      yield put({
        type: types.GET_TANG_BG_SUCCESS,
        payload: res.data,
      })
    }
  } catch (err) {}
}
function* getViTriBG(action) {
  try {
    const res = yield call(getVitriBGAPI, action.payload)
    if (res.status === 2000) {
      yield put({
        type: types.GET_VITRI_BG_SUCCESS,
        payload: res.data,
      })
    }
  } catch (err) {}
}
function* getKhuChiTietBG(action) {
  try {
    const res = yield call(getKhuChiTietBGAPI, action.payload)
    if (res.status === 2000) {
      yield put({
        type: types.GET_KHU_CHITIET_BG_SUCCESS,
        payload: res.data,
      })
    }
  } catch (err) {}
}
export default function* hopDongSaga(){
   yield takeEvery(types.GET_GIUCHO,getGiuCho);
   yield takeEvery(types.GET_DATCOC,getDatCoc);
   yield takeEvery(types.GET_HOPDONG_TRANGTHAI,getHDTrangThai);
   yield takeEvery(types.GET_BANGGIATT,getBangGiaTT);
   yield takeEvery(types.GET_TANG_BG,getTangBG);
   yield takeEvery(types.GET_VITRI_BG,getViTriBG);
   yield takeEvery(types.GET_KHU_CHITIET_BG,getKhuChiTietBG);
}
