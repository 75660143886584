import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
const DeleteModal = props => {
  const {data, isOpen, toggle } = props
  const accessDelete = () => {
    data.delete();
    toggle()
  }
  return (
    <Modal
      isOpen={data.isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      returnFocusAfterClose={true}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Thông báo!</ModalHeader>
        <ModalBody>
          <p>Bạn có muốn xóa không?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={accessDelete}>
            Đồng ý
          </Button>
          <Button color="secondary" onClick={toggle}>
            Hủy
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default React.memo(DeleteModal)
