import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react"
import PropTypes from "prop-types"
import { Button, Row, Col } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Drawer } from "antd"
import SelectCustom from "components/Forms/SelectCustom"
import InputCustom from "components/Forms/InputCustom"
import DatePickerCustom from "components/Forms/DatePickerCustom"
import { addEditCustomer, getCustomers } from "../../store/actions"

const FormModal = ({ isEdit, isOpen, detail }, ref) => {
  const {
    status,
    quyDanh,
    mucDich,
    address,
    groupCustomer,
    ngheNghiep,
    nguonDen,
  } = useSelector(state => ({
    status: state.Customer.status,
    quyDanh: state.Customer.quyDanh,
    mucDich: state.Customer.mucDich,
    address: state.Customer.address,
    groupCustomer: state.Customer.groupCustomer,
    ngheNghiep: state.Customer.ngheNghiep,
    nguonDen: state.Customer.nguonDen,
  }))

  const [open, setOpen] = useState(false)

  const [maTinh, setMaTinh] = useState(0)
  const [maHuyen, setMaHuyen] = useState(0)
  const [maTinh2, setMaTinh2] = useState(0)
  const [maHuyen2, setMaHuyen2] = useState(0)

  // useEffect(() => {
  //   setMaTinh(detail?.maTinh || 0)
  //   setMaHuyen(detail?.maHuyen || 0)
  //   setMaTinh2(detail?.maTinh2 || 0)
  //   setMaHuyen2(detail?.maHuyen2 || 0)
  // }, [detail])

  const dispatch = useDispatch()

  const handleValidOrderSubmit = values => {
    const insertAndUpdate = {
      MaKH: isEdit ? detail.maKH : null,
      TenKH: values.TenKH,
      MaSoKH: values.MaSoKH,
      NgaySinh: values.NgaySinh ? values.NgaySinh : new Date(),
      SoCMND: values.SoCMND,
      NgayCap: values.NgayCap ? values.NgayCap : new Date(),
      NoiCap: values.NoiCap,
      DiDong: values.DiDong,
      DiDong2: values.DiDong2,
      Email: values.Email,
      Email2: values.Email2,
      PurposeID: Number(values.PurposeID),
      ThuongTru: values.ThuongTru,
      DiaChi: values.DiaChi,
      MaXa: Number(values.MaXa) === 0 ? null : Number(values.MaXa),
      MaHuyen: Number(values.MaHuyen) === 0 ? null : Number(values.MaHuyen),
      MaTinh: Number(values.MaTinh) === 0 ? null : Number(values.MaTinh),
      MaXa2: Number(values.MaXa2) === 0 ? null : Number(values.MaXa2),
      MaHuyen2: Number(values.MaHuyen2) === 0 ? null : Number(values.MaHuyen2),
      MaTinh2: Number(values.MaTinh2) === 0 ? null : Number(values.MaTinh2),
      MaNKH: Number(values.MaNKH),
      HowToKnowID: Number(values.HowToKnowID),
      MaNN: Number(values.MaNN),
      MaTT: Number(values.MaTT),
      MaQD: Number(values.MaQD),
    }

    dispatch(addEditCustomer(insertAndUpdate))
    dispatch(getCustomers({ inputSearch: "", Offset: 1, Limit: 10 }))
    onClickClose()
  }

  const getHuyen = () => {
    return address?.find(item => item.maTinh === maTinh)?.listHuyen || []
  }

  const getXa = () => {
    return getHuyen().find(item => item.maHuyen === maHuyen)?.listXa || []
  }

  const getHuyen2 = () => {
    return address?.find(item => item.maTinh === maTinh2)?.listHuyen || []
  }

  const getXa2 = () => {
    return getHuyen2().find(item => item.maHuyen === maHuyen2)?.listXa || []
  }

  // const applyChange = (prop, value) => {
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [prop]: value,
  //   }))
  // }

  const [formData, setFormData] = useState({
    // TenKH: detail?.tenKH || "",
    // MaSoKH: detail?.maSoKH || "",
    // NgaySinh: detail?.ngaySinh || "",
    // SoCMND: detail?.soCMND || "",
    // NgayCap: detail?.ngayCap || "",
    // NoiCap: detail?.noiCap || "",
    // DiDong: detail?.diDong || "",
    // DiDong2: detail?.diDong2 || "",
    // Email: detail?.email || "",
    // Email2: detail?.email2 || "",
    // ThuongTru: detail?.thuongTru || "",
    // DiaChi: detail?.diaChi || "",
    // MaNKH: detail?.maNKH || "",
    // MaNN: detail?.maNN || "",
    // PurposeID: detail?.purposeID || "",
    // HowToKnowID: detail?.howToKnowID || "",
    // MaTinh: detail?.maTinh || "",
    // MaHuyen: detail?.maHuyen || "",
    // MaXa: detail?.maXa || "",
    // MaTinh2: detail?.maTinh2 || "",
    // MaHuyen2: detail?.maHuyen2 || "",
    // MaXa2: detail?.maXa2 || "",
    // MaTT: detail?.maTT || "",
    // MaQD: detail?.maQD || "",
  })
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(formData)
    switch (p) {
      default:
        _data[p] = v
        break
    }
    setFormData(_data)
  }

  const onClickClose = () => {
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true)
    },
    update: async data => {
      setOpen(true)
    },
  }))


  return (
    <Drawer
      title={isEdit ? "Sửa khách hàng" : "Thêm khách hàng"}
      open={open}
      onClose={onClickClose}
      width={920}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClickClose} style={{ marginRight: 8 }}>
            Close
          </Button>
          <Button
            onClick={() => handleValidOrderSubmit(formData)}
            type="primary"
          >
            Save changes
          </Button>
        </div>
      }
    >
      {/* <Row gutter={[20, 16]}> */}
      <Row>
        <Col span={8}>
          <SelectCustom
            label="Quý danh"
            value={formData.MaQD}
            prop="maQD"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              quyDanh?.map(item => ({ label: item.tenQD, value: item.maQD })) ||
              []
            }
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Họ tên"
            value={formData.TenKH}
            prop="tenKH"
            applyChange={applyChange}
            placeholder="Nhập họ tên"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Mã số KH"
            value={formData.MaSoKH}
            prop="maSoKH"
            applyChange={applyChange}
            placeholder="Nhập mã số"
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <DatePickerCustom
            label="Ngày sinh"
            applyChange={applyChange}
            prop="NgaySinh"
            value={formData?.NgaySinh}
            placeholder="Nhập ngày sinh"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Số CCCD"
            value={formData.SoCMND}
            prop="soCMND"
            applyChange={applyChange}
            placeholder="Nhập số CCCD"
          />
        </Col>
        <Col span={8}>
          <DatePickerCustom
            label="Ngày cấp"
            applyChange={applyChange}
            prop="ngayCap"
            value={formData.NgayCap}
            placeholder="Nhập ngày cấp"
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <InputCustom
            label="Nơi cấp"
            value={formData.NoiCap}
            prop="noiCap"
            applyChange={applyChange}
            placeholder="Nhập nơi cấp"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Di động"
            value={formData.DiDong}
            prop="diDong"
            applyChange={applyChange}
            placeholder="Nhập số đi động"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Di động 2"
            value={formData.DiDong2}
            prop="diDong2"
            applyChange={applyChange}
            placeholder="Nhập số đi động 2"
          />
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <InputCustom
            label="Email"
            value={formData.Email}
            prop="email"
            applyChange={applyChange}
            placeholder="Nhập email"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Email 2"
            value={formData.Email2}
            prop="email2"
            applyChange={applyChange}
            placeholder="Nhập email 2"
          />
        </Col>
        <Col span={8}>
          <InputCustom
            label="Địa chỉ"
            value={formData.DiaChi}
            prop="diaChi"
            applyChange={applyChange}
            placeholder="Nhập địa chỉ"
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <InputCustom
            label="Thường trú"
            value={formData.ThuongTru}
            prop="thuongTru"
            applyChange={applyChange}
            placeholder="Nhập địa chỉ thường trú"
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Nhóm KH"
            value={formData.MaNKH}
            prop="maNKH"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              groupCustomer?.map(item => ({
                label: item.tenNKH,
                value: item.maNKH,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Nghề nghiệp"
            value={formData.MaNN}
            prop="maNN"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              ngheNghiep?.map(item => ({
                label: item.tenNN,
                value: item.maNN,
              })) || []
            }
          />
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <SelectCustom
            label="Mục đích"
            value={formData.PurposeID}
            prop="purposeID"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              mucDich?.map(item => ({
                label: item.tenMD,
                value: item.purposeID,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Nguồn đến"
            value={formData.HowToKnowID}
            prop="howToKnowID"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              nguonDen?.map(item => ({
                label: item.name,
                value: item.id,
              })) || []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Tỉnh/Thành phố"
            value={formData.MaTinh}
            prop="maTinh"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              address?.map(item => ({
                label: item.tenTinh,
                value: item.maTinh,
              })) || []
            }
            onChange={e => setMaTinh(Number(e.target.value))}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <SelectCustom
            label="Quận/Huyện"
            value={formData.MaHuyen}
            prop="maHuyen"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getHuyen().map(item => ({
                label: item.tenHuyen,
                value: item.maHuyen,
              })) || []
            }
            onChange={e => setMaHuyen(Number(e.target.value))}
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Xã/Phường"
            value={formData.MaXa}
            prop="maXa"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getXa().map(item => ({ label: item.tenXa, value: item.maXa })) ||
              []
            }
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Tỉnh/Thành phố (HK)"
            value={formData.MaTinh2}
            prop="maTinh2"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              address?.map(item => ({
                label: item.tenTinh,
                value: item.maTinh,
              })) || []
            }
            onChange={e => setMaTinh2(Number(e.target.value))}
          />
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <SelectCustom
            label="Quận/Huyện (HK)"
            value={formData.MaHuyen2}
            prop="maHuyen2"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getHuyen2().map(item => ({
                label: item.tenHuyen,
                value: item.maHuyen,
              })) || []
            }
            onChange={e => setMaHuyen2(Number(e.target.value))}
          />
        </Col>
        <Col span={8}>
          <SelectCustom
            label="Xã/Phường (HK)"
            value={formData.MaXa2}
            prop="maXa2"
            applyChange={applyChange}
            placeholder="Chọn"
            options={
              getXa2().map(item => ({ label: item.tenXa, value: item.maXa })) ||
              []
            }
          />
        </Col>
      </Row>

      {/* </Row> */}
    </Drawer>
  )
}

// FormModal.propTypes = {
//   toggle: PropTypes.func.isRequired,
//   isOpen: PropTypes.bool.isRequired,
//   isEdit: PropTypes.bool,
//   detail: PropTypes.object,
// }

export default forwardRef(FormModal)
