import { Flex, Select } from "antd"
import React from "react"
import "./index.scss"

const SelectCustom = ({
  label,
  applyChange,
  value,
  prop,
  mode,
  placeholder,
  options,
  fieldNames,
  maxTagCount,
}) => {
  return (
    <Flex justify="flex-start" align="center" className="select-custom">
      <p style={{ fontWeight: "600", width: "35%" }}>{label}</p>
      <Select
        maxTagCount={maxTagCount ? maxTagCount : null}
        showSearch
        mode={mode ?? "single"}
        placeholder={placeholder}
        value={value}
        onChange={v => applyChange(prop, v)}
        style={{
          width: "65%",
        }}
        options={options ?? []}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        fieldNames={
          fieldNames ? fieldNames : { label: "label", value: "value" }
        }
      />
    </Flex>
  )
}

export default SelectCustom
