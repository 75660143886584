import * as types from "./actionTypes"

export const getStaffs = (payload) => ({
  type: types.GET_STAFFS,
  payload
});
export const getStaff = payload => ({
  type: types.GET_STAFF,
  payload,
})
export const getStaffSaga = payload => ({
  type: types.GET_STAFF_SAGA,
  payload,
})
export const getStaffKDSaga = payload => ({
  type: types.GET_STAFF_KD_SAGA,
  payload,
})
export const deleteStaff = payload => ({
  type: types.DELETE_STAFF,
  payload,
})

export const editStaff = payload => ({
  type: types.EDIT_STAFF,
  payload,
})
export const addStaff = payload => ({
  type: types.ADD_STAFF,
  payload,
})
export const getCategories = payload => ({
  type: types.GET_CATEGORIES,
  payload,
})
export const getMenu = () => ({
  type: types.GET_MENU
})