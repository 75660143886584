import { post } from "helpers/api_helper"

export const HeThongService = {
  ThongBao: {
    get: async payload => {
      return await post("hethong/noti/get", payload).then(res => res)
    },
    getHistoryNotification: async payload => {
      return await post("hethong/noti/get-filter", payload).then(res => res)
    },
  },
}
