import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import { Button, Col, DatePicker, Popover, Row, Select } from "antd"
import _ from "lodash"
import dayjs from "dayjs"
import "dayjs/locale/vi"
//   import { BanHangService } from "services/BanHangService";
//   import { NhanVienService } from "services/NhanVienService";
import ActiveRoute from "components/ActiveRoute"
//   import { OtherService } from "services/OtherService";
//   import { SoQuyService } from "services/SoQuyService";
import FilterDate from "components/FilterDate"
import ChiNhanhSelect from "components/ChiNhanhSelect"

const FilterModal = ({ ...props }) => {
  const { hinhThuc, loai, filterCondition, onChangeFilter } = props
  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "ListMaCT":
      case "LoaiThu":
      case "HinhThuc":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,"
        break
      case "TuNgay":
        _filter[p] = new Date(v)
        break
      case "DenNgay":
        _filter[p] = new Date(v)
        break
      case "MaCT":
        _filter[p] = v ? v : 0
        break
    }
    onChangeFilter(_filter)
  }

  const onChangeDate = v => {
    let _filter = _.cloneDeep(filterCondition)
    _filter.TuNgay = v.TuNgay
    _filter.DenNgay = v.DenNgay
    onChangeFilter(_filter)
  }
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      {/* <ChiNhanhSelect
          FormID={120}
          onChange={(v) => applyChangeFilter("MaCT", v)}
        /> */}

    </div>
  )
}
export default FilterModal
