import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"
import { Button, Col, DatePicker, Popover, Row, Select } from "antd"
import _ from "lodash"
import dayjs from "dayjs"

import "dayjs/locale/vi"
//   import { BanHangService } from "services/BanHangService";
//   import { NhanVienService } from "services/NhanVienService";
import ActiveRoute from "components/ActiveRoute"
import FilterDate from "components/FilterDate"
import { PermissionService } from "services/PermissionService"
import { CongTyService } from "services/CongTyService"
import ChiNhanhSelect from "components/ChiNhanhSelect"

const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props
  const [quay, setQuay] = useState([])
  const [nhanvien, setNhanvien] = useState([])
  // const [congty, setCongty] = useState([]);
  // const [per, setPer] = useState(0);
  const refPer = useRef(null)

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    switch (p) {
      case "DuAn":
      case "QuayBan":
      case "NhanVien":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,"
        break
      case "TrangThai":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : null
        break
      case "TuNgay":
        _filter[p] = new Date(v)
        break
      case "DenNgay":
        _filter[p] = new Date(v)
        break
    }

    onChangeFilter(_filter)
  }
  useEffect(() => {
    return
    BanHangService.getQuayBan().then(res => setQuay(res.data ?? []))
    NhanVienService.getNhanVien().then(res => setNhanvien(res.data ?? []))
    // PermissionService.checkQuyen({ FormID: 272 }).then((res) => {
    //   refPer.current = res.data;
    //   if (res.data == 1) {
    //     CongTyService.getCongTy().then((_ct) => {
    //       setCongty(_ct.data);
    //     });
    //   }
    // });
  }, [])
  const onChangeDate = v => {
    let _filter = _.cloneDeep(filterCondition)
    _filter.TuNgay = v.TuNgay
    _filter.DenNgay = v.DenNgay
    onChangeFilter(_filter)
  }

  return (
    <div className="">
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <ChiNhanhSelect
        FormID={272}
        // onChange={v => handleChange(v)}
        onChange={v => applyChangeFilter("DuAn", v)}
        lable="Dự án "
        placeholder="Chọn dự án"
      />
    </div>
  )
}
export default FilterModal
