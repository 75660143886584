import React, { useState, useEffect } from "react"

// interface IPaginationProps {
//   totalPage: number;
//   changePage: (page: number) => void;
// }
// interface IPagination {
//   page: number;
//   status: boolean;
// }
const setPagaStatus = (totalPage, page) => {
  var arr = []
  let isEllipLeft = false
  let isEllipRight = false
  for (let i = 1; i <= totalPage; i++) {
    if (i === 1 || i === totalPage || i === page) {
      var obj = {
        page: i,
        status: i === page ? true : false,
        isEllipsis: false,
      }
      arr.push(obj)
    } else {
      if (page <= 3) {
        if (i <= 5) {
          var obj = {
            page: i,
            status: i === page ? true : false,
            isEllipsis: false,
          }
          arr.push(obj)
        }
      } else {
        if (i < page - 2 && !isEllipLeft) {
          var obj = {
            page: i,
            status: i === page ? true : false,
            isEllipsis: true,
          }
          isEllipLeft = true
          arr.push(obj)
        } else if (
          i === page - 1 ||
          i === page - 2 ||
          i === page + 1 ||
          i === page + 2
        ) {
          var obj = {
            page: i,
            status: i === page ? true : false,
            isEllipsis: false,
          }
          arr.push(obj)
        } else if (i > page - 2 && !isEllipRight) {
          var obj = {
            page: i,
            status: i === page ? true : false,
            isEllipsis: true,
          }
          isEllipRight = true
          arr.push(obj)
        }
      }
    }
  }
  return arr
}

function Pagination(Props) {
  const { totalPage } = Props
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState([])
  useEffect(() => {
    var arr = setPagaStatus(totalPage, page)
    setPagination([...arr])
  }, [page, totalPage])

  const updatePage = item => {
    Props.changePage(item)
  }
  const changePage = item => {
    setPage(item)
    updatePage(item)
  }
  const prevPage = () => {
    setPage(page - 1)
    updatePage(page - 1)
  }
  const nextPage = () => {
    setPage(page + 1)
    updatePage(page + 1)
  }
  return (
    <React.Fragment>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
            <p className="page-link shadow-1" onClick={prevPage}>
              <i className="pi pi-angle-left"></i>
            </p>
          </li>

          {pagination.map(item => {
            if (item.isEllipsis === false) {
              return (
                <li
                  key={item.page}
                  className={item.status ? "page-item  active" : "page-item "}
                >
                  <p
                    className="page-link shadow-1"
                    onClick={e => changePage(item.page)}
                    style={{cursor: "pointer"}}
                  >
                    {item.page}
                  </p>
                </li>
              )
            } else {
              return (
                <li
                  key={item.page}
                  className={item.status ? "page-item  active" : "page-item "}
                >
                  <p className="page-link shadow-1">
                    ...
                  </p>
                </li>
              )
            }
          })}
          <li className={`page-item ${page === totalPage ? "disabled" : ""}`}>
            <p className="page-link shadow-1" onClick={nextPage}>
              <i className="pi pi-angle-right"></i>
            </p>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  )
}
export default Pagination
