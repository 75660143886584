import React, { useEffect, useState, useCallback,useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import _ from "lodash";
import Contract_Filter from "./Contract_Filter"
import {
  handleValidDate,
  Format_Currency
} from "../../../common/common"
import { Button } from "primereact/button"
import {
  Col,
  Container,
  Row,
} from "reactstrap"
import Pagination from "../../../components/Common/Pagination"
import { ContractService } from "services/Contract"
import { InputText } from "primereact/inputtext"
import Contract_Detail from './Contract_Detail';

import LoadingBar from 'components/LoadingBar'
import Contract_History from "./Contract_History"
import ToolBar from "components/Toolbar"

const Contract2 = props => {
  const refDetail = useRef(null);
  const refHistory = useRef(null);
  const [loading,setLoading] = useState(false);
  const defaultCondition = {
    TuNgay: "2019-01-04",
    DenNgay: "2024-10-13",
    inputSearch: "",
    DuAn: [],
    Offset: 1,
    Limit: 20
  }
  const [data, setData] = useState([]);
  const [filterCondition, setFilterCondition] = useState(defaultCondition);
  const filter = async (filter) => {
    setLoading(true);
    let _filter = _.cloneDeep(filter);
    _filter.DuAn = _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await ContractService.filter(_filter)
    setData(res?.data || []);
    setLoading(false);
  }
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const afterChangeFilter = (_filter) => {
    setFilterCondition(_filter);
    filter(_filter)
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "search":
        _filter.inputSearch = v;
        break;
      case "page":
        _filter.Offset = v;
        break;
      case "itemPerPage":
        _filter.Offset = 1;
        _filter.Limit = v;
        break;
    }
    setFilterCondition(_filter);
    filter(_filter)
  }
  const [item, setItem] = useState([])
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.maPGC !== row.maPGC
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "stt",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.stt}
          </Link>
        ),
      },
      {
        dataField: "ngayKy",
        text: "Ngày ký",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayKy),
      },
      {
        dataField: "soHDMB",
        text: "Số HDMB",
        sort: true,
        formatter: (cellContent, row) => {
          return <span onClick={()=>refHistory.current.view(row)}  className="link">{row.soHDMB}</span>
        },
      },
      {
        dataField: "kyHieu",
        text: "Mã sản phẩm",
        sort: true,
      },
      {
        dataField: "hoTenKH",
        text: "Tên KH",
        sort: true,
      },
      {
        dataField: "soCMND",
        text: "Số CMND",
        sort: true,
      },
      {
        dataField: "diDong",
        text: "Di động",
        sort: true,
      },
      {
        dataField: "tenTT",
        text: "Trạng thái",
        sort: true,
        formatter: (cellContent, row) => (
          <div
            style={{
              // backgroundColor: row.mauNen,
              padding: "5px",
            }}
          >
            {row.tenTT}
          </div>
        ),
      },
      {
        dataField: "tongGiaGomVAT",
        text: "Tổng giá gồm VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.tongGiaGomVAT),
      },
      {
        dataField: "phiBaoTri",
        text: "Phí bảo trì",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.phiBaoTri),
      },
      {
        dataField: "daThu",
        text: "Tổng đã thu",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.daThu),
      },
      {
        dataField: "sanGD",
        text: "Sàn giao dịch",
        sort: true
      },

      {
        dataField: "tenNVKD",
        text: "Nhân viên bán hàng",
        sort: true,
      },
      {
        dataField: "nguoiTao",
        text: "Người tạo",
        sort: true,
      },
      {
        dataField: "ngayTao",
        text: "Ngày tạo",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.ngayTao),
      },
      {
        dataField: "noiCap",
        text: "Nơi cấp",
        sort: true,
      },
      {
        dataField: "diaChi",
        text: "Địa chỉ",
        sort: true,
      },
      {
        dataField: "thuongTru",
        text: "Thường trú",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "diDong2",
        text: "Di động 2",
        sort: true,
      },
      {
        dataField: "tenDA",
        text: "Dự án",
        sort: true,
      },
    ]
  }, [])
  const rowStyle = (row, rowIndex) => {
    return rowIndex % 2 !== 0 ? {
      background: '#f6f8fb'
    } : null
  };
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  return (
    <React.Fragment>

      <div className="page-content">
        <MetaTags>
          <title>Hợp đồng| Beesky</title>
        </MetaTags>
        <Container fluid>
        <ToolBar
            left={() => (
              <div className="text-sm-start"> 
                <Button label="Thêm"
                  icon="mdi mdi-plus"
                  className="p-button-outlined p-button-success mr-1"
                  onClick={()=>refDetail.current.create()} 
                  />
              </div>
            )}
            right={() => <Contract_Filter filterCondition={filterCondition} afterChangeFilter={afterChangeFilter} />}
          />
          <Row>
            <Col xs="12" >
              {/* <ToolkitProvider
                keyField="stt"
                data={data === "[]" ? new Array([]) : data}
                columns={Columns()}
                bootstrap4
              >
                {toolkitProps => ( */}
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                      <LoadingBar loading={loading} />
                        <div className="table-responsive table-scrollable shadow-2" style={{
                          // height: window.innerHeight 
                        }}>
                          <BootstrapTable
                           data={data === "[]" ? new Array([]) : data}
                            keyField="maPGC"
                            responsive
                            columns={Columns()}
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            rowStyle={rowStyle}
                            // selectRow={selectRow}
                            // rowClasses ={rowClasses}
                            classes={
                              "table align-middle  table-nowrap table-check table-hover table-fixed "
                            }
                            headerWrapperClasses={"table-light"}
                            // {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div>
                          <select
                            className="form-select"
                            value={filterCondition.Limit}
                            onChange={e => onChangeFilter("itemPerPage", Number(e.target.value))}
                          >
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                          </select>
                        </div>
                        <Col className="pagination pagination-rounded justify-content-end  inner-custom-pagination">
                          <Pagination
                            totalPage={Math.ceil(
                              data[0]?.totalRows / filterCondition.Limit
                            )}
                            changePage={(i) => onChangeFilter("page", i)}
                          />
                        </Col>
                      </Col>
                    </Row>
                  </React.Fragment>
            </Col>
          </Row>
        </Container>
      </div>
      <Contract_Detail ref = {refDetail} /> 
      <Contract_History ref = {refHistory}/>
    </React.Fragment>
  )
}

Contract2.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Contract2))
