import * as types from "./actionTypes"

const INIT_STATE = {
  permission: [],
  listModule: [],
  listForm: [],
  perSDBID: [],
  listFeature: [],
  accessData: [],
}

const Permission = (state = INIT_STATE, action) => {
 
  switch (action.type) {
    case types.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload,
      }
    case types.DELETE_PERMISSION_SUCCESS:
      const perDelete = state.permission.filter(item => {
        return item.perID !== Number(action.payload.PerID)
      })
      return {
        ...state,
        permission: perDelete,
      }
    case types.GET_LIST_MODULE_SUCCESS:
      return {
        ...state,
        listModule: action.payload,
        listForm:[],
      }
    case types.GET_PER_SDBID_SUCCESS:
      return {
        ...state,
        perSDBID: action.payload,
      }
    case types.GET_FEATURE_HAS_NAME_SUCCESS:
      return {
        ...state,
        listFeature: action.payload,
      }
    case types.GET_FORM_BY_MODULE:
      const form = state.listModule.find(item => {
        return item.modulID === action.payload
      })?.detailForms
      return {
        ...state,
        listForm: form,
        perSDBID: [],
        listFeature: [],
      }
    case types.GET_ACCESSDATA_PER_SUCCESS:
      return {
        ...state,
        accessData: action.payload,
      }
    case types.ADD_FEATURE_PER_SUCCESS:
      const listFeatureWhenAdd = state.listFeature.filter(item => {
        if (item.featureID === action.payload.FeatureID) {
          item.check = !item.check
        }
        return item
      })
      return {
        ...state,
        listFeature: listFeatureWhenAdd,
      }
    case types.DELETE_FEATURE_PER_SUCCESS:
      const listFeatureWhenDelete = state.listFeature.filter(item => {
        if (item.featureID === action.payload.FeatureID) {
          item.check = !item.check
        }
        return item
      })
      return {
        ...state,
        listFeature: listFeatureWhenDelete,
      }
    default:
      return state
  }
}

export default Permission
