import * as types from "./actionTypes";

export const getChungCu = payload => ({
  type: types.GET_CHUNGCU,
  payload,
})
export const getDatNen = payload => ({
  type: types.GET_DATNEN,
  payload,
})
export const geSanPham = payload => ({
  type: types.GET_SANPHAM,
  payload,
})

export const addKhachHang = payload => ({
  type: types.ADD_KHACHHANG,
  payload,
})
export const addBooking = payload => ({
  type: types.ADD_BOOKING,
  payload,
})
