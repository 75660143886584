import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Popconfirm, Row, Table, Tooltip, notification } from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "../../Utility/common";
import { Format_Currency } from  "../../Utility/common";
// import { CiTrash } from "react-icons/ci";
// import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
// import { SoQuyService } from "services/SoQuyService";
// import { CongTyService } from "services/CongTyService";
// import { OtherService } from "services/OtherService";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaiPT, setLoaiPT] = useState([]);
  const [hinhThucTT, setHinhThucTT] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
    MaCT: 0,
    LoaiThu: ",,",
    HinhThuc: ",,",
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const loadCategories = async () => {
    return
    // let _resCT = await CongTyService.getCongTy();
    // setCompanies(_resCT.data);
     OtherService.getHinhThucTT().then((res) => setHinhThucTT(res.data ?? []));
     SoQuyService.PhieuThu.getLoaiPhieuThu().then((res) =>
       setLoaiPT(res.data ?? [])
     );
  };
  useEffect(() => {
    filter(filterCondition);
    loadCategories();
  }, []);
  const filter = async (_filter) => {
    return
    setLoading(true);
    let _res = await SoQuyService.PhieuThu.getPhieuThu(_filter);
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Số phiếu",
      dataIndex: "SoPhieu",
      fixed:'left',
    },
    {
      title: "Kí hiệu",
      dataIndex: "KiHieu",
      fixed:'left',

     
    },
    {
      title: "Ngay thu",
      dataIndex: "NgayThu",
      render: (item) => Format_Date(item),
      fixed:'left',

    },
    {
      title: "GridColumn19",
      dataIndex: "GridColumn19",
    },
    {
      title: "Tổng thu",
      dataIndex: "TongThu",
    },
    {
      title: "Người nộp",
      dataIndex: "NguoiNop",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
    },
    {
      title: "Diễn dãi",
      dataIndex: "DienDai",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Xác nhận",
      dataIndex: "XacNhan",
    },
    {
      title: "Nhân viên XN",
      dataIndex: "NhanVienXN",
    },
    {
      title: "Hình thức",
      dataIndex: "Hinh Thuc",
    },
    {
      title: "Ngày Nhập",
      dataIndex: "NgayNhap",
      render:item => Format_Date(item)
    },
    {
      title: "Chứng từ gốc",
      dataIndex: "ChungTuGoc",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "NgayCapNhat",
    },
    {
      title: "Số Tk",
      dataIndex: "SoTK",
    },
    {
      title: "Ngân hàng",
      dataIndex: "NganHang",
    },
    {
      title: "Nguồn tiền",
      dataIndex: "NguonTien",
    },
    {
      title: "Người câp nhật",
      dataIndex: "NguoiCapNhat",
    },
   
    // {
    //   title: "",
    //   fixed: "right",
    //   width: "100px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Tooltip placement="topRight" title="Sửa">
    //           <Button
    //             style={{ marginLeft: 10 }}
    //             type="primary"
    //             ghost
    //             // icon={<MdEdit />}
    //             onClick={() => refDetail.current.update(item)}
    //           />
    //         </Tooltip>

    //         <Popconfirm
    //           title="Xoá phiếu thu"
    //           description="Bạn có chắc chắn muốn xoá phiếu thu?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.ID)}
    //         >
    //           <Tooltip placement="topRight" title="Xoá">
    //             <Button
    //               style={{ marginLeft: 10 }}
    //               danger
    //               ghost
    //               // icon={<CiTrash />}
    //             />
    //           </Tooltip>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ];
  const remove = async (value) => {
    return
    let _res = await SoQuyService.PhieuThu.deletePhieuThu({ ID: Number(value) });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter(v=>v.ID !==Number(value) );
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá phiếu thu thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Row gutter={15} className="page-content">
      <Col span={4}>
        <FilterModal
          hinhThuc={hinhThucTT}
          loai={loaiPT}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          right={() => (
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
         scroll={{
            y: window.innerHeight - 150,
            x: 2500,
          }}
          size="small"
        />
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        hinhThuc={hinhThucTT}
        loai={loaiPT}
      />
      {contextHolder}
    </Row>
  );
}

