import { Box, SimpleGrid } from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
  Flex,
  ColorPicker,
} from "antd"
import { EditTwoTone, DeleteOutlined } from "@ant-design/icons"
import _ from "lodash"
import TabPane from "antd/es/tabs/TabPane"
import ChildrenTabsPQXTT from "./ChildrenTabsPQXTT"
import { DanhMucService } from "services/DanhMucService"
import FormAdd from "./FormAdd"

export default function MoiGioiLe() {
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const refDetail = useRef(null)
  const [loaisp, setLoaisp] = useState([])
  const [khuVuc, setKhuVuc] = useState([])
  const [activeTab, setActiveTab] = useState("0")

  const loadCateTab3 = async () => {
    // Load data for specific tab if needed
  }

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }

  const tabs = [
    { title: "Nguồn đến", key: "0" },
    { title: "Loại BĐS", key: "1" },
    { title: "Pháp lý", key: "2" },
    { title: "Loại đường", key: "3" },
    { title: "Trạng thái", key: "4" },
    { title: "Tình trạng", key: "5" },
    { title: "Mục đích mua thuê", key: "6" },
    { title: "Trạng thái mua/ thuê", key: "7" },
    { title: "Tiện ích", key: "8" },
    { title: "Phân quyền xem thông tin", key: "9" },
  ]

  useEffect(() => {
    filter(activeTab)
  }, [])

  const filter = async tab => {
    setLoading(true)
    let _res
    switch (tab) {
      case "0":
        _res = await DanhMucService.Nguon.get()
        break
      case "1":
        _res = await DanhMucService.LoaiBDS.get()
        break
      case "2":
        _res = await DanhMucService.phapLy.get()
        break
      case "3":
        _res = await DanhMucService.LoaiDuong.get()
        break
      case "4":
        _res = await DanhMucService.trangThai.get()
        break
      case "5":
        _res = await DanhMucService.tinhTrang.get()
        break
      case "6":
        _res = await DanhMucService.mucDichThue.get()
        break
      case "7":
        _res = await DanhMucService.trangThaiMuaThue.get()
        break
      case "8":
        _res = await DanhMucService.tienIch.get()
        break

      default:
        _res = { data: [] }
    }
    if (_res?.data) {
      setData(_res.data)
    }
    setLoading(false)
  }

  const onAfterSubmit = () => {
    filter(activeTab)
  }

  const columns = [
    {
      title: "Tên danh mục",
      dataIndex: "Name",
    },
    {
      title: () => (
        <Flex justify="center">
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab)}
          >
            Thêm
          </Button>
        </Flex>
      ),
      fixed: "right",
      width: "100px",
      render: item => (
        <div>
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refDetail.current.update(item, activeTab)}
            />
          </Tooltip>
          <Popconfirm
            title="Xoá danh mục"
            description="Bạn có chắc chắn muốn xoá danh mục?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.ID)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ]

  const columns5 = [
    {
      title: "Trạng thái",
      dataIndex: "Name",
    },
    {
      title: "Màu nền",
      dataIndex: "Color",
      render: item => <ColorPicker value={item ? item : null} disabled />,
    },
    {
      title: () => (
        <Flex justify="center">
          <Button
            type="primary"
            onClick={() => refDetail.current.show(activeTab)}
          >
            Thêm
          </Button>
        </Flex>
      ),
      fixed: "right",
      width: "100px",
      render: item => (
        <div>
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refDetail.current.update(item, activeTab)}
            />
          </Tooltip>
          <Popconfirm
            title="Xoá danh mục"
            description="Bạn có chắc chắn muốn xoá danh mục?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.ID)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ]

  const rowClassName = record => {
    return record.Color ? `row-color-${record.ID}` : ""
  }

  const rowStyles = {}
  data.forEach(item => {
    if (item.Color) {
      rowStyles[`.row-color-${item.ID}`] = { backgroundColor: item.Color }
    }
  })

  const remove = async value => {
    let _res
    switch (activeTab) {
      case "0":
        _res = await DanhMucService.Nguon.delete({ ID: Number(value) })
        break
      case "1":
        _res = await DanhMucService.LoaiBDS.delete({ ID: Number(value) })
        break
      case "2":
        _res = await DanhMucService.phapLy.delete({ ID: Number(value) })
        break
      case "3":
        _res = await DanhMucService.LoaiDuong.delete({ ID: Number(value) })
        break
      case "4":
        _res = await DanhMucService.trangThai.delete({ ID: Number(value) })
        break
      case "5":
        _res = await DanhMucService.tinhTrang.delete({ ID: Number(value) })
        break
      case "6":
        _res = await DanhMucService.mucDichThue.delete({ ID: Number(value) })
        break
      case "7":
        _res = await DanhMucService.trangThaiMuaThue.delete({
          ID: Number(value),
        })
        break
      case "8":
        _res = await DanhMucService.tienIch.delete({ ID: Number(value) })
        break
      case "9":
        _res = await DanhMucService.phanQuyenXemThongTin.delete({
          ID: Number(value),
        })
        break
      default:
        _res = { status: 4000, message: "Invalid Tab" }
    }
    if (_res?.status === 2000) {
      setData(prevData => prevData.filter(item => item.ID !== Number(value)))
      openNotification("success", "topRight", "Xoá danh mục thành công")
    } else {
      openNotification("error", "topRight", _res?.message)
    }
  }

  const onChange = newActiveKey => {
    setActiveTab(newActiveKey)
    filter(newActiveKey)
    if (newActiveKey === "2") loadCateTab3()
  }

  return (
    <Box className="page-content">
      <style>{`
        ${Object.entries(rowStyles)
          .map(([selector, styles]) => {
            return `${selector} { ${Object.entries(styles)
              .map(([key, value]) => `${key}: ${value};`)
              .join(" ")} }`
          })
          .join(" ")}
      `}</style>
      {activeTab === "9" ? (
        <>
          <Tabs onChange={onChange} activeKey={activeTab}>
            {tabs.map(item => (
              <TabPane tab={item.title} key={item.key}></TabPane>
            ))}
          </Tabs>
          <ChildrenTabsPQXTT />
        </>
      ) : (
        <Tabs onChange={onChange} activeKey={activeTab}>
          {tabs.map(item => (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={
                  activeTab === "4"
                    ? columns5
                    : activeTab === "7"
                    ? columns5
                    : columns
                }
                scroll={{ y: window.innerHeight - 350 }}
                size="small"
                rowClassName={rowClassName}
              />
            </TabPane>
          ))}
        </Tabs>
      )}
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{ LoaiSP: loaisp, KhuVuc: khuVuc }}
      />
      {contextHolder}
    </Box>
  )
}
