import { UserOutlined } from "@ant-design/icons"
import {
  Avatar,
  Button,
  Calendar,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Input,
  notification,
  Row,
  Table,
  Tabs,
} from "antd"

import TabPane from "antd/es/tabs/TabPane"
import React, { useEffect, useRef, useState } from "react"
import { FaUser } from "react-icons/fa6"
import "./index.scss"
import _, { includes } from "lodash"
import dayjs, { locale } from "dayjs"
// import { InfoService } from "services/infoService";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom"
import { InfoService } from "services/Infouser"

const InfoUser = () => {
  const [activeKey, setActiveKey] = useState("1")
  const [chucNang, setChucNang] = useState([])
  const [caiDat, setCaiDat] = useState([])
  const [checkedList, setCheckedList] = useState({
    newTask: false,
    mentionTask: false,
    dailySummary: false,
    newOrder: false,
    changeOrder: false,
    approveOrder: false,
    cancelOrder: false,
    thuEmail: false,
    chiEmail: false,
  })
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  useEffect(() => {
    // setActiveKey("3")
    if (location.state && location.state.activeKey) {
      setActiveKey(location.state.activeKey)
    }
  }, [location.state])

  const filter = async () => {
    let _resCN = await InfoService.getChucNang()
    if (_resCN.data) setChucNang(_resCN.data)
    let _resCD = await InfoService.getCaiDat()
    if (_resCD.data) setCaiDat(_resCD.data)

    // setToanha(_tn.data);
    // let _filter = _.cloneDeep(filterCondition);
    // _filter.MaTN = _tn.data[0]?.MaTN;
    // setFilterCondition(_filter);
    // loadcategoriesByMaTN(_tn.data[0]?.MaTN);
  }

  useEffect(() => {
    filter()
  }, [])

  
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const onChange = field => e => {
    setCheckedList({
      ...checkedList,
      [field]: e.target.checked,
    })
  }

  const handleTabChange = key => {
    setActiveKey(key)
  }
  const ThongTinCaNhan = () => {
    return (
      <div
        style={{ maxHeight: "68vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ textAlign: "center" }} span={2}>
            <Avatar size={80} icon={<UserOutlined />} />
          </Col>
          <Col style={{ paddingLeft: "20px", marginTop: "-5px" }} span={20}>
            <h2 style={{ fontSize: "2rem", fontWeight: "700" }}>API PM</h2>
            <p styles={{ color: "#6c757d", fontSize: "16px" }}>
              Nhân viên - System Admin
            </p>
            <p styles={{ color: "#6c757d", fontSize: "16px" }}>
              Hiện tại: Đang làm việc{" "}
            </p>
          </Col>
          <Col span={2}>
            <Button type="primary">Sửa thông tin</Button>
          </Col>
        </Row>
        <div style={{ paddingLeft: "20px" }}>
          <p
            style={{
              fontWeight: "600",
              borderBottom: "1px solid #dee2e6",
              marginBottom: "15px",
            }}
          >
            THÔNG TIN CHUNG
          </p>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <strong>Họ và tên:</strong>
            </Col>
            <Col span={6}>
              <strong>API PM:</strong>
            </Col>
            <Col span={6}>
              <strong>Nơi sinh:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Điện thoại:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Ngày sinh:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Email:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Nguyên quán:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Giới tính:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Chứng minh nhân dân:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Ngày cấp:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Nơi cấp:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Tình trạng hôn nhân:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Tôn giáo:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Dân tộc:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Quốc tịch:</strong>
            </Col>
            <Col span={6}>(Chưa cập nhật)</Col>
            <Col span={6}>
              <strong>Chữ ký:</strong>
            </Col>
          </Row>
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <p
            style={{
              fontWeight: "600",
              borderBottom: "1px solid #dee2e6",
              marginBottom: "15px",
              paddingTop: "15px",
            }}
          >
            LÝ LỊCH
          </p>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <strong>Mô tả công việc:</strong>
              <p>(Chưa cập nhật)</p>
            </Col>
            <Col span={12}>
              <strong>Kĩ năng chuyên môn:</strong>
              <p>(Chưa cập nhật)</p>
            </Col>
            <Col span={12}>
              <strong>Kinh nghiệm:</strong>
              <p>(Chưa cập nhật)</p>
            </Col>
            <Col span={12}></Col>
          </Row>
        </div>
      </div>
    )
  }
  const ThongBaoQuaEmail = () => {
    return (
      <div style={{ padding: "0 1%" }}>
        <h3 style={{ color: "black", fontSize: "18px" }}>
          Thiết lập nhận thông báo qua email
        </h3>
        <Row>
          <Col span={12}>
            <p style={{ padding: " 15px 0" }}>CÔNG VIỆC</p>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("newTask")}
                  checked={checkedList.newTask}
                >
                  Nhận email khi có công việc mới được giao
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("mentionTask")}
                  checked={checkedList.mentionTask}
                >
                  Nhận email khi được đánh dấu trong công việc
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("dailySummary")}
                  checked={checkedList.dailySummary}
                >
                  Nhận email tổng hợp công việc vào mỗi buổi sáng hàng ngày
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("newOrder")}
                  checked={checkedList.newOrder}
                >
                  Nhận email thông báo có đơn hàng mới
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("changeOrder")}
                  checked={checkedList.changeOrder}
                >
                  Nhận email thông báo khi thay đổi đơn hàng
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("approveOrder")}
                  checked={checkedList.approveOrder}
                >
                  Nhận email thông báo khi duyệt đơn hàng
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("cancelOrder")}
                  checked={checkedList.cancelOrder}
                >
                  Nhận email thông báo khi hủy đơn hàng
                </Checkbox>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <p style={{ padding: " 15px 0" }}>PHIẾU THU - PHIẾU CHI</p>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("thuEmail")}
                  checked={checkedList.thuEmail}
                >
                  Nhận email thông báo khi phiếu Thu được thu
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={onChange("chiEmail")}
                  checked={checkedList.chiEmail}
                >
                  Nhận email thông báo khi phiếu Chi được chi
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  const ThietLapThongBao = () => {
    const applyChange = async (key, value, record) => {
      setLoading(true)
      const _caiDat = _.cloneDeep(caiDat)
      let _res
      if (key === "ALL") {
        // Trường hợp `key` là "ALL"
        if (record.ALL === true) {
          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: "", // Mảng rỗng
          })
          setCaiDat(
            _caiDat.map(item =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? { ...item, HanhDong: "" }
                : item
            )
          )
        } else {
          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: ["ALL", "ADD", "EDIT", "DELETE", "OTHER"].join(","), // Thêm tất cả hành động
          })
          setCaiDat(
            _caiDat.map(item =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? {
                    ...item,
                    HanhDong: ["ALL", "ADD", "EDIT", "DELETE", "OTHER"].join(
                      ","
                    ),
                  }
                : item
            )
          )
        }
      } else {
        // Trường hợp `key` không phải "ALL"
        const foundItem = _caiDat.find(
          item =>
            item.MaChucNang === Number(record.key) &&
            item.LoaiThietBi === record.LoaiThietBi
        )

        let newArr = foundItem?.HanhDong
          ? foundItem.HanhDong.split(",").filter(Boolean)
          : []

        if (record[key] === true) {
          newArr = newArr.filter(action => action !== key && action !== "ALL")

          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: newArr.join(","),
          })

          setCaiDat(
            _caiDat.map(item =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? {
                    ...item,
                    HanhDong: newArr.join(","),
                  }
                : item
            )
          )
        } else {
          if (!newArr.includes(key)) {
            newArr.push(key)
          }
          if (
            newArr.includes("ADD") &&
            newArr.includes("EDIT") &&
            newArr.includes("DELETE") &&
            newArr.includes("OTHER") &&
            !newArr.includes("ALL")
          ) {
            newArr.push("ALL")
          }

          _res = await InfoService.addCaiDat({
            MaChucNang: Number(record.key),
            LoaiThietBi: record.LoaiThietBi,
            HanhDong: newArr.join(","),
          })
          setCaiDat(
            _caiDat.map(item =>
              item.MaChucNang === Number(record.key) &&
              item.LoaiThietBi === record.LoaiThietBi
                ? {
                    ...item,
                    HanhDong: newArr.join(","),
                  }
                : item
            )
          )
        }
      }

      setLoading(false)
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Thao tác thành công!`)
      } else {
        openNotification("error", "topRight", _res?.message)
      }
    }

    const dataSource = chucNang.flatMap(item => {
      const webSetting = caiDat.find(
        setting =>
          setting.MaChucNang === item.ID && setting.LoaiThietBi === "WEB"
      )
      const appSetting = caiDat.find(
        setting =>
          setting.MaChucNang === item.ID && setting.LoaiThietBi === "APP"
      )

      const webData = {
        key: `${item?.ID}`,
        ChucNang: item?.Name,
        LoaiThietBi: "WEB",
        ALL: webSetting?.HanhDong?.includes("ALL") || false,
        ADD: webSetting?.HanhDong?.includes("ADD") || false,
        EDIT: webSetting?.HanhDong?.includes("EDIT") || false,
        DELETE: webSetting?.HanhDong?.includes("DELETE") || false,
        OTHER: webSetting?.HanhDong?.includes("OTHER") || false,
      }

      // Tạo cấu hình cho APP
      const appData = {
        key: `${item?.ID}`,
        ChucNang: item?.Name,
        LoaiThietBi: "APP",
        ALL: appSetting?.HanhDong?.includes("ALL") || false,
        ADD: appSetting?.HanhDong?.includes("ADD") || false,
        EDIT: appSetting?.HanhDong?.includes("EDIT") || false,
        DELETE: appSetting?.HanhDong?.includes("DELETE") || false,
        OTHER: appSetting?.HanhDong?.includes("OTHER") || false,
      }

      // Trả về mảng gồm cả dữ liệu cho WEB và APP
      return [webData, appData]
    })

    const columns = [
      {
        title: "Chức năng",
        dataIndex: "ChucNang",
        render: (text, record, index) => {
          if (index % 2 === 0) {
            return {
              children: text,
              props: {
                rowSpan: 2,
              },
            }
          }
          if (index % 2 === 1) {
            return {
              children: null,
              props: {
                rowSpan: 0,
              },
            }
          }
          return text
        },
      },
      {
        title: "Loại thiết bị",
        dataIndex: "LoaiThietBi",
        key: "LoaiThietBi",
      },
      {
        title: "Tất cả",
        dataIndex: "ALL",
        key: "ALL",
        render: (item, record) => (
          <Checkbox
            onChange={e => applyChange("ALL", e.target.checked, record)}
            value={"ALL"}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Thêm",
        dataIndex: "ADD",
        key: "ADD",
        render: (item, record) => (
          <Checkbox
            onChange={e => applyChange("ADD", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Cập nhật",
        dataIndex: "EDIT",
        key: "EDIT",
        render: (item, record) => (
          <Checkbox
            onChange={e => applyChange("EDIT", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Xóa",
        dataIndex: "DELETE",
        key: "DELETE",
        render: (item, record) => (
          <Checkbox
            onChange={e => applyChange("DELETE", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
      {
        title: "Khác",
        dataIndex: "OTHER",
        key: "OTHER",
        render: (item, record) => (
          <Checkbox
            onChange={e => applyChange("OTHER", e.target.checked, record)}
            checked={item}
          ></Checkbox>
        ),
      },
    ]

    return (
      <>
        <Flex
          style={{ marginBottom: "5px" }}
          justify="space-between"
          align="center"
        >
          <h3
            style={{
              fontSize: "20px",
              color: "black",
              fontWeight: "600",
              paddingBottom: "10px",
            }}
          >
            Thiết lập nhận thông báo
          </h3>
          <div>
            {/* <Button type="primary" style={{ marginRight: "5px" }}>
              Nhận tất cả
            </Button>
            <Button  type="primary" danger>
              Bỏ nhận tất cả
            </Button> */}
          </div>
        </Flex>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
          scroll={{
            y: window.innerHeight - 250,
            x: 1000,
          }}
        />
      </>
    )
  }

  const LichSuDangNhap = () => {
    const dataSource = [
      {
        key: "1",
        stt: 1,
        time: "14:46:38 06-10-2024",
        ip: "117.6.78.123",
        device: "PC",
      },
      {
        key: "2",
        stt: 2,
        time: "13:43:54 04-10-2024",
        ip: "117.6.78.123",
        device: "PC",
      },
      {
        key: "3",
        stt: 3,
        time: "13:36:29 04-10-2024",
        ip: "118.70.127.204",
        device: "PC",
      },
      {
        key: "4",
        stt: 4,
        time: "20:35:55 19-09-2024",
        ip: "123.24.161.122",
        device: "PC",
      },
      {
        key: "5",
        stt: 5,
        time: "09:12:43 18-06-2024",
        ip: "222.252.39.223",
        device: "PC",
      },
      {
        key: "6",
        stt: 6,
        time: "06:29:29 16-05-2024",
        ip: "113.178.33.36",
        device: "PC",
      },
      {
        key: "7",
        stt: 7,
        time: "11:22:20 07-05-2024",
        ip: "14.248.68.78",
        device: "PC",
      },
      {
        key: "8",
        stt: 8,
        time: "17:34:30 19-04-2024",
        ip: "222.254.11.25",
        device: "PC",
      },
      {
        key: "9",
        stt: 9,
        time: "14:46:02 17-04-2024",
        ip: "222.252.101.223",
        device: "PC",
      },
    ]

    const columns = [
      {
        title: "#",
        dataIndex: "stt",
        key: "stt",
      },
      {
        title: "Thời gian",
        dataIndex: "time",
        key: "time",
      },
      {
        title: "Địa chỉ IP",
        dataIndex: "ip",
        key: "ip",
      },
      {
        title: "Loại thiết bị",
        dataIndex: "device",
        key: "device",
      },
    ]

    return (
      <>
        <h3
          style={{
            fontSize: "20px",
            color: "black",
            fontWeight: "600",
            paddingBottom: "10px",
          }}
        >
          Lịch sử đăng nhập
        </h3>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
        />{" "}
      </>
    )
  }
  return (
    <div>
      {/* <div>
        <Flex style={{ marginTop: "10px" }} align="center">
          <FaUser style={{ fontSize: "25px", marginRight: "8px" }} />
          Quản lý thông tin cá nhân
        </Flex>
      </div>
      <Row justify="" style={{ padding: "20px 0" }}>
        <Col xs={24}>
          <div style={{ color: "#fff" }}>
            <Tabs
              activeKey={activeKey}
              onChange={handleTabChange}
              style={{ width: "100%" }}
              className="custom-tabs"
              defaultActiveKey="1"
            >
              <TabPane tab="Thông tin cá nhân" key="1">
                <div style={{ width: "100%" }}>{ThongTinCaNhan()}</div>
              </TabPane>
              <TabPane tab="Thông báo qua email" key="2">
                <div style={{ width: "100%" }}>{ThongBaoQuaEmail()}</div>
              </TabPane>
              <TabPane tab="Thông báo trên hệ thống" key="3">
                <div style={{ width: "100%" }}>{ThietLapThongBao()}</div>
              </TabPane>
              <TabPane tab="Lịch sử đăng nhập" key="4">
                {LichSuDangNhap()}
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row> */}

      <div style={{ width: "100%", padding: "20px", marginTop: "50px" }}>
        {ThietLapThongBao()}
      </div>
      {contextHolder}
    </div>
  )
}

export default InfoUser
