import { Select } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { ProjectService } from "services/Project"
// import { CongTyService } from "services/CongTyService";
// import { PermissionService } from "services/PermissionService";
const ChiNhanhSelect = props => {
  const { onChange, FormID, lable, placeholder } = props
  const [congty, setCongty] = useState([])
  const refPer = useRef(null)

  useEffect(() => {
    ;(async () => {
      let res = await ProjectService.getList({
        inputSearch: "",
        Offset: 1,
        Limit: 20,
      })
      setCongty(res.data ?? [])
    })()
  }, [])
  return (
    // refPer.current === 1 && (
    <div className="box-search">
      <p className="label">{lable}</p>
      <Select
        mode="multiple"
        allowClear
        placeholder={placeholder}
        onChange={v => onChange(v)}
        style={{
          width: "100%",
        }}
        options={congty}
        fieldNames={{ label: "tenDA", value: "maDA" }}
      />
    </div>
    // )
  )
}
export default React.memo(ChiNhanhSelect)
