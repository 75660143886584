import { DatePicker, Flex } from "antd"
import React from "react"
import dayjs from "dayjs"
import vi from "dayjs/locale/vi"
import "dayjs/locale/vi"
import "./index.scss";

const DatePickerCustom = ({ label, applyChange, prop, value, placeholder }) => {
  return (
    <Flex className="date-picker-custom" align="center" justify="flex-start">
      <p style={{ fontWeight: "600",width:"35%" }}>{label}</p>
      <DatePicker
        allowClear={false}
        format="DD-MM-YYYY"
        style={{ width: "65% ", border: "none", paddingLeft: "8px" }}
        value={dayjs(value)}
        placeholder={placeholder}
        locale={vi}
        onChange={v =>
          typeof applyChange === "function" && applyChange(prop, v)
        }
      />
    </Flex>
  )
}

export default DatePickerCustom
