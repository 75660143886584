import { Box } from "@chakra-ui/react"
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react"
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Image,
  Form,
  Cascader,
  Transfer,
  Tabs,
  Flex,
} from "antd"
import _ from "lodash"
import TextArea from "antd/es/input/TextArea"
import InputCustom from "components/Forms/InputCustom"
import SelectCustom from "components/Forms/SelectCustom"
import InputNumberCustom2 from "components/Forms/InputNumberCustom2"
import { useDispatch, useSelector } from "react-redux"
import { getCustomers, getStaffs } from "store/actions"
import { DanhMucService } from "services/DanhMucService"
import { ProjectService } from "services/Project"
import DebounceSelect from "components/DebounceSelect"
import { SanPhamService } from "services/SanPhamService"
import { CustomerService } from "services/Customer"
import { CommonService } from "services/Common"
import { AgencyService } from "services/Agency"

const FormAddNew = (props, ref) => {
  const [dataNguon, setDataNguon] = useState([])
  const [huongCua, setHuongCua] = useState([])
  const [tinh, setTinh] = useState([])
  const [huyen, setHuyen] = useState([])
  const [dataDuAn, setDataDuAn] = useState([])
  const [dataLoaiBDS, setDataLoaiBDS] = useState([])
  const [dataTrangThai, setDataTrangThai] = useState([])
  const { onAfterSubmit } = props
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState({})
  const [dataLoai, setDataLoai] = useState()
  const [open, setOpen] = useState(false)
  const defaultErrors = {}
  const [errors, setErrors] = useState(defaultErrors)
  const dispatch = useDispatch()
  const { staffs, customers } = useSelector(state => ({
    staffs: state.Staff.staffs,
    customers: state.Customer.customers,
  }))
  const loadCategories = async data => {
    let _resNguon,
      _resLoaiBDS,
      _resTrangThai,
      _resDuAn,
      _resGetLoai,
      _resTinh,
      _resHuongCua

    _resNguon = await DanhMucService.Nguon.get()
    _resLoaiBDS = await DanhMucService.LoaiBDS.get()
    _resTrangThai = await DanhMucService.trangThai.get()
    _resDuAn = await ProjectService.getList({
      inputSearch: "",
      Offset: 1,
      Limit: 20,
    })
    _resHuongCua = await AgencyService.ForSale.getPhuongHuong().then(res => {
      setHuongCua(res.data || [])
    })
    _resTinh = await CommonService.getTinh().then(res => {
      setTinh(res.data || [])
    })
    _resGetLoai = await SanPhamService.NhuCauMuaThue.GetLoai.get({
      isSanPham: false,
    })

    if (_resGetLoai?.data) {
      setDataLoai(_resGetLoai?.data)

      if (!data) {
        setData({
          MaNhuCau: _resGetLoai?.data[0].ID,
        })
      }
    }

    if (_resDuAn?.data) {
      setDataDuAn(_resDuAn.data)
    }
    if (_resNguon?.data) {
      setDataNguon(_resNguon.data)
    }
    if (_resLoaiBDS?.data) {
      setDataLoaiBDS(_resLoaiBDS.data)
    }
    if (_resTrangThai?.data) {
      setDataTrangThai(_resTrangThai.data)
    }
    dispatch(
      getCustomers({
        inputSearch: "",
        MaTT: 0,
        MaNVCS: 0,
        MaNKH: 0,
        Offset: 1,
        Limit: 50,
      })
    )
    dispatch(getStaffs({ inputSearch: "", Offset: 1, Limit: 1000 }))
  }
  const loadHuyen = async maTinh => {
    let _res = await CommonService.getHuyen({ MaTinh: maTinh })
    setHuyen(_res.data || [])
  }

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true)
      loadCategories()
    },
    update: async data => {
      setOpen(true)
      loadCategories(data)
      let _resMaKH = await CustomerService.getById({ MaKH: data.MaKH })
      let _resListHuyen = await SanPhamService.NhuCauMuaThue.getByid({
        MaMT: data.MaMT,
      })
      if (_resListHuyen?.data) {
        loadHuyen(data.MaTinh)
        data.ListHuyen = _.map(_resListHuyen.data?.listHuyen, "MaHuyen")
        let dataHuong = _.map(_resListHuyen.data?.listHuong, "MaHuong")
        data.HuongCua = huongCua
          .filter(item => dataHuong.includes(item.maPhuongHuong))
          .map(item => item.tenPhuongHuong)
          .join(",")
        data.ListHuong = dataHuong
      }
      if (_resMaKH?.data) {
        data.tenKH = {
          value: _resMaKH.data.MaKH,
          label: _resMaKH.data.TenKH,
          title: JSON.stringify(_resMaKH.data),
        }
        data.DiDong = _resMaKH.data.DiDong
        data.DiDong2 = _resMaKH.data.DiDong2
        data.Email = _resMaKH.data.Email
        data.tenKH = data.HoTenKH
      }

      setData(data)
    },
  }))
 
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data)
    switch (p) {
      case "TimKH":
        _data[p] = v
        if (v) {
          _data.DiDong = JSON.parse(v.title)?.diDong
          _data.DiDong2 = JSON.parse(v.title)?.diDong2
          _data.Email = JSON.parse(v.title)?.email
          _data.tenKH = JSON.parse(v.title)?.tenKH
          _data.MaKH = v.value
        } else {
          _data.DiDong = ""
          _data.DiDong2 = ""
          _data.Email = ""
          _data.tenKH = ""
          _data.MaKH = null
        }
        break
      case "MaTinh":
        loadHuyen(v)
        _data[p] = v
        // _data.MaHuyen = targetKeys
        break
      case "ListHuong":
        _data.HuongCua = huongCua
          .filter(item => v.includes(item.maPhuongHuong))
          .map(item => item.tenPhuongHuong)
          .join(",")
        _data[p] = v

        break

      default:
        _data[p] = v
        break
    }
    setData(_data)
  }

  async function fetchUserList(username) {
    const results = customers
      ?.filter(
        customer =>
          customer.tenKH &&
          customer.tenKH.toLowerCase().includes(username.toLowerCase())
      )
      .map(item => ({
        label: item.tenKH + " - " + item.diDong,
        value: item.maKH,
        title: JSON.stringify(item),
      }))

    return results || []
  }

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const validate = () => {
    const newErrors = {}
    if (!data.MaNhuCau) newErrors.MaNhuCau = "Vui lòng chọn nhu cầu"

    if (!data.MaNVKT) newErrors.MaNVKT = "Vui lòng chọn nhân viên MG"
    if (!data.MaNVKD) newErrors.MaNVKD = "Vui lòng chọn nhân viên QL"

    if (!data.MaNguon) newErrors.MaNguon = "Vui lòng chọn nguồn"
    if (!data.tenKH) newErrors.tenKH = "Vui lòng nhập tên khách hàng"
    if (!data.GiaTu) newErrors.GiaTu = "Vui lòng nhập giá tiền từ"
    if (!data.GiaDen) newErrors.GiaDen = "Vui lòng nhấp giá tiền đến"
    if (!data.DienTichTu) newErrors.DienTichTu = "Vui lòng nhập diện tích từ"
    if (!data.DienTichDen) newErrors.DienTichDen = "Vui lòng nhập diện tích đến"
    if (!data.MaTT) newErrors.MaTT = "Vui lòng chọn trạng thái"
    if (!data.MaLBDS) newErrors.MaLBDS = "Vui lòng chọn loại BĐS"
    if (!data.MaTinh) newErrors.MaTinh = "Vui lòng chọn địa điểm"

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const onSubmit = async () => {
    if (!validate()) {
      return
    }
    let _res2 = await SanPhamService.NhuCauMuaThue.add(data)

    if (_res2.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${data.MaMT ? "Sửa" : "Thêm"} thẻ thành công!`
      )
      onClose()
      onAfterSubmit()
    } else {
      openNotification("error", "topRight", _res2?.message)
    }
  }
  const onClose = () => {
    setErrors({})
    setData({})
    setOpen(false)
  }
  return (
    <>
      <Drawer
        title="NHU CẦU MUA THUÊ"
        width={1280}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            {/* <Button onClick={()=>onSubmit()}>Lưu</Button> */}
            {/* <Button onClick={() => onSubmit()} type="primary" ghost>
              Lưu
            </Button> */}
            <Button onClick={() => onSubmit(true)} type="primary">
              Lưu & Đóng
            </Button>
          </Space>
        }
      >
        {contextHolder}

        <>
          {dataLoai?.map(item => {
            return (
              <Checkbox
                key={item.ID}
                value={item.ID}
                checked={data?.MaNhuCau === item.ID}
                onChange={v => applyChange("MaNhuCau", v.target.value)}
              >
                {item.TenLoai}
              </Checkbox>
            )
          })}
          <p style={{ color: "red" }}>{errors?.MaNhuCau}</p>
          <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
            Thông tin CSH
          </p>

          <Row gutter={[20, 12]}>
            <Col span={6}>
              <InputCustom
                label="Số đăng ký:"
                applyChange={applyChange}
                prop="SoDK"
                value={data?.SoDK}
                placeholder="Nhập số đăng ký"
                disabled={true}
              />
            </Col>

            <Col span={18}>
              <Flex
                style={{ borderBottom: "1px solid #d9d9d9" }}
                justify="space-between"
              >
                <p style={{ fontWeight: "600" }}>Tìm khách hàng:</p>
                <DebounceSelect
                  mode="single"
                  allowClear={true}
                  value={data?.TimKH}
                  placeholder="Tìm kiểm khách hàng"
                  fetchOptions={fetchUserList}
                  onChange={newValue => applyChange("TimKH", newValue)}
                  style={{
                    width: "85%",
                  }}
                />
              </Flex>
            </Col>
            <Col span={6}>
              <InputCustom
                label="Họ tên (*)"
                applyChange={applyChange}
                prop="tenKH"
                value={data?.tenKH}
                placeholder="Nhập tên khách hàng"
              />
              <p style={{ color: "red" }}>{errors?.tenKH}</p>
            </Col>
            <Col span={6}>
              <InputCustom
                label="Di động 1:"
                applyChange={applyChange}
                prop="DiDong"
                value={data?.DiDong}
                placeholder="Nhập di động 1"
              />
              <p style={{ color: "red" }}>{errors?.DiDong}</p>
            </Col>
            <Col span={6}>
              <InputCustom
                label="Di động 2:"
                applyChange={applyChange}
                prop="DiDong2"
                value={data?.DiDong2}
                placeholder="Nhập di động 2"
              />
            </Col>
            <Col span={6}>
              <InputCustom
                label="Email:"
                applyChange={applyChange}
                prop="Email"
                value={data?.Email}
                placeholder="Nhập email"
              />
              <p style={{ color: "red" }}>{errors?.Email}</p>
            </Col>
          </Row>

          <p style={{ fontWeight: "bold", padding: "20px 0px 10px" }}>
            Đặc điểm BĐS
          </p>
          <Row gutter={[20, 12]}>
            <Col span={16}>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <Row gutter={[0, 10]}>
                    <Col span={24}>
                      <Flex align="center">
                        <p
                          style={{
                            fontWeight: "600",
                            width: "20%",
                            borderBottom: "1px solid #d9d9d9",
                            padding: "2px 0px 3px",
                          }}
                        >
                          Diện tích:
                        </p>
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"DienTichTu"}
                          value={data?.DienTichTu}
                          placeholder={"Từ"}
                          max={9999}
                        />{" "}
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"DienTichDen"}
                          value={data?.DienTichDen}
                          placeholder={"Đến"}
                          max={9999}
                        />
                      </Flex>
                      <p style={{ color: "red" }}>
                        {errors?.DienTichTu || errors?.DienTichDen}
                      </p>
                    </Col>
                    <Col span={24}>
                      <Flex align="center">
                        <p
                          style={{
                            fontWeight: "600",
                            width: "20%",
                            borderBottom: "1px solid #d9d9d9",
                            padding: "2px 0px 3px",
                          }}
                        >
                          Mặt tiền:
                        </p>
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"MatTienTu"}
                          value={data?.MatTienTu}
                          placeholder={"Từ"}
                        />{" "}
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"MatTienDien"}
                          value={data?.MatTienDien}
                          placeholder={"Đến"}
                          max={255}
                        />
                      </Flex>
                    </Col>
                    <Col span={24}>
                      <Flex align="center">
                        <p
                          style={{
                            fontWeight: "600",
                            width: "20%",
                            borderBottom: "1px solid #d9d9d9",
                            padding: "2px 0px 3px",
                          }}
                        >
                          Tổng giá:
                        </p>
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"GiaTu"}
                          value={data?.GiaTu}
                          placeholder={"Từ"}
                        />{" "}
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"GiaDen"}
                          value={data?.GiaDen}
                          placeholder={"Đến"}
                        />
                      </Flex>
                      <p style={{ color: "red" }}>
                        {errors?.GiaTu || errors?.GiaDen}
                      </p>
                    </Col>
                    <Col span={24}>
                      <Flex align="center">
                        <p
                          style={{
                            fontWeight: "600",
                            width: "20%",
                            borderBottom: "1px solid #d9d9d9",
                            padding: "2px 0px 3px",
                          }}
                        >
                          Phòng ngủ:
                        </p>
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"PhNguTu"}
                          value={data?.PhNguTu}
                          placeholder={"Từ"}
                        />{" "}
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"PhNguDen"}
                          value={data?.PhNguDen}
                          placeholder={"Đến"}
                          max={255}
                        />
                      </Flex>
                    </Col>
                    <Col span={24}>
                      <Flex align="center">
                        <p
                          style={{
                            fontWeight: "600",
                            width: "20%",
                            borderBottom: "1px solid #d9d9d9",
                            padding: "2px 0px 3px",
                          }}
                        >
                          Số tầng:
                        </p>
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"TangTu"}
                          value={data?.TangTu}
                          placeholder={"Từ"}
                        />{" "}
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"TangDen"}
                          value={data?.TangDen}
                          placeholder={"Đến"}
                          max={255}
                        />
                      </Flex>
                    </Col>
                    {/* phí môi giới */}
                    {/* <Col span={24}>
                      <Flex align="center">
                        <p
                          style={{
                            fontWeight: "600",
                            width: "20%",
                            borderBottom: "1px solid #d9d9d9",
                            padding: "2px 0px 3px",
                          }}
                        >
                          Phí MG:
                        </p>
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"PhiMGTu"}
                          value={data?.PhiMGTu}
                          placeholder={"Từ"}
                        />{" "}
                        <InputNumberCustom2
                          applyChange={applyChange}
                          prop={"PhiMGDen"}
                          value={data?.PhiMGDen}
                          placeholder={"Đến"}
                        />
                      </Flex>
                    </Col> */}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[0, 5]}>
                    <Col span={24}>
                      <SelectCustom
                        label="Dự án:"
                        applyChange={applyChange}
                        prop="MaDA"
                        value={data?.MaDA}
                        placeholder="Chọn dự án"
                        options={dataDuAn}
                        fieldNames={{ label: "tenDA", value: "maDA" }}
                      />
                    </Col>
                    <Col span={24}>
                      <SelectCustom
                        mode="multiple"
                        label="Hướng cửa:"
                        applyChange={applyChange}
                        prop="ListHuong"
                        value={data?.ListHuong}
                        placeholder="Chọn hướng cửa"
                        options={huongCua}
                        fieldNames={{
                          label: "tenPhuongHuong",
                          value: "maPhuongHuong",
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <SelectCustom
                        label="Trạng thái (*):"
                        applyChange={applyChange}
                        prop="MaTT"
                        value={data?.MaTT}
                        placeholder="Chọn trạng thái"
                        options={dataTrangThai}
                        fieldNames={{ label: "Name", value: "ID" }}
                      />
                      <p style={{ color: "red" }}>{errors?.MaTT}</p>
                    </Col>
                    <Col span={24}>
                      <SelectCustom
                        label="Loại BĐS:"
                        applyChange={applyChange}
                        prop="MaLBDS"
                        value={data?.MaLBDS}
                        placeholder="Chọn loại BĐS"
                        options={dataLoaiBDS}
                        fieldNames={{ label: "Name", value: "ID" }}
                      />
                      <p style={{ color: "red" }}>{errors?.MaLBDS}</p>
                    </Col>
                    <Col span={24}>
                      <SelectCustom
                        label="Nhân viên MG:"
                        applyChange={applyChange}
                        prop="MaNVKT"
                        value={data?.MaNVKT}
                        placeholder="Chọn nhân viên MG"
                        options={staffs}
                        fieldNames={{ label: "hoTen", value: "maNV" }}
                      />

                      <p style={{ color: "red" }}>{errors?.MaNVKT}</p>
                    </Col>
                    <Col span={24}>
                      <SelectCustom
                        label="Nhân viên QL:"
                        applyChange={applyChange}
                        prop="MaNVKD"
                        value={data?.MaNVKD}
                        placeholder="Chọn nhân viên QL"
                        options={staffs}
                        fieldNames={{ label: "hoTen", value: "maNV" }}
                      />
                      <p style={{ color: "red" }}>{errors?.MaNVKD}</p>
                    </Col>
                    <Col span={24}>
                      <SelectCustom
                        label="Nguồn:"
                        applyChange={applyChange}
                        prop="MaNguon"
                        value={data?.MaNguon}
                        placeholder="Chọn nguồn"
                        options={dataNguon}
                        fieldNames={{ label: "Name", value: "ID" }}
                      />
                      <p style={{ color: "red" }}>{errors?.MaNguon}</p>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <p style={{ fontWeight: "600" }}>Ghi chú:</p>
                  <TextArea
                    value={data?.GhiChu}
                    onChange={e => applyChange("GhiChu", e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <SelectCustom
                label="Chọn địa điểm:"
                applyChange={applyChange}
                prop="MaTinh"
                value={data?.MaTinh}
                placeholder="Chọn địa điểm"
                options={
                  tinh?.map(item => ({
                    label: item.TenTinh,
                    value: item.MaTinh,
                  })) || []
                }
                onChange={e => setTinh(Number(e.target.value))}
              />
              <Flex style={{ marginTop: 16 }}>
                <p style={{ width: "55%" }}></p>
                <p style={{ width: "45%", fontWeight: "500" }}>
                  Địa điểm đã chọn:
                </p>
              </Flex>
              <Transfer
                dataSource={
                  huyen.map(item => ({
                    key: item.MaHuyen,
                    title: item.TenHuyen,
                    description: item.MaHuyen,
                  })) || []
                }
                targetKeys={data?.ListHuyen}
                onChange={v => applyChange("ListHuyen", v)}
                render={item => item.title}
              />
              <p style={{ color: "red" }}>{errors?.MaTinh}</p>
            </Col>
          </Row>
        </>
      </Drawer>
    </>
  )
}
export default forwardRef(FormAddNew)
