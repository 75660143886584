import { Box, useEditable } from "@chakra-ui/react"
import React, { useState, useImperativeHandle, forwardRef, useRef } from "react"
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Select,
  Checkbox,
  InputNumber,
  ColorPicker,
} from "antd"
import _ from "lodash"
import { DanhMucService } from "services/DanhMucService"
const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, dataIndex } = props
  // console.log(dataIndex,'dataIndex');
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState({})
  const [open, setOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("0")
  const [nganhang, setNganhang] = useState([])

  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  }
  const [errors, setErrors] = useState(defaultErrors)
  useImperativeHandle(ref, () => ({
    show: async tab => {
      setOpen(true)
      setActiveTab(tab)
      if (tab === "0") {
        // let _res = await OtherService.getNganHang()
        // setNganhang(_res.data ?? [])
        setNganhang([])
      }
    },
    update: async (data, tab) => {
      setOpen(true)
      setData(data)
      setActiveTab(tab)
    },
  }))
  const onClose = () => {
    setOpen(false)
    setData({})
  }
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data)
    _data[p] = v
    setData(_data)
  }

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const validate = () => {
    let _errors = _.cloneDeep(errors)
    Object.keys(_errors)?.map(item => {
      _errors[item] = data[item] ? null : "Không để trống"
    })
    setErrors(_errors)
    let valid = _.uniq(Object.values(_errors).filter(v => v))
    return valid
  }
  const onSubmit = async () => {
    setLoading(true)
    let _data = _.cloneDeep(data)
    let _res = null
    switch (activeTab) {
      case "0":
        _res = await DanhMucService.Nguon.add(_data)
        break

      case "1":
        _res = await DanhMucService.LoaiBDS.add(_data)
        break
      case "2":
        _res = await DanhMucService.phapLy.add(_data)
        break
      case "3":
        _res = await DanhMucService.LoaiDuong.add(_data)
        break
      case "4":
        _res = await DanhMucService.trangThai.add(_data)
        break
      case "5":
        _res = await DanhMucService.tinhTrang.add(_data)
        break
      case "6":
        _res = await DanhMucService.mucDichThue.add(_data)
        break
      case "7":
        _res = await DanhMucService.trangThaiMuaThue.add(_data)
        break
      case "8":
        _res = await DanhMucService.tienIch.add(_data)
        break
      case "9":
        _res = await DanhMucService.phanQuyenXemThongTin.add(_data)
        break
    }
    setLoading(false)
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      )
      onAfterSubmit()
      onClose()
    } else {
      openNotification("error", "topRight", _res?.message)
    }
  }
  const renderTab1 = () => {
    return (
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <p>Tên danh mục</p>
          <Input
            value={data?.Name}
            placeholder="Nhập tên danh mục"
            onChange={e => applyChange("Name", e.target.value)}
          />
        </Col>
      </Row>
    )
  }
  const renderTab2 = () => {
    return (
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <p>Tên danh mục</p>
          <Input
            value={data?.Name}
            placeholder="Nhập tên danh mục"
            onChange={e => applyChange("Name", e.target.value)}
          />
        </Col>

        <Col span={24}>
          <p>Màu nền</p>
          <ColorPicker
            value={data?.Color}
            onChangeComplete={color => {
              applyChange("Color", color.toHexString()) 
            }}
          />
        </Col>
      </Row>
    )
  }

  return (
    <Drawer
      title="Danh mục"
      width={activeTab === "0" ? 550 : 450}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          marginTop: 20,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}

      {activeTab === "4"
        ? renderTab2()
        : activeTab === "7"
        ? renderTab2()
        : renderTab1()}
    </Drawer>
  )
}
export default forwardRef(FormAdd)
