import React, { useEffect, useState, useCallback } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContractService } from "services/Contract"
import { InputText } from 'primereact/inputtext';
import Contract_Detail from './Contract_Detail'
const Contract = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const filter = (filter) => {
    setLoading(true)
    ContractService.filter({
      "TuNgay": "2019-01-04",
      "DenNgay": "2024-10-13",
      "inputSearch": "",
      "DuAn": "",
      "Offset": 1,
      "Limit": 50
    }).then((res) => {
      // _tableData.page = res.page;
      // 	_tableData.first = res.page * res.pageSize;
      // 	_tableData.size = res.pageSize;
      // 	_tableData.total = res.total;
      // let data = {
      //   content: res.data || [],
      //   total: res.data[0]?.totalRows,
      //   first: 
      // }
      setData(res?.data || [])
      setLoading(false)
    })
  }
  useEffect(() => {
    filter();
  }, []);
  const onPage = (e) => {
    // debugger
    filter()
  }
  const handleAdd = () => {
  }
  console.log(data);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Giữ chỗ| Beesky</title>
        </MetaTags>
        <Container fluid>
          <div style={{ background: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            className="p-2 my-2">
            <div>
              <Button
                type="button"
                color="success"
                onClick={handleAdd}
              >
                <i className="pi pi-filter-fil me-1" />

                {props.t("Add")}
              </Button>
            </div>
            <div>
              <span className="p-input-icon-right">
                <i className="pi pi-spin pi-spinner" />
                <InputText />
              </span>
            </div>
          </div>
          <Row>
            <Col xs="12">

              <Card>
                <CardBody>
                  <DataTable
                    value={data}
                    // selectionMode="single"
                    dataKey="maSP"
                    style={{ width: "100%" }}
                    className="p-datatable-gridlines p-datatable-paging border-none"
                    emptyMessage='Dữ liệu trống'
                    // scrollable
                    // scrollHeight="flex"
                    // resizableColumns 
                    // columnResizeMode="fit"
                    // lazy
                    // paginator
                    // paginatorTemplate="RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink"
                    // currentPageReportTemplate="{first} - {last} / {totalRecords}"
                    loading={loading}
                    // first={tableData.first}
                    // rows={tableData.size}
                    // totalRecords={tableData.total}
                    // rowsPerPageOptions={[20, 25, 50, 100]}
                    onPage={(event) => onPage(event)}
                  >
                    <Column field="daThu" header="STT"
                      body={(d, row) => <span>{row.rowIndex + 1}</span>}
                    />
                    <Column field="daThu" header="Đã thu"
                      style={{ width: '120px' }}
                    />
                    <Column field="tongGiaGomVAT" header="Tổng giá"
                      style={{ width: '120px' }} />
                    <Column field="phiBaoTri" header="Phí bảo trì" style={{ flex: '0 0 120px' }} />
                    <Column field="sanGD" header="Sàn" style={{ flex: '0 0 120px' }} />
                    <Column field="kyHieu" header="Sàn" style={{ width: '350px' }} />
                    <Column field="soHDMB" header="Số HDMB" style={{ flex: '0 0 120px' }} />
                    <Column field="tenTT" header="Tên TT" style={{ flex: '0 0 120px' }} />
                    <Column field="hoTenKH" header="Tên KH" style={{ flex: '0 0 120px' }} />
                    <Column field="soCMND" header="Số CCCD" style={{ flex: '0 0 120px' }} />
                    <Column field="noiCap" header="Nơi cấp" style={{ flex: '0 0 120px' }} />
                    <Column field="diaChi" header="Địa chỉ" style={{ flex: '0 0 120px' }} />
                    <Column field="thuongTru" header="Thường trú" style={{ flex: '0 0 120px' }} />
                    <Column field="email" header="Email" style={{ flex: '0 0 120px' }} />
                    <Column field="diDong" header="Di động" style={{ flex: '0 0 120px' }} />
                    <Column field="diDong2" header="Di động 2" style={{ flex: '0 0 120px' }} />
                    <Column field="tenDA" header="Tên DA" style={{ flex: '0 0 120px' }} />
                    <Column field="tenNVKD" header="Tên NVKD" style={{ flex: '0 0 120px' }} />
                    <Column field="nguoiTao" header="Người tạo" style={{ flex: '0 0 120px' }} />
                    <Column field="ngayNhap" header="Ngày tạo" style={{ flex: '0 0 120px' }} />
                  </DataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>
      <Contract_Detail /> 
    </React.Fragment>
  )
}

Contract.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Contract))
