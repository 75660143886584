import { del, get, post, put } from "../helpers/api_helper"
export const CongTyService = {
  getCongTy: async payload => {
    return await post("cong-ty/get", payload).then(res => res)
  },
  addCongTy: async payload => {
    return await post("cong-ty/add", payload).then(res => res)
  },
  addChiNhanh: async payload => {
    return await post("chi-nhanh/add", payload).then(res => res)
  },
  NhomModules: {
    Get: async () => {
      return await post("nhom-modules/get").then(res => res)
    },
    Add: async payload => {
      return await post("nhom-modules/add", payload).then(res => res)
    },
  },
}
