import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
  Container,
  CardBody,
  Card,
  Collapse,
  Media,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Form,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { Editor } from "react-draft-wysiwyg"
import { Link } from "react-router-dom"
import {
  addEditCustomer,
  getCustomers,
  getGhiChuCustomer,
  addGhiChuCustomer,
} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { handleValidDate } from "../../common/common"
const DetailModal = props => {
  const { isOpen, toggle, data } = props
  const [accor1, setAccor1] = useState(true)
  const [accor2, setAccor2] = useState(true)
  const [accor3, setAccor3] = useState(true)
  const [accor4, setAccor4] = useState(false)
  const [accor5, setAccor5] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const { historyCustomer } = useSelector(state => ({
    historyCustomer: state.Customer.historyCustomer,
  }))
  const dispatch = useDispatch()
  const onClickClose = () => {
    toggle()
  }
  const handleGhiChu = (e, values) => {
    const insert = {
      ID: null,
      MaKH: data.maKH,
      MaTT: 1,
      DienGiai: values.DienGiai,
    }
    // dispatch(addGhiChuCustomer(insert));
    // dispatch(getGhiChuCustomer({ MaKH: data.maKH }));
  }
  useEffect(() => {
    // dispatch(getGhiChuCustomer({ MaKH: data.maKH }))
  }, [data])
  return (
    <Modal className="mt-0 mb-0" size="xl" isOpen={isOpen}>
      <ModalHeader toggle={toggle} tag="h4">
        Thông tin chi tiết
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#f2f2f2" }}>
        <Container>
          <Row>
            <Col md="4">
              <div  className="rounded-0 border-0 shadow-lg bg-white p-2">
                <div className="accordion-item rounded-0 border-0 ">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button fw-medium"
                      type="button"
                      onClick={() => setAccor1(pre => !pre)}
                      style={{ cursor: "pointer" }}
                    >
                      THÔNG TIN CƠ BẢN
                    </button>
                  </h2>

                  <Collapse  isOpen={accor1} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <p>
                          <strong className="text-dark me-1">Ngày tạo:</strong>
                          {data.ngayDangKy}
                        </p>
                        <p>
                          <strong className="text-dark me-1">Mã KH:</strong>
                          {data.maSoKH}
                        </p>
                        <p>
                          <strong className="text-dark me-1">Họ tên:</strong>
                          {data.tenKH}
                        </p>
                        <p>
                          <strong className="text-dark me-1">
                            Số điện thoại:
                          </strong>
                          {data.diDong}
                        </p>
                        <p>
                          <strong className="text-dark me-1">Email:</strong>
                          {data.email},{data.email2}
                        </p>
                        <p>
                          <strong className="text-dark me-1">Địa chỉ:</strong>
                          {data.diaChi}
                        </p>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item rounded-0 border-0 ">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button fw-medium"
                      type="button"
                      onClick={() => setAccor2(pre => !pre)}
                      style={{ cursor: "pointer" }}
                    >
                      PHÂN LOẠI
                    </button>
                  </h2>

                  <Collapse isOpen={accor2} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <p>
                          <strong className="text-dark me-1">
                            Nhóm khách hàng:
                          </strong>
                          {data.tenNKH}
                        </p>
                        <p>
                          <strong className="text-dark me-1">
                            Trạng thái:
                          </strong>{" "}
                          {data.tenTT}
                        </p>{" "}
                        <p>
                          <strong className="text-dark me-1">Tên nguồn:</strong>{" "}
                          {data.tenNguon}
                        </p>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item rounded-0 border-0 ">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button fw-medium"
                      type="button"
                      onClick={() => setAccor3(pre => !pre)}
                      style={{ cursor: "pointer" }}
                    >
                      THÔNG TIN LIÊN HỆ
                    </button>
                  </h2>

                  <Collapse isOpen={accor3} className="accordion-collapse">
                    <div className="accordion-body">
                      <Card className="border ">
                        <CardBody>
                          <p>
                            <strong className="text-dark me-1">
                              Tên người LH:
                            </strong>
                            Nguyễn Văn B
                          </p>
                        </CardBody>
                      </Card>
                      <Button color="primary">Thêm người liên hệ</Button>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item rounded-0 border-0 ">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button fw-medium"
                      type="button"
                      onClick={() => setAccor4(pre => !pre)}
                      style={{ cursor: "pointer" }}
                    >
                      NHÂN SỰ PHỤ TRÁCH
                    </button>
                  </h2>

                  <Collapse isOpen={accor4} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <p>
                          <strong className="text-dark me-1">
                            Tên nhân viên:
                          </strong>
                          {data.nhanVienCS}
                        </p>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item rounded-0 border-0 ">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button fw-medium"
                      type="button"
                      onClick={() => setAccor5(pre => !pre)}
                      style={{ cursor: "pointer" }}
                    >
                      THÔNG TIN KHÁC
                    </button>
                  </h2>

                  <Collapse isOpen={accor5} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <p>
                          <strong className="text-dark me-1">Số CMND:</strong>
                          {data.soCMND}
                        </p>
                        <p>
                          <strong className="text-dark me-1">Nơi cấp:</strong>
                          {data.noiCap}
                        </p>
                        <p>
                          <strong className="text-dark me-1">Ngày cấp:</strong>
                          {data.ngayCap}
                        </p>
                        <p>
                          <strong className="text-dark me-1">
                            Thường trú:
                          </strong>
                          {data.thuongTru}
                        </p>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
            <Col md="8">
              <ul
                className="verti-timeline list-unstyled"
                style={{ borderLeft: "3px dashed #e0e0e0" }}
              >
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle font-size-18" />
                  </div>
                  <Media>
                    {/* <div className="me-3">
                      <h5 className="font-size-14">
                        22 Nov
                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                      </h5>
                    </div> */}
                    <Media body>
                      <Card>
                        <CardBody>
                          {/* <CardTitle className="mb-5">Activity</CardTitle> */}
                          <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={activeTab === "1" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("1")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Hoạt động
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={activeTab === "2" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("2")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Ghi chú
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={activeTab === "3" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("3")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Call</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={activeTab === "4" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("4")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">SMS</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={activeTab === "5" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("5")
                                }}
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">Email</span>
                              </NavLink>
                            </NavItem>
                          </Nav>

                          <TabContent
                            activeTab={activeTab}
                            className="p-3 text-muted"
                          >
                            <TabPane tabId="1">
                              <Row>
                                <Col sm="12">
                                  <CardText className="mb-0">
                                    Raw denim you probably haven't heard of them
                                    jean shorts Austin. Nesciunt tofu stumptown
                                    aliqua, retro synth master cleanse. Mustache
                                    cliche tempor, williamsburg carles vegan
                                    helvetica. Reprehenderit butcher retro
                                    keffiyeh dreamcatcher synth. Cosby sweater
                                    eu banh mi, qui irure terry richardson ex
                                    squid. Aliquip placeat salvia cillum iphone.
                                    Seitan aliquip quis cardigan american
                                    apparel, butcher voluptate nisi qui.
                                  </CardText>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="2">
                              <Row>
                                <Col sm="12">
                                  <AvForm onValidSubmit={handleGhiChu}>
                                    <AvField
                                      name="DienGiai"
                                      type="textarea"
                                      placeholder="Nội dung ghi chú. Tối đa 255 kí tự."
                                    />
                                    <Button color="primary" className="mt-3">
                                      Thêm ghi chú
                                    </Button>
                                  </AvForm>

                                  {/* <Form method="post">
                                    <Editor
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                    />
                                  </Form> */}
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Media>
                  </Media>
                </li>
                {activeTab === "2" &&
                  historyCustomer &&
                  historyCustomer.map((item, index) => {
                    return (
                      <li
                        key={item.id}
                        className={`event-list pb-4 ${
                          index === 0 ? "active" : ""
                        }`}
                      >
                        <div className="event-timeline-dot">
                          <i
                            className={`bx  font-size-18${
                              index === 0
                                ? " bxs-right-arrow-circle bx-fade-right"
                                : " bx-right-arrow-circle"
                            }`}
                          />
                        </div>
                        <Media>
                          <div className="me-3">
                            <h5 className="font-size-14">
                              {handleValidDate(item.ngayCN)}
                              <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                            </h5>
                          </div>
                          <Media body>
                            {/* <div id="activitytext"> */}
                            {item.dienGiai}
                            <p className="mb-0">
                              <strong>Nhân viên: </strong>Nguyễn Quang Hòa
                            </p>
                            {/* </div> */}
                          </Media>
                        </Media>
                      </li>
                    )
                  })}
                {/* 
                <li className="event-list active pb-4">
                  <div className="event-timeline-dot">
                    <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                  </div>
                  <Media>
                    <div className="me-3">
                      <h5 className="font-size-14">
                        15 Nov{" "}
                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                      </h5>
                    </div>
                    <Media body>
                      <div>Joined the group “Boardsmanship Forum”</div>
                    </Media>
                  </Media>
                </li> */}
              </ul>
              {/* <div className="text-center mt-4">
                <Link to="" className="btn btn-primary  btn-sm">
                  View More <i className="mdi mdi-arrow-right ms-1" />
                </Link>
              </div> */}
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <div className="text-end">
              <button
                className="btn btn-outline-primary save-user rounded-0 mr-1"
                onClick={() => onClickClose()}
              >
                Đóng lại
              </button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

DetailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default DetailModal
