import React from "react"

const ToolBar = ({ ...props }) => {
  const { left, right } = props
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "5px",
        backgroundColor:'white'
      }}
      className="  my-1"
    >
      {typeof left === "function" && left()}
      {typeof right === "function" && right()}
    </div>
  )
}
export default ToolBar
