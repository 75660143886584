import { call, put, takeEvery } from "redux-saga/effects"
// Staff Redux States
import * as types from "./actionTypes"
import { toast } from "react-toastify"
//Include Both Helper File with needed methods
import {
  getStaffs,
  getStaff,
  deleteStaffAPI,
  editStaffAPI,
  addStaffAPI,
  getCompanyAPI,
  getPhongBanAPI,
  getChucVuAPI,
  getNhomKDAPI,
  getNhomQuyenAPI,
  getMenuAPI,
} from "./staff.service"

function* fetchStaffs(action) {
  try {
    const response = yield call(getStaffs, action.payload)
    yield put({ type: types.GET_STAFFS_SUCCESS, payload: response.data })
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* fetchStaff(action) {
  try {
    const response = yield call(getStaff, action.payload)
    if(response.status === 2000){

      yield put({ type: types.GET_STAFF_SAGA_SUCCESS, payload: response.data })
    }
  } catch (error) {
  }
}
function* fetchStaffKD(action) {
  try {
    const response = yield call(getStaff, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.GET_STAFF_KD_SAGA_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* deleteStaff(action) {
  try {
    const response = yield call(deleteStaffAPI, action.payload)
    if (response.status === 2000) {
      toast.success("Xóa thành công!", {
        autoClose: 1500,
        className: "mt-5",
      })
      yield put({ type: types.DELETE_STAFF_SUCCESS, payload: action.payload })
    }
    // else{
    //   yield put({ type: types.DELETE_STAFF_FAIL, payload: response.message })
    // }
  } catch (error) {
    // yield put({ type: types.DELETE_STAFF_FAIL, payload: error })
  }
}

function* editStaff(action) {
  try {
    const response = yield call(editStaffAPI, action.payload)
    // if (response.status === 2000) { 
      
    //   yield put({ type: types.EDIT_STAFF_SUCCESS, payload: action.payload })
    // }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* addStaff(action) {
  try {
    const response = yield call(addStaffAPI, action.payload)
    // if (response.status === 2000) {
    //    toast.success("Thêm mới nhân viên thành công!", {
    //      autoClose: 1500,
    //      className: "mt-5",
    //    })
    //   yield put({ type: types.ADD_STAFF_SUCCESS, payload: action.payload })
    // }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* getCategories(action) {
  try {
    const response = yield call(getCompanyAPI, action.payload)
    const response1 = yield call(getPhongBanAPI)
    const response2 = yield call(getChucVuAPI)
    const response3 = yield call(getNhomKDAPI)
    const response4 = yield call(getNhomQuyenAPI)
    if (response.status === 2000) {
      yield put({
        type: types.GET_CATEGORIES_SUCCESS,
        payload: {
          companys: response.data,
          departments: response1.data,
          positions: response2.data,
          groups: response3.data,
          groupsPermission: response4.data,
        },
      })
    }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* getMenu(action) {
  try {
    const response = yield call(getMenuAPI)
    if (response.status === 2000) {
      yield put({ type: types.GET_MENU_SUCCESS, payload: response.data })
    }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
export default function* staffSaga() {
  yield takeEvery(types.GET_STAFFS, fetchStaffs)
  yield takeEvery(types.GET_STAFF_SAGA, fetchStaff)
  yield takeEvery(types.GET_STAFF_KD_SAGA, fetchStaffKD)
  yield takeEvery(types.DELETE_STAFF, deleteStaff)
  yield takeEvery(types.EDIT_STAFF, editStaff)
  yield takeEvery(types.ADD_STAFF, addStaff)
  yield takeEvery(types.GET_CATEGORIES, getCategories)
   yield takeEvery(types.GET_MENU, getMenu)
}
