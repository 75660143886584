import { del, get, post, put } from "../helpers/api_helper"
export const ContractService = {
  filter: async payload => {
    return await post("/admin/ListHopDong", payload)
  },
  getStatus: async payload => {
    //Type: 2= đặt cọc, 1= giữ chỗ
    return await post("/admin/hop-dong/trang-thai", payload)
  },
  Detail: {
    getLichTT: async payload => {
      //MaPGC
      return await post("/admin/ChiTietLichTT", payload)
    },
    getDocument: async payload => {
      //MaPGC
      return await post("admin/TaiLieuHopDong", payload)
    },
    getLichSuCN: async payload => {
      //MaPGC
      return await post("/admin/ChiTietChuyenNhuong", payload)
    },
    getThuChi: async payload => {
      //MaPGC
      return await post("/admin/ChiTietThuChi", payload)
    },
  },
  BanGiao: {
    getQuyBanGiao: async payload => {
      return await post("/admin/ListQuyBanGiao", payload)
    },
    getBBBanGiao: async payload => {
      return await post("admin/ListBanGiao", payload)
    },
    getSoDo: async payload => {
      return await post("admin/ListSoDo", payload)
    },
  },
  Liquidation: {
    filter: async payload => {
      return await post("/admin/ListThahLy", payload)
    },
  },
  Tranfer: {
    filter: async payload => {
      return await post("/admin/ListChuyenNhuong", payload)
    },
  },
  Deposit: {
    filter: async payload => {
      return await post("/admin/hop-dong/dat-coc", payload)
    },
  },
  Booking: {
    filter: async payload => {
      return await post("/admin/hop-dong/giu-cho", payload)
    },
    getImages: async payload => {
      return await post("/admin/hop-dong/anh-giu-cho", payload)
    },
    addImages: async payload => {
      return await post("/admin/hop-dong/add-anh-giu-cho", payload)
    },
    detailBooking: async payload => {
      return await post("admin/hop-dong/giu-cho/byid", payload)
    },
    deleteImage: async id => {
      return await get("/admin/hop-dong/delete-anh-giu-cho/" + id)
    },
  },
}
