import React from 'react'

const XemTongQuan = () => {
  return (
    <div className='page-content'>
      <h1>XemTongQuan</h1>
    </div>
  )
}

export default XemTongQuan
