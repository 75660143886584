import * as types from "./actionTypes"

export const getPermision = () => ({
  type: types.GET_PERMISSION
});

export const addPermision = (payload) => ({
  type: types.ADD_PERMISSION,
  payload
})

export const editPermision = (payload) => ({
  type: types.EDIT_PERMISSION,
  payload
})
export const deletePermision = payload => ({
  type: types.DELETE_PERMISSION,
  payload,
})
export const getListModule = payload => ({
  type: types.GET_LIST_MODULE,
  payload,
})
export const getPerSDBID = () => ({
  type: types.GET_PER_SDBID,
})
export const getFeatureHasName = payload => ({
  type: types.GET_FEATURE_HAS_NAME,
  payload,
})
export const getFormByModule = payload => ({
  type: types.GET_FORM_BY_MODULE,
  payload,
})
export const addAccessDataPer = payload => ({
  type: types.ADD_ACCESSDATA_PER,
  payload,
})
export const getAccessDataPer = payload => ({
  type: types.GET_ACCESSDATA_PER,
  payload,
})
export const addFeaturePer = payload => ({
  type: types.ADD_FEATURE_PER,
  payload,
})
export const deleteFeaturePer = payload => ({
  type: types.DELETE_FEATURE_PER,
  payload,
})