import { del, get, post, put } from "../helpers/api_helper"

export const DanhMucService = {
  Nguon: {
    get: async payload => {
      return await post("category/nguon/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/nguon/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/nguon/delete", payload).then(res => res)
    },
  },
  LoaiBDS: {
    get: async payload => {
      return await post("category/loaibds/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/loaibds/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/loaibds/delete", payload).then(res => res)
    },
  },
  phapLy: {
    get: async payload => {
      return await post("category/phaply/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/phaply/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/phaply/delete", payload).then(res => res)
    },
  },

  LoaiDuong: {
    get: async payload => {
      return await post("category/loaiduong/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/loaiduong/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/loaiduong/delete", payload).then(res => res)
    },
  },

  trangThai: {
    get: async payload => {
      return await post("category/trangthai/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/trangthai/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/trangthai/delete", payload).then(res => res)
    },
  },

  tinhTrang: {
    get: async payload => {
      return await post("category/tinhtrang/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/tinhtrang/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/tinhtrang/delete", payload).then(res => res)
    },
  },

  mucDichThue: {
    get: async payload => {
      return await post("category/mucdich/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/mucdich/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/mucdich/delete", payload).then(res => res)
    },
  },

  trangThaiMuaThue: {
    get: async payload => {
      return await post("category/trangthaimua/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/trangthaimua/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/trangthaimua/delete", payload).then(
        res => res
      )
    },
  },

  tienIch: {
    get: async payload => {
      return await post("category/tienich/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/tienich/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/tienich/delete", payload).then(res => res)
    },
  },

  phanQuyenXemThongTin: {
    get: async payload => {
      return await post("category/phanquyenxem/get", payload).then(res => res)
    },
    add: async payload => {
      return await post("category/phanquyenxem/add", payload).then(res => res)
    },
    delete: async payload => {
      return await post("category/phanquyenxem/delete", payload).then(
        res => res
      )
    },
  },
}
