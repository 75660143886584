import axios from "axios"
import { del, get, post, put } from "../../helpers/api_helper"

// add Events
export const getCustomersAPI = payload => post("/admin/Getlist_Customer", payload)
export const getCustomerAPI = payload => post("/admin/Customer/ViewChiTiet", payload)
export const addEditCustomersAPI = payload => post("/admin/AddCustom", payload)
export const deleteCustomerAPI = payload => post("/admin/DelteteCustomer", payload)

export const getGhiChuCustomerAPI = payload =>
  post("/admin/KhachHang/List_LichSuLamViec", payload)
export const addGhiChuCustomerAPI = payload =>
  post("/admin/KhachHang/AddLichSuLamViec", payload)

export const getQuyDanhAPI = () => post("/admin/QuyDanh")
export const getMucDichAPI = () => post("/admin/MucDichMua")
export const getAddressAPI = (payload) => post("/beeland/get-address",payload)
export const getNhomKH = () => post("/admin/NhomKH")
export const getNgheNghiep = () => post("/admin/NgheNghiep")
export const getTrangThai = () => post("/admin/khTrangThai")
export const getNguonDen = () => post("/admin/NguonDen")