import React, { useEffect, useState, useCallback } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import DeleteModal from "./DeleteModal"
import { Button } from "primereact/button"
import {
  Col,
  Container,
  Row,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
  getStaff,
  deleteStaff,
  getCategories,
} from "../../store/actions"
import DetailModal from "./DetailModal"
import FormModal from "./FormModal"
import ToolBar from "components/Toolbar"
import Pagination from "components/Common/Pagination"
import { StaffService } from 'services/Staff'
import LoadingBar from "components/LoadingBar"
import { InputText } from "primereact/inputtext"

const StaffIndex = props => {
  const dispatch = useDispatch()
  const { staffs } = useSelector(state => ({
    staffs: state.Staff.staffs,
  }))
  const selectRow = {
    mode: "checkbox",
  }
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState({})
  const [modal1, setModal1] = useState(false)
  const [staffForm, setStaffForm] = useState([])
  const [data, setData] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const defaultCondition = {
    inputSearch: "",
    Offset: 1,
    Limit: 20
  }
  const [filterCondition, setFilterCondition] = useState(defaultCondition);
  const toggleViewModal = useCallback(
    staff => {
      dispatch(getStaff(staff.maNV))
      setModal1(!modal1)
    },
    [modal1]
  )

  const StaffColumns = toggleModal => [
    {
      dataField: "stt",
      text: "STT",
      sort: true,
      formatter: (cellContent, row,i) => (
        <Link to="#" className="text-body fw-bold">
          {i+1}
        </Link>
      ),
    },
    {
      dataField: "hoTen",
      text: `${props.t("Full Name")}`,
      sort: true,
    },
    {
      dataField: "dienThoai",
      text: `${props.t("Phone")}`,
      sort: true,
    },
    {
      dataField: "email",
      text: `${props.t("Email")}`,
      sort: true,
      // formatter: (cellContent, row) => handleValidDate(row.orderdate),
    },
    {
      dataField: "tenCT",
      text: `${props.t("Company Name")}`,
      sort: true,
    },
    {
      dataField: "tenCV",
      text: "Tên CV",
      sort: true,
    },
    {
      dataField: "tenNKD",
      text: "Tên Nhóm KD",
      sort: true,
    },
    {
      dataField: "tenPB",
      text: "Tên PB",
      sort: true,
    },
    {
      style: {
        backgroundColor: 'white',
        position: 'sticky',
        right: 0,
        zIndex: 2,
        paddingLeft:'0px'
      },
      headerStyle: {
        backgroundColor: 'rgb(236, 240, 247)',
        position: 'sticky',
        right: 0,
        zIndex: 3
      },
      formatter: (cellContent, staff) => (
          <div className="d-flex gap-3" style={{boxShadow:'-5px 0 5px -5px #333'}}>
            {/* <Link
              to="#"
              className="text-info ps-2"
              onClick={() => toggleViewModal(staff)}
            >
              <i className="pi pi-eye" />
            </Link> */}
            <Link
              to="#"
              className="text-warning ps-3"
              onClick={() => handleStaffClick(staff)}
            >
              <i className="pi pi-pencil" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteStaff(staff.maNV)}
            >
              <i className="pi pi-trash" />
            </Link>
          </div>
      ),
    },
  ]
  useEffect(() => {
    // dispatch(ongetStaffs({ inputSearch: "", Offset: 1, Limit: 1000 }))
    dispatch(getCategories({ isSan: true }))
  }, [])
  const toggle = () => {
    setModal(!modal)
  }

  const handleStaffClick = staff => {
    setStaffForm({
      MaNV: staff.maNV,
      HoTen: staff.hoTen,
      MaSo: staff.maSo,
      SoCMND: staff.soCMND,
      NgayCap: staff.ngayCap,
      NoiCap: staff.noiCap,
      NgaySinh: staff.ngaySinh,
      DienThoai: staff.dienThoai,
      Email: staff.email,
      DiaChiLL: staff.diaChiLL,
      MaCT: staff.maCT,
      MaPB: staff.maPB,
      MaCV: staff.maCV,
      MaNKD: staff.maNKD,
      PerID: staff.perID,
    })
    setIsEdit(true)
    toggle()
  }
  const toggleDelete = () => {
    setModalDelete({
      isOpen: !modalDelete.isOpen
    })
  }
  const handleDeleteStaff = useCallback((maNV) => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(maNV),
    })
  }, [])
  const onClickDelete = useCallback((maNV) => {
    dispatch(deleteStaff({ MaNV: maNV }))
  }, [])

  const handleAddStaff = () => {
    setStaffForm([])
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  const filter = async (filter) => {
    setLoading(true);
    let res = await StaffService.filter(filter)
    setData(res.data || []);
    setLoading(false);
  }
  useEffect(() => {
    (async () => {
      await filter(filterCondition);
    })()
  }, [])
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "page":
        _filter.Offset = v;
        break;
      case "itemPerPage":
        _filter.Offset = 1;
        _filter.Limit = v;
        break;
      default:
        _filter[p] = v;
    }
    setFilterCondition(_filter);
    filter(_filter)
  }
  return (
    <React.Fragment>
      <DetailModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        data={modalDelete}
        // isOpen={modalDelete}
        toggle={toggleDelete}
      />
      <FormModal
        isEdit={isEdit}
        isOpen={modal}
        toggle={toggle}
        staffList={staffForm}
      />
      <div className="page-content">
        <MetaTags>
          <title>Nhân viên | Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <div>
                <Button label="Thêm"
                  icon="mdi mdi-plus"
                  className="p-button-outlined p-button-success mr-1 my-1"
                  onClick={handleAddStaff} />
              </div>
            )}
            right={() => (
               <span className="p-input-icon-left">
                        <InputText
                            value={filterCondition?.inputSearch}
                            onInput={(e) =>
                              onChangeFilter("inputSearch", e.target.value)
                            }
                            placeholder='Tìm kiếm'
                        />
                    </span>
            )}
          />
          <ToolkitProvider
            keyField="maNV"
            data={data}
            columns={StaffColumns(toggle)}
            bootstrap4
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <LoadingBar loading={loading} />
                <div className="table-responsive table-scrollable shadow-2">
                  <BootstrapTable
                    keyField="stt"
                    responsive
                    bordered={false}
                    striped={false}
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                    classes={
                      "table align-middle table-nowrap table-check table-fixed table-striped table-bordered "
                    }
                    headerWrapperClasses={"table-light"}
                    {...toolkitProps.baseProps}
                  />
                </div>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <select
                        className="form-select"
                        value={filterCondition.Limit}
                      onChange={e => onChangeFilter('itemPerPage', Number(e.target.value))}
                      >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </select>
                    </div>
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <Pagination
                        totalPage={Math.ceil(
                          data[0]?.totalRows / filterCondition.Limit
                        )}
                        changePage={(i) => onChangeFilter('page', i)}
                      />
                    </Col>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Container>
      </div>
    </React.Fragment>
  )
}

StaffIndex.propTypes = {
  staffs: PropTypes.array,
  ongetStaffs: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(StaffIndex))
