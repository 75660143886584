import axios from "axios"
import { del, get, post, put } from "../../helpers/api_helper"

// House for lease

// For sale
export const getForSaleAPI = payload => post("/admin/CanBan/List_MGLCanBan", payload)
export const addEditForSaleAPI = payload => post("/admin/CanBan/Add", payload)
export const deleteForSaleAPI = payload => post("/admin/CanBan/Delete", payload)
export const getImageForsaleAPI = payload =>
  post("/admin/CanBan/List_imageMGL", payload)
  export const addImageForsaleAPI = payload =>
    post("/admin/CanBan/upload-image", payload)

export const addCustomerForSaleAPI = payload => post("/admin/CanBan/add-customer", payload)

// export const getLoaiBDSAPI = () => post("/admin/CanBan/LoaiBDS")
export const getDuongAPI = (payload) => post("/admin/CanBan/Streets",payload)
export const getLoaiDuongAPI = () => post("/admin/CanBan/LoaiDuong")
export const getPhuongHuongAPI = () => post("/admin/CanBan/PhuongHuong")
export const getNguonGocAPI = () => post("/admin/CanBan/NguonGoc")
// export const getTrangThaiAPI = () => post("/admin/CanBan/TrangThai")
export const getPhapLyAPI = () => post("/admin/CanBan/list-phap-ly")
export const getQuyHoachAPI = () => post("/admin/CanBan/QuyHoach")
export const getAddressAPI = payload => post("/beeland/get-address", payload)
export const getStreetAPI = payload => post("/admin/CanBan/Streets", payload)
export const searchCustomerAPI = payload => post("/beeland/search-customer", payload)

//categories
export const getKhoangGiaAPI = () => post("/admin/CanBan/KhoangGia")
export const getKhoangDTAPI = () => post("/admin/CanBan/KhoangDT")
export const getDuAnAPI = payload => post("/beeland/get-project", payload);