import React, { useRef, useState, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Calendar } from 'primereact/calendar';
import _ from "lodash"
import { ProjectService } from 'services/Project';
import { Dropdown } from 'primereact/dropdown';

function ChungCu_Filter(props, ref) {
    const { filterCondition, afterChangeFilter } = props;
    const refFilterPanel = useRef(null);
    const [projects, setProjects] = useState([]);
    const [khu, setKhu] = useState([]);
    const [phanKhu, setPhanKhu] = useState([]);
    const [status, setStatus] = useState([]);
    const applyChangeFilter = (prop, val) => {
        let _filterCondition = _.cloneDeep(filterCondition);
        if (prop) {
            switch (prop) {
                case "MaDA":
                    _filterCondition[prop] = val;
                    getKhu(val);
                    break;
                case "MaKhu":
                    _filterCondition[prop] = val;
                    getPhanKhu(val);
                    break;
                default:
                    _filterCondition[prop] = val;
                    break;
            }
            afterChangeFilter(_filterCondition)
        }
    };
    useEffect(() => {
        ProjectService.filter({}).then((res) => setProjects(res?.data))
        ProjectService.getProductStatus().then((res) => setStatus(res?.data))
    }, [])
    const getKhu =async (MaDA) => {
        ProjectService.getKhu({ MaDA: Number(MaDA) }).then((res) => setKhu(res?.data))
    }
    const getPhanKhu =async (MaKhu) => {
        ProjectService.getPhanKhu({ MaKhu: Number(MaKhu) }).then((res) => setPhanKhu(res?.data))
    }
    const clearFilter =() =>{
        let _filterCondition = _.cloneDeep(filterCondition);
        _filterCondition.MaDA = -1;
        _filterCondition.MaKhu = -1;
        _filterCondition.MaPK = -1;
        _filterCondition.MaTT = -1;
        _filterCondition.KyHieu = "";
        afterChangeFilter(_filterCondition)
    }
    try {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <span className="p-input-icon-left">
                        {/* <i className="bx bx-search-alt" /> */}
                        <InputText
                            value={filterCondition?.inputSearch}
                            onInput={(e) =>
                                applyChangeFilter("inputSearch", e.target.value)
                            }
                            placeholder='Tìm kiếm'
                        />
                    </span>
                    <Button icon="pi pi-filter-fill"
                        className="p-button-rounded p-button-text p-button-plain"
                        onClick={(e) => refFilterPanel.current.toggle(e)}
                        aria-label="Filter" />
                    
                </div>
                <OverlayPanel
                    ref={refFilterPanel}
                    className="x-menu"
                    style={{ width: "600px" }}
                >
                    <div className="grid formgrid p-fluid  ">
                        <div className='col-6 mb-2'>
                            <label htmlFor="icon">Dự án</label>
                            <Dropdown value={filterCondition.MaDA} options={projects}
                                onChange={(e) => applyChangeFilter("MaDA", e.value)}
                                filter
                                optionLabel="TenDA"
                                optionValue='MaDA' />
                        </div>
                        <div className='col-6 mb-2'>
                            <label htmlFor="icon">Khu</label>
                            <Dropdown value={filterCondition.MaKhu} options={khu}
                                onChange={(e) => applyChangeFilter("MaKhu", e.value)}
                                filter
                                optionLabel="TenKhu"
                                optionValue='MaKhu' />
                        </div>
                        <div className='col-6 mb-2'>
                            <label htmlFor="icon">Phân khu</label>
                            <Dropdown value={filterCondition.MaPK} options={phanKhu}
                                onChange={(e) => applyChangeFilter("MaPK", e.value)}
                                filter
                                optionLabel="TenPK"
                                optionValue='MaPK' />
                        </div>
                        <div className='col-6 mb-2'>
                            <label htmlFor="icon">Trạng thái</label>
                            <Dropdown value={filterCondition.MaTT} options={status}
                                onChange={(e) => applyChangeFilter("MaTT", e.value)}
                                filter
                                optionLabel="TenTT"
                                optionValue='MaTT' />
                        </div>
                    </div>
                    <Button icon="pi pi-filter-slash"
                        className="p-button-raised p-button-danger p-button-text"
                        onClick={(e) => clearFilter()}
                        label='Xóa lọc'
                        aria-label="Filter" />
                </OverlayPanel>
            </React.Fragment>
        )
    }
    catch (err) {
        return null
    }

}
export default ChungCu_Filter;
