import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { NavLink } from "react-router-dom"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"

import { CheckCircleOutlined } from "@ant-design/icons"
import { CongTyService } from "services/CongTyService"
import { PermissionService } from "services/PermissionService"
import { NhanVienService } from "services/NhanVienService"
import { CRMService } from "services/CRMService"

const Register = props => {
  const dispatch = useDispatch()

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(registerUser(values))
  }

  useEffect(() => {
    dispatch(apiError(""))
  }, [])
  const textColor = useColorModeValue("navy.700", "white")
  const textColorSecondary = "gray.400"
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600")
  const textColorBrand = useColorModeValue("brand.500", "white")
  const brandStars = useColorModeValue("brand.500", "brand.400")
  const [show, setShow] = React.useState(false)
  const [loading2, setLoading2] = React.useState(false)
  const [step, setStep] = useState(1)
  const defaultData = {
    Server: "beesky",
    TenCT: "",
    MaCT: "",
    MaNhomSP: 1,
    DienThoai1: "",
    OTP: "",
    Password: "",
    RePassword: "",
  }
  const [data, setData] = useState(defaultData)
  const [otp, setOtp] = useState("")
  const [err, setErr] = useState("")
  const handleClick = () => setShow(!show)
  const [nhomsp, setNhomsp] = useState([])
  function checkString(inputString) {
    // Kiểm tra tiếng Việt có dấu
    const vietnameseWithTone =
      /[àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ]/
    // Kiểm tra ký tự đặc biệt
    const specialCharacter = /[^a-zA-Z0-9\s]/
    // Kiểm tra khoảng trắng
    const whitespace = /\s/
    return (
      vietnameseWithTone.test(inputString) ||
      specialCharacter.test(inputString) ||
      whitespace.test(inputString)
    )
  }
  function isValidVietnamesePhoneNumber(phoneNumber) {
    const vietnamesePhoneRegex = /^0[35789]\d{8}$/
    return vietnamesePhoneRegex.test(phoneNumber)
  }
  useEffect(() => {
    CongTyService.NhomModules.Get().then(res => {
      setNhomsp(res?.data ?? [])
    })
    // setNhomsp([{ TenSanPham: "test" }])
  }, [])
  const login = async () => {
    try {
      if (data?.DienThoai1?.length === 0 || data?.TenCT?.length === 0) {
        setErr("Nhập đầy đủ thông tin")
        return
      }
      let phone = isValidVietnamesePhoneNumber(data?.DienThoai1)
      if (phone === false) {
        setErr("Vui lòng nhập đúng số điện thoại")
        return
      } else {
        setErr("")
      }
      setLoading2(true)
      let _otp = Math.floor(Math.random() * 9999)
      setOtp(_otp.toString())
      let _res = await CRMService.Zalo.SendOTP({
        Phone: data?.DienThoai1,
        OTP: _otp.toString(),
      })
      
      if (_res.status === 2000) {
        setStep(2)
      } else {
        setErr(_res.message)
      }
      setLoading2(false)
    } catch {}
  }
  const confirmOTP = async () => {
    try {
      if (data?.OTP !== otp) {
        setErr("Vui lòng nhập đúng mã OTP gửi về điện thoại của bạn!")
        return
      }
      setErr("")
      setStep(3)
    } catch {}
  }
  const handleDone = async () => {
    try {
      if (data?.MaCT?.length === 0 || data?.Password?.length === 0) {
        setErr("Vui lòng nhập đầy đủ thông tin!")
        return
      }
      if (data?.MaCT?.length < 4) {
        setErr("Mã gian hàng phải lớn hơn 3 ký tự!")
        return
      }
      let a = checkString(data?.MaCT)
      if (a === true) {
        setErr("Mã gian hàng chỉ được nhập chữ không dấu và chữ số!")
        return
      }
      if (data.Password !== data?.RePassword) {
        setErr("Mật khẩu chưa trùng khớp. Vui lòng kiểm tra lại!")
        return
      }
      let _payload = {
        ...data,
        TaiKhoan: data.DienThoai1,
        MatKhau: data?.Password,
      }
      setLoading2(true)
      let _res = await CongTyService.addCongTy(_payload)
      if (_res.status === 2000) {
        let _resPer = await PermissionService.addNhomQuyen({
          PerName: "admin",
          Description: "admin",
          MaCTDK: _res.data,
          Server: data.Server,
        })
        let _resCN = await CongTyService.addChiNhanh({
          TenCTVT: data.MaCT,
          TenCT: data.TenCT,
          DiaChi: "",
          Email: "",
          DienThoai: data.DienThoai1,
          MaCTDK: _res.data,
          Server: data.Server,
        })
        if (_resPer.status === 2000) {
          let _n = await PermissionService.TheoNhomModules.getFormByMaNhom({
            MaNhom: data.MaNhomSP,
          })
          await Promise.all(
            _n?.data?.map(async item => {
              await PermissionService.addAccessData({
                HavePer: true,
                PerID: _resPer.data,
                FormID: item.FormID,
                SDBID: 1,
                Server: data.Server,
              })
              await PermissionService.TheoCongTy.addForm({
                MaCTDK: _res.data,
                Server: data.Server,
                FormID: item.FormID,
                HavePer: true,
              })
            })
          )
          let _resnv = await NhanVienService.addNhanVien({
            HoTen: "admin",
            MaSo: data.DienThoai1,
            Password: data.Password,
            PerID: _resPer.data,
            MaCT: _resCN.data,
            NgayCap: new Date(),
            NgaySinh: new Date(),
            MaCTDK: _res.data,
            Email: "",
            DienThoai: "",
            Server: data.Server,
          })
          if (_resnv?.status === 2000) {
            setStep(4)
          }
        }
        setErr("")
      } else {
        setErr(_res.message)
        setLoading2(false)
        return
      }
      setStep(4)
      setLoading2(false)
    } catch {
      setLoading2(false)
    }
  }
  const applyChange = (p, v) => {
    let _data = Object.assign({}, data)
    switch (p) {
      default:
        _data[p] = v
        break
    }
    setData(_data)
  }

  const renderStep1 = () => {
    return (
      <>
        <div className="bg-primary bg-soft">
          <Row>
            <Col className="col-7">
              <div className="text-primary p-4">
                <h5 className="text-primary">Đăng kí Free</h5>
                <p>Đăng kí để có thể đăng nhập.</p>
              </div>
            </Col>
            <Col className="col-5 align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <div className="p-5">
          <AvForm className="form-horizontal">
            <div className="mb-3">
              <AvField
                label="Tên công ty/ Shop*"
                name="TenCTDKVT"
                type="text"
                required
                placeholder="Nhập tên công ty"
                isRequired={true}
                value={data.TenCT}
                onChange={e => applyChange("TenCT", e.target.value)}
              />
            </div>
            <div className="mb-3">
              <AvField
                id=""
                name="MaNhomSP"
                label="Nhóm sản phẩm*"
                className="form-control"
                type="select"
                required
                value={data.MaNhomSP}
                onChange={e => applyChange("MaNhomSP", e.target.value)}
              >
                <option value="" disabled>
                  Select sản phẩm
                </option>
                {nhomsp.map((sp, index) => (
                  <option key={index} value={index}>
                    {sp.TenSanPham}
                  </option>
                ))}
              </AvField>
            </div>

            <div className="mb-3">
              <AvField
                name="phoneNumber"
                label="Số điện thoại*"
                placeholder="Nhập số điện thoại"
                value={data.DienThoai1}
                onChange={e => applyChange("DienThoai1", e.target.value)}
              />
              {err && <p style={{ color: "red" }}>{err}</p>}
            </div>

            <div className="mt-3 d-grid">
              <button
                onClick={() => login()}
                className="btn btn-primary btn-block"
              >
                Đăng ký
              </button>
            </div>
          </AvForm>
        </div>
      </>
    )
  }
  const renderStep2 = () => {
    return (
      <>
        <div className="bg-primary bg-soft">
          <Row>
            <Col className="col-10">
              <div className="text-primary p-4">
                <h4 className="text-primary">Nhập mã OTP</h4>
                <p>Vui lòng kiểm tra zalo hoặc tin nhắn điện thoại!</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="p-5">
          <AvForm className="form-horizontal">
            <div className="mb-3">
              <AvField
                name="OTP"
                label="Mã OTP*"
                type=""
                required
                placeholder="Nhập mã OTP"
                value={data.OTP}
                onChange={e => applyChange("OTP", e.target.value)}
              />
              {err && <p style={{ color: "red" }}>{err}</p>}
            </div>

            <div className="mt-3 d-grid">
              <button
                isLoading={loading}
                onClick={() => confirmOTP()}
                className="btn btn-primary btn-block"
              >
                Xác nhận
              </button>
            </div>
          </AvForm>
        </div>
      </>
    )
  }
  const renderStep3 = () => {
    return (
      <>
        <div className="bg-primary bg-soft">
          <Row>
            <Col className="col-10">
              <div className="text-primary p-4">
                <h4 className="text-primary">Thông tin đăng nhập </h4>
                <p>Bước cuối cùng. Vui lòng nhập thông tin để đăng nhập!</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="p-5">
          <AvForm className="form-horizontal">
            <div className="mb-3">
              <AvField
                name="MaCT"
                label="Mã công ty*"
                type=""
                required
                placeholder="Min 4. Chữ không dấu và chữ số!"
                value={data.MaCT}
                onChange={e => applyChange("MaCT", e.target.value)}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="password"
                label="Mật khẩu*"
                type={show ? "text" : "password"}
                required
                placeholder="12345678"
                value={data.Password}
                onChange={e => applyChange("Password", e.target.value)}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="password"
                label="Nhập lại mật khẩu*"
                type={show ? "text" : "password"}
                required
                placeholder="12345678"
                value={data.RePassword}
                onChange={e => applyChange("RePassword", e.target.value)}
              />
            </div>
            {err && <p style={{ color: "red" }}>{err}</p>}

            <div className="mt-3 d-grid">
              <button
                className="btn btn-primary btn-block"
                isLoading={loading}
                onClick={() => handleDone()}
              >
                Hoàn Thành
              </button>
            </div>
          </AvForm>
        </div>
      </>
    )
  }
  const renderStep4 = () => {
    return (
      <>
        <div className="box-search" style={{ height: 300, padding: 40 }}>
          {/* <IoCheckmarkDoneCircle size={30} color="#52c41a" /> */}
          <p className="label" style={{ fontSize: 20 }}>
            Công ty của bạn đã được tạo thành công{" "}
            <CheckCircleOutlined style={{ color: "#28A745" }} />
          </p>
          <p style={{ fontStyle: "italic", color: "#8c8c8c" }}>Mã công ty</p>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>{data?.MaCT}</p>
          <p style={{ fontStyle: "italic", color: "#8c8c8c" }}>Tên đăng nhập</p>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>
            {data?.DienThoai1}
          </p>
          <p style={{ fontStyle: "italic", color: "#8c8c8c" }}>Mật khẩu</p>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>
            {data.Password}
          </p>
        </div>

        <Row className="justify-content-center mb-4">
          <Col md={6} className="d-grid mt-3">
            <button className="btn btn-primary btn-block">
              <NavLink
                style={{ color: "#fff", width: "100%", textAlign: "center" }}
                to="/auth/sign-in"
              >
                Bắt đầu quản lý
              </NavLink>
            </button>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Đăng ký | Beesky - Admin</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {step === 1
                  ? renderStep1()
                  : step === 2
                  ? renderStep2()
                  : step === 3
                  ? renderStep3()
                  : renderStep4()}
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Bạn đã có tài khoản?
                  <Link to="/login" className="font-weight-medium text-primary">
                    Đăng nhập
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} Beesky. Crafted with
                  <i className="mdi mdi-heart text-danger" /> by Beesky
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
