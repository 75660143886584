import React, { useEffect, useState, useCallback } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-datepicker/dist/react-datepicker.css"
import Breadcrumbs from "../../components/Common/Breadcrumb3"
import {
  addKhachHang,
  getDuAn,
  getLoaiTinTuc,
  addTinTuc,
  uploadImage,
} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { withRouter,useHistory, useLocation } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// import MediaEmbed from '/src/mediaembed';



import { del, get, post, put, postImg } from "../../helpers/api_helper"
const FormTinTuc = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [noiDung, setNoiDung] = useState("")
  const [linkImg, setLinkImg] = useState("")
  const location = useLocation()
  const { duAn, loaiTinTuc } = useSelector(state => ({
    dataEdit: state.Agency.dataEdit,
    duAn: state.Agency.duAn,
    loaiTinTuc: state.TinTuc.loaiTinTuc,
  }))
  useEffect(() => {
    dispatch(getDuAn())
    dispatch(getLoaiTinTuc())
  }, [])
  const handleValidOrderSubmit = (e, values) => {
    if (Number(values.MaDA) === 0) {
      return
    }
    if (Number(values.MaLTin) === 0) {
      return
    }
    // debugger
    const matin = location.state ? location.state.maTin : null
    const add = {
      MaTin: matin,
      MaDA: Number(values.MaDA),
      MaLTin: Number(values.MaLTin),
      ViTri: Number(values.ViTri),
      TieuDe: values.TieuDe,
      MoTa: values.MoTa,
      imgIcon:
        linkImg.length > 0
          ? linkImg
          : location.state
          ? location.state?.imgIcon2
          : "",
      NoiDung: noiDung,
      isView: values.checkView,
      IsBanner: values.checkBanner,
      isHome: values.checkHome,
    }
    dispatch(
      addTinTuc({
        data: add,
        history,
      })
    )
  }
  let model = {
    checkView: location.state?.isView === true ? true : false,
    checkBanner: location.state?.isBanner === true ? true : false,
    checkHome: location.state?.isHome === true ? true : false,
  }
  const fileOnChange = async e => {
    if (e.target.files[0]) {
      let form = new FormData()
      form.append("Images", e.target.files[0], e.target.files[0].name)
      const res = await postImg("/beeland/upload-image", form)
      if (res.status === 2000) {
        setLinkImg(res.data[0]?.linkImage)
      }
    }
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Thêm tin tức | Beesky</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg="12">
            {/* <Card> */}
            {/* <CardBody> */}
            <AvForm onValidSubmit={handleValidOrderSubmit} model={model}>
              <Breadcrumbs
                title="Danh sách tin tức"
                route="/news"
                breadcrumbItem="Thêm/sửa tin tức"
              />
              <Row>
                <Col lg="9">
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Col md="12">
                          <AvField
                            required
                            name="TieuDe"
                            label="Tiêu đề"
                            type="text"
                            value={location.state?.tieuDe || ""}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="12">
                          <AvField
                            name="MoTa"
                            label="Mô tả"
                            type="textarea"
                            value={location.state?.moTa || ""}
                          />
                        </Col>
                      </FormGroup>
                      <h5>Nội dung</h5>
                      
                      <CKEditor
                        style={{ minHeight: "500px" }}
                        editor={ClassicEditor}
                       
                        data={location.state?.noiDung}
                        // onReady={editor => {
                        //   // You can store the "editor" and use when it is needed.
                        //   console.log("Editor is ready to use!", editor)
                        // }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setNoiDung(data)
                        }}
                        // onBlur={(event, editor) => {
                        //   console.log("Blur.", editor)
                        // }}
                        // onFocus={(event, editor) => {
                        //   console.log("Focus.", editor)
                        // }}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <div className="rounded border-0 shadow-lg bg-white">
                    {linkImg.length == 0 ? (
                      location.state?.imgIcon.length > 0 ? (
                        <img
                          className="rounded-top"
                          style={{ width: "100%", height: "150px" }}
                          src={location.state?.imgIcon}
                        />
                      ) : (
                        <Col md="12" style={{ height: "150px" }}>
                          <p className="text-center pt-5">Thêm ảnh đại diện</p>
                        </Col>
                      )
                    ) : (
                      <img
                        className="rounded-top"
                        style={{ width: "100%", height: "150px" }}
                        src={`https://quanlykinhdoanh.com.vn/httpdocs/Upload/REM/DEMO41/${linkImg}`}
                      />
                    )}
                    <div class="mb-3">
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        onChange={e => fileOnChange(e)}
                      />
                    </div>
                    {/* <input type="file" onChange={e => fileOnChange(e)} /> */}
                    <FormGroup className="p-2" row>
                      <Col md="12">
                        <AvField
                          required
                          name="MaDA"
                          // label="Sàn giao dịch"
                          type="select"
                          className="form-select"
                          value={location.state?.maDA}
                          // onChange={e => onChangeTinh(e)}
                        >
                          <option value={0}>Chọn dự án</option>
                          {duAn &&
                            duAn.map(item => {
                              return (
                                <option
                                  key={item.MaDA}
                                  value={Number(item.MaDA)}
                                >
                                  {item.TenDA}
                                </option>
                              )
                            })}
                        </AvField>
                      </Col>
                    </FormGroup>
                    <FormGroup className="p-2" row>
                      <Col md="12">
                        <AvField
                          required
                          name="MaLTin"
                          // label="Sàn giao dịch"
                          type="select"
                          className="form-select"
                          value={location.state?.maLTin}
                          // onChange={e => onChangeTinh(e)}
                        >
                          <option value={0}>Chọn loại tin</option>
                          {loaiTinTuc &&
                            loaiTinTuc.map(item => {
                              return (
                                <option
                                  key={item.MaLoai}
                                  value={Number(item.MaLoai)}
                                >
                                  {item.TenLoai}
                                </option>
                              )
                            })}
                        </AvField>
                      </Col>
                    </FormGroup>
                    <FormGroup className="p-2" row>
                      <Col md="12">
                        <AvField
                          name="ViTri"
                          placeholder="Vị trí"
                          type="number"
                          value={location.state?.viTri || ""}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="p-2" row>
                      <Col md="4">
                        <AvGroup>
                          <Label>
                            <AvInput type="checkbox" name="checkView" /> Hiển
                            thị
                          </Label>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Label>
                            <AvInput type="checkbox" name="checkBanner" />{" "}
                            Banner
                          </Label>
                        </AvGroup>
                      </Col>
                      <Col md="4">
                        <AvGroup>
                          <Label>
                            <AvInput type="checkbox" name="checkHome" /> Home
                          </Label>
                        </AvGroup>
                      </Col>
                    </FormGroup>

                    <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                  </div>
                </Col>
              </Row>
            </AvForm>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(FormTinTuc)
