import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
// import NumericFormat from "react-number-format"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  InputGroup,
} from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Breadcrumbs from "../../../components/Common/Breadcrumb3"
import {
  getCategoriesForSale,
  getStreet,
  getCustomers,
  getStaffs,
  addEditForSale,
  getCustomer,
  getStaffSaga,
  getStaffKDSaga,
} from "../../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { handleValidDate, Format_Currency } from "../../../common/common"
import { useHistory } from "react-router-dom"

const Form = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectKHValue, setSelectKHValue] = useState(null)
  const [selectKHOption, setSelectKHOption] = useState([])

  const [selectNVQLValue, setSelectNVQLValue] = useState(null)
  const [selectNVQLOption, setSelectNVQLOption] = useState([])

  const [selectNVKDValue, setSelectNVKDValue] = useState(null)

  const [maTinh, setMaTinh] = useState(0)
  const [maHuyen, setMaHuyen] = useState(0)
  const [maXa, setMaXa] = useState(0)
  const [dienTich, setDienTich] = useState("")
  const [donGia, setDonGia] = useState("")
  const [thanhTien, setThanhTien] = useState("")

  const {
    loaiBDS,
    loaiDuong,
    phuongHuong,
    nguonGoc,
    trangThai,
    phapLy,
    quyHoach,
    address,
    street,
    customers,
    customer,
    dataEdit,
    staffSearch,
    staffQL,
    staffKD,
  } = useSelector(state => ({
    loaiBDS: state.Agency.loaiBDS,
    loaiDuong: state.Agency.loaiDuong,
    phuongHuong: state.Agency.phuongHuong,
    nguonGoc: state.Agency.nguonGoc,
    trangThai: state.Agency.trangThai,
    phapLy: state.Agency.phapLy,
    quyHoach: state.Agency.quyHoach,
    address: state.Agency.address,
    street: state.Agency.street,
    customers: state.Customer.customers,
    customer: state.Customer.customer,
    dataEdit: state.Agency.dataEdit,
    staffSearch: state.Staff.staffs,
    staffQL: state.Staff.staffQL,
    staffKD: state.Staff.staffKD,
  }))
  useEffect(() => {
    if (!dataEdit) {
      //add
      //  dispatch(getCustomer({ MaKH: 0 }))
      //  console.log(dataEdit)
    } else {
      //edit
      setMaTinh(dataEdit?.maTinh)
      setMaHuyen(dataEdit?.maHuyen)
      setMaXa(dataEdit?.maXa)
      setDienTich(dataEdit?.dienTich)
      setDonGia(dataEdit?.donGia)
      dispatch(getCustomer({ MaKH: dataEdit?.maKH }))
      dispatch(getStaffSaga({ MaNV: dataEdit?.maNVQL }))
      dispatch(getStaffKDSaga({ MaNV: dataEdit?.maNVKD }))
    }
    dispatch(getCategoriesForSale())
  }, [])
  const handleValidOrderSubmit = (e, values) => {
    // if (!dataEdit) {
    //   //add
    //   console.log(dataEdit)
    // } else {
    //   //edit
    //   console.log(dataEdit)
    // }
    // debugger
    const insertAndUpdate = {
      TenKH: values.TenKH,
      DiDong: values.DiDong,
      DiaChi: values.DiaChi,
      Email: values.Email,

      MaBC: !dataEdit ? null : dataEdit?.maBC,
      MaLBDS: Number(values.MaLBDS) === "" ? null : Number(values.MaLBDS),
      NgangXD: Number(values.NgangXD),
      MaQuyHoach: null,
      SoTang: Number(values.SoTang),
      MaPL: Number(values.MaPL) === 0 ? null : Number(values.MaPL),
      MaXa: Number(values.MaXa) === 0 ? null : Number(values.MaXa),
      MaHuyen: Number(values.MaHuyen) === 0 ? null : Number(values.MaHuyen),
      MaTinh: Number(values.MaTinh) === 0 ? null : Number(values.MaTinh),
      MaDuong: Number(values.MaDuong) === 0 ? null : Number(values.MaDuong),
      TenDuong: values.TenDuong,
      DuongRong: Number(values.DuongRong),
      IsBan: values.IsBan === "" ? false : values.IsBan,
      DienTich: Number(dienTich.toString().replaceAll(",", "")),
      DonGia: Number(donGia.toString().replaceAll(",", "")),
      ThanhTien: Number(thanhTien),
      TyLeMG: Number(values.TyLeMG),
      CachTinhPMG: Number(values.CachTinhPMG),
      DaiXD: Number(values.DaiXD),
      SauXD: Number(values.SauXD),
      KyHieu: values.KyHieu,
      MaHuong: Number(values.MaHuong) === 0 ? null : Number(values.MaHuong),
      HuongBanCong:
        Number(values.HuongBanCong) === "" ? null : Number(values.HuongBanCong),
      SoDK: values.SoDK,
      SoNha: values.SoNha,
      DacTrung: values.DacTrung,
      MaLD: Number(values.MaLD) === 0 ? null : Number(values.MaLD),
      MaNVKD:
        selectNVKDValue === null ? selectNVKDValue : selectNVKDValue.value,
      MaNVQL:
        selectNVQLValue === null ? selectNVQLValue : selectNVQLValue.value,
      MaTT: Number(values.MaTT) === "" ? null : Number(values.MaTT),
      MaNguon: Number(values.MaNguon) === "" ? null : Number(values.MaNguon),
      ThuongLuong: values.ThuongLuong === "" ? false : values.ThuongLuong,
      TangHam: values.TangHam === "" ? false : values.TangHam,
      IsCanGoc: values.IsCanGoc === "" ? false : values.IsCanGoc,
      DauOto: values.DauOto === "" ? false : values.DauOto,
      IsThangMay: values.IsThangMay === "" ? false : values.IsThangMay,
    }
    dispatch(addEditForSale({ data: insertAndUpdate, history }))
  }
  const getHuyen = () => {
    return address?.find(item => {
      return item.maTinh === maTinh
    })?.listHuyen
  }
  const getXa = () => {
    return getHuyen()?.find(item => {
      return item.maHuyen === maHuyen
    })?.listXa
  }
  const onChangeTinh = e => {
    setMaTinh(Number(e.target.value))
  }
  const onChangeHuyen = e => {
    setMaHuyen(Number(e.target.value))
  }
  const onChangeXa = e => {
    setMaXa(Number(e.target.value))
  }
  useEffect(() => {
    // dispatch(getStreet({ DistrictID: maXa }))
  }, [maXa])

  const changeSearchKH = e => {
    setSelectKHValue(e)
  }
  useEffect(() => {
    if (!!dataEdit) {
      setSelectKHValue({
        label: `${customer[0]?.tenKH} - ${customer[0]?.diDong}`,
        value: customer[0]?.maKH,
        tenKH: customer[0]?.tenKH,
        diDong: customer[0]?.diDong,
        email: customer[0]?.email,
        diaChi: customer[0]?.diaChi,
      })
    }
  }, [customer])
  useEffect(() => {
    let arr = []
    if (customers != "[]") {
      customers?.map(item => {
        arr.push({
          label: `${item.tenKH} - ${item.diDong}`,
          value: item.maKH,
          tenKH: item.tenKH,
          diDong: item.diDong,
          email: item.email,
          diaChi: item.diaChi,
        })
      })
      setSelectKHOption(arr)
    }
  }, [customers])
  const onKeyDownSearchKH = e => {
    dispatch(
      getCustomers({
        inputSearch: e,
        MaTT: 0,
        MaNVCS: 0,
        MaNKH: 0,
        Offset: 1,
        Limit: 10,
      })
    )
  }

  const changeSearchNVQL = e => {
    setSelectNVQLValue(e)
  }
  useEffect(() => {
    if (!!dataEdit) {
      if (!!staffQL[0]) {
        setSelectNVQLValue({
          label: `${staffQL[0]?.hoTen} -${staffQL[0]?.maSo}- ${staffQL[0]?.dienThoai}`,
          value: staffQL[0]?.maNV,
        })
      } 
    } 
  }, [staffQL])

  useEffect(() => {
    let arr = []
    if (staffSearch != "[]") {
      staffSearch?.map(item => {
        arr.push({
          label: `${item.hoTen} -${item.maSo}- ${item.dienThoai}`,
          value: item.maNV,
        })
      })
      setSelectNVQLOption(arr)
    }
  }, [staffSearch])
  const onKeyDownSearchNV = e => {
    dispatch(
      getStaffs({
        inputSearch: e,
        Offset: 1,
        Limit: 10,
      })
    )
  }


  const changeSearchNVKD = e => {
    setSelectNVKDValue(e)
  }
useEffect(() => {
  if (!!dataEdit) {
    if (!!staffKD[0]) {
      setSelectNVKDValue({
        label: `${staffKD[0]?.hoTen} -${staffKD[0]?.maSo}- ${staffKD[0]?.dienThoai}`,
        value: staffKD[0]?.maNV,
      })
    }
  }
}, [staffKD])
  const onChangeDienTich = e => {
    setDienTich(e.target.value)
  }
  const onChangeDonGia = e => {
    setDonGia(e.target.value)
  }
  const onChangeThanhTien = e => {
    // setDonGia(e.target.value)
  }
  useEffect(() => {
    setThanhTien(
      donGia?.toString().replaceAll(",", "") *
        dienTich?.toString().replaceAll(",", "")
    )
  }, [donGia, dienTich])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Thêm mới | Beesky</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg="12">
            {/* <Card> */}
            {/* <CardBody> */}
            <AvForm onValidSubmit={handleValidOrderSubmit}>
              <Breadcrumbs
                title="Cần bán"
                route="/forsale"
                breadcrumbItem="Thêm mới"
              />
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="MaTinh"
                            label="Tỉnh"
                            type="select"
                            className="form-select"
                            value={dataEdit?.maTinh || 0}
                            onChange={e => onChangeTinh(e)}
                          >
                            <option value={0}>Chọn</option>
                            {address &&
                              address.map(item => {
                                return (
                                  <option
                                    key={item.maTinh}
                                    value={Number(item.maTinh)}
                                  >
                                    {item.tenTinh}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaHuyen"
                            type="select"
                            label="Huyện"
                            className="form-select"
                            value={dataEdit?.maHuyen || null}
                            onChange={e => onChangeHuyen(e)}
                          >
                            <option value={0}>Chọn</option>
                            {getHuyen() &&
                              getHuyen().map(item => {
                                return (
                                  <option
                                    key={item.maHuyen}
                                    value={Number(item.maHuyen)}
                                  >
                                    {item.tenHuyen}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaXa"
                            label="Xã"
                            type="select"
                            className="form-select"
                            value={maXa}
                            onChange={e => onChangeXa(e)}
                          >
                            <option value={0}>Chọn</option>
                            {getXa() &&
                              getXa().map(item => {
                                return (
                                  <option
                                    key={item.MaXa}
                                    value={Number(item.MaXa)}
                                  >
                                    {item.TenXa}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="MaDuong"
                            label="Đường"
                            type="select"
                            className="form-select"
                            value={dataEdit?.maDuong || null}
                            // onChange={e => onChangeTinh(e)}
                          >
                            <option value={null}>Chọn</option>
                            {street &&
                              street.map(item => {
                                return (
                                  <option key={item.ID} value={item.ID}>
                                    {item.Names}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="TenDuong"
                            label="Tên đường"
                            type="text"
                            errorMessage="Nhập tên đường"
                            validate={{
                              required: { value: true },
                            }}
                            value={dataEdit?.duong || ""}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="SoNha"
                            label="Số nhà"
                            type="text"
                            errorMessage="Nhập số nhà"
                            validate={{
                              required: { value: true },
                            }}
                            value={dataEdit?.soNha || ""}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <Label>Diện tích</Label>
                          <InputGroup>
                            {/* <NumericFormat
                              value={dienTich}
                              className="is-untouched is-pristine av-valid form-control"
                              displayType="input"
                              decimalSeparator="."
                              thousandSeparator={true}
                              onValueChange={values => {
                                const { formattedValue, value } = values
                                setDienTich(formattedValue)
                              }}
                            /> */}
                            <div className="input-group-text">m2</div>
                          </InputGroup>
                        </Col>

                        <Col md="4">
                          <Label>Đơn giá</Label>
                          <InputGroup>
                            {/* <NumericFormat
                              value={donGia}
                              className="is-untouched is-pristine av-valid form-control"
                              displayType="input"
                              decimalSeparator="."
                              thousandSeparator={true}
                              onValueChange={values => {
                                const { formattedValue, value } = values
                                setDonGia(formattedValue)
                              }}
                            /> */}
                            <div className="input-group-text">vnđ</div>
                          </InputGroup>
                        </Col>
                        <Col md="4">
                          <Label>Thành tiền</Label>
                          <InputGroup>
                            {/* <NumericFormat
                              value={thanhTien}
                              className="is-untouched is-pristine av-valid form-control"
                              displayType="input"
                              decimalSeparator="."
                              onChange={e => onChangeThanhTien(e)}
                              thousandSeparator={true}
                              // disabled
                            /> */}
                            <div className="input-group-text">vnđ</div>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="MaLBDS"
                            label="Loại bất động sản"
                            type="select"
                            className="form-select"
                            errorMessage="Vui lòng chọn loại BĐS"
                            validate={{
                              required: { value: true },
                            }}
                            value={dataEdit?.maLBDS || null}
                          >
                            <option value={null}>Chọn</option>
                            {loaiBDS &&
                              loaiBDS.map(item => {
                                return (
                                  <option key={item.maLBDS} value={item.maLBDS}>
                                    {item.tenLBDS}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaLD"
                            type="select"
                            label="Loại đường"
                            className="form-select"
                            value={dataEdit?.maLD || null}
                            // onChange={e => onChangeHuyen(e)}
                          >
                            <option value={null}>Chọn</option>
                            {loaiDuong &&
                              loaiDuong.map(item => {
                                return (
                                  <option key={item.maLD} value={item.maLD}>
                                    {item.tenLD}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaPL"
                            label="Pháp lý"
                            type="select"
                            className="form-select"
                            value={dataEdit?.maPL || null}
                          >
                            <option value={null}>Chọn</option>
                            {phapLy &&
                              phapLy.map(item => {
                                return (
                                  <option key={item.maPL} value={item.maPL}>
                                    {item.tenPL}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                      </FormGroup>
                      <FormGroup  row>
                        <Col md="12">
                          <AvField
                            name="DacTrung"
                            label="Đặc trưng"
                            type="text"
                            value={dataEdit?.dacTrung || ""}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Col md="6">
                          <Label>Khách hàng</Label>
                          <Select
                            name="MaKH"
                            value={selectKHValue}
                            onChange={e => changeSearchKH(e)}
                            options={selectKHOption}
                            isLoading={true}
                            onInputChange={e => onKeyDownSearchKH(e)}
                          />
                        </Col>
                        <Col md="6">
                          <AvField
                            name="TenKH"
                            label="Tên khách hàng"
                            type="text"
                            value={selectKHValue?.tenKH || ""}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="4">
                          <AvField
                            name="DiDong"
                            label="Di động"
                            type="number"
                            value={selectKHValue?.diDong || null}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="Email"
                            label="Email"
                            type="email"
                            value={selectKHValue?.email || null}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="DiaChi"
                            label="Địa chỉ"
                            type="text"
                            value={selectKHValue?.diaChi || null}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="SoDK"
                            label="Số ĐK"
                            type="text"
                            value={dataEdit?.soDK || ""}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="KyHieu"
                            label="Ký hiệu"
                            type="text"
                            // errorMessage="Vui lòng nhập ký hiệu"
                            // validate={{
                            //   required: { value: true },
                            // }}
                            value={dataEdit?.kyHieu || ""}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaTT"
                            label="Trạng thái"
                            type="select"
                            className="form-select"
                            value={dataEdit?.maTT || null}
                          >
                            {/* <option value={null}>Chọn</option> */}
                            {trangThai &&
                              trangThai.map(item => {
                                return (
                                  <option key={item.maTT} value={item.maTT}>
                                    {item.tenTT}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="3">
                          <AvField
                            name="SauXD"
                            label="Sau XD"
                            type="number"
                            value={dataEdit?.sauXD || null}
                          />
                        </Col>
                        <Col md="3">
                          <AvField
                            name="DaiXD"
                            label="DaiXD"
                            type="number"
                            value={dataEdit?.daiXD || null}
                          />
                        </Col>
                        <Col md="3">
                          <AvField
                            name="NgangXD"
                            label="NgangXD"
                            type="number"
                            value={dataEdit?.ngangXD || null}
                          />
                        </Col>
                        <Col md="3">
                          <AvField
                            name="SoTang"
                            label="Số tầng"
                            type="number"
                            value={dataEdit?.soTang || null}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="3">
                          <AvField
                            name="CachTinhPMG"
                            label="Cách tính PMG"
                            type="text"
                            value={
                              (dataEdit && String(dataEdit?.cachTinhPMG)) || 0
                            }
                          />
                        </Col>
                        <Col md="3">
                          <AvField
                            name="TyLeMG"
                            label="Tỷ lệ MG"
                            type="number"
                            value={dataEdit?.tyLeMG || null}
                          />
                        </Col>
                        <Col md="3">
                          <AvField
                            name="DuongRong"
                            label="Đường rộng"
                            type="number"
                            value={dataEdit?.duongRong || null}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="MaQuyHoach"
                            label="Quy hoạch"
                            type="select"
                            className="form-select"
                            value={dataEdit?.maQuyHoach || null}
                            // onChange={e => onChangeTinh(e)}
                          >
                            <option value={null}>Chọn</option>
                            {quyHoach &&
                              quyHoach.map(item => {
                                return (
                                  <option key={item.id} value={Number(item.id)}>
                                    {item.tenQuyHoach}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaHuong"
                            label="Phương hướng"
                            type="select"
                            value={dataEdit?.maHuongCua || ""}
                          >
                            <option value={null}>Chọn</option>
                            {phuongHuong &&
                              phuongHuong.map(item => {
                                return (
                                  <option
                                    key={item.maPhuongHuong}
                                    value={item.maPhuongHuong}
                                  >
                                    {item.tenPhuongHuong}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          <AvField
                            name="MaNguon"
                            type="select"
                            label="Nguồn"
                            className="form-select"
                            value={dataEdit?.maNguon || null}
                          >
                            <option value={null}>Chọn</option>
                            {nguonGoc &&
                              nguonGoc.map(item => {
                                return (
                                  <option
                                    key={item.maNguon}
                                    value={item.maNguon}
                                  >
                                    {item.tenNguon}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="4">
                          <AvField
                            name="HuongBanCong"
                            label="Hướng ban công"
                            type="select"
                            value={dataEdit?.maHuongBC || null}
                          >
                            <option value={null}>Chọn</option>
                            {phuongHuong &&
                              phuongHuong.map(item => {
                                return (
                                  <option
                                    key={item.maPhuongHuong}
                                    value={item.maPhuongHuong}
                                  >
                                    {item.tenPhuongHuong}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                        <Col md="4">
                          {/* <FormGroup className="mb-4" row> */}
                          <Label>Nhân viên quản lý</Label>
                          <Select
                            name="MaNV"
                            value={selectNVQLValue}
                            onChange={e => changeSearchNVQL(e)}
                            options={selectNVQLOption}
                            // isLoading={true}
                            onInputChange={e => onKeyDownSearchNV(e)}
                          />
                          {/* </FormGroup> */}
                        </Col>
                        <Col md="4">
                          <Label>Nhân viên kinh doanh</Label>
                          <Select
                            value={selectNVKDValue}
                            onChange={e => changeSearchNVKD(e)}
                            options={selectNVQLOption}
                            // isLoading={true}
                            onInputChange={e => onKeyDownSearchNV(e)}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Col md="3">
                          <Label>Bán</Label>
                          <AvRadioGroup
                            name="IsBan"
                            inline
                            defaultValue={true}
                            value={dataEdit?.isBan || false}
                          >
                            <AvRadio label="Có" value={true} />
                            <AvRadio label="Không" value={false} />
                          </AvRadioGroup>
                        </Col>
                        <Col md="3">
                          <Label>Thương lượng</Label>
                          <AvRadioGroup
                            name="ThuongLuong"
                            inline
                            defaultValue={false}
                            value={dataEdit?.thuongLuong || false}
                          >
                            <AvRadio label="Có" value={true} />
                            <AvRadio label="Không" value={false} />
                          </AvRadioGroup>
                        </Col>
                        <Col md="3">
                          <Label>Tầng hầm</Label>
                          <AvRadioGroup
                            name="TangHam"
                            inline
                            defaultValue={false}
                            value={dataEdit?.tangHam || false}
                          >
                            <AvRadio label="Có" value={true} />
                            <AvRadio label="Không" value={false} />
                          </AvRadioGroup>
                        </Col>
                        <Col md="3">
                          <Label>Căn góc</Label>
                          <AvRadioGroup
                            name="IsCanGoc"
                            inline
                            defaultValue={false}
                            value={dataEdit?.isCanGoc || false}
                          >
                            <AvRadio label="Có" value={true} />
                            <AvRadio label="Không" value={false} />
                          </AvRadioGroup>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="3">
                          <Label>Đậu ô tô</Label>
                          <AvRadioGroup
                            name="DauOto"
                            inline
                            defaultValue={false}
                            value={dataEdit?.dauOto || false}
                          >
                            <AvRadio label="Có" value={true} />
                            <AvRadio label="Không" value={false} />
                          </AvRadioGroup>
                        </Col>
                        <Col md="3">
                          <Label>Thang máy</Label>
                          <AvRadioGroup
                            name="IsThangMay"
                            inline
                            defaultValue={false}
                            value={dataEdit?.isThangMay || false}
                          >
                            <AvRadio label="Có" value={true} />
                            <AvRadio label="Không" value={false} />
                          </AvRadioGroup>
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <Row className="justify-content-end">
                <Col lg="12">
                  <Button type="submit" color="primary">
                    Lưu
                  </Button>
                </Col>
              </Row> */}
            </AvForm>
            {/* </CardBody> */}
            {/* </Card> */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(Form)
