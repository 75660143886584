import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Format_Currency, formatNumberPT } from "../../common/common"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-datepicker/dist/react-datepicker.css"
import Breadcrumbs from "../../components/Common/Breadcrumb3"
import {
  getCustomers,
  getCustomer,
  addKhachHang,
  getSanGiaoDich,
} from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { ProductService } from "services/Product"
import { color } from "framer-motion"

const AddGiuChoFromBangGia = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const [selectKHValue, setSelectKHValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectKHOption, setSelectKHOption] = useState([])
  const [data, setData] = useState({})
  const getProduct = () => {
    ProductService.DatNen.getById({ MaSP: Number(params.MaSP) }).then(_res => {
      if (_res.status === 2000) setData(_res.data)
    })
  }
  const { customers, listSanGiaoDich } = useSelector(state => ({
    address: state.Agency.address,
    customers: state.Customer.customers,
    customer: state.Customer.customer,
    dataEdit: state.Agency.dataEdit,
    listSanGiaoDich: state.Common.listSanGiaoDich,
  }))
  useEffect(() => {
    dispatch(getCustomer({ MaKH: 0 }))
    dispatch(getSanGiaoDich())
    getProduct()
  }, [])
  const handleValidOrderSubmit = (e, values) => {
    const addKH = {
      TenKH: values.TenKH,
      DiDong: values.DiDong,
      DiaChi: values.DiaChi,
      Email: values.Email,
      SoCMND: values.SoCMND,
      MaXa: 0, //quanlykinhdoanhNumber(values.MaXa) === 0 ? null : Number(values.MaXa),
      MaHuyen: 0, //Number(values.MaHuyen) === 0 ? null : Number(values.MaHuyen),
      MaTinh: 0, // Number(values.MaTinh) === 0 ? null : Number(values.MaTinh),
    }

    const dataBK = {
      MaSP: data?.MaSP,
      MaSan: Number(values.MaSan),
      MaDA: data?.MaDA,
      TongGiaGomPBT: data?.TongGiaTriHDMB,

      DTThongThuy: data?.DTTimTuong,
      DonGiaTT: data?.DonGiaThongThuy,
      TongGiaGomVAT: data?.TongGiaGomVAT,
      PhiBaoTri: data?.PhiBaoTri,

      DienTichDat: data?.DienTichDat,
      DonGiaDat: data?.DonGiaDat,
      TongGiaDat: data?.TongGiaDat,
      DienTichXD: data?.DienTichXD,
      DonGiaXD: data?.DonGiaXD,
      ThanhTienXD: data?.ThanhTienXD,
    }
    dispatch(
      addKhachHang({
        dataKH: addKH,
        dataBoking: dataBK,
        history,
        type: 3,
      })
    )
  }
  useEffect(() => {
    let arr = []
    // debugger
    if (customers != "[]") {
      customers?.map(item => {
        arr.push({
          label: `${item.tenKH} - ${item.diDong} - ${item.email}`,
          value: item.maKH,
          tenKH: item.tenKH,
          diDong: item.diDong,
          email: item.email,
          diaChi: item.diaChi,
        })
      })
      setSelectKHOption(arr)
    }
  }, [customers])
  const onKeyDownSearchKH = e => {
    setLoading(true)
    dispatch(
      getCustomers({
        inputSearch: e,
        MaTT: 0,
        MaNVCS: 0,
        MaNKH: 0,
        Offset: 1,
        Limit: 10,
      })
    )
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  const changeSearchKH = selectedOption => {
    setSelectKHValue(selectedOption)
    console.log(selectedOption)
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Giữ chỗ | Beesky</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg="8">
            {/* <Card> */}
            {/* <CardBody> */}
            <AvForm onValidSubmit={handleValidOrderSubmit}>
              <Breadcrumbs
                title="Bảng giá"
                route="/banggiatructuyen"
                breadcrumbItem="Thêm giữ chỗ"
              />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-4" row>
                        <Col md="12">
                          <Label>Khách hàng</Label>
                          <Select
                            styles={{
                              singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "#333",
                              }),
                            }}
                            name="MaKH"
                            value={selectKHValue}
                            onChange={e => changeSearchKH(e)}
                            options={selectKHOption}
                            isLoading={loading}
                            onInputChange={e => onKeyDownSearchKH(e)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="6">
                          <AvField
                            name="TenKH"
                            label="Tên khách hàng"
                            type="text"
                            value={selectKHValue?.tenKH || ""}
                          />
                        </Col>
                        <Col md="6">
                          <AvField
                            name="SoCMND"
                            label="Số CMND"
                            type="text"
                            value={selectKHValue?.soCMND || ""}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            name="DiDong"
                            label="Di động"
                            type="number"
                            value={selectKHValue?.diDong || null}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="Email"
                            label="Email"
                            type="email"
                            value={selectKHValue?.email || null}
                          />
                        </Col>
                        <Col md="4">
                          <AvField
                            name="DiaChi"
                            label="Địa chỉ"
                            type="text"
                            value={selectKHValue?.diaChi || null}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Col md="4">
                          <AvField
                            required
                            name="MaSan"
                            label="Sàn giao dịch"
                            type="select"
                            className="form-select"
                          >
                            <option value={0}>Chọn</option>
                            {listSanGiaoDich &&
                              listSanGiaoDich.map(item => {
                                return (
                                  <option key={item.ID} value={Number(item.ID)}>
                                    {item.TenCT}
                                  </option>
                                )
                              })}
                          </AvField>
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </Col>
          <Col lg="4">
            <div className="rounded border-0 shadow-lg bg-white">
              <div>
                <img
                  className="rounded-top"
                  style={{ width: "100%", height: "200px" }}
                  src={data?.HinhAnh}
                />
                <h5 className="text-center fw-bold mt-2">{data?.tenDA}</h5>
              </div>
              <div className="row p-3" style={{ lineHeight: "200%" }}>
                {data.MaLBDS === 1 ? (
                  <>
                    <Col lg="7">Mã sản phẩm</Col>
                    <Col lg="5" className="fw-bold">
                      {data.KyHieu}
                    </Col>
                    <Col lg="7">Hướng</Col>
                    <Col lg="5" className="fw-bold">
                      {data?.TenPhuongHuong}
                    </Col>
                    <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7">Diện tích đất</Col>
                    <Col className="fw-bold" lg="5">
                      {data?.DienTichDat} m2
                    </Col>
                    <Col lg="7">Đơn giá đất</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.DonGiaDat)}
                    </Col>
                    <Col lg="7">Thành tiền đất</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data.ThanhTienDat)}
                    </Col>
                    <Col lg="7">Diện tích XD</Col>
                    <Col lg="5" className="fw-bold">
                      {data?.DienTichXD} m2
                    </Col>
                    <Col lg="7">Đơn giá XD</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.DonGiaXD)}
                    </Col>
                    <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7">Thành tiền XD có VAT</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.ThanhTienXDCoVAT)}
                    </Col>
                    <Col lg="7">VAT</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.TienVATXD)}
                    </Col>
                    <hr className=" mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7" className="fw-bold">
                      Tổng giá trị HĐ
                    </Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.TongGiaTriHDMB)}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg="7">Mã sản phẩm</Col>
                    <Col lg="5" className="fw-bold">
                      {data?.KyHieu}
                    </Col>
                    <Col lg="7">Hướng</Col>
                    <Col lg="5" className="fw-bold">
                      {data?.TenPhuongHuong}
                    </Col>
                    <Col lg="7">Tầng</Col>
                    <Col lg="5" className="fw-bold">
                      {data?.Tang}
                    </Col>
                    <hr className="mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7">Diện tích thông thủy</Col>
                    <Col className="fw-bold" lg="5">
                      {data?.DTTimTuong} m2
                    </Col>
                    <Col lg="7">Đơn giá thông thủy</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.DonGiaThongThuy)}
                    </Col>
                    <Col lg="7">Thành tiền gổm VAT</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.TongGiaGomVAT)}
                    </Col>
                    <Col lg="7">Phí bảo trì</Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.PhiBaoTri)}
                    </Col>

                    <hr className=" mt-3 bg-danger border-2 border-top border-danger" />
                    <Col lg="7" className="fw-bold">
                      Tổng giá gồm PBT
                    </Col>
                    <Col lg="5" className="fw-bold">
                      {Format_Currency(data?.TongGiaTriHDMB)}
                    </Col>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default React.memo(AddGiuChoFromBangGia)
