import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Table,
  notification,
  Row,
  Col,
  Input,
  Tag,
  Tooltip,
  Flex,
  Popconfirm,
  Space,
  Avatar,
} from "antd"
import FilterModal from "./FilterModal"
import { EditTwoTone, DeleteOutlined } from "@ant-design/icons"
// import FormAdd from "./FormAdd";
// import { Format_Date } from "layouts/ultis/common";
// import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash"
import "./scss/index.scss"
// import Toolbar from "components/Toolbar";
// import { BanHangService } from "services/BanHangService";
// import { OtherService } from "services/OtherService";
// import InHoaDon from "./InHoaDon";
// import { IoMdPrint } from "react-icons/io";
// import { useReactToPrint } from "react-to-print";
// import { MdCancel } from "react-icons/md";
import ExcelJS from "exceljs"
// import { Format_Datetime } from "layouts/ultis/common";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons"
import ToolBar from "components/Toolbar"
import { Format_Currency } from "common/common"
import FormAddNew from "./FormAddNew"
import { SanPhamService } from "services/SanPhamService"
import Pagination from "components/Common/Pagination"
import { Badge } from "reactstrap"
import { Format_Date } from "pages/Utility/common"

export default function NhuCauMuaThue() {
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    InputSearch: "",
    DuAn: ",,",
    Offset: 1,
    Limit: 20,
    MaTT: 1,
    IsMua: true,
  })

  // badge filter
  const [stateActive, setStateActive] = useState({
    ID: 0,
    Name: "Tất cả",
    Color: "gray",
  })
  const changeState = state => {
    if (state === 0) {
      // setData(dataAll)
    } else {
      // let _data = dataAll.filter(v => v.MaTT === state)
      // setData(_data)
    }
  }

  const state = {
    NEW: "Mới",
    ACCEPT: "Đã thanh toán",
    CANCEL: "Đã huỷ",
    REJECT: "Từ chối",
    RETURN: "Đã huỷ",
  }
  const stateColor = {
    NEW: "blue",
    PAYMENT: "cyan",
    CANCEL: "orange",
    REJECT: "red",
    ACCEPT: "green",
    RETURN: "orange",
  }
  const refDetail = useRef(null)

  useEffect(() => {
    filter(filterCondition)
  }, [])
  const filter = async _filter => {
    let _res = await SanPhamService.NhuCauMuaThue.get(_filter)
    if (_res.data) {
      setData(_res.data)
    }
  }
  const onChangeFilter = _filter => {
    filter(_filter)
    setFilterCondition(_filter)
  }
  const onClickchangePage = (p, v) => {
    let _filter = _.cloneDeep(filterCondition)
    _filter[p] = v
    setFilterCondition(_filter)
    filter(_filter)
  }

  const onAfterSubmit = () => {
    filter(filterCondition)
  }

  const columns = [
    {
      title: "Số ĐK",
      dataIndex: "SoDK",
    },
    {
      title: "Nhu cầu",
      dataIndex: "MaNhuCau",
    },
    {
      title: "Loại BĐS",
      dataIndex: "TenLBDS",
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
    }, 
    {
      title: "Diện tích(m2)",
      render: (item)=> <p>Từ {item.DienTichTu} đến {item.DienTichDen}</p>
    },
    {
      title: "Khoảng giá",
      render: (item)=> <p>Từ {item.GiaTu} đến {item.GiaDen}</p>
    },
    {
      title: "Phòng ngủ",
      render: (item)=> <p>Từ {item.PhNguTu} đến {item.PhNguDen}</p>

    },
    {
      title: "Hướng cửa",
      dataIndex: "HuongCua",
    },
   
    {
      title: "Ngày ĐK",
      dataIndex: "NgayDK",
      render: (item)=> Format_Date(item)

    },
    {
      title: "Khách hàng",
      dataIndex: "HoTenKH",
    },
    {
      title: "Điện thoai",
      dataIndex: "DienThoai",
    },
    {
      title: "Nhân viên QL",
      dataIndex: "HoTenNV",
    },
    {
      title: "Nhân viên MG",
      dataIndex: "HoTenNVKD",
    },
    {
      fixed: "right",
      width: "100px",
      render: item => (
        <div>
          <Tooltip placement="topRight" title="Sửa">
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<EditTwoTone />}
              onClick={() => refDetail.current.update(item)}
              // onClick={() => console.log(item)}
            />
          </Tooltip>
          <Popconfirm
            title="Xoá danh mục"
            description="Bạn có chắc chắn muốn xoá danh mục?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.ID)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button
                style={{ marginLeft: 10 }}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ]
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const handleCancel = async value => {
    return
    setLoading(true)
    let _res = await BanHangService.changeState({
      ID: value.ID,
      State: "RETURN",
      TongTien: value?.KhachTra,
      MaKH: value.MaKH,
      LyDo: "Huỷ hoá đơn bán hàng",
      MaLPC: 4,
      The: [],
    })
    if (_res.status === 2000) {
      filter(filterCondition)
      openNotification("success", "topRight", `Huỷ hoá đơn thành công!`)
    } else {
      openNotification("error", "topRight", _res?.message)
    }
    setLoading(false)
  }
  const [dataprint, setDataprint] = useState({ SanPhamService: [] })

  const print = async value => {
    return
    let _res = await BanHangService.getBHSanPHam({ MaBH: value.ID })
    // let _ht = await OtherService.getHinhThucTT();
    if (_res.status === 2000) {
      let _db = _.cloneDeep(dataprint)
      _db = value
      _db.SanPhamService = _res.data
      //  _ht?.data?.find(
      //   (v) => v.ID === value.HinhThucTT
      // )?.TenHinhThuc;
      setDataprint(_db)
      handlePrintLai()
    }
  }
  //   const handlePrintLai = useReactToPrint({

  //     content: () => refPrintHD.current,
  //   });
  const onSearch = v => {
    return
    let _ft = _.cloneDeep(filterCondition)
    _ft.InputSearch = v
    setFilterCondition(_ft)
  }
  useEffect(() => {
    return
    let time = null
    if (!time) {
      time = setTimeout(() => {
        filter(filterCondition)
      }, 800)
    }
    return () => clearTimeout(time)
  }, [filterCondition.InputSearch])
  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet("BaoCao")
    sheet.properties.defaultRowHeight = 20
    sheet.columns = [
      {
        header: "Nhu cầu",
        key: "NhuCau",
        width: 15,
        font: {
          bold: true,
          size: 24,
        },
      },
      {
        header: "Loại BĐS",
        key: "LoaiBDS",
        width: 20,
      },
      {
        header: "Trạng thái",
        key: "TrangThai",
        width: 20,
      },
      {
        header: "Mục đích",
        key: "MucDich",
        width: 20,
      },
      {
        header: "Khu Vực",
        key: "KhuVuc",
        width: 20, // Added width for consistency
      },
      {
        header: "Diện tích(m2)",
        key: "DienTich",
        width: 20,
      },
      {
        header: "Khoảng giá",
        key: "KhoangGia",
        width: 20,
      },
      {
        header: "Phòng ngủ",
        key: "PhongNgu",
        width: 20,
      },
      {
        header: "Phòng VS",
        key: "PhongVS",
        width: 20,
      },
      {
        header: "Hướng cửa",
        key: "HuongCua",
        width: 20,
      },
      {
        header: "Nguồn",
        key: "Nguon",
        width: 20,
      },
      {
        header: "Số ĐK",
        key: "SoDK",
        width: 20,
      },
      {
        header: "Ngày ĐK",
        key: "NgayDK",
        width: 20,
      },
      {
        header: "Khách hàng",
        key: "KhachHang",
        width: 20,
      },
      {
        header: "Điện thoai",
        key: "DienThoai",
        width: 20,
      },
      {
        header: "Nhân viên QL",
        key: "NhanVienQL",
        width: 20,
      },
      {
        header: "Nhân viên MG",
        key: "NhanVienMG",
        width: 20,
      },
    ]
    data?.map(item => {
      sheet.addRow({
        ngaybh: Format_Date(item.NgayBH),
        sohd: item.SoBH,
        nhanvien: item.HoTen,
        trangthai: state[item.State],
        khachhang: item.TenCongTy,
        tenban: item.TenBan,
        tongtien: item.ThanhTien,
        tienck: item.TienCK,
        tongsauck: item.KhachTra,
      })
    })
    sheet.getCell("A1").numFmt = "# ?/?"

    sheet.addRow({
      ngaybh: "Tổng: " + data.length,

      tongtien: _.sumBy(data, o => o.ThanhTien),
      tienck: _.sumBy(data, o => o.TienCK),
      tongsauck: _.sumBy(data, o => o.KhachTra),
    })
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement("a")
      anchor.href = url
      anchor.download = "banhang.xlsx"
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }

  const expandedRowRender = row => {
    const columns = [
      {
        title: "Sản phẩm",
        dataIndex: "TenSP",
      },
      {
        title: "Số lượng",
        dataIndex: "SoLuong",
      },
      {
        title: "Đơn giá",
        dataIndex: "DonGia",
        render: item => Format_Currency(item),
      },
      {
        title: "Thành tiền",
        render: item => Format_Currency(item.SoLuong * item.DonGia),
      },
      {
        title: "Tiền CK",
        dataIndex: "TienCK",
        render: item => Format_Currency(item),
      },
      {
        title: "Tiền sau CK",
        dataIndex: "ThanhTien",
        render: item => Format_Currency(item),
      },
    ]
    return (
      <Table
        columns={columns}
        dataSource={dataDetail[row.ID]}
        pagination={false}
      />
    )
  }
  const [dataDetail, setDataDetail] = useState({})
  const loadDataExpand = async value => {
    return
    let _res = await BanHangService.getBHSanPHam({
      MaBH: value.ID,
    })
    let _dt = _.cloneDeep(dataDetail)
    _dt[value.ID] = _res.data
    setDataDetail(_dt)
  }

  const remove = async value => {
    console.log(value, "huan")

    return
    let _res = await SanPhamService.NhuCauMuaThue.delete({ ID: Number(value) })
    if (_res?.status === 2000) {
      setData(prevData => prevData.filter(item => item.ID !== Number(value)))
      openNotification("success", "topRight", "Xoá danh mục thành công")
    } else {
      openNotification("error", "topRight", _res?.message)
    }
  }

  return (
    <Row className="page-content" gutter={15}>
      <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          setData={setData}
          data={data}
        />
      </Col>

      <Col span={20}>
        <ToolBar
          left={() => (
            <Flex gap={10}>
              <Input
                style={{
                  width: "200px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm kiếm tên, điện thoại, số hoá đơn"
                onChange={e => onSearch(e.target.value)}
              />

              {/* {status?.map(item => { */}
              {[
                { ID: 0, Name: "Tất cả", Total: 12, Color: "CCCCCC" },
                { ID: 2, Name: "Mới", Total: 5, Color: "00A6FF" },
                { ID: 3, Name: "Đang xử lý", Total: 2, Color: "C2C23A" },
              ]?.map(item => {
                return (
                  <Badge
                    key={item.ID}
                    count={99}
                    // count={item.Total}
                    onClick={() => {
                      setStateActive(item)
                      changeState(item.ID)
                    }}
                  >
                    <div
                      style={{
                        border: `1px solid ${"#" + item.Color}`,
                        padding: "7px 17px",
                        cursor: "pointer",
                        borderRadius: 10,
                        fontSize: "14px",
                        // background:
                        //   stateActive.ID === item.ID ? "#" + item.Color : null,
                        // color: stateActive.ID === item.ID ? "white" : null,
                        background:
                          stateActive.ID === item.ID ? "#" + item.Color : null,
                        color: stateActive.ID === item.ID ? "white" : "#333",
                      }}
                    >
                      <p>{item.Name}</p>
                    </div>
                  </Badge>
                )
              })}
            </Flex>
          )}
          right={() => (
            <Flex gap={10}>
              <Button onClick={() => exportExcel()}>Export</Button>
              <Button type="primary" onClick={() => refDetail.current.show()}>
                Thêm
              </Button>
            </Flex>
          )}
        />

        <Table
          // style={{
          //   height: 100,
          //   // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
          //   // borderRadius:0
          // }}
          pagination={false}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 2000,
          }}
          size="small"
          //   summary={pageData => {
          //     return (
          //       <Table.Summary fixed>
          //         <Table.Summary.Row>
          //           <Table.Summary.Cell index={0} colSpan={8}>
          //             <p style={{ fontWeight: "bold" }}>
          //               Tổng: {pageData.length}
          //             </p>
          //           </Table.Summary.Cell>
          //           <Table.Summary.Cell index={1}>
          //             <p style={{ fontWeight: "bold" }}>
          //               {Format_Currency(_.sum(_.map(pageData, "ThanhTien")))}
          //             </p>
          //           </Table.Summary.Cell>
          //           <Table.Summary.Cell index={2}>
          //             <p style={{ fontWeight: "bold" }}>
          //               {Format_Currency(_.sum(_.map(pageData, "TienCK")))}
          //             </p>
          //           </Table.Summary.Cell>
          //           <Table.Summary.Cell index={3}>
          //             <p style={{ fontWeight: "bold" }}>
          //               {Format_Currency(_.sum(_.map(pageData, "KhachTra")))}
          //             </p>
          //           </Table.Summary.Cell>
          //         </Table.Summary.Row>
          //       </Table.Summary>
          //     )
          //   }}
          rowKey="ID"
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ["0"],
          }}
          expandIcon={({ expanded, onExpand, record }) => {
            return expanded ? (
              <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
            ) : (
              // <Button
              //   onClick={(e) => {
              //     onExpand(record, e);
              //   }}
              // >
              //   Rút gọn
              // </Button>
              <PlusCircleTwoTone
                onClick={e => {
                  onExpand(record, e)
                  loadDataExpand(record)
                }}
              />

              // <Button
              //   onClick={(e) => {
              //     onExpand(record, e);
              //     loadDataExpand(record);
              //   }}
              // >
              //   Chi tiết
              // </Button>
            )
          }}
        />

        <Flex justify="space-between">
          <Col className="mt-3">
            <div className="d-inline">
              <select
                className="form-select"
                value={filterCondition.Limit}
                onChange={e =>
                  onClickchangePage("Limit", parseInt(e.target.value, 10))
                }
              >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
              </select>
            </div>
          </Col>
          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination mt-3">
            <Pagination
              totalPage={Math.ceil(
                data?.length > 0 ? data[0].TotalRows : 0 / filterCondition.Limit
              )}
              changePage={v => onClickchangePage("Offset", v)}
            />
          </Col>
        </Flex>
      </Col>
      <div style={{ display: "none" }}>
        {/* <InHoaDon ref={refPrintHD} data={dataprint} /> */}
      </div>
      <FormAddNew ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  )
}
