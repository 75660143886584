import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Select, Drawer, Divider, Checkbox, Row, Col } from "antd";
import _ from "lodash";
// import { PermissionService } from "services/PermissionService";
import { Accordion, AccordionTab } from "primereact/accordion";
import { PermissionService } from "services/PermissionService";

const FormAddPerNhomModules = (props, ref) => {
  const { onAfterSubmit } = props;
  const [data, setData] = useState([
    {
        "FormID": 9,
        "FormName": "Dữ liệu khách hàng",
        "Description": null,
        "ModulID": 3,
        "ControlName": "itemDanhSachKH",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 53,
        "Per": 9
    },
    {
        "FormID": 15,
        "FormName": "Danh sách nhân viên",
        "Description": "",
        "ModulID": 4,
        "ControlName": "itemDanhSachNV",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": 15
    },
    {
        "FormID": 62,
        "FormName": "Phân quyền",
        "Description": "",
        "ModulID": 4,
        "ControlName": "itemPhanQuyen",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": 62
    },
    {
        "FormID": 75,
        "FormName": "Danh sách sản phẩm",
        "Description": null,
        "ModulID": 25,
        "ControlName": "itemDanhSachSP",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": 75
    },
    {
        "FormID": 88,
        "FormName": "Quản lý hợp đồng",
        "Description": null,
        "ModulID": 29,
        "ControlName": "itemDanhSachHD",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 53,
        "Per": null
    },
    {
        "FormID": 118,
        "FormName": "Danh sách nhập kho",
        "Description": "",
        "ModulID": 69,
        "ControlName": "itemDSNhapKho",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 55,
        "Per": 118
    },
    {
        "FormID": 119,
        "FormName": "Danh sách xuất kho",
        "Description": "",
        "ModulID": 70,
        "ControlName": "itemDSXuatKho",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 55,
        "Per": null
    },
    {
        "FormID": 120,
        "FormName": "Phiếu thu",
        "Description": "",
        "ModulID": 38,
        "ControlName": "itemPhieuThu",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 57,
        "Per": 120
    },
    {
        "FormID": 121,
        "FormName": "Phiếu chi",
        "Description": "",
        "ModulID": 38,
        "ControlName": "itemPhieuChi",
        "OrderBy": 4,
        "isView": true,
        "ModulIDLevel1": 57,
        "Per": null
    },
    {
        "FormID": 148,
        "FormName": "Danh mục nhân viên - Trụ sở, chi nhánh",
        "Description": "",
        "ModulID": 62,
        "ControlName": "itemTruSoChiNhanh",
        "OrderBy": 4,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": 148
    },
    {
        "FormID": 233,
        "FormName": "Loại thẻ",
        "Description": "",
        "ModulID": 80,
        "ControlName": "itemLoaiTheTV",
        "OrderBy": 32,
        "isView": true,
        "ModulIDLevel1": null,
        "Per": null
    },
    {
        "FormID": 262,
        "FormName": "Danh sách tồn kho",
        "Description": "",
        "ModulID": 71,
        "ControlName": "itemTonKho",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 55,
        "Per": null
    },
    {
        "FormID": 272,
        "FormName": "Danh sách phiếu bán hàng",
        "Description": "",
        "ModulID": 75,
        "ControlName": "itemDSBanHang",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 56,
        "Per": 272
    },
    {
        "FormID": 282,
        "FormName": "Danh sách thẻ",
        "Description": "",
        "ModulID": 80,
        "ControlName": "itemDanhSachTheTV",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 58,
        "Per": 282
    },
    {
        "FormID": 283,
        "FormName": "Tra cứu thông tin thẻ",
        "Description": "",
        "ModulID": 80,
        "ControlName": "itemTraCuuTheTV",
        "OrderBy": 3,
        "isView": true,
        "ModulIDLevel1": null,
        "Per": null
    },
    {
        "FormID": 284,
        "FormName": "Nạp tiền",
        "Description": "",
        "ModulID": 81,
        "ControlName": "itemThemTichDT",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": null,
        "Per": null
    },
    {
        "FormID": 285,
        "FormName": "Lịch sử thẻ",
        "Description": "",
        "ModulID": 81,
        "ControlName": "itemDiemThuong",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 58,
        "Per": 285
    },
    {
        "FormID": 288,
        "FormName": "Lịch Sử Đổi Quà",
        "Description": "",
        "ModulID": 82,
        "ControlName": "itemDanhSachDQ",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": null,
        "Per": null
    },
    {
        "FormID": 289,
        "FormName": "Danh sách quà tặng",
        "Description": "",
        "ModulID": 82,
        "ControlName": "itemLinhKien",
        "OrderBy": 3,
        "isView": true,
        "ModulIDLevel1": null,
        "Per": null
    },
    {
        "FormID": 291,
        "FormName": "Lịch sử vào",
        "Description": "",
        "ModulID": 83,
        "ControlName": "itemLichSuVao",
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 58,
        "Per": 291
    },
    {
        "FormID": 392,
        "FormName": "Kiểm kê kho",
        "Description": "",
        "ModulID": 71,
        "ControlName": "",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 55,
        "Per": null
    },
    {
        "FormID": 393,
        "FormName": "Bán vé",
        "Description": "",
        "ModulID": 75,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 95,
        "Per": null
    },
    {
        "FormID": 394,
        "FormName": "Ticket online",
        "Description": "",
        "ModulID": 75,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 95,
        "Per": null
    },
    {
        "FormID": 395,
        "FormName": "Khu vui chơi",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 395
    },
    {
        "FormID": 396,
        "FormName": "Danh mục nhân viên",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 396
    },
    {
        "FormID": 397,
        "FormName": "Danh mục khách hàng",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 397
    },
    {
        "FormID": 398,
        "FormName": "Danh mục thẻ",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 398
    },
    {
        "FormID": 399,
        "FormName": "Danh mục sản phẩm",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 399
    },
    {
        "FormID": 400,
        "FormName": "Danh mục khác",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 400
    },
    {
        "FormID": 401,
        "FormName": "Lịch sử ca làm việc",
        "Description": "",
        "ModulID": 51,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": 401
    },
    {
        "FormID": 402,
        "FormName": "Danh mục khu/bàn",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 402
    },
    {
        "FormID": 403,
        "FormName": "Sơ đồ bàn",
        "Description": "",
        "ModulID": 56,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 56,
        "Per": 403
    },
    {
        "FormID": 404,
        "FormName": "Báo cáo bán hàng theo sản phẩm",
        "Description": "",
        "ModulID": 61,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 61,
        "Per": 404
    },
    {
        "FormID": 405,
        "FormName": "Báo cáo bán hàng hàng ngày",
        "Description": "",
        "ModulID": 61,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 61,
        "Per": 405
    },
    {
        "FormID": 406,
        "FormName": "Báo cáo lịch sử vé",
        "Description": "",
        "ModulID": 61,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 61,
        "Per": null
    },
    {
        "FormID": 408,
        "FormName": "Báo cáo vé lỗi",
        "Description": "",
        "ModulID": 61,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 61,
        "Per": null
    },
    {
        "FormID": 409,
        "FormName": "Vé kinh doanh",
        "Description": "",
        "ModulID": 95,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 95,
        "Per": null
    },
    {
        "FormID": 410,
        "FormName": "Phân quyền công ty",
        "Description": null,
        "ModulID": 51,
        "ControlName": null,
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": null
    },
    {
        "FormID": 411,
        "FormName": "Danh mục CRM",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 12,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 411
    },
    {
        "FormID": 412,
        "FormName": "Danh mục bảng giá",
        "Description": "",
        "ModulID": 94,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 94,
        "Per": 412
    },
    {
        "FormID": 413,
        "FormName": "Vé tháng",
        "Description": "",
        "ModulID": 95,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 95,
        "Per": null
    },
    {
        "FormID": 414,
        "FormName": "Lịch sử thao tác",
        "Description": "",
        "ModulID": 51,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": 414
    },
    {
        "FormID": 415,
        "FormName": "Danh sách Email",
        "Description": "",
        "ModulID": 53,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 53,
        "Per": 415
    },
    {
        "FormID": 416,
        "FormName": "Danh sách SMS",
        "Description": "",
        "ModulID": 53,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 53,
        "Per": 416
    },
    {
        "FormID": 417,
        "FormName": "Báo cáo khác",
        "Description": "",
        "ModulID": 61,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 61,
        "Per": 417
    },
    {
        "FormID": 418,
        "FormName": "Báo cáo bán hàng chi tiết sản phẩm",
        "Description": "",
        "ModulID": 61,
        "ControlName": "",
        "OrderBy": 0,
        "isView": true,
        "ModulIDLevel1": 61,
        "Per": 418
    },
    {
        "FormID": 419,
        "FormName": "Trang chủ",
        "Description": null,
        "ModulID": null,
        "ControlName": null,
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": null
    },
    {
        "FormID": 420,
        "FormName": "Lịch sử checkin",
        "Description": null,
        "ModulID": 51,
        "ControlName": null,
        "OrderBy": 2,
        "isView": true,
        "ModulIDLevel1": 51,
        "Per": null
    },
    {
        "FormID": 421,
        "FormName": "Danh sách giải đấu",
        "Description": "",
        "ModulID": 96,
        "ControlName": "",
        "OrderBy": 1,
        "isView": true,
        "ModulIDLevel1": 96,
        "Per": null
    }
]);
  const [open, setOpen] = useState(false);
  const [nhommodules, setNhommodules] = useState({});
  const [menulv1, setMenulv1] = useState([
    {
        "ModulID": 51,
        "ModulName": "HỆ THỐNG",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 1,
        "Level": 1,
        "ControlName": "ribbonHeThong",
        "IsViewWeb": true
    },
    {
        "ModulID": 53,
        "ModulName": "CRM",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 3,
        "Level": 1,
        "ControlName": "ribbonCRM",
        "IsViewWeb": true
    },
    {
        "ModulID": 55,
        "ModulName": "KHO HÀNG",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 5,
        "Level": 1,
        "ControlName": "ribbonPage5",
        "IsViewWeb": true
    },
    {
        "ModulID": 56,
        "ModulName": "BÁN HÀNG",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 6,
        "Level": 1,
        "ControlName": "ribbonPage6",
        "IsViewWeb": true
    },
    {
        "ModulID": 57,
        "ModulName": "SỔ QUỸ",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 7,
        "Level": 1,
        "ControlName": "ribbonPage2",
        "IsViewWeb": true
    },
    {
        "ModulID": 58,
        "ModulName": "THẺ THÀNH VIÊN",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 8,
        "Level": 1,
        "ControlName": "ribbonPage7",
        "IsViewWeb": true
    },
    {
        "ModulID": 61,
        "ModulName": "BÁO CÁO",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 11,
        "Level": 1,
        "ControlName": "ribbonBaoCao",
        "IsViewWeb": true
    },
    {
        "ModulID": 94,
        "ModulName": "DANH MỤC",
        "Description": "K",
        "ParentID": 0,
        "OrderBy": 5,
        "Level": 0,
        "ControlName": "DANHMUC",
        "IsViewWeb": true
    },
    {
        "ModulID": 95,
        "ModulName": "BÁN VÉ",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 3,
        "Level": 0,
        "ControlName": "",
        "IsViewWeb": true
    },
    {
        "ModulID": 96,
        "ModulName": "Giải đấu",
        "Description": "",
        "ParentID": 0,
        "OrderBy": 0,
        "Level": 0,
        "ControlName": "",
        "IsViewWeb": true
    }
]);
  const loadCategories = async (MaNhom) => {
    let _res = await PermissionService.TheoNhomModules.getForm({
      MaNhom: MaNhom,
    });
   
    setData(_res?.data ?? []);
    let _res2 = await PermissionService.getMenuLevel1({});
    setMenulv1(_res2?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      setNhommodules(item);
      loadCategories(item.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    onAfterSubmit();
  };

  const onChangePer = async (item) => {
    if (item.Per) {
      let payload = {
        MaNhom: nhommodules.ID,
        FormID: item.FormID,
        HavePer: null,
      };
      let _res = await PermissionService.TheoNhomModules.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.FormID === item.FormID);
        _dt.Per = null;
        setData(_data);
      }
    } else {
      let payload = {
        MaNhom: nhommodules.ID,
        FormID: item.FormID,
        HavePer: true,
      };
      let _res = await PermissionService.TheoNhomModules.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.FormID === item.FormID);
        _dt.Per = item.FormID;
        setData(_data);
      }
    }
  };
  return (
    <Drawer
      title="PHÂN QUYỀN CÔNG TY"
      width={620}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
    >
      <Accordion multiple>
        {menulv1?.map((lv1) => {
          return (
            <AccordionTab key={lv1.ModulID} header={lv1.ModulName}>
              {data
                ?.filter((v) => v.ModulIDLevel1 === lv1.ModulID)
                ?.map((item) => {
                  return (
                    <div key={item.FormID}>
                      <Row gutter={[20]}>
                        <Col span={24}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            <Checkbox
                              onChange={() => onChangePer(item)}
                              checked={item.Per}
                            />
                            <p style={{ fontWeight: "500", fontSize: "16px" }}>
                              {item.FormName}
                           
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Divider dashed style={{ margin: "8px 0px" }} />
                    </div>
                  );
                })}
            </AccordionTab>
          );
        })}
      </Accordion>
    </Drawer>
  );
};
export default forwardRef(FormAddPerNhomModules);
