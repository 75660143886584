import React, { useState } from "react";
import { Tabs, Table, Form, Input, Button, Drawer, Row, Col, Select } from "antd";

const { TabPane } = Tabs;
const { Option } = Select;

const initialData = [];

const ChildrenFormAdd = () => {
  const [dataSource, setDataSource] = useState(initialData);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();

  const handleSave = values => {
    const newData = [
      ...dataSource,
      {
        ...values,
        key: `${dataSource.length + 1}`,
      },
    ];
    setDataSource(newData);
    setDrawerVisible(false); // Close drawer after successful save
    form.resetFields(); // Reset form fields after save
  };

  const handleAdd = () => {
    setDrawerVisible(true); // Open drawer to add new row
  };

  const handleCancel = () => {
    setDrawerVisible(false); // Cancel adding new row, close drawer
    form.resetFields(); // Reset form fields on cancel
  };

  const handleDelete = key => {
    const newData = dataSource.filter(item => item.key !== key);
    setDataSource(newData);
  };

  const columns = [
    {
      title: "Xóa",
      dataIndex: "Xoa",
      key: "Xoa",
      render: (_, record) => (
        <Button onClick={() => handleDelete(record.key)}>Xóa</Button>
      ),
    },
    {
      title: "Nhân viên",
      dataIndex: "NhanVien",
      key: "NhanVien",
      editable: true,
    },
    {
      title: "Tổng giá trị",
      dataIndex: "TongGiaTri",
      key: "TongGiaTri",
      editable: true,
    },
    {
      title: "Tỷ lệ",
      dataIndex: "TyLe",
      key: "TyLe",
      editable: true,
    },
    {
      title: "Số tiền",
      dataIndex: "SoTien",
      key: "SoTien",
      editable: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
      editable: true,
    },
  ];

  const mergedColumns = columns.map(col => ({
    ...col,
    onCell: record => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave: handleSave,
    }),
  }));

  return (
    <Tabs defaultActiveKey="1">
      <TabPane key="1">
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          Thêm nhân viên
        </Button>
        <Table
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ x: 1000 }}
        />
        <Drawer
          title="Thêm dòng mới"
          placement="right"
          closable={false}
          onClose={handleCancel}
          visible={drawerVisible}
          width="60%"
          footer={
            <div style={{ textAlign: "right" }}>
              <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                Hủy
              </Button>
              <Button onClick={() => form.submit()} type="primary">
                Lưu
              </Button>
            </div>
          }
        >
          <Form form={form} onFinish={handleSave} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  name="NhanVien"
                  label="Chọn nhân viên"
                  rules={[
                    { required: true, message: "Vui lòng chọn nhân viên" },
                  ]}
                >
                  <Select placeholder="Chọn nhân viên">
                    <Option value="NhanVien1">Nhân viên 1</Option>
                    <Option value="NhanVien2">Nhân viên 2</Option>
                    <Option value="NhanVien3">Nhân viên 3</Option>
                    {/* Thêm các tùy chọn nhân viên khác ở đây */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="TongGiaTri"
                  label="Tổng giá trị"
                  rules={[{ required: true, message: "Vui lòng nhập Tổng giá trị" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="TyLe"
                  label="Tỷ lệ"
                  rules={[
                    { required: true, message: "Vui lòng nhập Tỷ lệ" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="SoTien"
                  label="Số tiền"
                  rules={[
                    { required: true, message: "Vui lòng nhập Số tiền" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="GhiChu"
                  label="Ghi chú"
                  rules={[
                    { required: true, message: "Vui lòng nhập Ghi chú" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </TabPane>
    </Tabs>
  );
};

export default ChildrenFormAdd;
