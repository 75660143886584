import React, { useEffect, useState } from "react"
import { Row, Col, Avatar, Button } from "antd"
import {
  HomeOutlined,
  DollarOutlined,
  CompassOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  BankOutlined,
} from "@ant-design/icons"
import { Image } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { geSanPham } from "store/actions"
import { useParams } from "react-router-dom"
import { SanPhamService } from "services/SanPhamService"
import { Format_Currency } from "common/common"

const LinkPublickBDS = () => {
  const params = useParams()
  const [images, setImages] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [data, setData] = useState({})
  useEffect(() => {
    let indexmact = params?.param?.indexOf("&mact")
    let id = params?.param?.substring(3, indexmact)
    let mact = params?.param?.substring(indexmact + 6)
    SanPhamService.BanChoThue.getByidNoauth({
      ID: Number(id),
      TenCTDK: mact,
    }).then(res => {
      setImages(res.images)
      setData(res.data)
    })
  }, [])
  const info = [
    {
      icon: <HomeOutlined />,
      label: "Diện tích",
      value: `${data?.DienTich} m²`,
    },
    { icon: <CompassOutlined />, label: "Hướng nhà", value: "Đông" },
    {
      icon: <DollarOutlined />,
      label: "Mức giá",
      value: `${Format_Currency(data?.DonGia * data?.DienTich)} đ`,
    },
    {
      icon: <CompassOutlined />,
      label: "Hướng ban công",
      value: `${data?.HuongBanCong} `,
    },
    {
      icon: <AppstoreAddOutlined />,
      label: "Số phòng ngủ",
      value: `${data?.PhongNgu}`,
    },
    {
      icon: <AppstoreAddOutlined />,
      label: "Số toilet",
      value: `${data?.SoToilet}`,
    },
    { icon: <FileTextOutlined />, label: "Pháp lý", value: `${data?.PhapLy}` },
    { icon: <BankOutlined />, label: "Nội thất", value: "Đầy đủ" },
  ]
  const handleThumbnailClick = index => {
    setSelectedImageIndex(index)
  }

  return (
    <div className="page-content" style={{ padding: "50px 10%" }}>
      <Row>
        <Col span={18}>
          <div style={{ marginBottom: 20, paddingTop: "30px" }}>
            <Image
              width={"90%"}
              height={400}
              src={images[selectedImageIndex]?.LinkImage}
              alt="Selected"
            />
          </div>
          <Row gutter={[10, 10]}>
            {images.map((image, index) => (
              <Col span={3}>
                <Image
                  key={index}
                  width="100%"
                  height={80}
                  src={image.LinkImage}
                  onClick={() => handleThumbnailClick(index)}
                  preview={false}
                  style={{
                    marginRight: "20px",
                    cursor: "pointer",
                    borderRadius: "5px",
                    border:
                      index === selectedImageIndex
                        ? "2px solid #1890ff"
                        : "none",
                  }}
                  alt={`Thumbnail ${index + 1}`}
                />{" "}
              </Col>
            ))}
          </Row>
        </Col>
        <Col style={{ marginTop: "100px" }} span={6}>
          <div style={{ textAlign: "center" }}>
            <Avatar
              size={64}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcl3_HClJdicIDvbCW59mlEaDdcJk5bm3Jgg&s"
            />
            <p style={{ margin: "10px 0", fontWeight: "600" }}>{data?.HoTen}</p>
            {/* <span>Xem thêm 99 tin khác</span> */}
            <Button style={{ margin: "10px 0" }} type="primary" block>
              {data?.DienThoai}
            </Button>
            <Button block>Nhắn tin zalo</Button>
          </div>
        </Col>
      </Row>

      <h3 style={{ fontWeight: "600" }} className="mt-5 mb-4">
        Đặc điểm bất động sản
      </h3>
      <Row style={{ padding: "0 " }} gutter={[50, 30]}>
        {info.map((item, index) => (
          <Col span={8} key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #d9d9d9",
                paddingBottom: "10px",
                fontWeight: "600",
              }}
            >
              <p style={{ width: "50%" }}>
                {item.icon} {item.label}
              </p>
              <p style={{ width: "50%", textAlign: "right" }}>{item.value}</p>
            </div>
          </Col>
        ))}
      </Row>
      <h3 style={{ fontWeight: "600" }} className="mt-4 mb-4">
        Thông tin mô tả
      </h3>
      <p>{data?.DacTrung}</p>
    </div>
  )
}

export default LinkPublickBDS
