import React, { useEffect, useState } from "react"
import {
  Form,
  Dropdown,
  Button,
  Tabs,
  List,
  Checkbox,
  Radio,
  Input,
} from "antd"
import { CommonService } from "services/Common"

const { TabPane } = Tabs

const ChilrendForsaleKV = () => {
  const [province, setProvince] = useState(null)
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])
  const [currentTab, setCurrentTab] = useState("province")
  const [visible, setVisible] = useState(false)
  const [tinh, setTinh] = useState([])
  const [huyen, setHuyen] = useState([])
  const [xa, setXa] = useState([])
  const [data, setData] = useState({ maTinh: null, maHuyen: [] })
  const [provinceSearch, setProvinceSearch] = useState("")
  const [districtSearch, setDistrictSearch] = useState("")
  const [wardSearch, setWardSearch] = useState("")
  const [selectedProvinceName, setSelectedProvinceName] = useState("Chọn Khu Vực")

  const loadOptions = async () => {
    try {
      const resTinh = await CommonService.getTinh()
      let updatedTinh = resTinh.data || []

      updatedTinh.forEach(item => {
        if (item.TenTinh === "Không xác đinh") {
          item.TenTinh = "Tất cả địa điểm"
        }
      })

      setTinh(updatedTinh)
    } catch (error) {
      console.error("Error loading options:", error)
    }
  }

  useEffect(() => {
    loadOptions()
  }, [])

  const applyChange = (p, v) => {
    let _data = { ...data }
    switch (p) {
      case "maTinh":
        if (v === 1) {
          setHuyen([])
          setXa([])
        } else {
          loadHuyen(v)
          setCurrentTab("district")
        }
        _data[p] = v
        break
      case "maHuyen":
        loadXaDuong(v[0])
        _data[p] = v
        setCurrentTab("ward")
        break
      default:
        _data[p] = v
        break
    }
    setData(_data)
  }

  const loadHuyen = async maTinh => {
    try {
      const resHuyen = await CommonService.getHuyen({ MaTinh: maTinh })
      setHuyen(resHuyen.data || [])
    } catch (error) {
      console.error("Error loading districts:", error)
    }
  }

  const loadXaDuong = async maHuyen => {
    try {
      const resXa = await CommonService.getXa({ MaHuyen: maHuyen })
      setXa(resXa.data || [])
    } catch (error) {
      console.error("Error loading wards and streets:", error)
    }
  }

  const handleProvinceChange = e => {
    const maTinh = e.target.value
    const selectedProvince = tinh.find(province => province.MaTinh === maTinh)
    const provinceName = selectedProvince ? selectedProvince.TenTinh : "Chọn Khu Vực"
    setSelectedProvinceName(provinceName)

    setProvince(maTinh)
    setDistricts([])
    setWards([])
    applyChange("maTinh", maTinh)
  }

  const handleDistrictChange = checkedValues => {
    setDistricts(checkedValues)
    setWards([])
    applyChange("maHuyen", checkedValues)
  }

  const handleWardChange = checkedValues => {
    setWards(checkedValues)
  }

  const handleTabChange = key => {
    setCurrentTab(key)
  }

  const handleReset = () => {
    setProvince(null)
    setDistricts([])
    setWards([])
    setData({ maTinh: null, maHuyen: [] })
    setCurrentTab("province")
    setSelectedProvinceName("Chọn Khu Vực")
  }

  const handleApply = () => {
    console.log("Selected Data:", data)
    console.log("Selected Wards:", wards)
    setVisible(false)
  }

  const renderProvinces = () => (
    <div>
      <Input
        placeholder="Tìm kiếm tỉnh/thành phố"
        value={provinceSearch}
        onChange={e => setProvinceSearch(e.target.value)}
        style={{ marginBottom: 8 }}
      />
      <Radio.Group
        style={{
          width: "100%",
          maxHeight: 200,
          overflowY: "scroll",
          backgroundColor: "",
        }}
        value={data.maTinh}
        onChange={handleProvinceChange}
      >
        <List
          dataSource={tinh.filter(item =>
            item.TenTinh.toLowerCase().includes(provinceSearch.toLowerCase())
          )}
          renderItem={item => (
            <List.Item>
              <Radio value={item.MaTinh}>{item.TenTinh}</Radio>
            </List.Item>
          )}
        />
      </Radio.Group>
    </div>
  )

  const renderDistricts = () => {
    const handleCheckboxToggle = value => {
      let newSelected = [...data.maHuyen]
      if (newSelected.includes(value)) {
        newSelected = newSelected.filter(item => item !== value)
      } else {
        newSelected.push(value)
      }
      applyChange("maHuyen", newSelected)
    }

    return (
      <div>
        <Input
          placeholder="Tìm kiếm quận/huyện"
          value={districtSearch}
          onChange={e => setDistrictSearch(e.target.value)}
          style={{ marginBottom: 8 }}
        />
        <Checkbox.Group
          style={{ width: "100%", maxHeight: 200, overflowY: "scroll" }}
          value={data.maHuyen}
          onChange={handleDistrictChange}
        >
          <List
            style={{ width: "100%", maxHeight: 200, overflowY: "scroll" }}
            dataSource={huyen.filter(item =>
              item.TenHuyen.toLowerCase().includes(districtSearch.toLowerCase())
            )}
            renderItem={item => (
              <List.Item onClick={() => handleCheckboxToggle(item.MaHuyen)}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>{item.TenHuyen}</span>
                  <Checkbox
                    value={item.MaHuyen}
                    onClick={e => e.stopPropagation()}
                  />
                </div>
              </List.Item>
            )}
          />
        </Checkbox.Group>
      </div>
    )
  }

  const renderWards = () => {
    const handleCheckboxToggle = value => {
      let newSelected = [...wards]
      if (newSelected.includes(value)) {
        newSelected = newSelected.filter(item => item !== value)
      } else {
        newSelected.push(value)
      }
      setWards(newSelected)
    }

    return (
      <div>
        <Input
          placeholder="Tìm kiếm phường/xã"
          value={wardSearch}
          onChange={e => setWardSearch(e.target.value)}
          style={{ marginBottom: 8 }}
        />
        <Checkbox.Group
          style={{ width: "100%", maxHeight: 200, overflowY: "scroll" }}
          value={wards}
          onChange={handleWardChange}
        >
          <List
            style={{ width: "100%", maxHeight: 200, overflowY: "scroll" }}
            dataSource={xa.filter(item =>
              item.TenXa.toLowerCase().includes(wardSearch.toLowerCase())
            )}
            renderItem={item => (
              <List.Item onClick={() => handleCheckboxToggle(item.MaXa)}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span>{item.TenXa}</span>
                  <Checkbox
                    value={item.MaXa}
                    onClick={e => e.stopPropagation()}
                  />
                </div>
              </List.Item>
            )}
          />
        </Checkbox.Group>
      </div>
    )
  }

  return (
    <Form.Item style={{ width: "100%" }} className="">
      <Dropdown
        overlay={
          <div
            style={{
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <Tabs activeKey={currentTab} onChange={handleTabChange}>
              <TabPane tab="Tỉnh/Thành phố" key="province">
                {renderProvinces()}
              </TabPane>
              <TabPane tab="Quận/Huyện" key="district" disabled={!data.maTinh}>
                {renderDistricts()}
              </TabPane>
              <TabPane
                tab="Phường/Xã"
                key="ward"
                disabled={!data.maHuyen.length}
              >
                {renderWards()}
              </TabPane>
            </Tabs>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Button onClick={handleReset}>Đặt lại</Button>
              <Button type="primary" onClick={handleApply}>
                Áp dụng
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onVisibleChange={setVisible}
        trigger={["click"]}
      >
        <p
          style={{
            border: "none",
            marginBottom: "",
            borderRadius: "0px",
            backgroundColor: "white",
            padding: "5px 0",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => setVisible(!visible)}
        >
          {selectedProvinceName}
        </p>
      </Dropdown>
    </Form.Item>
  )
}

export default ChilrendForsaleKV
