
/* ORDERS */
export const GET_STAFFS = "GET_STAFFS"
export const GET_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS"
export const GET_STAFFS_FAIL = "GET_STAFFS_FAIL"

export const GET_STAFF = "GET_STAFF"

export const GET_STAFF_SAGA = "GET_STAFF_SAGA"
export const GET_STAFF_SAGA_SUCCESS = "GET_STAFF_SAGA_SUCCESS"


export const GET_STAFF_KD_SAGA = "GET_STAFF_KD_SAGA"
export const GET_STAFF_KD_SAGA_SUCCESS = "GET_STAFF_KD_SAGA_SUCCESS"

export const DELETE_STAFF = "DELETE_STAFF"
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS"
export const DELETE_STAFF_FAIL = "DELETE_STAFF_FAIL"

export const EDIT_STAFF = "EDIT_STAFF"
export const EDIT_STAFF_SUCCESS = "EDIT_STAFF_SUCCESS"
export const EDIT_STAFF_FAIL = "EDIT_STAFF_FAIL"

export const ADD_STAFF = "ADD_STAFF"
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS"
export const ADD_STAFF_FAIL = "ADD_STAFF_FAIL"

export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

export const GET_MENU = "GET_MENU"
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS"