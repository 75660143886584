import React, { useState, useImperativeHandle, forwardRef } from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import { Card, CardBody } from "reactstrap"
import { Button } from "primereact/button"
import { BEE_InputText, BEE_XLAYOUT_FORM } from "pages/Forms/BEE_Form"
import { ProjectService } from "services/Project"
import { AgencyService } from "services/Agency"
function Category_Detail(props, ref) {
  const { afterSubmit } = props
  const [show, setShow] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [data, setData] = useState({})
  useImperativeHandle(ref, () => ({
    add: async (_item, active) => {
      setActiveTab(active)
      setData(_item)
      setShow(true)
    },
  }))
  const cancel = () => {
    setShow(false)
    setActiveTab(0)
    setData({})
  }
  const applyChange = (p, v) => {
    let _data = _.cloneDeep(data)
    _data[p] = v
    setData(_data)
  }

  const submit = async () => {
    let _data = _.cloneDeep(data)
    let _res = null
    switch (activeTab) {
      case 0:
        _res = await ProjectService.addKhu(_data)
        break
      case 1:
        _res = await AgencyService.ForSale.addPhongNgu(_data);
        break;
      case 2:
        _res = await AgencyService.ForSale.addView(_data)
        break
      case 3:
        _res = await AgencyService.Categories.addLoaiBDS(_data)
        break
    }
    if (_res?.status === 2000) {
      afterSubmit()
      cancel()
    }
  }
  const renderAddKhu = () => {
    return (
      <React.Fragment>
        <BEE_InputText
          col={12}
          object={data}
          onChangeFunction={applyChange}
          prop="tenKhu"
          label="Tên khu"
        />
      </React.Fragment>
    )
  }
  const renderPhongNgu = () => {
    return (
      <React.Fragment>
        <BEE_InputText
          col={12}
          object={data}
          onChangeFunction={applyChange}
          prop="tenLoaiPN"
          label="Tên loại phòng ngủ"
        />
      </React.Fragment>
    )
  }
  const renderView = () => {
    return (
      <React.Fragment>
        <BEE_InputText
          col={12}
          object={data}
          onChangeFunction={applyChange}
          prop="tenVeiw"
          label="Tên View"
        />
      </React.Fragment>
    )
  }
  const renderLoaiBDS = () => {
    return (
      <React.Fragment>
        <BEE_InputText
          col={12}
          object={data}
          onChangeFunction={applyChange}
          prop="tenLBDS"
          label="Tên loại bất động sản"
        />
      </React.Fragment>
    )
  }
  try {
    return (
      <Dialog
        visible={show}
        header="Thêm/ Sửa danh mục"
        style={{ width: "30%", height: "40%", position: "relative" }}
        className={` ${
          show ? "fadeinleft" : "fadeoutleft"
        } animation-duration-300 animation-iteration-1`}
        modal
        onHide={() => cancel()}
        // maximizable
        // maximized
      >
        <div
          style={{
            height: "35px",
            background: "#ffffff",
            position: "absolute",
            top: 39,
            width: "100%",
            zIndex: 4,
          }}
          className="shadow-2 rounded flex align-items-center"
        >
          <Button
            label="Lưu"
            icon="pi pi-save"
            className="p-button-success p-button-text"
            onClick={() => submit()}
          />
        </div>
        <Card className="mb-0 mt-4">
          <CardBody>
            {activeTab === 0 ? (
              <div className="grid px-2 pb-2 ">{renderAddKhu()}</div>
            ) : activeTab === 1 ? (
              <div className="grid px-2 pb-2 ">{renderPhongNgu()}</div>
            ) : activeTab === 2 ? (
              <div className="grid px-2 pb-2 ">{renderView()}</div>
            ) : activeTab === 3 ? (
              <div className="grid px-2 pb-2 ">{renderLoaiBDS()}</div>
            ) : null}
          </CardBody>
        </Card>
      </Dialog>
    )
  } catch (error) {}
}
Category_Detail = forwardRef(Category_Detail)
export default Category_Detail
