import * as types from "./actionTypes"

const INIT_STATE = {
  listGiuCho: [],
  listDatCoc: [],
  isDone: true,
  isLoadFilter: false,
  listTrangThaiHD: [],
  listBangGiaTT:[],
  listTangBG:[],
  listViTriBG:[],
  listKhuChiTiet:[],
}

 const   HopDong = ( state = INIT_STATE, action ) => {
  switch (action.type) {
    case types.GET_GIUCHO:
      return { ...state, isLoadFilter: true }
    case types.GET_GIUCHO_SUCCESS:
      return { ...state, listGiuCho: action.payload, isLoadFilter: false }
    case types.GET_DATCOC:
      return { ...state, isLoadFilter: true }
    case types.GET_DATCOC_SUCCESS:
      return { ...state, listDatCoc: action.payload, isLoadFilter: false }
    case types.GET_BANGGIATT:
      return { ...state, isLoadFilter: true }
    case types.GET_BANGGIATT_SUCCESS:
      return { ...state, listBangGiaTT: action.payload, isLoadFilter: false }
    case types.GET_TANG_BG:
      return { ...state, isLoadFilter: true }
    case types.GET_TANG_BG_SUCCESS:
      return { ...state, listTangBG: action.payload, isLoadFilter: false }
    case types.GET_VITRI_BG:
      return { ...state, isLoadFilter: true }
    case types.GET_VITRI_BG_SUCCESS:
      return { ...state, listViTriBG: action.payload, isLoadFilter: false }
    case types.GET_KHU_CHITIET_BG_SUCCESS:
      return { ...state, listKhuChiTiet: action.payload }
    case types.GET_HOPDONG_TRANGTHAI_SUCCESS:
      return {
        ...state,
        listTrangThaiHD: action.payload,
      }
    default:
      return { ...state }
  }
}
export default HopDong;