import * as types from "./actionTypes"

const INIT_STATE = {
  tinTuc: [],
  loaiTinTuc:[]
}

const TinTuc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_TINTUC_SUCCESS:
      return {
        ...state,
        tinTuc: action.payload,
      }
    case types.GET_LOAI_TINTUC_SUCCESS:
      return {
        ...state,
        loaiTinTuc: action.payload,
      }
    case types.DELETE_TINTUC_SUCCESS:
      // debugger
      const tintuc = state.tinTuc.filter(item => {
        return item.maTin !== Number(action.payload.MaTin)
      })
      return {
        ...state,
        tinTuc: tintuc,
      }
    default:
      return state
  }
}

export default TinTuc
