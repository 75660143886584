import { Flex, InputNumber } from "antd"
import React from "react"
import "./index.scss"
const InputNumberCustom2 = ({ applyChange, prop, value, placeholder, max }) => {
  return (
    <Flex className="input-custom" align="center" justify="flex-start">
      {/* <p style={{ fontWeight: "600", width: "35%" }}>{label}</p> */}
      <InputNumber
        style={{ width: "100% ", border: "none" }}
        value={value}
        placeholder={placeholder}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        onChange={value =>
          typeof applyChange === "function" && applyChange(prop, value)
        }
        max={max ? max : null}
      />
    </Flex>
  )
}
export default InputNumberCustom2
