import { Flex, InputNumber } from "antd"
import React from "react"
import "./index.scss";
const InputNumberCustom = ({ label, applyChange, prop, value, placeholder }) => {
  return (
    <Flex className="input-custom" align="center" justify="flex-start">
      <p 
    //   className="text-overflow-ellipsis white-space-nowrap overflow-hidden" 
      style={{fontWeight:'600',width:"35%"}}>{label}</p>
      <InputNumber
       formatter={(value) =>
        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
        style={{ maxWidth: "65% ", border: "none" }}
        value={value}
        placeholder={placeholder}
        onChange={value =>
          typeof applyChange === "function" && applyChange(prop, value)
        }
      />
    </Flex>
  )
}

export default InputNumberCustom
