import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd"
import FilterModal from "./FilterModal"
import FormAdd from "./FormAdd";
import { Format_Date } from "../../Utility/common"
import { Format_Currency } from "../../Utility/common"
// import { CiTrash } from "react-icons/ci";
// import { MdEdit } from "react-icons/md";
import _ from "lodash"
import Toolbar from "components/Toolbar"
import { Input } from "reactstrap"
// import { SoQuyService } from "services/SoQuyService";
// import { CongTyService } from "services/CongTyService";
// import { OtherService } from "services/OtherService";
export default function HopDongMoiGoi() {
  const [api, contextHolder] = notification.useNotification()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loaiPT, setLoaiPT] = useState([])
  const [hinhThucTT, setHinhThucTT] = useState([])
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
    MaCT: 0,
    LoaiThu: ",,",
    HinhThuc: ",,",
  })
  
  const refDetail = useRef(null)
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const loadCategories = async () => {
    return
    // let _resCT = await CongTyService.getCongTy();
    // setCompanies(_resCT.data);
    OtherService.getHinhThucTT().then(res => setHinhThucTT(res.data ?? []))
    SoQuyService.PhieuThu.getLoaiPhieuThu().then(res =>
      setLoaiPT(res.data ?? [])
    )
  }
  useEffect(() => {
    filter(filterCondition)
    loadCategories()
  }, [])
  const filter = async _filter => {
    return
    setLoading(true)
    let _res = await SoQuyService.PhieuThu.getPhieuThu(_filter)
    if (_res.data) setData(_res.data)
    setLoading(false)
  }
  const onChangeFilter = _filter => {
    filter(_filter)
    setFilterCondition(_filter)
  }
  const onAfterSubmit = () => {
    filter(filterCondition)
  }
  const columns = [
    {
      title: "Số GD",
      dataIndex: "SoGD",
    },
    {
      title: "Ngày GD",
      dataIndex: "NgayGD",
    },
    {
      title: "Ngày đặt cọc",
      dataIndex: "NgayDatCoc",
    },
    {
      title: "Ngày kí HĐ",
      dataIndex: "NgayKiHD",
    },
    {
      title: "Thời hạn",
      dataIndex: "ThoiHan",
    },
    {
      title: "Trạng Thái",
      dataIndex: "TrangThai",
    },
    {
      title: "Tiền cọc",
      dataIndex: "TienCoc",
    },
    {
      title: "Ký hiệu",
      dataIndex: "KyHieu",
    },
    {
      title: "Hình thức",
      dataIndex: "HinhThuc",
    },
    {
      title: "Loại BĐS",
      dataIndex: "LoaiBDS",
    },
    {
      title: "Diện tích (m2)",
      dataIndex: "DienTich",
    },
    {
      title: "Đơn giá",
      dataIndex: "DonGia",
    },
    {
      title: "Thành tiền",
      dataIndex: "ThanhTien",
    },
    {
      title: "PMG bên mua",
      dataIndex: "PMGBenMua",
    },
    {
      title: "PMG bên bán",
      dataIndex: "PMGBenBan",
    },
    {
      title: "Tổng phí MG",
      dataIndex: "TongPhiMG",
    },
    {
      title: "Đã thu",
      dataIndex: "DaThu",
    },
    {
      title: "Còn lại",
      dataIndex: "ConLai",
    },
    {
      title: "Bên bán",
      dataIndex: "BenBan",
    },
    {
      title: "Nhân viên bán",
      dataIndex: "NhanVienBan",
    },
    {
      title: "ĐT bên bán",
      dataIndex: "DTBenBan",
    },
    {
      title: "Bên mua",
      dataIndex: "BenMua",
    },
    {
      title: "Nhân viên mua",
      dataIndex: "NhanVienMua",
    },
    {
      title: "ĐT bên mua",
      dataIndex: "DTBenMua",
    },
    {
      title: "Người lập",
      dataIndex: "NguoiLap",
    },

    {
      title: "Diễn giải",
      dataIndex: "DIenGiai",
    },

    // {
    //   title: "",
    //   fixed: "right",
    //   width: "100px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Tooltip placement="topRight" title="Sửa">
    //           <Button
    //             style={{ marginLeft: 10 }}
    //             type="primary"
    //             ghost
    //             // icon={<MdEdit />}
    //             onClick={() => refDetail.current.update(item)}
    //           />
    //         </Tooltip>

    //         <Popconfirm
    //           title="Xoá phiếu thu"
    //           description="Bạn có chắc chắn muốn xoá phiếu thu?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.ID)}
    //         >
    //           <Tooltip placement="topRight" title="Xoá">
    //             <Button
    //               style={{ marginLeft: 10 }}
    //               danger
    //               ghost
    //               // icon={<CiTrash />}
    //             />
    //           </Tooltip>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ]
  const remove = async value => {
    return
    let _res = await SoQuyService.PhieuThu.deletePhieuThu({ ID: Number(value) })
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data)
      let fil = _data.filter(v => v.ID !== Number(value))
      setData(fil)
      openNotification("success", "topRight", "Xoá phiếu thu thành công")
    } else {
      openNotification("error", "topRight", _res?.message)
    }
  }
  return (
    <Row style={{ padding: "65px 20px" }} gutter={15} className="page-content">
      <Col span={4}>
        <FilterModal
          hinhThuc={hinhThucTT}
          loai={loaiPT}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              style={{
                width: "300px",
                padding: "6px 11px",
                borderRadius: 10,
                border: "none",
                borderBottom: "1px solid #d9d9d9",
                backgroundColor: "#ffff",
                color: "#bfbfbf;",
              }}
              placeholder="Tìm kiếm mã, tên sản phẩm"
              loading={loading}
              onChange={e => onSearch(e.target.value)}
              // enterButton
            />
          )}
          right={() => (
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 150,
            x: 2800,
          }}
          size="small"
        />
      </Col>
      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        hinhThuc={hinhThucTT}
        loai={loaiPT}
      />
      {contextHolder}
    </Row>
  )
}
