
export const GET_PERMISSION = "GET_PERMISSION"
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS"

export const ADD_PERMISSION = "ADD_PERMISSION"
export const EDIT_PERMISSION = "EDIT_PERMISSION"


export const DELETE_PERMISSION = "DELETE_PERMISSION"
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS"


export const GET_LIST_MODULE = "GET_LIST_MODULE"
export const GET_LIST_MODULE_SUCCESS = "GET_LIST_MODULE_SUCCESS"

export const GET_PER_SDBID = "GET_PER_SDBID"
export const GET_PER_SDBID_SUCCESS = "GET_PER_SDBID_SUCCESS"



export const ADD_ACCESSDATA_PER = "ADD_ACCESSDATA_PER"

export const GET_ACCESSDATA_PER = "GET_ACCESSDATA_PER"
export const GET_ACCESSDATA_PER_SUCCESS = "GET_ACCESSDATA_PER_SUCCESS"

export const GET_FEATURE_HAS_NAME = "GET_FEATURE_HAS_NAME"
export const GET_FEATURE_HAS_NAME_SUCCESS = "GET_FEATURE_HAS_NAME_SUCCESS"

export const ADD_FEATURE_PER = "ADD_FEATURE_PER"
export const ADD_FEATURE_PER_SUCCESS = "ADD_FEATURE_PER_SUCCESS"

export const DELETE_FEATURE_PER = "DELETE_FEATURE_PER"
export const DELETE_FEATURE_PER_SUCCESS = "DELETE_FEATURE_PER_SUCCESS"
//redux not saga
export const GET_FORM_BY_MODULE = "GET_FORM_BY_MODULE"