import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import {
  BEE_Dropdown,
  BEE_InputDate,
  BEE_InputNumber,
  BEE_InputText,
  BEE_InputTextArea,
  BEE_XLAYOUT_FORM,
} from "../../Forms/BEE_Form"
import { Button } from "primereact/button"
function BBBanGiao_Detail(props, ref) {
  const { afterSubmit, reloadPage, openTask } = props
  const refFunctionMode = useRef(null)
  const [show, setShow] = useState(false)
  const defaultdata = {}
  const [data, setData] = useState(defaultdata)
  useImperativeHandle(ref, () => ({
    create: async () => {
      let _data = _.cloneDeep(data)
      refFunctionMode.current = "create"
      setData(_data)
      setShow(true)
    },
    update: async _item => {
      let _data = _.cloneDeep(data)
      refFunctionMode.current = "update"
      setData(_data)
      setShow(true)
    },
    view: async _item => {
      refFunctionMode.current = "view"
      setData(_item)
      setShow(true)
    },
  }))
  const cancel = () => {
    if (!openTask && typeof reloadPage == "function") {
      reloadPage()
    }
    setShow(false)
    setData(defaultdata)
  }
  try {
    return (
      <Dialog
        visible={show}
        header={
          refFunctionMode.current === "create"
            ? "Thêm"
            : refFunctionMode.current === "update"
            ? "Sửa"
            : "Xem"
        }
        className={`${
          show ? "fadeinleft" : "fadeoutleft"
        } animation-duration-300 animation-iteration-1`}
        modal
        maximizable
        maximized
        onHide={() => cancel()}
      >
        <div
          style={{ height: "35px", background: "#ffffff" }}
          className="shadow-3 rounded mt-2"
        >
			 <Button label="Luu"
                  icon="pi pi-save"
                  className="p-button-success mr-1"
                  onClick={()=>refDetail.current.create()} 
                  />
		</div>
        <BEE_XLAYOUT_FORM
          header="Thông tin chung"
          fullWidth
          // icon='bx bx-list-plus'
          renderBody={() => (
            <React.Fragment>
              <div className="flex gap-3 mb-3">
                <BEE_InputText
                  // col={3}
                  require
                  // object={tripRequest}
                  className="flex-1 p-disabled"
                  prop="name"
                  label="Họ và tên: "
                  errors={{ name: "Không để trống" }}
                  onChangeFunction={() => {}}
                />
                <BEE_InputText
                  // col={3}
                  require
                  // object={tripRequest}
                  className="flex-1"
                  prop="tripPlanCode"
                  label="Di động:"
                  onChangeFunction={() => {}}
                />
                <BEE_InputText
                  // col={3}
                  // require
                  // object={tripRequest}
                  className="flex-1"
                  prop="tripPlanCode"
                  label="Email"
                  onChangeFunction={() => {}}
                />
                <BEE_InputText
                  // col={3}
                  // require
                  // object={tripRequest}
                  className="flex-1"
                  prop="tripPlanCode"
                  label="Di động 2"
                  onChangeFunction={() => {}}
                />
              </div>
              <div className="flex gap-3">
                <BEE_InputNumber
                  // colLabel={3}
                  // require
                  className="flex-1"
                  onChangeFunction={() => {}}
                  prop="tien"
                  label="So tien"
                />
                <BEE_Dropdown
                  // colLabel={3}
                  // require
                  options={[
                    { id: 1, Name: " tesst" },
                    { id: 2, Name: "test 2" },
                  ]}
                  optionValue="id"
                  optionLabel="Name"
                  className="flex-1"
                  onChangeFunction={() => {}}
                  prop="tien"
                  label="So tien"
                />
                <BEE_InputTextArea
                  autoResize
                  // require
                  className="flex-1"
                  onChangeFunction={() => {}}
                  prop="tien"
                  label="Ghi chu"
                />
                <BEE_InputDate
                  showTime
                  // require
                  // object={tripRequest}
                  className="flex-1"
                  display="month"
                  prop="date"
                  label="Nội dung"
                  onChangeFunction={() => {}}
                />
              </div>
            </React.Fragment>
          )}
        />
      </Dialog>
    )
  } catch (error) {}
}
BBBanGiao_Detail = forwardRef(BBBanGiao_Detail)
export default BBBanGiao_Detail
