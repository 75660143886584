import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Button,
  Nav,
  NavItem,
  TabContent,
  Col,
  Card,
  CardBody,
  Container,
  NavLink,
  Row,
  ListGroupItem,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  getListModule,
  getPerSDBID,
  getFeatureHasName,
  getFormByModule,
  addAccessDataPer,
  getAccessDataPer,
  addFeaturePer,
  deleteFeaturePer,
} from "../../store/actions"
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { useParams } from "react-router-dom"
const SetupPermission = props => {
  const dispatch = useDispatch()
  const { listModule, perSDBID, listFeature, listForm, accessData } =
    useSelector(state => ({
      listModule: state.Permission.listModule,
      perSDBID: state.Permission.perSDBID,
      listFeature: state.Permission.listFeature,
      listForm: state.Permission.listForm,
      accessData: state.Permission.accessData,
    }))
  const param = useParams()
  useEffect(() => {
    dispatch(getListModule({ PerID: Number(param.id) }))
  }, [])
  const [active, setActive] = useState(35)
  const [activeDetailForm, setActiveDetailForm] = useState(0)

  const onClickModule = id => {
    setActive(id)
    setActiveDetailForm(0)
    dispatch(getFormByModule(Number(id)))
  }
  const onClickForm = id => {
    setActiveDetailForm(id)
    dispatch(getPerSDBID())
    dispatch(
      getFeatureHasName({
        PerID: Number(param.id),
        FormID: id,
      })
    )
  }
  useEffect(() => {
    dispatch(
      getAccessDataPer({ PerID: Number(param.id), FormID: activeDetailForm })
    )
  }, [perSDBID])
  useEffect(() => {}, [accessData])
  const onChangePer = id => {
    dispatch(
      addAccessDataPer({
        SDBID: Number(id.target.value),
        PerID: Number(param.id),
        FormID: activeDetailForm,
      })
    )
    // dispatch(getPerSDBID())
  }
  const onChangeFeature = (id, e) => {
    if (e.target.checked === true) {
      dispatch(
        addFeaturePer({
          FormID: activeDetailForm,
          FeatureID: Number(id),
          PerID: Number(param.id),
        })
      )
    } else {
      dispatch(
        deleteFeaturePer({
          FormID: activeDetailForm,
          FeatureID: Number(id),
          PerID: Number(param.id),
        })
      )
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Danh sách"
          route="/permission"
          breadcrumbItem="Phân quyền"
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody style={{ minHeight: "700px" }}>
                <Row>
                  <Col md="3 mt-1">
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i>
                      Module
                    </h5>
                    {listModule &&
                      listModule.map(item => (
                        <div
                          style={{ cursor: "pointer" }}
                          key={item.modulID}
                          className={`rounded border mb-1 p-2  ${
                            active === item.modulID ? "active-tab-permission" : ""
                          }`}
                          onClick={() => onClickModule(item.modulID)}
                        >
                          <span className="d-block d-sm-none">
                            <i className={item.icon}></i>
                          </span>
                          <span className="d-none d-sm-block">
                            {item.nameWeb}
                          </span>
                        </div>
                      ))}
                  </Col>
                  <Col md="9">
                    <Row className="mt-1">
                      <Col md="3">
                        <h5 className="font-size-14 mb-4">
                          <i className="mdi mdi-arrow-right text-primary mr-1"></i>
                          Form
                        </h5>
                        {listForm.length === 0 ? (
                          <span>Danh sách trống</span>
                        ) : (
                          listForm.map(item => (
                            <div
                              style={{ cursor: "pointer" }}
                              key={item.FormID}
                              className={`rounded border mb-1 p-2 ${
                                item.FormID === activeDetailForm ? "active-tab-permission" : ""
                              }`}
                              onClick={() => onClickForm(item.FormID)}
                            >
                              {item.NameWeb}
                            </div>
                          ))
                        )}
                      </Col>
                      <Col md="3">
                        <h5 className="font-size-14 mb-4">
                          <i className="mdi mdi-arrow-right text-primary mr-1"></i>
                          Quyền xem dữ liệu
                        </h5>
                        <AvForm>
                          {activeDetailForm !== 0 ? (
                            <AvRadioGroup
                              name="perID"
                              defaultValue={6}
                              value={(accessData && accessData[0]?.sdbid) || 6}
                              onChange={e => onChangePer(e)}
                            >
                              <div className="mb-3">
                                <AvRadio label="Tất cả" value={1} />
                              </div>
                              <div className="mb-3">
                                <AvRadio label="Theo phòng" value={2} />
                              </div>
                              <div className="mb-3">
                                <AvRadio label="Theo nhóm" value={3} />
                              </div>
                              <div className="mb-3">
                                <AvRadio label="Theo nhân viên" value={4} />
                              </div>
                              <div className="mb-3">
                                <AvRadio label="Không có quyền" value={6} />
                              </div>
                            </AvRadioGroup>
                          ) : null}
                        </AvForm>
                      </Col>
                      <Col md="3">
                        <h5 className="font-size-14 mb-4">
                          <i className="mdi mdi-arrow-right text-primary mr-1"></i>
                          Thao tác
                        </h5>
                        <AvForm>
                          <AvCheckboxGroup inline name="checkFeatures">
                            {listFeature &&
                              listFeature.map(item => {
                                return (
                                  <AvCheckbox
                                    key={item.featureID}
                                    name={item.featureID}
                                    className="form-check-input mb-3"
                                    label={item.nameWeb}
                                    value={item.featureID}
                                    checked={item.check}
                                    disabled={
                                      accessData[0]?.sdbid === 6 ? true : false
                                    }
                                    onChange={e =>
                                      onChangeFeature(item.featureID, e)
                                    }
                                  />
                                )
                              })}
                          </AvCheckboxGroup>
                        </AvForm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

// SetupPermission.propTypes = {
//   toggle: PropTypes.func,
//   isOpen: PropTypes.bool,
// }

export default React.memo(SetupPermission)
