import axios from "axios"
import { del, get, post, put } from "../../helpers/api_helper"

// add Events
export const getPermissionAPI = () => post("/admin/List_Permission")
export const addPermissionAPI = payload => post("/admin/AddPermission", payload)
export const editPermissionAPI = payload => post("/admin/AddPermission", payload)

export const deletePermissionAPI = payload => post("/admin/DeltetePermission", payload)
export const getListModuleAPI = payload => post("/admin/ListModule ", payload)

export const getPerSDBIDAPI = () => post("/admin/perSDBID")
export const getFeatureHasNameAPI = payload => post("/admin/ListTinhNang ", payload)
export const addAccessDataPerAPI = payload => post("/admin/AddAccessData ", payload)
export const getAccessDataPerAPI = payload => post("/admin/GetAccessData ", payload)
export const addFeaturePerAPI = payload => post("/admin/Addfeature ", payload)
export const deleteFeaturePerAPI = payload => post("/admin/Deltetefeature ", payload)