import axios from "axios"
import { del, get, post, put, postImg } from "../../helpers/api_helper"

// add Events
export const uploadImageAPI = payload => postImg("/beeland/upload-image", payload)
export const getKhuAPI = payload => post("/admin/project/list_khu",payload);
export const getKhuByListDAAPI = payload =>
  post("/admin/project/list_khu_by_listda", payload)
export const getPhanKhuAPI = payload => post("/admin/project/list_phan_khu", payload)
export const getBDSTrangThaiAPI = () => get("/admin/san-pham/trang-thai")
export const getSanGiaoDichAPI = () => get("/admin/get-san-giao-dich");