import * as types from './actionTypes'

export const getTinTuc = (payload) =>({ 
    type: types.GET_TINTUC,
    payload: payload
})
export const addTinTuc = payload => ({
  type: types.ADD_TINTUC,
  payload: payload,
})
export const deleteTinTuc = payload => ({
  type: types.DELETE_TINTUC,
  payload: payload,
})
export const getLoaiTinTuc = () => ({
  type: types.GET_LOAI_TINTUC
})