import { call, put, takeEvery } from "redux-saga/effects"
// Staff Redux States
import * as types from "./actionTypes"
import { toast } from "react-toastify"
//Include Both Helper File with needed methods
import {
  getBDSTrangThaiAPI,
  getKhuAPI,
  getPhanKhuAPI,
  uploadImageAPI,
  getKhuByListDAAPI,
  getSanGiaoDichAPI,
} from "./service"
import { addImageForsale } from "../actions"

function* uploadImage(action) {
  try {
    // debugger
    const response = yield call(uploadImageAPI, action.payload.formData)
    if (response.status === 2000 && response.data !== "[]") {
      yield put(
        addImageForsale({
          MaBC: action.payload.maBC,
          RequestIMG: response.data,
        })
      )
       yield put({
        type: types.UPLOAD_IMAGE_SUCCESS,
      })
    }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* getKhu(action) {
  try {
    const response = yield call(getKhuAPI, action.payload);
    if (response.status === 2000 && response.data !== "[]") {
      yield put({type: types.GET_KHU_SUCCESS,payload:response.data});
    }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* getKhuByListDA(action) {
  try {
    const response = yield call(getKhuByListDAAPI, action.payload)
    if (response.status === 2000 && response.data !== "[]") {
      yield put({ type: types.GET_KHU_BY_LISTDA_SUCCESS, payload: response.data })
    }
  } catch (error) {
    
  }
}
function* getPhanKhu(action) {
  try {
    
    const response = yield call(getPhanKhuAPI, action.payload);
    if (response.status === 2000 && response.data !== "[]") {
      yield put({ type: types.GET_PHANKHU_SUCCESS, payload: response.data })
    }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* getBDSTrangThai(action) {
  try {
    const response = yield call(getBDSTrangThaiAPI)
    if (response.status === 2000 && response.data !== "[]") {
      yield put({ type: types.GET_BDS_TRANGTHAI_SUCCESS, payload: response.data })
    }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
function* getSanGiaoDich() {
  try {
    const response = yield call(getSanGiaoDichAPI)
    if (response.status === 2000 && response.data !== "[]") {
      yield put({
        type: types.GET_SANGIAODICH_SUCCESS,
        payload: response.data,
      })
    }
  } catch (error) {
    // yield put(getOrdersFail(error))
  }
}
export default function* commonSaga() {
  yield takeEvery(types.UPLOAD_IMAGE, uploadImage);
  yield takeEvery(types.GET_KHU, getKhu);
  yield takeEvery(types.GET_KHU_BY_LISTDA, getKhuByListDA);
  yield takeEvery(types.GET_PHANKHU, getPhanKhu);
  yield takeEvery(types.GET_BDS_TRANGTHAI, getBDSTrangThai);
  yield takeEvery(types.GET_SANGIAODICH, getSanGiaoDich);
}
