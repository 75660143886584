import React, { useState, useImperativeHandle, forwardRef } from "react"
import { Dialog } from "primereact/dialog"
import _ from "lodash"
import { Card, CardBody } from "reactstrap"
import { getListModuleAPI,getFeatureHasNameAPI } from "store/permission/per.service"
import { Tree } from "primereact/tree"

function Permision_Detail(props, ref) {
  const [show, setShow] = useState(false)
  const [listModule, setListModule] = useState([])
  useImperativeHandle(ref, () => ({
    view: async _item => {
      getListModuleAPI({ PerID: Number(_item.perID) }).then(res => {
        let module = []
        res.data?.map(item => {
          module.push({
            ...item,
            key: item.modulID,
            label: item.nameWeb,
            children: item.detailForms.map((v)=>{
              return {  
                key: v.FormID,
                label: v.NameWeb,
              }
            }),
          })
        })
         (module)
      })
      setShow(true)
    },
  }))
  const cancel = () => {
    setShow(false)
  }
  const onExpand = (event) => {
  //  debugger
}
  try {
    return (
      <Dialog
        visible={show}
        header="Phân quyền"
        style={{ width: "40%", height: "100%" }}
        className={`relative ${
          show ? "fadeinleft" : "fadeoutleft"
        } animation-duration-300 animation-iteration-1`}
        modal
        onHide={() => cancel()}
        maximizable
      >
        <Card className="mb-0">
          <CardBody>
            <Tree
              value={listModule}
              selectionMode="single"
              // selectionKeys={selectedNodeKey}
              // onSelectionChange={e => setSelectedNodeKey(e.value)}
              onExpand={onExpand}
              // onCollapse={onCollapse}
              // onSelect={onSelect}
              // onUnselect={onUnselect}
            />
          </CardBody>
        </Card>
      </Dialog>
    )
  } catch (error) {}
}
Permision_Detail = forwardRef(Permision_Detail)
export default Permision_Detail
