import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import { useSelector } from "react-redux"
import img7 from "../../assets/images/product/img-7.png"
// import img4 from "../../../assets/images/product/img-4.png"
import { handleValidDate } from "../../common/common"
const DetailModal = props => {  
   const { staff } = useSelector(state => ({
     staff: state.Staff.staff,
   }))
  const { isOpen, toggle } = props;
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      // backdrop={false}
      returnFocusAfterClose={true}
      size="lg"
      // style={{ maxWidth: "1000px", width: "100%" }}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Nhân viên chi tiết</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <p className="mb-3">
                Mã nhân viên:
                <span className="text-primary">{staff?.maSo}</span>
              </p>
              <p className="mb-3">
                Họ tên: <span className="text-primary">{staff?.hoTen}</span>
              </p>
              <p className="mb-3">
                Điện thoại:
                <span className="text-primary">{staff?.dienThoai}</span>
              </p>
              <p className="mb-3">
                Email: <span className="text-primary">{staff?.email}</span>
              </p>
            </div>
            <div className="col-md-6">
              <p className="mb-3">
                Ngày sinh:
                <span className="text-primary">{staff?.ngaySinh}</span>
              </p>
              <p className="mb-3">
                Số CMND:
                <span className="text-primary">{staff?.soCMND}</span>
              </p>
              <p className="mb-3">
                Ngày cấp: <span className="text-primary">{staff?.ngayCap}</span>
              </p>
              <p className="mb-3">
                Nơi cấp:
                <span className="text-primary">{staff?.noiCap}</span>
              </p>
            </div>
            <div className="col-md-12">
              <p className="mb-3">
                Phòng ban:
                <span className="text-primary">{staff?.tenPB}</span>
              </p>
              <p className="mb-3">
                Nhóm kinh doanh:
                <span className="text-primary">{staff?.tenNKD}</span>
              </p>
              <p className="mb-3">
                Chức vụ: <span className="text-primary">{staff?.tenCV}</span>
              </p>
              <p className="mb-3">
                Tên công ty:
                <span className="text-primary">{staff?.tenCT}</span>
              </p>
              <p className="mb-3">
                Ngày nhập:
                <span className="text-primary">
                  {handleValidDate(staff?.ngayNhap)}
                </span>
              </p>
              <p className="mb-3">
                Địa chỉ:
                <span className="text-primary">{staff?.diaChi}</span>
              </p>
              <p className="mb-3">
                Địa chỉ LL:
                <span className="text-primary">{staff?.diaChiLL}</span>
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Đóng
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

DetailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default React.memo(DetailModal)
