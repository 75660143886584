import { post } from "../helpers/api_helper"
export const ProductService = {
  checkBooking: async payload => {
    return await post("admin/san-pham/check-booking", payload)
  },
  Categories: {},
  DatNen: {
    filter: async payload => {
      let _payload = {
        ...payload,
        MaLBDS: -1,
        MaKhu: -1,
        MaPK: -1,
        MaTT: -1,
      }
      return await post("admin/san-pham/dat-nen", _payload)
    },
    getById: async payload => {
      //MaSP
      return await post("admin/SanPham/ChiTiet", payload)
    },
    lockCan: async payload => {
      //maSP
      return await post("admin/san-pham/lock-can", payload)
    },
    addProduct: async payload => {
      return await post("admin/san-pham/add-product-coord", payload)
    },
    removeViTri: async payload => {
      return await post("admin/san-pham/delete-coord", payload)
    },
  },
}
