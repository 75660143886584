import React, { useEffect, useRef, useState } from "react"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { InputTextarea } from "primereact/inputtextarea"
import { XCalendar } from "./x-calendar/XCalendar"
import { AutoComplete } from "primereact/autocomplete"
import _ from "lodash"
import "./FormComponent.scss"
import { Accordion, AccordionTab } from "primereact/accordion"
import { useImperativeHandle } from "react"
import { forwardRef } from "react"
import { Flex } from "antd"
function BEE_XLAYOUT_FORM(props, ref) {
  const { header, icon, className, renderBody, formType } = props
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    let findHeader = document.querySelector(
      ".bee-form  .p-accordion-header .p-accordion-header-text"
    )
    if (findHeader) {
      let findI = findHeader.querySelector("i")
      if (!findI) {
        findHeader.classList.add("flex")
        findHeader.classList.add("align-items-center")
        findHeader.innerHTML = `<i class='${icon} mr-2'></i> ${header}`
      }
    }
    return () => findHeader
  })

  useImperativeHandle(ref, () => ({
    setActiveIndex: index => {
      setActiveIndex(index)
    },
  }))

  return (
    <div className={`bee-form pt-2 px-3  ${className || ""}`}>
      <Accordion
        activeIndex={activeIndex}
        onTabChange={e => setActiveIndex(e.index)}
      >
        <AccordionTab
          headerClassName="shadow-5 mb-2"
          headerStyle={{ backgroundColor: "var(--main-color-light)" }}
          header={header}
          className="shadow-2 mb-2"
        >
          <div className="grid px-2 pb-2 ">{renderBody()}</div>
        </AccordionTab>
      </Accordion>
    </div>
  )
}
function getRandomId() {
  return Math.round(Math.random() * 10000000)
}
function renderErrors(prop, errors) {
  if (_.isEmpty(errors && errors[prop])) {
    return <></>
  } else {
    return <small className="p-invalid">{errors[prop]}</small>
  }
}
function BEE_Dropdown(props) {
  const {
    col,
    label,
    options,
    optionLabel,
    optionValue,
    object,
    prop,
    disabled,
    filter,
    errors,
    onChangeFunction,
    require,
    className,
    classNameInput,
    itemTemplate,
    valueTemplate,
    isShowClear,
  } = props
  return localStorage.getItem("formType") === "line" ? (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input px-0  ${className ? className : ""} 
				${
          errors && errors[prop]
            ? "bee-form-input-border-valid"
            : "bee-form-input-border"
        } `}
      >
        <div className="flex align-items-center">
          {!label ? null : (
            <label className="mb-0 flex-none">
              {label} {require && <span className="text-danger">*</span>}
            </label>
          )}
          <div className="flex-1 p-0">
            <Dropdown
              valueTemplate={valueTemplate}
              itemTemplate={itemTemplate}
              options={options}
              disabled={disabled}
              filter={filter}
              optionLabel={optionLabel}
              optionValue={optionValue || "id"}
              value={object && object[prop]}
              className={`text-overflow-ellipsis ${classNameInput} ${
                errors && errors[prop] ? "p-invalid" : ""
              }`}
              showClear={isShowClear}
              onChange={e => onChangeFunction(prop, e.value)}
            ></Dropdown>
            {renderErrors(prop, errors)}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input-box px-0  ${className ? className : ""}  `}
      >
        {!label ? null : (
          <label className="mb-0 ">
            {label} {require && <span className="text-danger">*</span>}
          </label>
        )}
        <div className="p-0">
          <Dropdown
            valueTemplate={valueTemplate}
            itemTemplate={itemTemplate}
            options={options}
            disabled={disabled}
            filter={filter}
            optionLabel={optionLabel}
            optionValue={optionValue || "id"}
            value={object && object[prop]}
            className={`w-full text-overflow-ellipsis ${classNameInput} ${
              errors && errors[prop] ? "p-invalid" : ""
            }`}
            showClear={isShowClear}
            onChange={e => onChangeFunction(prop, e.value)}
          ></Dropdown>
          {renderErrors(prop, errors)}
        </div>
      </div>
    </div>
  )
}
function BEE_InputText(props) {
  const {
    col,
    object,
    prop,
    disabled,
    errors,
    onChangeFunction,
    require,
    className,
    label,
  } = props
  return localStorage.getItem("formType") === "line" ? (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input px-0  ${className ? className : ""} 
			${
        errors && errors[prop]
          ? "bee-form-input-border-valid"
          : "bee-form-input-border"
      } `}
      >
        <div className="flex align-items-center">
          {!label ? null : (
            <label className="mb-0 flex-none">
              {label} {require && <span className="text-danger">*</span>}
            </label>
          )}
          <div className="flex-1 p-0">
            <InputText
              disabled={disabled}
              value={object && object[prop]}
              className={`w-full input-line ${
                errors && errors[prop] ? "p-invalid" : ""
              }`}
              onChange={e => onChangeFunction(prop, e.target.value)}
            />
          </div>
          {renderErrors(prop, errors)}
        </div>
      </div>
    </div>
  ) : (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input-box px-0  ${className ? className : ""} `}
      >
        <div className=" align-items-center">
          {!label ? null : (
            <label className="mb-0 ">
              {label} {require && <span className="text-danger">*</span>}
            </label>
          )}
          <InputText
            disabled={disabled}
            value={object && object[prop]}
            className={`w-full ${errors && errors[prop] ? "p-invalid" : ""}`}
            onChange={e => onChangeFunction(prop, e.target.value)}
          />
          {renderErrors(prop, errors)}
        </div>
      </div>
    </div>
  )
}
function BEE_InputNumber(props) {
  const {
    col,
    object,
    prop,
    disabled,
    errors,
    onChangeFunction,
    require,
    className,
    label,
    min,
    max,
  } = props
  return localStorage.getItem("formType") === "line" ? (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input px-0   ${className ? className : ""} 
			${
        errors && errors[prop]
          ? "bee-form-input-border-valid"
          : "bee-form-input-border"
      } `}
      >
        <div className="flex align-items-center">
          {!label ? null : (
            <label className="mb-0 flex-none">
              {label} {require && <span className="text-danger">*</span>}
            </label>
          )}
          <div className="flex-1 p-0">
            <InputNumber
              value={object && object[prop]}
              // locale={CommonFunction.getCurrentLanguage()}
              mode="decimal"
              min={min}
              max={max}
              minFractionDigits={0}
              maxFractionDigits={2}
              disabled={disabled}
              className={`input-line w-full ${
                errors && errors[prop] ? "p-invalid" : ""
              }`}
              onChange={e => onChangeFunction(prop, e.value)}
            />
            {renderErrors(prop, errors)}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input-box px-0   ${className ? className : ""}  `}
      >
        {!label ? null : (
          <label className="mb-0 ">
            {label} {require && <span className="text-danger">*</span>}
          </label>
        )}
        <InputNumber
          value={object && object[prop]}
          mode="decimal"
          min={min}
          max={max}
          minFractionDigits={0}
          maxFractionDigits={2}
          disabled={disabled}
          className={`p-input-number w-full ${
            errors && errors[prop] ? "p-invalid" : ""
          }`}
          onChange={e => onChangeFunction(prop, e.value)}
        />
        {renderErrors(prop, errors)}
      </div>
    </div>
  )
}
function BEE_InputTextArea(props) {
  const {
    col,
    object,
    prop,
    errors,
    onChangeFunction,
    require,
    autoResize,
    className,
    label,
  } = props
  return localStorage.getItem("formType") === "line" ? (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input px-0   ${className ? className : ""} 
			${
        errors && errors[prop]
          ? "bee-form-input-border-valid"
          : "bee-form-input-border"
      } `}
      >
        <div className="flex align-items-center">
          {!label ? null : (
            <label className="mb-0 flex-none">
              {label} {require && <span className="text-danger">*</span>}
            </label>
          )}
          <div className="flex-1 p-0">
            <InputTextarea
              value={object && object[prop]}
              mode="decimal"
              minFractionDigits={0}
              maxFractionDigits={2}
              autoResize={autoResize}
              className={`w-full ${errors && errors[prop] ? "p-invalid" : ""}`}
              onChange={e => onChangeFunction(prop, e.target.value)}
            />
            {renderErrors(prop, errors)}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input-box px-0  ${className ? className : ""} `}
      >
        {!label ? null : (
          <label className="mb-0">
            {label} {require && <span className="text-danger">*</span>}
          </label>
        )}
        <InputTextarea
          value={object && object[prop]}
          mode="decimal"
          minFractionDigits={0}
          maxFractionDigits={2}
          autoResize={autoResize}
          className={`w-full ${errors && errors[prop] ? "p-invalid" : ""}`}
          onChange={e => onChangeFunction(prop, e.target.value)}
        />
        {renderErrors(prop, errors)}
      </div>
    </div>
  )
}
function BEE_InputDate(props) {
  const {
    col,
    label,
    object,
    prop,
    disabled,
    showTime,
    showDate,
    display,
    timeOnly,
    isSeparateLabel,
    errors,
    onChangeFunction,
    require,
    className,
  } = props
  const refId = useRef(getRandomId())
  return (
    <div
      className={`bee-form-input px-0 col-${col} ${className ? className : ""}
			${
        errors && errors[prop]
          ? "bee-form-input-border-valid"
          : "bee-form-input-border"
      } `}
      id={`BEE_InputDate-${refId}`}
    >
      <div className={`flex align-items-center`}>
        {!label ? null : <label className={`mb-0 flex-none`}>{label}</label>}
        <div className="flex-1 p-0">
          <XCalendar
            showTime={showTime}
            showDate={showDate}
            display={display}
            timeOnly={timeOnly}
            className={`${errors && errors[prop] ? "p-invalid" : ""}`}
            value={object && object[prop]}
            require={require}
            disabled={disabled}
            onChange={e => onChangeFunction(prop, e)}
          ></XCalendar>
          {renderErrors(prop, errors)}
        </div>
      </div>
    </div>
  )
}
// function BEE_AutoComplete(props) {
//   const {
//     col,
//     object,
//     prop,
//     disabled,
//     errors,
//     onChangeFunction,
//     require,
//     className,
//     label,
//     params,
//     api,
//     name,
//   } = props

//   const [data, setData] = useState(null)

//   const search = async e => {
//     let param = {
//       inputSearch: e.query,
//       ...params,
//     }

//     try {
//       let res = await api(param)

//       // Ensure res.data is an array
//       let rescus = Array.isArray(res.data)
//         ? res.data.map(item => {
//             return {
//               ...item,
//               name: item.name,
//               diDong: item.diDong,
//             }
//           })
//         : []

//       setData(rescus)
//     } catch (error) {
//       console.error("Error fetching data:", error)
//       setData([])
//     }
//   }
//   console.log(data, "huan")
//   return localStorage.getItem("formType") === "line" ? (
//     <div className={`col-${col}`}>
//       <div
//         className={`bee-form-input px-0  ${className ? className : ""}
//   		${
//         errors && errors[prop]
//           ? "bee-form-input-border-valid"
//           : "bee-form-input-border"
//       } `}
//       >
//         <div className="flex align-items-center">
//           {!label ? null : (
//             <label className="mb-0 flex-none">
//               {label} {require && <span className="text-danger">*</span>}
//             </label>
//           )}
//           <div className="flex-1 p-0">
//             <InputText
//               disabled={disabled}
//               value={object && object[prop]}
//               className={`w-full input-line ${
//                 errors && errors[prop] ? "p-invalid" : ""
//               }`}
//               onChange={e => onChangeFunction(prop, e.target.value)}
//             />
//           </div>
//           {renderErrors(prop, errors)}
//         </div>
//       </div>
//     </div>
//   ) : (
//     <div>
//       <div>
//         <div>
//           <Flex className="CustomTKKH" justify="flex-start" align="center">
//             <p style={{ fontWeight: "600", width: "37%" }}>Tìm khách hàng:</p>

//             <AutoComplete
//               style={{ border: "none", boxShadow: "none", width: "63%" }}
//               field={name}
//               suggestions={data}
//               placeholder="Tìm kiếm khách hàng "
//               completeMethod={e => search(e)}
//               onChange={e => onChangeFunction(prop, e.value)}
//               value={object && object[prop]}
//               itemTemplate={item => (
//                 <div>
//                   <p>{item[name] + " - " + item[diDong]} </p>
//                 </div>
//               )}
//             />
//             {renderErrors(prop, errors)}
//           </Flex>
//         </div>
//       </div>
//     </div>
//   )
// }

function BEE_AutoComplete(props) {
  const {
    col,
    object,
    prop,
    disabled,
    errors,
    onChangeFunction,
    require,
    className,
    label,
    params,
    api,
    name,
    diDong, // Make sure to pass diDong as a prop
  } = props

  const [data, setData] = useState(null)

  const search = async e => {
    let param = {
      inputSearch: e.query,
      ...params,
    }

    try {
      let res = await api(param)

      // Ensure res.data is an array
      let rescus = Array.isArray(res.data)
        ? res.data.map(item => {
            return {
              ...item,
              name: item.name,
              diDong: item.diDong, // Ensure diDong is included
            }
          })
        : []

      setData(rescus)
    } catch (error) {
      console.error("Error fetching data:", error)
      setData([])
    }
  }

  console.log(data, "huan")
  return localStorage.getItem("formType") === "line" ? (
    <div className={`col-${col}`}>
      <div
        className={`bee-form-input px-0 ${className ? className : ""} ${
          errors && errors[prop]
            ? "bee-form-input-border-valid"
            : "bee-form-input-border"
        } `}
      >
        <div className="flex align-items-center">
          {!label ? null : (
            <label className="mb-0 flex-none">
              {label} {require && <span className="text-danger">*</span>}
            </label>
          )}
          <div className="flex-1 p-0">
            <InputText
              disabled={disabled}
              value={object && object[prop]}
              className={`w-full input-line ${
                errors && errors[prop] ? "p-invalid" : ""
              }`}
              onChange={e => onChangeFunction(prop, e.target.value)}
            />
          </div>
          {renderErrors(prop, errors)}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div>
        <div>
          <Flex className="CustomTKKH" justify="flex-start" align="center">
            <p style={{ fontWeight: "600", width: "37%" }}>Tìm khách hàng:</p>
            <AutoComplete
              style={{ border: "none", boxShadow: "none", width: "63%" }}
              field={name}
              suggestions={data}
              placeholder="Tìm kiếm khách hàng "
              completeMethod={e => search(e)}
              onChange={e => onChangeFunction(prop, e.value)}
              value={object && object[prop]}
              itemTemplate={item => (
                <div>
                  <p>{item[name] + " - " + item[diDong]}</p>
                </div>
              )}
            />
            {renderErrors(prop, errors)}
          </Flex>
        </div>
      </div>
    </div>
  )
}

// export default BEE_AutoComplete;

BEE_XLAYOUT_FORM = forwardRef(BEE_XLAYOUT_FORM)
export {
  BEE_Dropdown,
  BEE_InputText,
  BEE_InputNumber,
  BEE_InputDate,
  BEE_InputTextArea,
  BEE_AutoComplete,
  renderErrors,
  BEE_XLAYOUT_FORM,
}
