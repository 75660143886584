import * as types from './actionTypes';

export const getGiuCho = (payload) => ({
    type: types.GET_GIUCHO,
    payload
})
export const getDatCoc = payload => ({
  type: types.GET_DATCOC,
  payload,
})
export const getHopDongTrangThai = payload => ({
  type: types.GET_HOPDONG_TRANGTHAI,
  payload,
})
export const getBangGiaTT = payload => ({
  type: types.GET_BANGGIATT,
  payload,
})
export const getTangBG = payload => ({
  type: types.GET_TANG_BG,
  payload,
})
export const getViTriBG = payload => ({
  type: types.GET_VITRI_BG,
  payload,
})
export const getKhuChiTietBG = payload => ({
  type: types.GET_KHU_CHITIET_BG,
  payload,
})