import { del, get, post, put } from "../helpers/api_helper"

export const InfoService = {
  getChucNang: async payload => {
    return await post("get-chucnangthongbao", payload).then(res => res)
  },
  getCaiDat: async payload => {
    return await post("thong-bao/get-caidat", payload).then(res => res)
  },
  addCaiDat: async payload => {
    return await post("thong-bao/add-caidat", payload).then(res => res)
  },
  confirmRead: async payload => {
    return await post("hethong/noti/read", payload).then(res => res)
  },
}
