import { Button, Drawer, Image, Spin } from "antd"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { ProductService } from "services/Product"
import { useHistory } from "react-router-dom"
import { Format_Currency, getHexColor } from "common/common"

const DetailProduct = (props, ref) => {
  const history = useHistory()
  const [data, setData] = useState({})
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [timeLock, setTimeLock] = useState(0)
  const [isStart, setIsStart] = useState(false)
  useImperativeHandle(ref, () => ({
    show: async data => {
      setOpen(true)
      getProduct(data.MaSP)
    },
  }))
  useEffect(() => {
    let _time = null
    if (isStart === true) {
      _time = setInterval(() => {
        setTimeLock(pre => {
          pre < 0 && setIsStart(false)
          return pre - 1
        })
      }, 1000)
    }
    return () => clearInterval(_time)
  }, [isStart])
  const getProduct = async maSP => {
    setLoading(true)
    let _res = await ProductService.DatNen.getById({
      maSP: maSP,
    })
    if (_res.status === 2000) {
      setData(_res.data)
      setTimeLock(_res.data?.ThoiGianConLai)
      _res.data?.ThoiGianConLai > 0 && setIsStart(true)
    }
    setLoading(false)
  }
  const addBooking = () => {
    history.push("/banggiatructuyen/addgiucho/" + data.MaSP)
  }
  const lockCan = async () => {
    setLoading(true)
    let _res = await ProductService.DatNen.lockCan({ maSP: data.MaSP })
    if (_res.status === 2000) {
        setTimeLock(_res.data)
        setIsStart(true)
    }
    setLoading(false);
  }
  const getValueTime = () => {
    let _ss = Math.floor(timeLock % 60)
    let _mm = Math.floor((timeLock % (60 * 60)) / 60)
    return `${_mm < 10 ? "0" + _mm : _mm}:${_ss < 10 ? "0" + _ss : _ss}`
  }
  const onClose = () => {
    setOpen(false)
  }
  return (
    <Drawer
      title="Thông tin sản phẩm"
      width={500}
      onClose={() => onClose()}
      open={open}
      //   extra={
      //     <Space>
      //       <Dropdown menu={{ items }} placement="bottomRight" arrow>
      //         <i className="pi pi-ellipsis-v" />
      //       </Dropdown>
      //     </Space>
      //   }
    >
      <Spin spinning={loading}>
        <Image
          width="100%"
          height={250}
          src={data?.HinhAnh}
          style={{ borderRadius: "5px" }}
        />
        <div className="product-content">
          <p className="mt-3 mb-2" style={{ color: "#646464" }}>
            Dự án:{" "}
            <span style={{ fontWeight: "700", fontSize: 14, color: "#343434" }}>
              {data?.TenDA}
            </span>
          </p>
          <p className="mt-3 mb-2" style={{ color: "#646464" }}>
            Mã sản phẩm:{" "}
            <span style={{ fontWeight: "700", fontSize: 14, color: "#343434" }}>
              {data?.KyHieu}
            </span>
          </p>
          <div className="flex align-items-center  mb-2">
            <p className="mb-0" style={{ color: "#646464" }}>
              Trạng thái:
            </p>
            <div
              className="py-1 px-2"
              style={{
                backgroundColor: getHexColor(data.MauNen),
                borderRadius: "5px",
                width: "100px",
              }}
            >
              <p className="m-0" style={{ fontSize: 12, fontWeight: "700" }}>
                {data.TenTT}
              </p>
            </div>
          </div>
          <p className="mb-2" style={{ color: "#646464" }}>
            Giá trị:{" "}
            <span style={{ fontWeight: "900", fontSize: 15, color: "#343434" }}>
              {" "}
              {Format_Currency(data.TongGiaTriHDMB)}
            </span>{" "}
          </p>
        </div>
        <div className="product-footer flex  gap-3 pt-4">
          <Button
            onClick={() => lockCan()}
            danger
            loading={loading}
            disabled={timeLock > 0 ? true : false}
          >
            {timeLock > 0 ? getValueTime() : "Lock căn"}
          </Button>
          <Button onClick={() => addBooking()} type="primary">
            Book ngay
          </Button>
        </div>
      </Spin>
    </Drawer>
  )
}
export default forwardRef(DetailProduct)
