import * as types from "./actionTypes"

// House For Lease
export const getHouseForLeases = payload => ({
  type: types.GET_HOUSE_FOR_LEASES,
  payload,
})

//For Sale
export const getForSale = payload => ({
  type: types.GET_FORSALE,
  payload,
})
export const deleteForSale = payload => ({
  type: types.DELETE_FORSALE,
  payload,
})
export const getCategoriesForSale = () => ({
  type: types.GET_CATEGORIES_FORSALE
})

export const getStreet = (payload) => ({
  type: types.GET_STREET,
  payload
})
export const editForSale = payload => ({
  type: types.EDIT_FORSALE,
  payload,
})
export const addEditForSale = payload => ({
  type: types.ADD_EDIT_FORSALE,
  payload,
})
export const searchCustomersForsale = payload => ({
  type: types.SEARCH_CUSTOMERS_FORSALE,
  payload,
})

export const getLoaiBDS = () => ({
  type: types.GET_LOAIBDS,
})

export const getKhoangGia = () => ({
  type: types.GET_KHOANGGIA
})
export const getKhoangDT = () => ({
  type: types.GET_KHOANGDT,
})
export const getTrangThaiForSale = () => ({
  type: types.GET_TRANGTHAI_FORSALE,
})
export const getDuAn = () => ({
  type: types.GET_DUAN,
})
export const getImageForsale = payload => ({
  type: types.GET_IMAGE_FORSALE,
  payload,
})
export const addImageForsale = payload => ({
  type: types.ADD_IMAGE_FORSALE,
  payload,
})



