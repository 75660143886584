import React, { useState } from "react"
import { Row, Col, Avatar, Button } from "antd"
import {
  HomeOutlined,
  DollarOutlined,
  CompassOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  BankOutlined,
} from "@ant-design/icons"
import { Image } from "antd"

const GopVon = () => {
  const images = [
    {
      large:
        "https://gcs.tripi.vn/public-tripi/tripi-feed/img/474122nkG/anh-blackpink-ro-net_021421543.jpg",
      thumbnail:
        "https://gcs.tripi.vn/public-tripi/tripi-feed/img/474122nkG/anh-blackpink-ro-net_021421543.jpg",
    },
    {
      large:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-_R8UE94HgnfAYrM142HZjb4zJRt1CKr2_A&s",
      thumbnail:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-_R8UE94HgnfAYrM142HZjb4zJRt1CKr2_A&s",
    },
    {
      large:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcl3_HClJdicIDvbCW59mlEaDdcJk5bm3Jgg&s",
      thumbnail:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcl3_HClJdicIDvbCW59mlEaDdcJk5bm3Jgg&s",
    },
  ]

  const [selectedImageIndex, setSelectedImageIndex] = useState(0)

  const handleThumbnailClick = index => {
    setSelectedImageIndex(index)
  }

  const info = [
    { icon: <HomeOutlined />, label: "Diện tích", value: "100 m²" },
    { icon: <CompassOutlined />, label: "Hướng nhà", value: "Đông" },
    { icon: <DollarOutlined />, label: "Mức giá", value: "2 tỷ" },
    { icon: <CompassOutlined />, label: "Hướng ban công", value: "Tây" },
    { icon: <AppstoreAddOutlined />, label: "Số phòng ngủ", value: 3 },
    { icon: <AppstoreAddOutlined />, label: "Số toilet", value: 2 },
    { icon: <FileTextOutlined />, label: "Pháp lý", value: "Sổ hồng" },
    { icon: <BankOutlined />, label: "Nội thất", value: "Đầy đủ" },
  ]

  return (
    <div className="page-content" style={{ padding: 50 }}>
      <div style={{ marginBottom: 20, paddingTop: "30px" }}>
        <Image
          width={"70%"}
          height={400}
          src={images[selectedImageIndex].large}
          alt="Selected"
        />
      </div>
      <Row>
        {images.map((image, index) => (
          <Col span={2}>
            <Image
              key={index}
              width={110}
              height={80}
              src={image.thumbnail}
              onClick={() => handleThumbnailClick(index)}
              preview={false}
              style={{
                marginRight: "20px",
                cursor: "pointer",
                borderRadius: "5px",
                border:
                  index === selectedImageIndex ? "2px solid #1890ff" : "none",
              }}
              alt={`Thumbnail ${index + 1}`}
            />{" "}
          </Col>
        ))}
      </Row>
      <h3 style={{ fontWeight: "600" }} className="mt-4 mb-4">
        Đặc điểm bất động sản
      </h3>
      <Row style={{ padding: "0 30px" }} gutter={[50, 30]}>
        <Col span={18}>
          <Row gutter={[40, 20]}>
            {info.map((item, index) => (
              <Col span={12} key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #d9d9d9",
                    paddingBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <p style={{ width: "50%" }}>
                    {item.icon} {item.label}
                  </p>
                  <p style={{ width: "50%", textAlign: "right" }}>
                    {item.value}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col style={{ marginTop: "-45px" }} span={6}>
          <div style={{ textAlign: "center" }}>
            <Avatar
              size={64}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcl3_HClJdicIDvbCW59mlEaDdcJk5bm3Jgg&s"
            />
            <p style={{ margin: "10px 0", fontWeight: "600" }}>Nguyễn Văn A</p>
            <span>Xem thêm 99 tin khác</span>
            <Button style={{ margin: "20px 0" }} type="primary" block>
              Primary
            </Button>
            <Button block>Default</Button>
          </div>
        </Col>
      </Row>
      <h3 style={{ fontWeight: "600" }} className="mt-4 mb-4">
        Thông tin mô tả
      </h3>
      <div>
        <p>
          Cần bán gấp căn 1PN full nội thất xịn xò, giá bao rẻ thời điểm hiện
          tại. Lợi nhuận cao
        </p>
      </div>
    </div>
  )
}

export default GopVon

// import React, { useEffect } from "react"
// import { Row, Col, Image } from "antd"
// import {
//   HomeOutlined,
//   DollarOutlined,
//   CompassOutlined,
//   AppstoreAddOutlined,
//   FileTextOutlined,
//   BankOutlined,
// } from "@ant-design/icons"
// import { useDispatch, useSelector } from "react-redux"
// import { useParams } from "react-router-dom"
// import { geSanPham } from "../../../store/actions"
// const FormInformationBDS = () => {
//   const dispatch = useDispatch()
//   const { data } = useSelector(state => ({
//     data: state.SanPham.listSanPham,
//   }))
//   let { id } = useParams()
//   const numericId = parseInt(id, 10)

//   useEffect(() => {
//     if (!data || data.length === 0) {
//       dispatch(geSanPham())
//     }
//   }, [dispatch, data])

//   // Find the item in `data` with matching `maBC`
//   const newData = data && data?.find(item => item.maBC === numericId)
//   console.log(newData)
//   console.log(data)
//   const info = [
//     {
//       icon: <HomeOutlined />,
//       label: "Diện tích",
//       value: `${newData?.dienTich} m²`,
//     },
//     { icon: <CompassOutlined />, label: "Hướng nhà", value: "Đông" },
//     {
//       icon: <DollarOutlined />,
//       label: "Mức giá",
//       value: `${newData?.thanhTien} đ`,
//     },
//     {
//       icon: <CompassOutlined />,
//       label: "Hướng ban công",
//       value: `${newData?.huongBC} `,
//     },
//     { icon: <AppstoreAddOutlined />, label: "Số phòng ngủ", value: 3 },
//     { icon: <AppstoreAddOutlined />, label: "Số toilet", value: 2 },
//     { icon: <FileTextOutlined />, label: "Pháp lý", value: "Sổ hồng" },
//     { icon: <BankOutlined />, label: "Nội thất", value: "Đầy đủ" },
//   ]
//   useImperativeHandle(ref, () => ({
//     show: async () => {
//       // let _data = _.cloneDeep(data)
//       // loadOptions()
//       setShow(true)
//     },
//   }))
//   return (
//     <div className="page-content">
//       <h3 style={{ fontWeight: "600" }} className="mt-4 mb-4 ">
//         Đặc điểm bất động sản
//       </h3>
//       <Row style={{ padding: "0 30px" }} gutter={[50, 30]}>
//         {info.map((item, index) => (
//           <Col span={8} key={index}>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 borderBottom: "1px solid #d9d9d9",
//                 paddingBottom: "10px",
//                 fontWeight: "600",
//               }}
//             >
//               <p style={{ width: "50%" }}>
//                 {item.icon} {item.label}
//               </p>
//               <p style={{ width: "50%", textAlign: "right" }}>{item.value}</p>
//             </div>
//           </Col>
//         ))}
//       </Row>
//       <h3 style={{ fontWeight: "600" }} className="mt-4 mb-4 ">
//         Thông tin mô tả
//       </h3>
//       <div style={{ padding: "0 30px 20px" }}>
//         <p>{newData?.dacTrung}</p>
//       </div>
//       <Row>
//         <Col span={4}>
//           <Image
//             width={200}
//             src="https://gcs.tripi.vn/public-tripi/tripi-feed/img/474122nkG/anh-blackpink-ro-net_021421543.jpg"
//           />
//         </Col>
//         <Col span={4}>
//           <Image
//             width={200}
//             src="https://gcs.tripi.vn/public-tripi/tripi-feed/img/474122nkG/anh-blackpink-ro-net_021421543.jpg"
//           />
//         </Col>
//         <Col span={4}>
//           <Image
//             width={200}
//             src="https://gcs.tripi.vn/public-tripi/tripi-feed/img/474122nkG/anh-blackpink-ro-net_021421543.jpg"
//           />
//         </Col>
//         <Col span={4}>
//           <Image
//             width={200}
//             src="https://gcs.tripi.vn/public-tripi/tripi-feed/img/474122nkG/anh-blackpink-ro-net_021421543.jpg"
//           />
//         </Col>
//       </Row>
//     </div>
//   )
// }

// export default FormInformationBDS
