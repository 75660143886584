import React, { useEffect, useState, useCallback } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { withTranslation } from "react-i18next"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  handleValidDate,
  Format_Currency,
  formatNumberPT,
} from "../../../common/common"
import DatCoc_Filter from './DatCoc_Filter'
import {
  Col,
  Container,
  Row,
} from "reactstrap"
import Pagination from "../../../components/Common/Pagination"
import { ContractService } from "services/Contract"
import ToolBar from "components/Toolbar"
import LoadingBar from "components/LoadingBar"
import { Dropdown,Flex ,Button} from "antd"
import { IoIosAdd } from "react-icons/io"
import { MdEdit } from "react-icons/md"
import { CiTrash } from "react-icons/ci"
import { RiListIndefinite } from "react-icons/ri"

const DatCocIndex = props => {
  let preyear = (new Date()).setFullYear(new Date().getFullYear() -5)
  const defaultCondition = {
    inputSearch: "",
    DuAn: [],
    TuNgay: new Date(preyear),
    DenNgay: new Date(),
    MaKhu: 0,
    MaTT: 0,
    Offset: 1,
    Limit: 20
  }
  const [filterCondition, setFilterCondition] = useState(defaultCondition);
  const [modalDelete, setModalDelete] = useState({})
  const [modalDropBtn, setModalDropBtn] = useState(false)
  const [item, setItem] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const toggleDropdownBtn = () => setModalDropBtn(prevState => !prevState)
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setItem([...item, row])
      } else {
        const filter = item.filter(item => {
          return item.MaPDC !== row.MaPDC
        })
        setItem(filter)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setItem(rows)
      } else {
        setItem([])
      }
    },
    bgColor: "#eff2f7",
  }
  const Columns = useCallback(() => {
    return [
      {
        dataField: "STT",
        text: "STT",
        sort: true,
        formatter: (cellContent, row) => (
          <Link to="#" className="text-body fw-bold">
            {row.STT}
          </Link>
        ),
      },
      // {
      //   dataField: "MaPDC",
      //   text: "Xem",
      //   sort: true,
      //   formatter: (cellContent, row) => (
      //     <Link
      //       to="#"
      //       // onClick={() => toggleDetail(row)}
      //       className="text-body fw-bold"
      //     >
      //       <i className="mdi mdi-17px mdi-eye"></i>
      //     </Link>
      //   ),
      // },
      {
        dataField: "MaSanPham",
        text: "Mã sản phẩm",
        sort: true,
      },
      {
        dataField: "NgayDatCoc",
        text: "Ngày đặt cọc",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.NgayDatCoc),
      },
      {
        dataField: "SoPhieu",
        text: "Số phiếu",
        sort: true,
      },
      {
        dataField: "TenTT",
        text: "Trạng thái",
        sort: true,
        formatter: (cellContent, row) => (
          <div
            className="shadow-4 rounded"
            style={{
              backgroundColor: row.MauNen,
              padding: "5px",
            }}
          >
            {row.TenTT}
          </div>
        ),
      },
      {
        dataField: "TienCoc",
        text: "Tiền cọc",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.TienCoc),
      },
      {
        dataField: "TyLeThu",
        text: "Tỷ lệ thu",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.TyLeThu),
      },
      {
        dataField: "ChietKhau",
        text: "Chiết khấu",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.ChietKhau),
      },
      {
        dataField: "KhachHang",
        text: "Khách hàng",
        sort: true,
      },
      {
        dataField: "nNguoiCungDungTen",
        text: "Người cùng đứng tên",
        sort: true,
      },
      {
        dataField: "NgaySinh",
        text: "Ngày sinh",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.NgaySinh),
      },
      {
        dataField: "SoCMND",
        text: "Số CMND",
        sort: true,
      },
      {
        dataField: "NgayCap",
        text: "Ngày cấp",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.NgayCap),
      },
      {
        dataField: "NoiCap",
        text: "Nơi cấp",
        sort: true,
      },
      {
        dataField: "DiaChi",
        text: "Địa chỉ",
        sort: true,
      },
      {
        dataField: "ThuongTru",
        text: "Thường trú",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "DiDong",
        text: "Di động",
        sort: true,
      },
      {
        dataField: "DiDong2",
        text: "Di động 2",
        sort: true,
      },
      {
        dataField: "NhomBangHang",
        text: "Nhóm bảng hàng",
        sort: true,
      },
      {
        dataField: "TenDA",
        text: "Dự án",
        sort: true,
      },
      {
        dataField: "Khu",
        text: "Khu",
        sort: true,
      },
      {
        dataField: "DienTichDat",
        text: "Diện tích đất",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.DienTichDat),
      },
      {
        dataField: "DienTichXD",
        text: "Diện tích XD",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.DienTichXD),
      },
      {
        dataField: "DTTimTuong",
        text: "S Tim tường",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.DTTimTuong),
      },
      {
        dataField: "DienTichTT",
        text: "Diện tích TT",
        sort: true,
        formatter: (cellContent, row) => formatNumberPT(row.DienTichTT),
      },

      {
        dataField: "GiaChuaVAT",
        text: "Giá chưa VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.GiaChuaVAT),
      },
      {
        dataField: "TienVAT",
        text: "Tiền VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.TienVAT),
      },
      {
        dataField: "TongGiaGomVAT",
        text: "Tổng giá gồm VAT",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.TongGiaGomVAT),
      },
      {
        dataField: "PhiBaoTri",
        text: "Phí bảo trì",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.PhiBaoTri),
      },
      {
        dataField: "TongGiaDat",
        text: "Tổng giá đất",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.TongGiaDat),
      },
      {
        dataField: "TongGiaXD",
        text: "Tổng giá XD",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.TongGiaXD),
      },
      {
        dataField: "TongGiaTriHDMB",
        text: "Tổng giá trị HDMB",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.TongGiaTriHDMB),
      },
      {
        dataField: "DonGiaTT",
        text: "Đơn giá T.Thủy",
        sort: true,
        formatter: (cellContent, row) => Format_Currency(row.DonGiaTT),
      },
      {
        dataField: "NhanVienBH",
        text: "Nhân viên BH",
        sort: true,
      },
      {
        dataField: "SanGiaoDich",
        text: "Sàn giao dịch",
        sort: true,
      },
      {
        dataField: "NgayNhap",
        text: "Ngày nhập",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.NgayNhap),
      },
      {
        dataField: "NgaySua",
        text: "Ngày sửa",
        sort: true,
        formatter: (cellContent, row) => handleValidDate(row.NgaySua),
      },
      {
        dataField: "NguoiNhap",
        text: "Người nhập",
        sort: true,
      },

      {
        dataField: "NguoiSua",
        text: "Người sửa",
        sort: true,
      },
    ]
  }, [])
  const filter = async (filter) => {
    setLoading(true);
    let _filter = _.cloneDeep(filter);
    _filter.DuAn = _filter.DuAn?.length > 0 ? "," + _filter.DuAn.join(",") + "," : ""
    let res = await ContractService.Deposit.filter(_filter)
    setData(res.data || []);
    setLoading(false);
  }
  useEffect(() => {
    (async () => {
      await filter(filterCondition);
    })()
  }, [])
  const handleEdit = () => {
  }
  const onClickXemAnh = () => {
  }
  const handleDelete = useCallback(itemDL => {
    setModalDelete({
      isOpen: !modalDelete.isOpen,
      delete: () => onClickDelete(itemDL),
    })
  }, [])
  const onClickDelete = useCallback(itemDL => {
  }, [])

  const handleAdd = () => {
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]
  // useEffect(() => {
  //   let time
  //   if (!time) {
  //     time = setTimeout(() => {
  //       dispatch(
  //         getDatCoc({
  //           TuNgay: dateFrom,
  //           DenNgay: dateTo,
  //           DuAn: maDuAn,
  //           MaTT: maTT,
  //           MaKhu: maKhu,
  //           inputSearch: textSearch,
  //           Offset: page,
  //           Limit: itemPerPage,
  //         })
  //       )
  //       setItem([])
  //     }, [1000])
  //   }
  //   return () => {
  //     clearTimeout(time)
  //   }
  // }, [textSearch])
  const afterChangeFilter = async (_filter) => {
    setFilterCondition(_filter);
    await filter(_filter);
  }
  const onChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "page":
        _filter.Offset = v;
        break;
      case "itemPerPage":
        _filter.Offset = 1;
        _filter.Limit = v;
        break;
    }
    setFilterCondition(_filter);
    filter(_filter)
  }

  const items = [
    {
      key: "1",
      label: <p>Duyệt</p>,
    },
    {
      key: "2",
      label: <p>Chờ duyệt</p>,
    },
    {
      key: "3",
      label: <p>Huỷ phiếu</p>,
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Đặt cọc| Beesky</title>
        </MetaTags>
        <Container fluid>
          <ToolBar
            left={() => (
              <Flex gap={5}>
                <Button icon={<IoIosAdd />} onClick={handleAdd}>
                  Thêm
                </Button>
                {item.length === 1 && (
                  <Button icon={<MdEdit />} onClick={handleEdit}>
                    Sửa
                  </Button>
                )}
                {item.length >= 1 && (
                  <Button icon={<CiTrash />} danger onClick={handleDelete}>
                    Xoá
                  </Button>
                )}
                {item.length >= 1 && (
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                  >
                    <Button icon={<RiListIndefinite />}>Hành động</Button>
                  </Dropdown>
                )}
              </Flex>
            )}
            right={() => (
              <DatCoc_Filter
                filterCondition={filterCondition}
                afterChangeFilter={afterChangeFilter}
              />
            )}
          />
          <ToolkitProvider
            keyField="MaPDC"
            data={data}
            columns={Columns()}
            bootstrap4
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <LoadingBar loading={loading} />
                    <div className="table-responsive table-scrollable">
                      <BootstrapTable
                        keyField="MaPDC"
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={selectRow}
                        classes={
                          "table align-middle  table-nowrap table-check table-hover table-fixed "
                        }
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <select
                        className="form-select"
                        value={filterCondition.Limit}
                        onChange={e =>
                          onChangeFilter("itemPerPage", Number(e.target.value))
                        }
                      >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                      </select>
                    </div>
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <Pagination
                        totalPage={Math.ceil(
                          data[0]?.TotalRows / filterCondition.Limit
                        )}
                        changePage={i => onChangeFilter("page", i)}
                      />
                    </Col>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Container>
      </div>
    </React.Fragment>
  )
}

DatCocIndex.propTypes = {
  customers: PropTypes.array,
  onGetPermision: PropTypes.func,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DatCocIndex))
