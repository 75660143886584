import React from "react"
import * as moment from "moment"
import { toast } from "react-toastify"

export const handleValidDate = date => {
  const dateFormat = moment(new Date(date)).format("DD/MM/Y")
  return dateFormat
}
export const handleValidHour = date => {
  const dateFormat = moment(new Date(date)).format("HH:mm:ss")
  return dateFormat
}
export function formatDateTime(dateString) {
  const date = new Date(dateString);
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600); // Tính số giờ
  const minutes = Math.floor((seconds % 3600) / 60); // Tính số phút
  const remainingSeconds = seconds % 60; // Tính số giây còn lại

  // Trả về định dạng hh:mm:ss
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const Format_CurrencyV2 = number => {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(number)
}
export const formatNumberPT = number => {
  return new Intl.NumberFormat().format(number)
}
export const Format_Currency = number => {
  const defaultFormatValue = (lengthArr, number, num) => {
    if (lengthArr > 1) {
      const parts = Number.parseFloat(number).toFixed(1).split(".")
      num =
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        (parts[1] ? "," + parts[1] : "")
    } else {
      num = number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    return num
  }
  let num
  const lengthArr = number?.toString().split(".").length
  // let formatCurrency = window.app_context?.currencies?.find(v => v.code === currencyCode);
  // if (formatCurrency) {
  // const { decimalSeparator, thousandSeparator, roundLevel, status } = formatCurrency
  if (lengthArr > 1) {
    const parts = Number.parseFloat(number).toFixed(0).split(".")
    num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "")
  } else {
    num = number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }
  // } else {
  //   num = defaultFormatValue(lengthArr, number, num)
  // }

  return num
}

export const Toast = {
  success: payload => {
    toast.success(payload, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  },
  error: payload => {
    toast.error(payload, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  },
}

export const getHexColor = number => {
  return "#" + (number >>> 0).toString(16).slice(-6)
}
