import { Box } from "@chakra-ui/react"
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react"
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Image,
  Form,
  Cascader,
  Transfer,
  Tabs,
  Modal,
  Flex,
} from "antd"
import { SearchOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons"
import _ from "lodash"
// import DebounceSelect from "components/DebounceSelect";
import dayjs from "dayjs"
import "dayjs/locale/vi"
import { DatePicker } from "antd/lib"
import vi from "dayjs/locale/vi"
import TextArea from "antd/es/input/TextArea"
// import FormModalUser from "./FormModalUser"
import DebounceSelect from "components/DebounceSelect"
import ChildrenFormAdd from "./ChildrenFormAdd"
// import { CardService } from "services/CardService";
// import { FileUpload } from "primereact/fileupload";
// import { HomeService } from "services/HomeService";
// import { API_URL } from "services/api_helper";
// import { CustomerService } from "services/CustomerService";

const { Option } = Select
const yourOptions = [
  {
    label: "Tỉnh 1",
    value: "Tỉnh 1",
    children: [
      {
        label: "Huyện A",
        value: "Huyện A",
        children: [
          { label: "Xã X", value: "Xã X" },
          { label: "Xã Y", value: "Xã Y" },
        ],
      },
      {
        label: "Huyện B",
        value: "Huyện B",
        children: [{ label: "Xã Z", value: "Xã Z" }],
      },
    ],
  },
  {
    label: "Tỉnh 2",
    value: "Tỉnh 2",
    children: [
      {
        label: "Huyện C",
        value: "Huyện C",
        children: [{ label: "Xã W", value: "Xã W" }],
      },
    ],
  },
]

const FormAdd = (props, ref) => {
  const [selectedOption, setSelectedOption] = useState("option1") // Lưu option được chọn
  const [transferData, setTransferData] = useState([]) // Dữ liệu cho Transfer

  // Mô phỏng dữ liệu cho các options
  const optionData = {
    option1: [
      { key: "1", title: "địa điểm" },
      { key: "2", title: "Item 2" },
      { key: "3", title: "Item 3" },
    ],
    option2: [
      { key: "4", title: "Item 4" },
      { key: "5", title: "Item 5" },
      { key: "6", title: "Item 6" },
    ],
    option3: [
      { key: "7", title: "Item 7" },
      { key: "8", title: "Item 8" },
      { key: "9", title: "Item 9" },
    ],
  }

  // Effect để xử lý thay đổi khi selectedOption thay đổi
  useEffect(() => {
    // Cập nhật dữ liệu cho Transfer dựa vào selectedOption
    setTransferData(optionData[selectedOption] || [])
  }, [selectedOption]) // Kích hoạt lại khi selectedOption thay đổi

  // Hàm xử lý sự kiện khi thay đổi Select
  const handleSelectChange = value => {
    setSelectedOption(value) // Cập nhật selectedOption khi thay đổi
  }

  const { onAfterSubmit } = props
  const [api, contextHolder] = notification.useNotification()
  const defaultData = {
    NgayHH: new Date(),
    NgayPH: new Date(),
    NgayHL: new Date(),
    NgaySinh: new Date(),
  }
  const [data, setData] = useState(defaultData)
  const [files, setFiles] = useState([])

  const [isupdate, setIsUpdate] = useState(false)
  const [open, setOpen] = useState(false)
  const [cardType, setCardType] = useState([])
  const [customerGroup, setCustomerGroup] = useState([])
  const refPrint = useRef(null)
  const defaultErrors = {
    TenCongTy: null,
    MaLT: null,
    DiDong: null,
    MaVach: null,
  }
  const [errors, setErrors] = useState(defaultErrors)
  const [sanPham, setSanPham] = useState([])
  const loadCategories = async () => {
    return
    let _restype = await CardService.getType()
    let _resgr = await CardService.getCustomerGroup()
    let _sp = await SanPhamService.getListByMaNhom({
      InputSearch: "",
      MaNhom: 6,
    })
    setCardType(_restype?.data ?? [])
    setCustomerGroup(_resgr?.data ?? [])
    setSanPham(_sp?.data ?? [])
  }
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true)
      setIsUpdate(false)
      loadCategories()
    },
    update: async data => {
      setOpen(true)
      loadCategories()
      setIsUpdate(true)
      setData(data)
    },
  }))
  const onClose = () => {
    setOpen(false)
    setData(defaultData)
  }
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data)
    switch (p) {
      default:
        _data[p] = v
        break
    }
    setData(_data)
  }
  async function fetchUserList(username) {
    return
    return CardService.filterCustomer({ InputSearch: username }).then(res => {
      return res?.data?.map(item => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
        title: item.TenCongTy,
        DiDong: item.DiDong,
      }))
    })
  }
  const openNotification = (type, placement, message) => {
    return
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    })
  }
  const validate = () => {
    return
    let _errors = _.cloneDeep(errors)
    Object.keys(_errors)?.map(item => {
      if (item === "TenCongTy" || item === "DiDong") {
        console.log(data.MaKH)
        if (data?.MaKH?.length === 0 || data?.MaKH === undefined) {
          _errors[item] = data[item] ? null : "Không để trống"
        }
        //
      } else {
        _errors[item] = data[item] ? null : "Không để trống"
      }
    })
    setErrors(_errors)
    let valid = _.uniq(Object.values(_errors).filter(v => v))
    return valid
  }
  const onSubmit = async close => {
    return
    let _data = _.cloneDeep(data)
    let valid = validate()
    if (valid?.length > 0) {
      return
    }
    if (_data.MaKH?.length > 0) {
      _data.MaKH = _data?.MaKH[0].value
    } else {
      _data.MaKH = null
    }
    let _res = null
    if (files?.length > 0) {
      var formData = new FormData()
      formData.append("Image", files[0])
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"))
      let _resupload = await HomeService.upload(formData)
      if (_resupload.status === 2000) {
        _data.HinhAnh2 = API_URL() + "/" + _resupload.data
        setData(pre => {
          return { ...pre, HinhAnh2: _data.HinhAnh2 }
        })
      }
    }
    if (isupdate) {
      _res = await CardService.updateCard(_data)
    } else {
      _res = await CardService.addCard(_data)
      setData(pre => {
        return { ...pre, ID: _res.data }
      })
    }
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thẻ thành công!`
      )
      if (close) onClose()
      setIsUpdate(true)
      onAfterSubmit()
    } else {
      openNotification("error", "topRight", _res?.message)
    }
  }
  // const handlePrint = useReactToPrint({
  //   content: () => refPrint.current,
  // });
  const onBeforeUpload = v => {
    setFiles(v.files)
  }

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [staffForm, setStaffForm] = useState([])

  const toggle = () => {
    setModal(!modal)
  }
  const handleAdd = () => {
    setStaffForm([])
    setIsEdit(false)
    toggle()
  }

  async function fetchUserList(username) {
    return
    return CustomerService.filter({ InputSearch: username }).then(res => {
      return res?.data?.map(item => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
      }))
    })
  }
  return (
    <Drawer
      title="Giao dịch"
      width={1120}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          {/* <Button onClick={()=>onSubmit()}>Lưu</Button> */}
          <Button onClick={() => onSubmit()} type="primary" ghost>
            Lưu
          </Button>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Tabs
        style={{ marginTop: "-20px" }}
        className="childrenMGL"
        defaultActiveKey="CanBan"
        centered
        tabBarGutter={5}
      >
        {["Thông tin giao dịch", "Nhân viên tham gia"].map((tab, index) => (
          <Tabs.TabPane tab={tab} key={tab}>
            {index === 0 ? (
              <>
                <Row gutter={[20, 12]}>
                  <Col span={12}>
                    <p>Số phiếu</p>
                    <Input
                      value={data?.Ten}
                      placeholder="Nhập số đăng ký"
                      onChange={e => applyChange("Ten", e.target.value)}
                    />
                    <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
                  </Col>
                  <Col span={12}>
                    <p>Ngày giao dịch</p>
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      // defaultValue={dayjs(filterCondition.TuNgay)}
                      value={dayjs(data?.NgayHL)}
                      placeholder="nhập ngày thu"
                      locale={vi}
                      onChange={v => applyChange("NgayHL", v)}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Ngày đặt cọc</p>
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      // defaultValue={dayjs(filterCondition.TuNgay)}
                      value={dayjs(data?.NgayHL)}
                      placeholder="nhập ngày thu"
                      locale={vi}
                      onChange={v => applyChange("NgayHL", v)}
                    />
                  </Col>

                  <Col span={12}>
                    <p>Tiền cọc</p>
                    <Input
                      value={data?.Ten}
                      placeholder="Nhập tiềm cọc"
                      onChange={e => applyChange("Ten", e.target.value)}
                    />
                    <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
                  </Col>
                  <Col span={12}>
                    <p>Thời hạn</p>
                    <Input
                      value={data?.Ten}
                      placeholder="Nhập thời hạn"
                      onChange={e => applyChange("Ten", e.target.value)}
                    />
                    <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
                  </Col>
                  <Col span={12}>
                    <p>Ngày kí HĐ</p>
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      // defaultValue={dayjs(filterCondition.TuNgay)}
                      value={dayjs(data?.NgayHL)}
                      placeholder="nhập ngày thu"
                      locale={vi}
                      onChange={v => applyChange("NgayHL", v)}
                    />
                  </Col>

                  <Col span={12}>
                    <p>Trạng thái </p>

                    <Select
                      mode="single"
                      style={{ width: "100%" }}
                      onChange={value => applyChange("selectedOption", value)}
                    >
                      <Option value="option1">Thành công</Option>
                      <Option value="option2">Thất bại</Option>
                    </Select>
                    <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
                  </Col>
                  <Col span={12}>
                    <p>Tổng PMG</p>
                    <Input
                      value={data?.Ten}
                      placeholder="Nhập tổng PMG"
                      onChange={e => applyChange("Ten", e.target.value)}
                    />
                    <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
                  </Col>
                  <Col span={12}>
                    <p>Hoa hồng bán</p>
                    <Input
                      value={data?.Ten}
                      placeholder="Nhập hoa hồng bán"
                      onChange={e => applyChange("Ten", e.target.value)}
                    />
                    <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
                  </Col>
                  <Col span={12}>
                    <p>Hoa hồng mua</p>
                    <Input
                      value={data?.Ten}
                      placeholder="Nhập hoa hồng mua"
                      onChange={e => applyChange("Ten", e.target.value)}
                    />
                    <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
                  </Col>

                  <Col span={24}>
                    <p>Diễn giải</p>
                    <TextArea
                      value={data?.TenCongTy}
                      onChange={e => applyChange("TenCongTy", e.target.value)}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                 <ChildrenFormAdd />
              </>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Drawer>
  )
}
export default forwardRef(FormAdd)
