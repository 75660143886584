// import React, { useState } from "react"
// import { Tabs, Table, Form, Input, Button, Drawer, Row, Col } from "antd"

// const { TabPane } = Tabs

// const initialData = []

// const ChildrenFormAdd = () => {
//   const [dataSource, setDataSource] = useState(initialData)
//   const [drawerVisible, setDrawerVisible] = useState(false)
//   const [form] = Form.useForm()

//   const handleSave = values => {
//     const newData = [
//       ...dataSource,
//       {
//         ...values,
//         key: `${dataSource.length + 1}`,
//       },
//     ]
//     setDataSource(newData)
//     setDrawerVisible(false) // Close drawer after successful save
//     form.resetFields() // Reset form fields after save
//   }

//   const handleAdd = () => {
//     setDrawerVisible(true) // Open drawer to add new row
//   }

//   const handleCancel = () => {
//     setDrawerVisible(false) // Cancel adding new row, close drawer
//     form.resetFields() // Reset form fields on cancel
//   }

//   const handleDelete = key => {
//     const newData = dataSource.filter(item => item.key !== key)
//     setDataSource(newData)
//   }
//   const columns = [
//     {
//       title: "Xóa",
//       dataIndex: "Xoa",
//       key: "Xoa",
//       render: (_, record) => (
//         <Button onClick={() => handleDelete(record.key)}>Xóa</Button>
//       ),
//     },
//     {
//       title: "Chọn sản phẩm",
//       dataIndex: "ChonSanPham",
//       key: "ChonSanPham",
//       editable: true,
//     },
//     {
//       title: "Đợt TT",
//       dataIndex: "DotTT",
//       key: "DotTT",
//       editable: true,
//     },
//     {
//       title: "Loại thu",
//       dataIndex: "LoaiThu",
//       key: "LoaiThu",
//       editable: true,
//     },
//     {
//       title: "Ngày thu chi",
//       dataIndex: "NgayThuChi",
//       key: "NgayThuChi",
//       editable: true,
//     },
//     {
//       title: "CK thanh toán",
//       dataIndex: "CKThanhToan",
//       key: "CKThanhToan",
//       editable: true,
//     },
//     {
//       title: "Chọn gia dịch",
//       dataIndex: "ChonGiaoDich",
//       key: "ChonGiaoDich",
//       editable: true,
//     },
//     {
//       title: "Kho phiếu thu",
//       dataIndex: "KhoPhieuThu",
//       key: "KhoPhieuThu",
//       editable: true,
//     },
//     {
//       title: "Số giao dịch",
//       dataIndex: "SoGiaoDich",
//       key: "SoGiaoDich",
//       editable: true,
//     },
//     {
//       title: "Số phiếu thu",
//       dataIndex: "SoPhieuThu",
//       key: "SoPhieuThu",
//       editable: true,
//     },
//     {
//       title: "Mã căn",
//       dataIndex: "MaCan",
//       key: "MaCan",
//       editable: true,
//     },
//     {
//       title: "Loại thu chi",
//       dataIndex: "LoaiThuChi",
//       key: "LoaiThuChi",
//       editable: true,
//     },
//     {
//       title: "Hình thức TT",
//       dataIndex: "HinhThucTT",
//       key: "HinhThucTT",
//       editable: true,
//     },
//     {
//       title: "Ghi chú",
//       dataIndex: "GhiChu",
//       key: "GhiChu",
//       editable: true,
//     },
//     {
//       title: "Người nộp",
//       dataIndex: "NguoiNop",
//       key: "NguoiNop",
//       editable: true,
//     },
//     {
//       title: "Email",
//       dataIndex: "Email",
//       key: "Email",
//       editable: true,
//     },
//   ]

//   const mergedColumns = columns.map(col => ({
//     ...col,
//     onCell: record => ({
//       record,
//       editable: col.editable,
//       dataIndex: col.dataIndex,
//       title: col.title,
//       handleSave: handleSave,
//     }),
//   }))
  

//   return (
//     <Tabs defaultActiveKey="1">
//     <TabPane key="1">
//       <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
//         Thêm dòng mới
//       </Button>
//       <Table
//         bordered
//         dataSource={dataSource}
//         columns={mergedColumns}
//         rowClassName="editable-row"
//         pagination={false}
//         scroll={{ x: 2000 }}
//       />
//       <Drawer
//         title="Thêm dòng mới"
//         placement="right"
//         closable={false}
//         onClose={handleCancel}
//         visible={drawerVisible}
//         width="60%"
//         footer={
//           <div style={{ textAlign: "right" }}>
//             <Button onClick={handleCancel} style={{ marginRight: 8 }}>
//               Hủy
//             </Button>
//             <Button onClick={() => form.submit()} type="primary">
//               Lưu
//             </Button>
//           </div>
//         }
//       >
//           <Form form={form} onFinish={handleSave} layout="vertical">
//             <Row gutter={[16, 16]}>
//               <Col span={8}>
//                 <Form.Item
//                   name="ChonSanPham"
//                   label="Chọn sản phẩm"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Chọn sản phẩm" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="DotTT"
//                   label="Đợt TT"
//                   rules={[{ required: true, message: "Vui lòng nhập Đợt TT" }]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="LoaiThu"
//                   label="Loại thu"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Loại thu" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="NgayThuChi"
//                   label="Ngày thu chi"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Ngày thu chi" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="CKThanhToan"
//                   label="CK thanh toán"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập CK thanh toán" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="ChonGiaoDich"
//                   label="Chọn gia dịch"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Chọn gia dịch" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="KhoPhieuThu"
//                   label="Kho phiếu thu"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Kho phiếu thu" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="SoGiaoDich"
//                   label="Số giao dịch"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Số giao dịch" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="SoPhieuThu"
//                   label="Số phiếu thu"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Số phiếu thu" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="MaCan"
//                   label="Mã căn"
//                   rules={[{ required: true, message: "Vui lòng nhập Mã căn" }]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="LoaiThuChi"
//                   label="Loại thu chi"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Loại thu chi" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="HinhThucTT"
//                   label="Hình thức TT"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Hình thức TT" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="GhiChu"
//                   label="Ghi chú"
//                   rules={[{ required: true, message: "Vui lòng nhập Ghi chú" }]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="NguoiNop"
//                   label="Người nộp"
//                   rules={[
//                     { required: true, message: "Vui lòng nhập Người nộp" },
//                   ]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Form.Item
//                   name="Email"
//                   label="Email"
//                   rules={[{ required: true, message: "Vui lòng nhập Email" }]}
//                 >
//                   <Input />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Form>
//           </Drawer>
//       </TabPane>
//     </Tabs>
//   )
// }

// export default ChildrenFormAdd


import React, { useState } from "react"
import { Tabs, Table, Button, Drawer, Row, Col } from "antd"
import InputCustom from "components/Forms/InputCustom"

const { TabPane } = Tabs

const initialData = []

const ChildrenFormAdd = () => {
  const [dataSource, setDataSource] = useState(initialData)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [errors, setErrors] = useState({})

  const handleSave = () => {
    const newData = [
      ...dataSource,
      {
        ...formValues,
        key: `${dataSource.length + 1}`,
      },
    ]
    setDataSource(newData)
    setDrawerVisible(false) // Close drawer after successful save
    setFormValues({}) // Reset form values after save
  }

  const handleAdd = () => {
    setDrawerVisible(true) // Open drawer to add new row
  }

  const handleCancel = () => {
    setDrawerVisible(false) // Cancel adding new row, close drawer
    setFormValues({}) // Reset form values on cancel
  }

  const handleDelete = key => {
    const newData = dataSource.filter(item => item.key !== key)
    setDataSource(newData)
  }

  const applyChange = (prop, value) => {
    setFormValues({
      ...formValues,
      [prop]: value,
    })
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formValues.ChonSanPham) newErrors.ChonSanPham = "Vui lòng nhập Chọn sản phẩm"
    if (!formValues.DotTT) newErrors.DotTT = "Vui lòng nhập Đợt TT"
    if (!formValues.LoaiThu) newErrors.LoaiThu = "Vui lòng nhập Loại thu"
    if (!formValues.NgayThuChi) newErrors.NgayThuChi = "Vui lòng nhập Ngày thu chi"
    if (!formValues.CKThanhToan) newErrors.CKThanhToan = "Vui lòng nhập CK thanh toán"
    if (!formValues.ChonGiaoDich) newErrors.ChonGiaoDich = "Vui lòng nhập Chọn gia dịch"
    if (!formValues.KhoPhieuThu) newErrors.KhoPhieuThu = "Vui lòng nhập Kho phiếu thu"
    if (!formValues.SoGiaoDich) newErrors.SoGiaoDich = "Vui lòng nhập Số giao dịch"
    if (!formValues.SoPhieuThu) newErrors.SoPhieuThu = "Vui lòng nhập Số phiếu thu"
    if (!formValues.MaCan) newErrors.MaCan = "Vui lòng nhập Mã căn"
    if (!formValues.LoaiThuChi) newErrors.LoaiThuChi = "Vui lòng nhập Loại thu chi"
    if (!formValues.HinhThucTT) newErrors.HinhThucTT = "Vui lòng nhập Hình thức TT"
    if (!formValues.GhiChu) newErrors.GhiChu = "Vui lòng nhập Ghi chú"
    if (!formValues.NguoiNop) newErrors.NguoiNop = "Vui lòng nhập Người nộp"
    if (!formValues.Email) newErrors.Email = "Vui lòng nhập Email"
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (validateForm()) {
      handleSave()
    }
  }

  const columns = [
    {
      title: "Xóa",
      dataIndex: "Xoa",
      key: "Xoa",
      render: (_, record) => (
        <Button onClick={() => handleDelete(record.key)}>Xóa</Button>
      ),
    },
    {
      title: "Chọn sản phẩm",
      dataIndex: "ChonSanPham",
      key: "ChonSanPham",
      editable: true,
    },
    {
      title: "Đợt TT",
      dataIndex: "DotTT",
      key: "DotTT",
      editable: true,
    },
    {
      title: "Loại thu",
      dataIndex: "LoaiThu",
      key: "LoaiThu",
      editable: true,
    },
    {
      title: "Ngày thu chi",
      dataIndex: "NgayThuChi",
      key: "NgayThuChi",
      editable: true,
    },
    {
      title: "CK thanh toán",
      dataIndex: "CKThanhToan",
      key: "CKThanhToan",
      editable: true,
    },
    {
      title: "Chọn gia dịch",
      dataIndex: "ChonGiaoDich",
      key: "ChonGiaoDich",
      editable: true,
    },
    {
      title: "Kho phiếu thu",
      dataIndex: "KhoPhieuThu",
      key: "KhoPhieuThu",
      editable: true,
    },
    {
      title: "Số giao dịch",
      dataIndex: "SoGiaoDich",
      key: "SoGiaoDich",
      editable: true,
    },
    {
      title: "Số phiếu thu",
      dataIndex: "SoPhieuThu",
      key: "SoPhieuThu",
      editable: true,
    },
    {
      title: "Mã căn",
      dataIndex: "MaCan",
      key: "MaCan",
      editable: true,
    },
    {
      title: "Loại thu chi",
      dataIndex: "LoaiThuChi",
      key: "LoaiThuChi",
      editable: true,
    },
    {
      title: "Hình thức TT",
      dataIndex: "HinhThucTT",
      key: "HinhThucTT",
      editable: true,
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
      editable: true,
    },
    {
      title: "Người nộp",
      dataIndex: "NguoiNop",
      key: "NguoiNop",
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      editable: true,
    },
  ]

  const mergedColumns = columns.map(col => ({
    ...col,
    onCell: record => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave: handleSave,
    }),
  }))

  return (
    <Tabs defaultActiveKey="1">
      <TabPane key="1">
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          Thêm dòng mới
        </Button>
        <Table
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ x: 2000 }}
        />
        <Drawer
          title="Thêm dòng mới"
          placement="right"
          closable={false}
          onClose={handleCancel}
          visible={drawerVisible}
          width="60%"
          footer={
            <div style={{ textAlign: "right" }}>
              <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                Hủy
              </Button>
              <Button onClick={handleSubmit} type="primary">
                Lưu
              </Button>
            </div>
          }
        >
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <InputCustom
                label="Chọn SP"
                applyChange={applyChange}
                prop={"ChonSanPham"}
                value={formValues.ChonSanPham}
                placeholder={"Nhập Chọn sản phẩm"}
              />
              <p style={{ color: "red" }}>{errors.ChonSanPham}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Đợt TT"
                applyChange={applyChange}
                prop={"DotTT"}
                value={formValues.DotTT}
                placeholder={"Nhập Đợt TT"}
              />
              <p style={{ color: "red" }}>{errors.DotTT}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Loại thu"
                applyChange={applyChange}
                prop={"LoaiThu"}
                value={formValues.LoaiThu}
                placeholder={"Nhập Loại thu"}
              />
              <p style={{ color: "red" }}>{errors.LoaiThu}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Ngày thu chi"
                applyChange={applyChange}
                prop={"NgayThuChi"}
                value={formValues.NgayThuChi}
                placeholder={"Nhập Ngày thu chi"}
              />
              <p style={{ color: "red" }}>{errors.NgayThuChi}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="CK thanh toán"
                applyChange={applyChange}
                prop={"CKThanhToan"}
                value={formValues.CKThanhToan}
                placeholder={"Nhập CK thanh toán"}
              />
              <p style={{ color: "red" }}>{errors.CKThanhToan}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Chọn gia dịch"
                applyChange={applyChange}
                prop={"ChonGiaoDich"}
                value={formValues.ChonGiaoDich}
                placeholder={"Nhập Chọn gia dịch"}
              />
              <p style={{ color: "red" }}>{errors.ChonGiaoDich}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Kho phiếu thu"
                applyChange={applyChange}
                prop={"KhoPhieuThu"}
                value={formValues.KhoPhieuThu}
                placeholder={"Nhập Kho phiếu thu"}
              />
              <p style={{ color: "red" }}>{errors.KhoPhieuThu}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Số giao dịch"
                applyChange={applyChange}
                prop={"SoGiaoDich"}
                value={formValues.SoGiaoDich}
                placeholder={"Nhập Số giao dịch"}
              />
              <p style={{ color: "red" }}>{errors.SoGiaoDich}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Số phiếu thu"
                applyChange={applyChange}
                prop={"SoPhieuThu"}
                value={formValues.SoPhieuThu}
                placeholder={"Nhập Số phiếu thu"}
              />
              <p style={{ color: "red" }}>{errors.SoPhieuThu}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Mã căn"
                applyChange={applyChange}
                prop={"MaCan"}
                value={formValues.MaCan}
                placeholder={"Nhập Mã căn"}
              />
              <p style={{ color: "red" }}>{errors.MaCan}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Loại thu chi"
                applyChange={applyChange}
                prop={"LoaiThuChi"}
                value={formValues.LoaiThuChi}
                placeholder={"Nhập Loại thu chi"}
              />
              <p style={{ color: "red" }}>{errors.LoaiThuChi}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Hình thức TT"
                applyChange={applyChange}
                prop={"HinhThucTT"}
                value={formValues.HinhThucTT}
                placeholder={"Nhập Hình thức TT"}
              />
              <p style={{ color: "red" }}>{errors.HinhThucTT}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Ghi chú"
                applyChange={applyChange}
                prop={"GhiChu"}
                value={formValues.GhiChu}
                placeholder={"Nhập Ghi chú"}
              />
              <p style={{ color: "red" }}>{errors.GhiChu}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Người nộp"
                applyChange={applyChange}
                prop={"NguoiNop"}
                value={formValues.NguoiNop}
                placeholder={"Nhập Người nộp"}
              />
              <p style={{ color: "red" }}>{errors.NguoiNop}</p>
            </Col>
            <Col span={8}>
              <InputCustom
                label="Email"
                applyChange={applyChange}
                prop={"Email"}
                value={formValues.Email}
                placeholder={"Nhập Email"}
              />
              <p style={{ color: "red" }}>{errors.Email}</p>
            </Col>
          </Row>
        </Drawer>
      </TabPane>
    </Tabs>
  )
}

export default ChildrenFormAdd
