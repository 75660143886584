import { call, put, takeEvery } from "redux-saga/effects"
// Staff Redux States
import * as types from "./actionTypes"
import { toast } from "react-toastify"
import { deleteCustomerSuccess } from "./actions"
//Include Both Helper File with needed methods
import {
  getForSaleAPI,
  // getLoaiBDSAPI,
  getDuongAPI,
  getLoaiDuongAPI,
  getPhuongHuongAPI,
  getNguonGocAPI,
  // getTrangThaiAPI,
  getPhapLyAPI,
  getQuyHoachAPI,
  getStreetAPI,
  searchCustomerAPI,
  addEditForSaleAPI,
  addCustomerForSaleAPI,
  deleteForSaleAPI,
  getKhoangGiaAPI,
  getKhoangDTAPI,
  getDuAnAPI,
  getImageForsaleAPI,
  addImageForsaleAPI,
} from "./service"
import { getAddressAPI } from "../customer/service"

// for sale
function* getForSale(action) {
  try {
    const response = yield call(getForSaleAPI, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.GET_FORSALE_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* deleteForSale(action) {
  try {
    const response = yield call(deleteForSaleAPI, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.DELETE_FORSALE_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* getCategoriesForsale() {
  try {
    // const response1 = yield call(getLoaiBDSAPI)
    const response2 = yield call(getLoaiDuongAPI)
    const response3 = yield call(getPhuongHuongAPI)
    const response4 = yield call(getNguonGocAPI)
    // const response5 = yield call(getTrangThaiAPI)
    const response6 = yield call(getPhapLyAPI)
    const response7 = yield call(getQuyHoachAPI)
    const response8 = yield call(getAddressAPI, {
      TenCTDKVT: localStorage.getItem("TenCTDKVT"),
    })
    if (response2.status === 2000) {
      yield put({
        type: types.GET_CATEGORIES_FORSALE_SUCCESS,
        payload: {
          // loaiBDS: response1.data,
          loaiDuong: response2.data,
          phuongHuong: response3.data,
          nguonGoc: response4.data,
          // trangThai: response5.data,
          phapLy: response6.data,
          quyHoach: response7.data,
          address: response8.data,
        },
      })
    }
  } catch (error) {}
}
function* getStreet(action) {
  try {
    const response = yield call(getStreetAPI, action.payload)
    if (response.status === 2000) {
      yield put({ type: types.GET_STREET_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
// function* searChCustomer(action) {
//   try {
//     const response = yield call(searchCustomerAPI, action.payload)
//     if (response.status === 2000) {
//       yield put({
//         type: types.SEARCH_CUSTOMERS_FORSALE_SUCCESS,
//         payload: response.data,
//       })
//     }
//   } catch (error) {}
// }
function* addEditForSale(action) {
  try {
    const response = yield call(addCustomerForSaleAPI, {
      TenKH: action.payload.data.TenKH,
      DiDong: action.payload.data.DiDong,
      DiaChi: action.payload.data.DiaChi,
      Email: action.payload.data.Email,
    })
    if (response.status === 2000) {
      const response2 = yield call(addEditForSaleAPI, {
        ...action.payload.data,
        MaKH: Number(response.data),
      })

      if (response2.status === 2000) {
        yield put({
          type: types.ADD_EDIT_FORSALE_SUCCESS,
          payload: response.data,
        })
        action.payload.history.push("/forsale")
      }
    }
  } catch (error) {}
}
function* getKhoangGia() {
  try {
    const response = yield call(getKhoangGiaAPI)
    if (response.status === 2000) {
      yield put({ type: types.GET_KHOANGGIA_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* getKhoangDT() {
  try {
    const response = yield call(getKhoangDTAPI)
    if (response.status === 2000) {
      yield put({ type: types.GET_KHOANGDT_SUCCESS, payload: response.data })
    }
  } catch (error) {}
}
function* getLoaiBDS() {
  try {
    // const response = yield call(getLoaiBDSAPI)
    // if (response.status === 2000) {
    //   yield put({ type: types.GET_LOAIBDS_SUCCESS, payload: response.data })
    // }
  } catch (error) {}
}
function* getTrangThaiForSale() {
  try {
    // const response = yield call(getTrangThaiAPI)
    // if (response.status === 2000) {
    //   yield put({
    //     type: types.GET_TRANGTHAI_FORSALE_SUCCESS,
    //     payload: response.data,
    //   })
    // }
  } catch (error) {}
}
function* getDuAn() {
  try {
    const response = yield call(getDuAnAPI, {
      TenCTDKVT: localStorage.getItem("TenCTDKVT"),
    })
    if (response.status === 2000) {
      yield put({
        type: types.GET_DUAN_SUCCESS,
        payload: response.data,
      })
    }
  } catch (error) {}
}
function* getImageForsale(action) {
  try {
    const response = yield call(getImageForsaleAPI, action.payload)
    if (response.status === 2000) {
      yield put({
        type: types.GET_IMAGE_FORSALE_SUCCESS,
        payload: response.data,
      })
    }
  } catch (error) {}
}
function* addImageForsale(action) {
  try {
    const response = yield call(addImageForsaleAPI, action.payload)
    if (response.status === 2000) {
      yield put({
        type: types.ADD_IMAGE_FORSALE_SUCCESS
      })
    }
  } catch (error) {}
}
export default function* agencySaga() {
  // for Sale
  yield takeEvery(types.GET_FORSALE, getForSale)
  yield takeEvery(types.GET_CATEGORIES_FORSALE, getCategoriesForsale)
  yield takeEvery(types.GET_STREET, getStreet)
  // yield takeEvery(types.SEARCH_CUSTOMERS_FORSALE, searChCustomer)
  yield takeEvery(types.ADD_EDIT_FORSALE, addEditForSale)
  yield takeEvery(types.DELETE_FORSALE, deleteForSale)

  yield takeEvery(types.GET_KHOANGDT, getKhoangDT)
  yield takeEvery(types.GET_KHOANGGIA, getKhoangGia)
  yield takeEvery(types.GET_TRANGTHAI_FORSALE, getTrangThaiForSale)
  yield takeEvery(types.GET_LOAIBDS, getLoaiBDS)
  yield takeEvery(types.GET_DUAN, getDuAn)
  yield takeEvery(types.GET_IMAGE_FORSALE, getImageForsale)
  yield takeEvery(types.ADD_IMAGE_FORSALE, addImageForsale)
}
