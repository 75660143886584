// import routes from "../components/HorizontalLayout/routes";

// const ActiveRoute = () => {
//   const getActiveRoute = (routes) => {
//     let activeRoute = "Default Brand Text";
//     for (let i = 0; i < routes?.length; i++) {
//       if (routes[i].children) {
//         let collapseActiveRoute = getActiveRoute(routes[i].children);
//         if (collapseActiveRoute !== activeRoute) {
//           return collapseActiveRoute;
//         }
//       } else {
//         if (
//           window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
//         ) {
//           return routes[i].label;
//         }
//       }
//     }
//     return activeRoute;
//   };
//   return (
//     <p
//       style={{
//         fontWeight: "bold",
//         fontSize: 15,
//         color: "coral",
//         padding: "10px 0px 5px",
//       }}
//     >
//       {getActiveRoute(routes)}
//     </p>
//   );
// };
// export default ActiveRoute;
import routes from "../components/HorizontalLayout/routes";
import React from "react";

const ActiveRoute = () => {
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    
    const findActiveRoute = (routes) => {
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].children && routes[i].children.length > 0) {
          const collapseActiveRoute = findActiveRoute(routes[i].children);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else {
          const fullPath = (routes[i].layout || '') + routes[i].path;
          if (window.location.href.indexOf(fullPath) !== -1) {
            return routes[i].label;
          }
        }
      }
      return activeRoute;
    };

    return findActiveRoute(routes);
  };

  return (
    <p
      style={{
        fontWeight: "bold",
        fontSize: 15,
        color: "coral",
        padding: "10px 0px 5px",
      }}
    >
      {getActiveRoute(routes)}
    </p>
  );
};

export default ActiveRoute;
