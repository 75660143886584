import React from 'react'

const ChiTietSoQuy = () => {
  return (
    <div className='page-content'>
      <h2>Chi tiet so quy</h2>
    </div>
  )
}

export default ChiTietSoQuy
