export const GET_TINTUC = "GET_TINTUC";
export const GET_TINTUC_SUCCESS = "GET_TINTUC_SUCCESS"

export const ADD_TINTUC = "ADD_TINTUC"
export const ADD_TINTUC_SUCCESS = "ADD_TINTUC_SUCCESS"

export const DELETE_TINTUC = "DELETE_TINTUC"
export const DELETE_TINTUC_SUCCESS = "DELETE_TINTUC_SUCCESS"

export const GET_LOAI_TINTUC = "GET_LOAI_TINTUC"
export const GET_LOAI_TINTUC_SUCCESS = "GET_LOAI_TINTUC_SUCCESS"