import { del, get, post, put } from "../helpers/api_helper";
export const ProjectService = {
    filter : async (payload) => {
        payload.TenCTDKVT = localStorage.getItem("TenCTDKVT");
        return await post("/beeland/get-project", payload)
    },
    add : async (payload) => {
        return await post("/admin/Project/Add_DuAn", payload)
    },
    removeProject : async (payload) => {
        return await post("/admin/Project/Delete", payload)
    },
    getList : async (payload) => {
        payload.TenCTDKVT = localStorage.getItem("TenCTDKVT");
        return await post("/admin/Project/List_DuAn", payload)
    },
    getKhu : async (payload) => {
        return await post("/admin/project/list_khu", payload)
    },
    addKhu : async (payload) => {
        return await post("/admin/project/add-khu", payload)
    },
    removeKhu : async (payload) => {
        return await post("/admin/project/xoa-khu", payload)
    },
    getPhanKhu : async (payload) => {
        return await post("/admin/project/list_phan_khu", payload)
    },
    getProductStatus : async () => {
        return await post("/admin/san-pham/trang-thai")
    },
    getBangGia : async (payload) => {
        return await post("/admin/san-pham/DanhSachBangGiaSP",payload)
    },
    getCategoryBangGia : async (payload) => {
        return await post("/admin/san-pham/DanhMucBangGia",payload)
    },
    ChungCu: {
        filter : async (payload) => {
            return await post("/admin/san-pham/chung-cu", payload)
        },
    },
    DatNen: {
        filter : async (payload) => {
            return await post("/admin/san-pham/dat-nen", payload)
        },
    },
}
