import * as types from "./actionTypes"

const INIT_STATE = {
  forSale: [],
  loaiBDS: [],
  loaiDuong: [],
  phuongHuong: [],
  nguonGoc: [],
  trangThai: [],
  phapLy: [],
  quyHoach: [],
  address: [],
  street: [],
  isDone: false,
  dataEdit: null,
  customerSearch: [],
  khoangGia: [],
  khoangDT: [],
  duAn:[],
  isLoadFilter:false,
  images:[],
  isDoneUpload: false,
}

const Agency = (state = INIT_STATE, action) => {
  switch (action.type) {
    // for sale
    case types.GET_FORSALE:
      return {
        ...state,
        isLoadFilter: true,
      }
    case types.GET_FORSALE_SUCCESS:
      return {
        ...state,
        forSale: action.payload,
        isLoadFilter: false,
      }
    case types.EDIT_FORSALE:
      return {
        ...state,
        dataEdit: action.payload,
      }
    case types.SEARCH_CUSTOMERS_FORSALE_SUCCESS:
      return {
        ...state,
        customerSearch: action.payload,
      }
    case types.DELETE_FORSALE:
      return {
        ...state,
        isDone: false,
      }
    case types.DELETE_FORSALE_SUCCESS:
      return {
        ...state,
        isDone: true,
      }
    case types.GET_CATEGORIES_FORSALE_SUCCESS:
      return {
        ...state,
        loaiBDS: action.payload.loaiBDS,
        loaiDuong: action.payload.loaiDuong,
        phuongHuong: action.payload.phuongHuong,
        nguonGoc: action.payload.nguonGoc,
        trangThai: action.payload.trangThai,
        phapLy: action.payload.phapLy,
        quyHoach: action.payload.quyHoach,
        address: action.payload.address,
      }
    case types.GET_STREET_SUCCESS:
      return {
        ...state,
        street: action.payload,
      }
    case types.GET_LOAIBDS_SUCCESS:
      return {
        ...state,
        loaiBDS: action.payload,
      }
    case types.GET_KHOANGDT_SUCCESS:
      return {
        ...state,
        khoangDT: action.payload,
      }
    case types.GET_KHOANGGIA_SUCCESS:
      return {
        ...state,
        khoangGia: action.payload,
      }
    case types.GET_TRANGTHAI_FORSALE_SUCCESS:
      return {
        ...state,
        trangThai: action.payload,
      }
    case types.GET_DUAN_SUCCESS:
      return {
        ...state,
        duAn: action.payload,
      }
    case types.GET_IMAGE_FORSALE:
     
      return {
        ...state,
        isDoneUpload: false,
      }
    case types.GET_IMAGE_FORSALE_SUCCESS:
      // let newIM = action.payload.map(item => {
      //   return {
      //     original: item.duongDan,
      //     thumbnail: item.duongDan,
      //   }
      // })
      return {
        ...state,
        images: action.payload,
      }
    case types.ADD_IMAGE_FORSALE:
      return {
        ...state,
        isDoneUpload: false,
      }
    case types.ADD_IMAGE_FORSALE_SUCCESS:
      return {
        ...state,
        isDoneUpload: true,
      }
    default:
      return state
  }
}

export default Agency
