import axios from "axios"
import { del, get, post, put } from "../../helpers/api_helper"

// add Events
export const getStaffs = payload => post('/admin/staffs',payload)
export const getStaff = payload => post("/admin/Staff/ViewChiTiet", payload)

export const deleteStaffAPI = payload => post("/admin/DelteteStaff", payload)
export const editStaffAPI = payload => post("/admin/EditStaff", payload)
export const addStaffAPI = payload => post("/admin/AddStaff", payload)
export const getCompanyAPI = payload => post("/admin/Company", payload)
export const getPhongBanAPI = () => post("/admin/PhongBan")
export const getChucVuAPI = () => post("/admin/ChucVu")
export const getNhomKDAPI = () => post("/admin/NhomKD")
export const getNhomQuyenAPI = () => post("/admin/NhomQuyen")

export const getMenuAPI = () => post("/admin/FormView")