import axios from "axios"

export const API_UPLOAD_URL = "https://upload.beesky.vn/"
const axiosApi = headers => {
  return axios.create({
    baseURL: API_UPLOAD_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
  })
}
export default axiosApi
export const UploadService = {
  upload: async payload => {
    return await axiosApi({
      "Content-Type": "multipart/form-data",
    })
      .post("api/Upload", payload)
      .then(res => res.data)
  },
  customUpload: async options => {
    const { file, onSuccess, onError, onProgress } = options
    const formData = new FormData()
    formData.append("Image", file)
    formData.append("TenCTDK", localStorage.getItem("MaCTDK"))
    try {
      const response = await axiosApi({
        "Content-Type": "multipart/form-data",
      }).post("api/Upload", formData)
      console.log(response)
      if (response.data.status === 2000) {
        onSuccess(response.data, file)
      } else {
        onError(new Error("Upload failed"))
      }
    } catch (error) {
      onError(new Error("Upload failed"))
    }
  },
}
