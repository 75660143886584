
/* ORDERS */

export const UPLOAD_IMAGE = "UPLOAD_IMAGE"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"


export const GET_KHU = "GET_KHU"
export const GET_KHU_SUCCESS = "GET_KHU_SUCCESS"

export const GET_KHU_BY_LISTDA = "GET_KHU_BY_LISTDA"
export const GET_KHU_BY_LISTDA_SUCCESS = "GET_KHU_BY_LISTDA_SUCCESS"

export const GET_PHANKHU = "GET_PHANKHU"
export const GET_PHANKHU_SUCCESS = "GET_PHANKHU_SUCCESS"


export const GET_BDS_TRANGTHAI = "GET_BDS_TRANGTHAI"
export const GET_BDS_TRANGTHAI_SUCCESS = "GET_BDS_TRANGTHAI_SUCCESS"


export const GET_SANGIAODICH = "GET_SANGIAODICH"
export const GET_SANGIAODICH_SUCCESS = "GET_SANGIAODICH_SUCCESS"

